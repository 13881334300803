import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { Subscription } from 'rxjs';

import { AuthService } from '../../../../services/auth/auth.service';

import { Client } from '../../../../models/client';

import { ButtonModule } from 'primeng/button';
import { MultiSelectModule } from 'primeng/multiselect';
import { Table, TableModule } from 'primeng/table';
import { ApiTablesService } from '../../../../services/api/api-tables.service';
import { CustomTable } from '../../../../models/custom-table';
import { Router } from '@angular/router';
import { PanelModule } from 'primeng/panel';

@Component({
  selector: 'app-tables-list',
  templateUrl: './tables-list.component.html',
  styleUrl: './tables-list.component.css',
  standalone: true,
  imports: [ButtonModule, CommonModule, FormsModule, MultiSelectModule, PanelModule, TableModule, TranslocoModule],
})
export class TablesListComponent implements OnInit, OnDestroy {
  constructor(
    private apiTablesService: ApiTablesService,
    private authService: AuthService,
    private router: Router
  ) {}
  tablesSubscription: Subscription = new Subscription();
  customTables: CustomTable[] = [];
  licences : Client[] = [];
  dataLoaded: boolean = false;

  @ViewChild('dt') dt: Table | null= null;

  
  ngOnInit() {
    this.tablesSubscription = this.apiTablesService
      .getTables()
      .subscribe((data: CustomTable[]) => {
        this.customTables = data;
        this.dataLoaded = true;
      });
    this.licences = this.authService.getUserLicences();
  }
  canWrite(): boolean {
    return this.authService.canWrite();
  }
  addCustomTable() {
    this.router.navigate(['settings/tables-add']);
  }
  redirectDatas(oTable: CustomTable) {
    this.router.navigate(['settings/tables-datas', oTable.id]);
  }
  ngOnDestroy() {
    this.tablesSubscription.unsubscribe();
  }
}
