import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';

import { TranslocoModule, TranslocoService } from '@ngneat/transloco';

import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { ChartModule } from 'primeng/chart';
import { InputNumberModule } from 'primeng/inputnumber';
import { PanelModule } from 'primeng/panel';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';

import {  Subscription } from 'rxjs';

import { ApiEshopService } from '../../../services/api/api-eshop.service';
import { AuthService } from '../../../services/auth/auth.service';

@Component({
  selector: 'app-eshop-stats',
  templateUrl: './eshop-stats.component.html',
  styleUrl: './eshop-stats.component.css',
  standalone: true,
  imports: [
    CalendarModule,
    ChartModule,
    ButtonModule,
    CardModule,
    CommonModule,
    FormsModule,
    InputNumberModule,
    PanelModule,
    ReactiveFormsModule,
    TableModule,
    TabViewModule,
    TranslocoModule,
  ],
})
export class EshopStatsComponent implements OnDestroy, OnInit {
  statsSubscription: Subscription = new Subscription();
  dataLoaded: boolean = false;
  searchForm: FormGroup;
  
  @ViewChild('chartOrders') chartRefOrders!: any;
  @ViewChild('chartOrdersCount') chartRefOrdersCount!: any;
  @ViewChild('chartCategories') chartRefCategories!: any;
  @ViewChild('chartCategoriesCount') chartRefCategoriesCount!: any;

  documentStyle = getComputedStyle(document.documentElement);

  periods: Array<string> = ['JOUR', 'SEMAINE', 'MOIS', 'ANNEE', 'PERIODE'];
  
  datasOrders: any = {
    labels: this.periods,
    datasets: [
      {
        label:this.translateService.translate('Total HT'),
        data: [],
        backgroundColor:this.documentStyle.getPropertyValue('--couleur-orange')
      }
    ],
  };

  configOrders =  {
    plugins: {
      title: {
        display: true,
        text: this.translateService.translate('TOTAL HT'),
      },
      legend: {
        display: false,
        position: 'bottom'
      }
    }
  };

  datasOrdersCount: any = {
    labels: this.periods,
    datasets: [
      {
        label:this.translateService.translate('Nombre'),
        data: [],
        backgroundColor:this.documentStyle.getPropertyValue('--couleur-bleu-roi')
      }
    ],
  };

  configOrdersCount =  {
      plugins: {
        title: {
          display: true,
          text: this.translateService.translate('NOMBRE DE COMMANDES'),
        },
        legend: {
          display: false,
          position: 'bottom'
        }
      },
      scales: {
        y: {
          ticks: {
            stepSize: 1
          }
        }
      }
    };
  
  datasetsCategories : any = {
    'Matériels': 0,
    'Consommables': 1,
    'Formations': 2
  }
  datasCategories: any = {
    labels: this.periods,
    datasets: [
      {
        label:'Matériels',
        data: [0, 0, 0, 0, 0],
        backgroundColor:this.documentStyle.getPropertyValue('--couleur-orange')
      },
      {
        label:'Consommables',
        data: [0, 0, 0, 0, 0],
        backgroundColor:this.documentStyle.getPropertyValue('--couleur-gris')
      },
      {
        label:'Formations',
        data: [0, 0, 0, 0, 0],
        backgroundColor:this.documentStyle.getPropertyValue('--couleur-bleu-roi')
      }
    ],
  };
  configCategories =  {
    plugins: {
      legend: {
        position: 'bottom'
      },
      title: {
        display: true,
        text: this.translateService.translate('TOTAL HT'),
      }
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true
      }
    }
  };

  
  datasCategoriesCount: any = {
    labels: this.periods,
    datasets: [
      {
        label:'Matériels',
        data: [0, 0, 0, 0, 0],
        backgroundColor:this.documentStyle.getPropertyValue('--couleur-orange')
      },
      {
        label:'Consommables',
        data: [0, 0, 0, 0, 0],
        backgroundColor:this.documentStyle.getPropertyValue('--couleur-gris')
      },
      {
        label:'Formations',
        data: [0, 0, 0, 0, 0],
        backgroundColor:this.documentStyle.getPropertyValue('--couleur-bleu-roi')
      }
    ],
  };
  configCategoriesCount =  {
    plugins: {
      legend: {
        position: 'bottom'
      },
      title: {
        display: true,
        text: this.translateService.translate('NOMBRE DE PRODUITS'),
      }
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true
      }
    }
  };

  dataProducts: any = [];


  constructor(
    private apiEshopService: ApiEshopService,
    private authService: AuthService,
    private cd: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private translateService : TranslocoService
  ) {
    let tmpDateStart = new Date();
    tmpDateStart.setDate(tmpDateStart.getDate() - 30);

    this.searchForm = this.formBuilder.group({
      periodStart: [tmpDateStart, Validators.required],
      periodEnd: [new Date(), Validators.required],
    });
  }
  ngOnInit(): void {
    this.loadStats();
  }

  ngOnDestroy(): void {
    if (this.statsSubscription) {
      this.statsSubscription.unsubscribe();
    }
  }
  isRoot(): boolean {
    return this.authService.isRoot();
  }

  clearGraphData() {
    this.datasCategories.datasets.forEach((dataset: any) => {
      dataset.data = [];
    });
    this.datasCategoriesCount.datasets.forEach((dataset: any) => {
      dataset.data = [];
    });
    this.datasOrders.datasets.forEach((dataset: any) => {
      dataset.data = [];
    });
    this.datasOrdersCount.datasets.forEach((dataset: any) => {
      dataset.data = [];
    });
  }

  refreshCharts() {
    this.chartRefCategories.refresh();
    this.chartRefCategoriesCount.refresh();
    this.chartRefOrders.refresh();
    this.chartRefOrdersCount.refresh();
  }

  loadStats() {
    if(this.isRoot()) {
      if (
        this.searchForm.controls['periodStart'].value >
        this.searchForm.controls['periodEnd'].value
      ) {
        let tmpDate: Date = this.searchForm.controls['periodStart'].value;
        tmpDate.setDate(tmpDate.getDate() + 1);
        this.searchForm.controls['periodEnd'].setValue(tmpDate);
      }
      this.dataLoaded = true;

      this.clearGraphData();

      this.statsSubscription = this.apiEshopService
        .getStats(
          this.searchForm.controls['periodStart'].value,
          this.searchForm.controls['periodEnd'].value
        )
        .subscribe((statsList: any) => {
          // données commandes
          this.datasOrders.datasets[0].data.push(statsList.ventes.jour.total_ht);
          this.datasOrders.datasets[0].data.push(statsList.ventes.semaine.total_ht);
          this.datasOrders.datasets[0].data.push(statsList.ventes.mois.total_ht);
          this.datasOrders.datasets[0].data.push(statsList.ventes.annee.total_ht);
          this.datasOrders.datasets[0].data.push(statsList.ventes.periode.total_ht);
          
          this.datasOrdersCount.datasets[0].data.push(statsList.ventes.jour.order_count);
          this.datasOrdersCount.datasets[0].data.push(statsList.ventes.semaine.order_count);
          this.datasOrdersCount.datasets[0].data.push(statsList.ventes.mois.order_count);
          this.datasOrdersCount.datasets[0].data.push(statsList.ventes.annee.order_count);
          this.datasOrdersCount.datasets[0].data.push(statsList.ventes.periode.order_count);

          //données catégories
          statsList.categories.jour.forEach((oneCategory :any) => {
            this.datasCategories.datasets[this.datasetsCategories[oneCategory.eca_title]].data[0] = oneCategory.total_ht;
            this.datasCategoriesCount.datasets[this.datasetsCategories[oneCategory.eca_title]].data[0] = oneCategory.product_count;
          });
          statsList.categories.semaine.forEach((oneCategory :any) => {
            this.datasCategories.datasets[this.datasetsCategories[oneCategory.eca_title]].data[1] = oneCategory.total_ht;
            this.datasCategoriesCount.datasets[this.datasetsCategories[oneCategory.eca_title]].data[1] = oneCategory.product_count;
          });
          statsList.categories.mois.forEach((oneCategory :any) => {
            this.datasCategories.datasets[this.datasetsCategories[oneCategory.eca_title]].data[2] = oneCategory.total_ht;
            this.datasCategoriesCount.datasets[this.datasetsCategories[oneCategory.eca_title]].data[2] = oneCategory.product_count;
          });
          statsList.categories.annee.forEach((oneCategory :any) => {
            this.datasCategories.datasets[this.datasetsCategories[oneCategory.eca_title]].data[3] = oneCategory.total_ht;
            this.datasCategoriesCount.datasets[this.datasetsCategories[oneCategory.eca_title]].data[3] = oneCategory.product_count;
          });
          statsList.categories.periode.forEach((oneCategory :any) => {
            this.datasCategories.datasets[this.datasetsCategories[oneCategory.eca_title]].data[4] = oneCategory.total_ht;
            this.datasCategoriesCount.datasets[this.datasetsCategories[oneCategory.eca_title]].data[4] = oneCategory.product_count;
          });

          this.dataProducts = statsList.produits;
          this.cd.detectChanges();

          this.refreshCharts();
        });
      }
  }
}
