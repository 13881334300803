import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { Router } from '@angular/router';

import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';

import { Subscription } from 'rxjs';
import { ApiIndicatorsService } from '../../../../../services/api/api-indicators.service';
import { Indicator } from '../../../../../models/indicator';
import { ConfirmationService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { PanelModule } from 'primeng/panel';

@Component({
  selector: 'app-indicators-list',
  templateUrl: './indicators-list.component.html',
  styleUrl: './indicators-list.component.css',
  standalone: true,
  imports: [ButtonModule, PanelModule, TableModule, TranslocoModule, ConfirmDialogModule],
  providers: [ConfirmationService],
})
export class IndicatorsListComponent implements OnInit, OnDestroy {
  indicators: Indicator[] = [];
  dataLoaded: boolean = false;
  indicSubscription: Subscription = new Subscription();

  constructor(
    private apiIndicatorService: ApiIndicatorsService,
    private confirmationService: ConfirmationService,
    private router: Router,
    private translocoService: TranslocoService
  ) {}

  ngOnInit() {
    this.indicSubscription = this.apiIndicatorService
      .getIndicators()
      .subscribe((data: Indicator[]) => {
        this.indicators = data;
        this.dataLoaded = true;
      });
  }
  ngOnDestroy() {
    this.indicSubscription.unsubscribe();
  }

  addIndicator() {
    this.router.navigate(['admin/indicators-add']);
  }

  editIndicator(indicData: Indicator) {
    this.router.navigate(['admin/indicators-edit', indicData.id]);
  }

  usersIndicator(indicData: Indicator) {
    this.router.navigate(['admin/indicators-users', indicData.id]);
  }

  statsIndicator(indicData: Indicator) {
    this.router.navigate(['admin/indicators-stats', indicData.id]);
  }
  
  deleteIndicator(indicData: Indicator) {
    this.confirmationService.confirm({
      message:
        this.translocoService.translate(
          'Confirmez-vous la suppression de l\'indicateur'
        ) +
        ' "' +
        indicData.label +
        '" ?',
      header: 'Suppression',
      acceptLabel: this.translocoService.translate('Oui'),
      rejectLabel: this.translocoService.translate('Non'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.apiIndicatorService
          .deleteIndicator(indicData.id)
          .subscribe((response: any) => {
            if (response) {
              this.indicators = this.indicators.filter(
                (val: Indicator) => val.id !== indicData.id
              );
            }
          });
      },
    });
  }
}
