import { EshopProduct } from "./eshop-product";

export class EshopOrderProduct extends EshopProduct {
    public quantity: number;
    public totalHtPrice: number;
    public totalVAT: number;

    constructor(
        id: number,
        title: string,
        titleForUrl: string,
        description: string,
        shortDescription: string,
        photo1: string,
        photo2: string,
        photo3: string,
        htPrice: number,
        tva: number,
        categoryId: number,
        quantity: number,
        totalHtPrice: number,
        totalVAT: number
    ) {
        super(id, title, titleForUrl, description, shortDescription, photo1, photo2, photo3, htPrice, tva, categoryId);

        // Initialiser les nouveaux champs
        this.quantity = quantity;
        this.totalHtPrice = totalHtPrice;
        this.totalVAT = totalVAT;
    }

}
