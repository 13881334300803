import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import {ProductLS} from "../../models/productLS";
import moment from "moment";
import {Client} from "../../models/client";

@Injectable({
  providedIn: 'root',
})
export class ApiLsproductsService {

  constructor(private http: HttpClient) {}

  getProductLS(first:number,  end: number, cli_id?: number, libelle?: string, eancode?: number, status?: number, marque?: string,): Observable<any[]> {
    return this.http
      .get<any[]>(environment.apiURL +
        'espace_client/get_products_ls.php?first=' + first +
        '&end=' + end +
        '&lcp_cli_id=' + cli_id +
        '&lpr_libelle=' + libelle +
        '&lpr_ean=' + eancode +
        '&status=' + status +
        '&marque=' + marque)
      .pipe(
        map((response: any[]) => {
          return response.map((dataProductLS) => {
            return new ProductLS(
              parseInt(dataProductLS.lpr_id),
              dataProductLS.lpr_libelle,
              dataProductLS.lpr_marque,
              parseInt(dataProductLS.lpr_ean),
              dataProductLS.lpr_lang,
            dataProductLS.lpr_create_date,
              dataProductLS.lst_name,
              dataProductLS.lcp_cli_id,
              new Client(
                parseInt(dataProductLS.lcp_cli_id),
                dataProductLS.cli_name
              )
            )
          })
        }),
        catchError((error) => {
          return of([]);
        })
      );
  }

  updateProductLs(rowData: any): Observable<any> {
    const body = new URLSearchParams();
    for (const key in rowData) {
      if (rowData.hasOwnProperty(key)) {
        body.set(key, rowData[key]);
      }
    }
    return this.http.post<any>(
      environment.apiURL + 'espace_client/post_update_product_ls.php',
      body.toString(),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
    );
  }


  delOrTransfert(rowData: any): Observable<any> {
    const body = new URLSearchParams();
    for (const key in rowData) {
      if (rowData.hasOwnProperty(key)) {
        body.set(key, rowData[key]);
      }
    }
    return this.http.post<any>(
      environment.apiURL + 'espace_client/post_ls_products_del_transfert.php',
      body.toString(),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
    );
  }

}
