import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';

import { TranslocoModule } from '@ngneat/transloco';

import { ButtonModule } from 'primeng/button';
import { PanelModule } from 'primeng/panel';

import { Subscription } from 'rxjs';

import { CartService } from '../../../services/eshop/cart.service';
import { OrderService } from '../../../services/eshop/order.service';
import { PaymentService } from '../../../services/eshop/payment.service';

@Component({
  selector: 'app-eshop-payment-success',
  templateUrl: './eshop-payment-success.component.html',
  styleUrl: './eshop-payment-success.component.css',
  standalone: true,
  imports: [
    ButtonModule,
    CommonModule,
    PanelModule,
    TranslocoModule,
  ]
})

export class EshopPaymentSuccessComponent implements OnInit {
  
  productSubscription: Subscription = new Subscription();
  cartSubscription: Subscription = new Subscription()
  cartProducts: any[] = [];
  dataLoaded: boolean = false;
  cartTotalPrice: number = 0;
  cartTotalTax: number = 0;
  orderInfos: any = [];
  paymentInfos: any;

  constructor(
    private cartService: CartService,
    public orderService: OrderService,
    public paymentService: PaymentService,
  ) {}

  ngOnInit(): void {
    
    this.orderInfos = this.orderService.getOrderInfos();
    this.paymentInfos = this.paymentService.getPaymentInfos();

    this.dataLoaded = true;

    if(this.orderInfos.modePaiement == this.orderService.MODE_CARTE_BANCAIRE) {
        //on vide le panier
        this.cartService.clearCart();      

        //les infos de paiement
        this.paymentService.clear();

        //et une partie des infos de commande
        this.orderService.clear()
    }
  }
}
