import {Component, OnInit, ViewEncapsulation, ChangeDetectorRef} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {ActivatedRoute} from "@angular/router";
import {EditComponent} from "../../../core/edit/edit.component";
import {TranslocoModule, TranslocoService} from '@ngneat/transloco';
import {ConfirmationService, MessageService, SortEvent} from 'primeng/api';

import {ButtonModule} from "primeng/button";
import {ConfirmDialogModule} from "primeng/confirmdialog";
import {DropdownModule} from "primeng/dropdown";
import {ToastModule} from "primeng/toast";
import {PanelModule} from "primeng/panel";
import {InputNumberModule} from "primeng/inputnumber";
import {CalendarModule} from "primeng/calendar";
import {ToggleButtonModule } from 'primeng/togglebutton';

import {Client } from '../../../../models/client';
import {AuthService } from '../../../../services/auth/auth.service';
import {MultiSelectModule} from "primeng/multiselect";
import {ApiLsproductsService} from "../../../../services/api/api-ls-products";
import {ProductLS} from "../../../../models/productLS";
import {TableLazyLoadEvent, TableModule} from "primeng/table";
import {DialogModule} from "primeng/dialog";

@Component({
  selector: 'app-ls-products-list',
  templateUrl: './ls-products-list.component.html',
  styleUrls: ['./ls-products-list.component.css'],
  standalone: true,
  imports: [
    ButtonModule,
    CommonModule,
    FormsModule,
    ConfirmDialogModule,
    TranslocoModule,
    DropdownModule,
    ReactiveFormsModule,
    ToastModule,
    PanelModule,
    InputNumberModule,
    CalendarModule,
    ToggleButtonModule,
    MultiSelectModule,
    TableModule,
    DialogModule,
  ],
  providers: [
    { provide: MessageService, useClass: MessageService },
    { provide: ConfirmationService, useClass: ConfirmationService },
  ],
  encapsulation: ViewEncapsulation.None,
})

export class LsProductsListComponent  extends EditComponent implements OnInit {

  ProductLSSubscription: Subscription = new Subscription();

  licences: Client[] = [];
  productLS: ProductLS[] = [];
  selectedproductsLS = [];

  eanLength = 0;
  totalRecords: number = 50;
  isLoading = false;
  first = 0;
  rows = 50;
  dt: any;

  filters: any = {}; // 🔥 Stocke les filtres appliqués
  filteredProductLS: ProductLS[] = [];

  sortField: string | undefined = '';   // 🔥 Stocke le champ trié
  sortOrder: number | undefined = 1;

  originalProductLS: ProductLS[] = [];
  cli_id_selected = 0;
  lpr_libelle_selected = '';
  licenceSelected = { originalEvent: null, value: -1 };  // Au lieu de null

  eanCode = 0;

  status: { label: string, value: number }[];
  selectedStatus: number = 5;
  selectedLS: number[] = [];
  lpr_marque_selected = '';
  visible = false;
  checkable= true;

  editForm: FormGroup;
  lpr_id = 0;
  coched = 0;

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private confirmationService: ConfirmationService,
    private translocoService: TranslocoService,
    private messageService: MessageService,
    private apiLsproductsService: ApiLsproductsService,
    private cdr: ChangeDetectorRef
    ){
    super(route);
    this.status = [];

    this.editForm = this.formBuilder.group({
      libelle: ['', Validators.required],
      marque: ['', Validators.required],
      ean: [{ value: '', disabled: true }],
      statusLS: [{ value: '', disabled: true }]
    });
  }

  override ngOnInit() {
    super.ngOnInit();

    this.productLS = [];
    this.originalProductLS = [...this.productLS];
    this.loadProductLSLazy({ first: 0, rows: this.rows });
    this.licences = this.authService.getUserLicences().filter(licence => licence.isLS === 1);
    this.dataLoaded = true;

    this.status = [
      { label: 'Tous', value : 5 },
      { label: 'En vente', value : 1 },
      { label: 'En rupture', value : 2 },
      { label: 'Rupture longue', value : 3 },
      { label: 'Arrêt point de vente', value : 4 },
    ];
  }

  ngAfterViewInit() {
    setTimeout(() => {
      const tableElement = document.querySelector('.p-datatable-wrapper');
      if (tableElement) {
        tableElement.addEventListener('scroll', () => {
          const scrollPosition = tableElement.scrollTop + tableElement.clientHeight;
          const tableHeight = tableElement.scrollHeight;
          if (scrollPosition >= tableHeight - 7) {
            this.loadProductLSLazy({ first: this.first, rows: 50 } as TableLazyLoadEvent);
          }
        });
      } else {
        console.warn("⚠️ Impossible de détecter `p-table` pour le scroll.");
      }
    }, 1000);
  }

  loadProductLSLazy(event: TableLazyLoadEvent) {
    if (event.filters && event.filters['client.id']) {
      const filter = event.filters['client.id'];
      if (typeof filter === 'object' && 'value' in filter) {
        filter.value = this.cli_id_selected;
      }
    }
    if (event.filters && event.filters['lpr_libelle']) {
      const filter = event.filters['lpr_libelle'];
      if (typeof filter === 'object' && 'value' in filter) {
        filter.value = this.lpr_libelle_selected;
      }
    }

    let start = this.first;
    let rows = event.rows && event.rows > 0 ? event.rows : 50;
    let end = start + rows;
    if (this.isLoading) {
      console.warn("⏳ Requête déjà en cours, annulation.");
      return;
    }
    this.isLoading = true;
    this.ProductLSSubscription = this.apiLsproductsService.getProductLS(start, rows, this.cli_id_selected, this.lpr_libelle_selected, this.eanCode, this.selectedStatus, this.lpr_marque_selected).subscribe(
      (data: any[]) => {
        if (data.length > 0) {
          this.productLS = [...this.productLS, ...data];
          // 🔥 Correction : Augmenter `this.first`
          this.first += rows;

          if (this.totalRecords <= this.first) {
            this.totalRecords += rows;
          }
        } else {
          console.warn("⚠️ Aucun nouveau produit reçu.");
        }
        this.isLoading = false;
        this.cdr.detectChanges();
      },
      (error) => {
        console.error("❌ Erreur lors du chargement des données:", error);
        this.isLoading = false;
      }
    );
  }

  licenceCliSelected(event: any, filterName: string, eanCode: number, status?: number, marque?: string) {
    this.licenceSelected = event;
    this.checkable = false;
    if (this.eanCode == undefined) {
      this.eanCode = 0;
    }
    if (Object.keys(this.licenceSelected).length === 0) {
      console.log("⚠️ Aucun élément sélectionné, mise à 0");
      this.cli_id_selected = -1;
    } else {
      this.cli_id_selected = event.value;
    }
    let start = 0;
    let rows = event.rows && event.rows > 0 ? event.rows : 50;
    this.ProductLSSubscription = this.apiLsproductsService.getProductLS(start, rows, this.cli_id_selected, this.lpr_libelle_selected,
      this.eanCode, status, this.lpr_marque_selected).subscribe(
        (data: ProductLS[]) => {
            console.log(`✅ Réponse API reçue (${data.length} lignes)`);

            if (data.length > 0) {
                this.productLS = [...data];  // 🔥 Remplace les données existantes
                this.productLS = [...this.productLS];  // 🔥 Met à jour la table
                this.first = 0;  // 🔥 Réinitialise le Lazy Loading
              if (this.totalRecords <= this.first) {
                this.totalRecords += rows;
              }
            } else {
              this.productLS = [];
                console.warn("⚠️ Aucun nouveau produit reçu.");
            }

            this.cdr.detectChanges();
        },
        (error) => {
            console.error("❌ Erreur lors du chargement des données :", error);
        }
    );
  }

  resetStatus() {
    this.selectedStatus = 0;
    setTimeout(() => this.selectedStatus = 5, 0);
    this.cdr.detectChanges();
  }

  applyFilter(event: KeyboardEvent) {
    setTimeout(() => {
      const inputElement = event.target as HTMLInputElement;
      this.lpr_libelle_selected = inputElement.value;
      this.licenceCliSelected(this.licenceSelected, this.lpr_libelle_selected, 0);
    }, 0);
    if (this.lpr_libelle_selected.length === 0) {
      if (!this.licenceSelected || typeof this.licenceSelected !== 'object') {
        this.licenceSelected = { originalEvent: null, value: -1 };
      } else {
        this.licenceSelected.value = -1;
      }
      this.ngOnInit();
    }
  }

  applyFilterMarque(event: KeyboardEvent) {
    setTimeout(() => {
      const inputElement = event.target as HTMLInputElement;
      this.lpr_marque_selected = inputElement.value;
      this.licenceCliSelected(this.licenceSelected, this.lpr_libelle_selected, 0, 5, this.lpr_marque_selected);
    }, 0);
    if (this.lpr_marque_selected.length === 0) {
      if (!this.licenceSelected || typeof this.licenceSelected !== 'object') {
        this.licenceSelected = { originalEvent: null, value: -1 };
      } else {
        this.licenceSelected.value = -1;
      }
      this.ngOnInit();
    }
  }

  sortData(event: SortEvent) {
    if (!event.field) {
      console.warn("⚠️ Aucun champ de tri reçu !");
      return;
    }

    this.sortField = event.field;
    this.sortOrder = event.order ?? 1; // ✅ Assure que `sortOrder` est toujours défini

    this.productLS.sort((a, b) => {
      let valueA = a[this.sortField as keyof ProductLS] ?? ''; // ✅ Évite undefined
      let valueB = b[this.sortField as keyof ProductLS] ?? '';

      if (typeof valueA === 'string' && typeof valueB === 'string') {
        valueA = valueA.toLowerCase();
        valueB = valueB.toLowerCase();
      }

      return (valueA < valueB ? -1 : 1) * (this.sortOrder ?? 1); // ✅ Assure que `sortOrder` n'est jamais undefined
    });
  }

  validateNumberInput(event: KeyboardEvent): boolean {
    const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'];

    if (!/^\d$/.test(event.key) && !allowedKeys.includes(event.key)) {
      event.preventDefault();
      return false;
    }
    return true;
  }

  sanitizeInput(event: Event): void {
    const input = event.target as HTMLInputElement;

    // Supprimer tous les caractères non numériques et limiter à 13 chiffres
    input.value = input.value.replace(/\D/g, '').slice(0, 13);

    // Remettre à zéro certaines valeurs
    this.lpr_libelle_selected = '';

    this.eanLength = input.value.length;

    // Si le champ est vide, remettre eanCode à null
    if (this.eanLength === 0) {
      this.eanCode = 0;  // Force la mise à jour de la variable
      this.ngOnInit();
    }

    if (this.eanLength === 13) {
      this.eanCode = parseInt(input.value, 10);
      if (!this.licenceSelected || typeof this.licenceSelected !== 'object') {
        this.licenceSelected = { originalEvent: null, value: -1 };
      } else {
        this.licenceSelected.value = -1;
      }
      this.first = 0;
      this.licenceCliSelected(this.licenceSelected.value, this.lpr_libelle_selected, this.eanCode);
    }

    // Détection de changement pour mise à jour immédiate
    this.cdr.detectChanges();
  }

  loadProductsByStatus(event: any) {
    this.licenceCliSelected(this.licenceSelected, this.lpr_libelle_selected, this.eanCode, event.value);

  }

  onSelectionChange(event: any) {
    this.coched = event.length;
    if (this.coched > 0) {
      this.visible = true;
      this.lpr_id = event[0]['lpr_id']
      this.editForm.get('libelle')?.setValue(event[0]['lpr_libelle']);
      this.editForm.get('marque')?.setValue(event[0]['lpr_marque']);
      this.editForm.get('ean')?.setValue(event[0]['lpr_ean']);
      this.editForm.get('statusLS')?.setValue(event[0]['lst_name']);
    }
  }

  save() {
    let formData = new FormData();
    const formControls = Object.keys(this.editForm.controls);
    formControls.forEach(controlName => {
      const control = this.editForm.get(controlName);
      if (control) {
        formData.append(controlName, control.value);
      }
    });

    const rowData: any = {
      lpr_id: this.lpr_id,
      lpr_libelle: formData.get('libelle'),
      lpr_marque: formData.get('marque'),
    };

    this.apiLsproductsService
      .updateProductLs(rowData)
      .subscribe((response: any) => {
        if (response) {
          this.editForm.reset();
          this.visible= false;
          this.selectedLS = [];
          this.licenceCliSelected(0, '', 0, 5, '');
          this.licences = this.authService.getUserLicences().filter(licence => licence.isLS === 1);
          console.log('Update OK');
        } else {
          response;
        }
      });
  }

  ngOnDestroy() {
    this.ProductLSSubscription.unsubscribe();
  }
}
