import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { ButtonModule } from 'primeng/button';
import { PanelModule } from 'primeng/panel';
import { ConfirmationService } from 'primeng/api';
import { KeyFilterModule } from 'primeng/keyfilter';

import { EditComponent } from '../../../core/edit/edit.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiTablesService } from '../../../../services/api/api-tables.service';
import { DropdownModule } from 'primeng/dropdown';
import { Subscription } from 'rxjs';
import { CustomTableData } from '../../../../models/custom-table-data';

@Component({
  selector: 'app-tables-datas-edit',
  templateUrl: './tables-datas-edit.component.html',
  styleUrl: './tables-datas-edit.component.css',
  standalone: true,
  imports: [
    ButtonModule,
    DropdownModule,
    KeyFilterModule,
    ReactiveFormsModule,
    PanelModule,
    TranslocoModule,
  ],
  providers: [ConfirmationService],
})
export class TablesDatasEditComponent
  extends EditComponent
  implements OnInit
{
  editForm: FormGroup;
  saveError: boolean = false;
  tableId:string = '';
  dataId:string = '';
  tablesSubscription: Subscription = new Subscription();

  constructor(
    private apiTablesService: ApiTablesService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    super(route);

    this.editForm = this.formBuilder.group({
      libelle: ['', Validators.required],
      datas: ['', Validators.required],
    });
  }

  override ngOnInit() {
    super.ngOnInit();
    this.tableId = this.route.snapshot.paramMap.get('tableId') ?? '';   
    this.dataId = this.route.snapshot.paramMap.get('dataId') ?? '';    
    this.titlePage = this.route.snapshot.data['path'].slice(-1)[0]['label'];

    if(this.tableId != '') {

      if(this.dataId != '') {
      this.tablesSubscription = this.apiTablesService
        .getTablesData(this.dataId)
        .subscribe((customData: CustomTableData) => {
          this.dataId = customData.id.toString();
          this.editForm.controls['libelle'].setValue(customData.libelle);
          this.editForm.controls['datas'].setValue(customData.datas);

          this.dataLoaded = true;
        });
      } else {
        this.dataLoaded = true;
      }

    }
  }

  save() {
    let formData = new FormData();
    formData.append('tableId', this.tableId);
    formData.append('dataId', this.dataId);
    formData.append('libelle', this.editForm.controls['libelle'].value);
    formData.append('datas', this.editForm.controls['datas'].value);

    this.apiTablesService
      .postTableData(formData)
      .subscribe((response: any) => {
        this.saveError = !response;

        if (response) {
            this.router.navigate(['settings/tables-datas', this.tableId]);
        }
      });
  }
}
