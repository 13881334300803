import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { Client } from '../../models/client';
import { ReceptionOutputList } from '../../models/reception-output-list';
import { ReceptionType } from '../../models/reception-type-list';
import {ReceptionOutputType} from '../../models/reception-output-type';

@Injectable({
  providedIn: 'root',
})
export class ApiReceptionOutputListService {
  constructor(private http: HttpClient) {
  }

  getReceptionOutputList(): Observable<any[]> {
    return this.http
      .get<any[]>(environment.apiURL + 'espace_client/get_process_reception.php')
      .pipe(
        map((response: any[]) => {
          return response.map((dataReceptionList) => {
            return new ReceptionOutputList(
              parseInt(dataReceptionList.cli_id),
              dataReceptionList.cpr_name,
              parseInt(dataReceptionList.cpr_id),
              parseInt(dataReceptionList.rou_id),
              dataReceptionList.rou_name,
              parseInt(dataReceptionList.rou_rot_id),
              dataReceptionList.rot_name,
              parseInt(dataReceptionList.rou_compulsory),
              parseInt(dataReceptionList.rou_order),
              dataReceptionList.rou_exec_path,
              dataReceptionList.rou_flag,
              new Client(
                parseInt(dataReceptionList.cli_id),
                dataReceptionList.cli_name)
            )
          })
        }),
        catchError((error) => {
          return of([]);
        })
      );
  }

  getReceptionOutputListByLicence(cpr_id: number): Observable<any[]> {
    return this.http
      .get<any[]>(environment.apiURL + 'espace_client/get_process_reception_by_licence.php?', {params: {cpr_id: cpr_id.toString()}})
      .pipe(
        map((response: any[]) => {
          return response.map((dataReceptionList) => {
            return new ReceptionOutputList(
              parseInt(dataReceptionList.cli_id),
              dataReceptionList.cpr_name,
              parseInt(dataReceptionList.cpr_id),
              parseInt(dataReceptionList.rou_id),
              dataReceptionList.rou_name,
              parseInt(dataReceptionList.rou_rot_id),
              dataReceptionList.rot_name,
              parseInt(dataReceptionList.rou_compulsory),
              parseInt(dataReceptionList.rou_order),
              dataReceptionList.rou_exec_path,
              dataReceptionList.rou_flag,
              new Client(
                parseInt(dataReceptionList.cli_id),
                dataReceptionList.cli_name)
            )
          })
        }),
        catchError((error) => {
          return of([]);
        })
      );
  }

  deleteOutput(rou_id: number): Observable<any> {
    return this.http.get<any>(
      environment.apiURL +
      'espace_client/delete_output_reception.php?rou_id=' +
      rou_id
    );
  }

  updateOutput(rowData: any): Observable<any> {
    const body = new URLSearchParams();
    for (const key in rowData) {
      if (rowData.hasOwnProperty(key)) {
        body.set(key, rowData[key]);
      }
    }
    return this.http.post<any>(
      environment.apiURL + 'espace_client/post_update_output_reception.php',
      body.toString(),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
    );
  }

  getReceptionType(cli_id: number): Observable<ReceptionType[]> {
    return this.http
      .get<any[]>(environment.apiURL + 'espace_client/get_reception_type.php?', {params: {cli_id}})
      .pipe(
        map((response: any[]) => {
          return response.map((dataReceptionTypeList) => {
            return new ReceptionType(
              parseInt(dataReceptionTypeList.rty_id, 10),
              dataReceptionTypeList.rty_name,
              parseInt(dataReceptionTypeList.rty_menu, 10),//cpr_id
              parseInt(dataReceptionTypeList.rty_traceability, 10),
              parseInt(dataReceptionTypeList.cpr_id, 10),
            )
          })
        }),
        catchError((error) => {
          return of([]);
        })
      );
  }
}
