import { SurveyQuestion } from "./survey-question";

export class SurveyPage {
    public id: number;
    public name: string;
    public order: number;
    public questions: SurveyQuestion[];
    
    constructor(
        id: number,
        name: string,
        order: number,
        questions: SurveyQuestion[] = []
    ) {
        this.id = id;
        this.name = name;
        this.order = order;
        this.questions = questions;
    }
}