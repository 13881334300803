import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import { CommonModule } from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';

import {interval, Subscription} from 'rxjs';

import { AuthService } from '../../../services/auth/auth.service';
import { ApiAlertsListService } from "../../../services/api/api-alerts-users";

import { Client } from '../../../models/client';
import { AlertsType } from "../../../models/alerts-users-type-list";
import { AlertsFrequencies } from "../../../models/alerts-users-frequencies-list";

import {TranslocoModule, TranslocoService} from '@ngneat/transloco';

import { ButtonModule } from 'primeng/button';
import { MultiSelectModule } from 'primeng/multiselect';
import { TableModule } from 'primeng/table';
import { TooltipModule } from "primeng/tooltip";
import { ConfirmationService, MessageService } from 'primeng/api';
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { DropdownModule } from "primeng/dropdown";
import { EditComponent } from '../../core/edit/edit.component';
import {InputTextareaModule} from "primeng/inputtextarea";
import { RadioButtonModule } from 'primeng/radiobutton';
import { SliderModule } from 'primeng/slider';
import { InputTextModule } from 'primeng/inputtext';
import {ToastModule} from "primeng/toast";
import { PanelModule } from 'primeng/panel';
import {InputNumberModule} from "primeng/inputnumber";
import {Alerts} from "../../../models/alerts-users";
import {TriStateCheckboxModule} from "primeng/tristatecheckbox";
import {ReceptionOutputList} from "../../../models/reception-output-list";

@Component({
  selector: 'app-alerts-for-users-add',
  templateUrl: './alerts-for-users-add.component.html',
  styleUrl: './alerts-for-users-add.component.css',
  providers: [MessageService, ConfirmationService],
  standalone: true,
  imports: [
    ButtonModule,
    CommonModule,
    FormsModule,
    ConfirmDialogModule,
    MultiSelectModule,
    TableModule,
    TranslocoModule,
    TooltipModule,
    DropdownModule,
    ReactiveFormsModule,
    InputTextareaModule,
    RadioButtonModule,
    SliderModule,
    InputTextModule,
    ToastModule,
    PanelModule,
    InputNumberModule,
    TriStateCheckboxModule
  ],
  encapsulation: ViewEncapsulation.None,
})

export class AlertsForUsersAddComponent extends EditComponent implements OnDestroy, OnInit {

  AlertsTypeSubscription: Subscription = new Subscription();
  AlertsFrequenciesSubscription: Subscription = new Subscription();
  AlertsListSubscription: Subscription = new Subscription();
  name: string = '';
  licences : Client[] = [];
  clientId: string = '';

  alertList: Alerts[] = [];
  alertsType: AlertsType[] = [];
  alertsFrequencies: AlertsFrequencies[] = [];

  editForm: FormGroup;
  dropdownAlertsType: { label: string, value: number }[] = [];
  dropdownAlertsFrequencies: { label: string, value: number }[] = [];

  selectedAlertTypeId: number | null = null;
  selectedBouttonRadio = "Important";
  alert: any;
  displayTableau = false;
  selectedRow: any = null;
  selectedAlert: any[] = [];
  addEnabled = true;
  cancelEnabled = false;
  modifInProgress=false;
  cal_id = 0;
  delay = 0;
  setting = '';

  value: number = 1;
  constructor(
    private apiAlertsListService: ApiAlertsListService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private translocoService: TranslocoService,
    private router: Router,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
  ) {
    super(route);

    this.editForm = this.formBuilder.group({
      param_alertsType: ['', Validators.required],
      param_alertsFrequencies: ['', Validators.required],
      param_alertsDelay_H: [0, Validators.required],
      param_alertsDelay_M: [0, Validators.required],
      param_messagePerso: [''],
      alertGroup: [''],
    })
    this.dropdownAlertsType = [];
    this.dropdownAlertsFrequencies = [];
  }

  override ngOnInit() {
    super.ngOnInit();

    this.clientId = this.route.snapshot.paramMap.get('id') ?? '';
    this.titlePage = this.route.snapshot.data['path'].slice(-1)[0]['label'];
    this.route.queryParams.subscribe(params => {
      this.name = params['name'];
    });
    const cliId = parseInt(this.clientId);
    this.AlertsListSubscription = this.apiAlertsListService
      .getAlertsList()
      .subscribe((data: Alerts[]) => {
        this.alertList = data.filter(alert => alert.client.id === cliId);
      });

    this.AlertsTypeSubscription = this.apiAlertsListService
      .getAlertsType()
      .subscribe((dataAlertsType: AlertsType[]) => {
        this.alertsType = dataAlertsType;
        this.dropdownAlertsType = dataAlertsType.map(alertsType => ({
          label: alertsType.ale_name,
          value: alertsType.ale_id
        }));
      })

    this.AlertsFrequenciesSubscription = this.apiAlertsListService
      .getAlertsFrequencies()
      .subscribe((dataAlertsFrequencies: AlertsFrequencies[]) => {
        this.alertsFrequencies = dataAlertsFrequencies;
        this.dropdownAlertsFrequencies = dataAlertsFrequencies.map(alertsFrequencies => ({
          label: alertsFrequencies.afr_name,
          value: alertsFrequencies.afr_id
        }));
      })

    this.dataLoaded = true;
    this.licences = this.authService.getUserLicences();
  }

  getLabelForValue(value: number | null, dropdownList: { label: string; value: number; }[]): string {
    const selectedOption = dropdownList.find(option => option.value === value);
    return selectedOption ? selectedOption.label : '';
  }
  onRowSelect(event: any) {
    this.setting = "Modification de l'alerte";
    this.displayTableau = true;
    this.cancelEnabled = true;
    this.addEnabled = false;
    this.modifInProgress = true;
    this.alert = event;
    this.cal_id = event.cal_id;
    if (this.alert.client.id !== '') {
      const cliId = parseInt(this.clientId);
      const tempsTotal = event.cal_repeat_delay;
      const tempsHeures = Math.floor(tempsTotal / 60);
      const tempsMinutes = tempsTotal % 60;
      this.AlertsListSubscription = this.apiAlertsListService
        .getAlertsList()
        .subscribe((data: Alerts[]) => {
          this.alertList = data.filter(alert => alert.client.id === cliId);
          for (const item of this.alertList) {
            if (item.cal_id == event.cal_id) {
              this.editForm.controls['param_alertsType'].setValue(event.alertsType.ale_id);
              this.editForm.controls['param_alertsFrequencies'].setValue(event.cal_afr_id);
              this.editForm.controls['param_alertsDelay_H'].setValue(tempsHeures);
              this.editForm.controls['param_alertsDelay_M'].setValue(tempsMinutes);
              this.editForm.controls['param_messagePerso'].setValue(event.cal_message_perso);
              this.editForm.controls['alertGroup'].setValue(event.cal_importance);
            }
          }
        });
    }
  }
  addAlerte() {
    this.setting = "Nouvelle alerte";
    this.displayTableau = true;
    this.addEnabled = false;
    this.cancelEnabled = true;
    this.modifInProgress = true;
    this.cal_id = 0;
    this.editForm.controls['param_alertsDelay_H'].setValue(0);
    this.editForm.controls['param_alertsDelay_M'].setValue(0);

  }
  cancel(){
    this.displayTableau = false;
    this.cancelEnabled = false;
    this.addEnabled = true;

    this.editForm.controls['param_alertsType'].setValue('');
    this.editForm.controls['param_alertsFrequencies'].setValue(1);
    this.editForm.controls['param_alertsDelay_H'].setValue('');
    this.editForm.controls['param_alertsDelay_M'].setValue('');
    this.editForm.controls['param_messagePerso'].setValue('');
    this.editForm.controls['alertGroup'].setValue('Important');

  }
  deleteAlerte(rowData: any) {
    this.confirmationService.confirm({
      message:
        this.translocoService.translate(
          'Confirmez-vous la suppression DEFINITIVE de l\'alerte'
        ) +
        ' "' +
        rowData.ale_name.substring(2) +
        '" ?',
      header: 'Suppression',
      acceptLabel: this.translocoService.translate('Oui'),
      rejectLabel: this.translocoService.translate('Non'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.apiAlertsListService.deleteAlerts(rowData.cal_id).subscribe((response) => {
          if (response) {
            this.alertList = this.alertList.filter(
              (val: Alerts) => val.cal_id !== rowData.cal_id
            );
            console.log('DEL OK');
          } else {
            console.log('DEL KO');
          }
        });
      },
    });
  }
  save() {
    let formData = new FormData();
    const formControls = Object.keys(this.editForm.controls);
    formControls.forEach(controlName => {
      const control = this.editForm.get(controlName);
      if (control) {
        formData.append(controlName, control.value);
      }
    });

    const selectedLabelType = this.getLabelForValue(this.selectedAlertTypeId, this.dropdownAlertsType);
    let ico = "";
    let refHtml = "";
    let icoAlert = "";
    let doubleIco = "";
    const paramAlertsType = formData.get('param_alertsType');
    let buttonToGo = "";
    let debutPhrase = "";

    switch (Number(paramAlertsType)) {
      case 1:
      case 3:
      case 4:
      case 5:
      case 6:
      case 7:
      case 8:
      case 9:
      case 10:
      case 11:
      case 17:
      case 23:
        refHtml = "launcher";
        break;
      case 2:
      case 18:
        refHtml = "anomalies";
        break;
      case 12:
      case 13:
      case 14:
      case 15:
      case 16:
        refHtml = "ls_list";
        break;
      case 19:
        refHtml = "traceability";
        break;
      case 20:
        refHtml = "temperatures";
        break;
      case 21:
        refHtml = "tasks";
        break;
      default: break;
    }
    switch (formData.get('alertGroup')) {
      case "Information":
        ico = "info";
        icoAlert = "info";
        break;
      case "Important":
        ico = "warning";
        icoAlert = "warning";
        break;
      case "Très important":
        ico = "exclamation-triangle";
        icoAlert = "danger";
        break;
      default: break;
    }

    if (icoAlert == "danger") {
      doubleIco = "<i class='fa fa-exclamation-triangle fa-2x'></i>&nbsp;"
    } else{
      doubleIco = "<i class='fa fa-" + ico + "'></i>&nbsp;"
    }
    if (formData.get('param_alertsType') == '24') {
      debutPhrase = "Vous n'avez effectué ";
    } else{
      buttonToGo = "<div class='pull-center'><a href=\"" + refHtml + ".html\" class='btn my_button'>" + (selectedLabelType).substring(2) +  "</a></div>";
      debutPhrase = "Vous avez des  ";
    }
    if (formData.get('param_alertsFrequencies') !== '1') {
      this.delay = Number(formData.get('param_alertsDelay_H')) * 60 + Number(formData.get('param_alertsDelay_M'));
    } else {
      this.editForm.controls['param_alertsDelay_H'].setValue(0);
      this.editForm.controls['param_alertsDelay_M'].setValue(0);
    }


    const rowData: any = {
      cal_id: this.cal_id,
      client : this.clientId,
      cal_ale_id : formData.get('param_alertsType'),
      cal_afr_id : formData.get('param_alertsFrequencies'),
      cal_repeat_delay: this.delay,
      cal_to : "",
      cal_importance : formData.get('alertGroup'),
      cal_message : "<div class='alert alert-block alert-" + icoAlert + " fade in'>"
        + doubleIco
        + debutPhrase
        + (selectedLabelType).substring(2) + " !"
        + buttonToGo
        + "<br>"
        + formData.get('param_messagePerso') + "</div>",
      cal_message_perso : formData.get('param_messagePerso')
    };
    this.apiAlertsListService.updateAlert(rowData).subscribe((response) => {
      if (response) {
        const cliId = parseInt(this.clientId);
        this.AlertsListSubscription = this.apiAlertsListService
          .getAlertsList()
          .subscribe((data: Alerts[]) => {
            this.alertList = data.filter(alert => alert.client.id === cliId);
          });
        this.cancel();
        console.log('Update OK', rowData);
      } else {
        this.messageService.add({ severity: 'warn', summary: 'Attention', detail: 'Tous les champs marqués d\'un astérisque sont obligatoires.' });
        console.log('Update KO', response);
      }
    });
    }

  ngOnDestroy() {
    this.AlertsTypeSubscription.unsubscribe();
    this.AlertsFrequenciesSubscription.unsubscribe();
  }
}
