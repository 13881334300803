import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import moment from 'moment';

import { environment } from '../../../environments/environment';
import { Client } from '../../models/client';
import { ClientMonitoring } from '../../models/client-monitoring';
import { ClientGroup } from '../../models/client-group';
import { ClientStatsTarget } from '../../models/client-stats-target';
import { ClientParametersReception } from '../../models/client-parameters-reception';

@Injectable({
  providedIn: 'root',
})
export class ApiClientsService {
  constructor(private http: HttpClient) {}

  getMonitoring(): Observable<ClientMonitoring[]> {
    return this.http
      .get<any>(
        environment.apiURL +
          'espace_client/get_clients_monitoring.php'
      )
      .pipe(
        map((response: any[]) => {
          return response.map((dataMonitoring) => {
            return new ClientMonitoring(
              new Client(
                parseInt(dataMonitoring.ccs_cli_id),
                dataMonitoring.cli_name,
                '',
                new ClientGroup(parseInt(dataMonitoring['group1_id']), dataMonitoring['group1_name']),
                new ClientGroup(parseInt(dataMonitoring['group2_id']), dataMonitoring['group2_name'])
              ),
              moment(new Date(dataMonitoring.ccs_maj_date)),
              parseInt(dataMonitoring.ccs_total_docs),
              parseInt(dataMonitoring.ccs_deleted_docs),
              parseFloat(dataMonitoring.ccs_db_size),
              parseInt(dataMonitoring.ccs_anomalie),
              parseInt(dataMonitoring.ccs_treatment),
              parseInt(dataMonitoring.ccs_treatment_histo),
              parseInt(dataMonitoring.ccs_lot),
              parseInt(dataMonitoring.ccs_treatment_image),
              parseInt(dataMonitoring.ccs_treatment_image_delete),
              parseInt(dataMonitoring.ccs_traca),
              parseInt(dataMonitoring.ccs_reception),
              parseInt(dataMonitoring.ccs_ls_client),
              parseInt(dataMonitoring.ccs_task),
              parseInt(dataMonitoring.ccs_temperature),
              parseInt(dataMonitoring.ccs_oil_change),
              parseInt(dataMonitoring.ccs_oil_control),
              parseInt(dataMonitoring.ccs_measure),
              parseInt(dataMonitoring.ccs_task_histo),
              parseInt(dataMonitoring.ccs_temperature_histo),
              parseInt(dataMonitoring.ccs_oil_change_histo),
              parseInt(dataMonitoring.ccs_oil_control_histo),
              parseInt(dataMonitoring.ccs_measure_histo)
            );
          });
        }),
        catchError((error) => {
          return of([]);
        })
      );
  }

  getStatTargets(): Observable<ClientStatsTarget[]> {
    return this.http
      .get<any>(
        environment.apiURL +
          'espace_client/get_clients_stats_targets.php'
      )
      .pipe(
        map((response: any[]) => {
          return response.map((dataStatsTargets) => {
            return new ClientStatsTarget(
              new Client(
                parseInt(dataStatsTargets.cli_id),
                dataStatsTargets.cli_name
              ),
              parseInt(dataStatsTargets.cpa_stats_target_tasks),
              parseInt(dataStatsTargets.cpa_stats_target_temperatures),
              parseInt(dataStatsTargets.cpa_stats_target_ncs),
              parseInt(dataStatsTargets.cpa_stats_target_treatments),
              parseInt(dataStatsTargets.cpa_stats_target_lots),
              parseInt(dataStatsTargets.cpa_stats_target_photos)
            );
          });
        }),
        catchError((error) => {
          return of([]);
        })
      );
  }

  postStatTarget(formData: any): Observable<any> {
    return this.http.post<any>(
      environment.apiURL + 'espace_client/post_clients_stats_targets.php',
      formData
    );
  }

  getReceptionParameters(cprId:number): Observable<ClientParametersReception> {
    return this.http
      .get<any>(
        environment.apiURL +
          'espace_client/get_clients_parameters_reception.php?cprId=' +
          cprId
      )
      .pipe(
        map((dataProcess: any) => {
          return new ClientParametersReception(
            parseInt(dataProcess.cpr_id),
            dataProcess.cpr_name,
            parseInt(dataProcess.cpr_tlo),
            parseInt(dataProcess.cpr_img_force),
            parseInt(dataProcess.cpr_dlc),
            parseInt(dataProcess.cpr_numlot),
            parseInt(dataProcess.cpr_temperature),
            parseInt(dataProcess.cpr_output),
            parseInt(dataProcess.cpr_supplier),
            parseInt(dataProcess.cpr_auto_close_lot)
          );
        })
      );
  }

  postReceptionParameters(formData: any): Observable<any> {
    return this.http.post<any>(
      environment.apiURL + 'espace_client/post_clients_parameters_reception.php',
      formData
    );
  }

  getClients(): Observable<Client[]> {
    return this.http
      .get<any>(
        environment.apiURL +
          'espace_client/get_clients.php'
      )
      .pipe(
        map((response: any[]) => {
          return response.map((datasClients) => {
            let oClient = new Client(
                parseInt(datasClients.cli_id),
                datasClients.cli_name,
                datasClients.cli_city
              );

            if(datasClients.cli_last_login_date) {
              oClient.lastLoginDate = moment(new Date(datasClients.cli_last_login_date));
            }
            return oClient;
          });
        }),
        catchError((error) => {
          return of([]);
        })
      );
  }

}
