import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { Client } from '../../models/client';
import { CustomTable } from '../../models/custom-table';
import { CustomTableData } from '../../models/custom-table-data';

@Injectable({
  providedIn: 'root',
})
export class ApiTablesService {
  constructor(private http: HttpClient) {}

  getTables(): Observable<CustomTable[]> {
    return this.http
      .get<any>(environment.apiURL + 'espace_client/get_clients_custom_tables.php')
      .pipe(
        map((response: any[]) => {
          return response.map((dataCustomTable) => {
            return new CustomTable(
              parseInt(dataCustomTable.cct_id),
              dataCustomTable.cct_name,
              new Client(
                parseInt(dataCustomTable.cct_cli_id),
                dataCustomTable.cli_name
              )                           
            );
          });
        }),
        catchError((error) => {
          return of([]);
        })
      );
  }
  getTablesDatas(tableId:string): Observable<CustomTableData[]> {
    return this.http
      .get<any>(environment.apiURL + 'espace_client/get_clients_custom_tables_datas.php?tableId='+tableId)
      .pipe(
        map((response: any[]) => {
          return response.map((dataCustomTable) => {
            return new CustomTableData(
              parseInt(dataCustomTable.ccd_id),
              dataCustomTable.ccd_libelle,
              dataCustomTable.ccd_data,                      
            );
          });
        }),
        catchError((error) => {
          return of([]);
        })
      );
  }

  
  getTablesData(dataId:string): Observable<CustomTableData> {
    return this.http
      .get<any>(
        environment.apiURL +
          'espace_client/get_clients_custom_tables_datas.php?dataId='+ dataId
      )
      .pipe(
        map((dataCustomTable: any) => {
            return new CustomTableData(
              
              parseInt(dataCustomTable.ccd_id),
              dataCustomTable.ccd_libelle,
              dataCustomTable.ccd_data, 
            );
        })
      );
  }

  postTable(formData: FormData): Observable<any> {
    return this.http.post<any>(
      environment.apiURL + 'espace_client/post_clients_custom_tables.php',
      formData
    );
  }
  postTableData(formData: FormData): Observable<any> {
    return this.http.post<any>(
      environment.apiURL + 'espace_client/post_clients_custom_tables_data.php',
      formData
    );
  }
  deleteData(dataId: number): Observable<any> {
    return this.http.get<any>(
      environment.apiURL +
        'espace_client/delete_clients_custom_tables_datas.php?customDataId=' +
        dataId
    );
  }

}
