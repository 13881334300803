import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { ButtonModule } from 'primeng/button';
import { EditComponent } from '../../../core/edit/edit.component';
import { ActivatedRoute, Router } from '@angular/router';
import { PanelModule } from 'primeng/panel';
import { Subscription } from 'rxjs';
import { ConfirmationService, TreeNode } from 'primeng/api';
import { TreeService } from '../../../../services/tree/tree.service';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { Client } from '../../../../models/client';
import { DropdownModule } from 'primeng/dropdown';
import { AuthService } from '../../../../services/auth/auth.service';
import { ApiSurveysService } from '../../../../services/api/api-surveys.service';
import { Survey } from '../../../../models/survey';
import { SurveyPage } from '../../../../models/survey-page';
import { TableModule } from 'primeng/table';

@Component({
  selector: 'app-surveys-edit',
  templateUrl: './surveys-edit.component.html',
  styleUrl: './surveys-edit.component.css',
  standalone: true,
  imports: [
    ButtonModule,
    ConfirmDialogModule,
    DropdownModule,
    ReactiveFormsModule,
    PanelModule,
    TableModule,
    TranslocoModule,
  ],
  providers: [ConfirmationService],
})
export class SurveysEditComponent
  extends EditComponent
  implements OnInit, OnDestroy
{
  
  licences : Client[] = [];
  surveySubscription: Subscription = new Subscription();
  ordreMax:number = 0;
  pages: SurveyPage[] = [];

  //a revoir / valider
  editForm: FormGroup;
  saveError: boolean = false;
  surveyId: string = '';
  indicatorFields: TreeNode[] = [];
  allNodesExpanded = false;

  constructor(
    private apiSurveyService: ApiSurveysService,
    private authService: AuthService,
    private confirmationService: ConfirmationService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private translocoService: TranslocoService,
    private treeService: TreeService,
  ) {
    super(route);

    this.editForm = this.formBuilder.group({
      sur_cli_id: ['', Validators.required],
      sur_name: ['', Validators.required],
      sur_comment: [''],
    });
  }

  override ngOnInit() {
    super.ngOnInit();

    this.surveyId = this.route.snapshot.paramMap.get('id') ?? '';
    this.titlePage = this.route.snapshot.data['path'].slice(-1)[0]['label'];
    
    this.licences = this.authService.getUserLicences();    

    if (this.surveyId != '') {
      // recuperer les données de l'indicateur'
      this.surveySubscription = this.apiSurveyService
        .getSurvey(this.surveyId)
        .subscribe((survey: Survey) => {

          //on calcule l'ordre max pour chaque parent
          this.ordreMax = survey.pages.reduce((max, page) => {
            return page.order > max ? page.order : max;
          }, 0)

          this.pages = survey.pages;

          this.editForm.controls['sur_name'].setValue(survey.name);
          this.editForm.controls['sur_comment'].setValue(survey.comment);
          this.editForm.controls['sur_cli_id'].setValue(survey.client.id);
        });
    }
  }

  ngOnDestroy() {
    this.surveySubscription.unsubscribe();
  }

  save() {
    let formData = new FormData();
    formData.append('sur_id', this.surveyId);
    formData.append('sur_cli_id', this.editForm.controls['sur_cli_id'].value);
    formData.append('sur_name', this.editForm.controls['sur_name'].value);
    formData.append('sur_comment', this.editForm.controls['sur_comment'].value);

    this.apiSurveyService
      .postSurvey(formData)
      .subscribe((response: any) => {
        this.saveError = !response;

        if (response) {
          if(this.surveyId == '') {
            //création on reste sur le formulaire pour ajouter les champs
            this.router.navigate(['settings/surveys-edit', response.sur_id])
          } else {
            this.router.navigate(['settings/surveys']);
          }
          
        }
      });
  }

  canWrite(): boolean {
    return this.authService.canWrite();
  }

  addPage(parentId:number) {
    const nextOrder = (this.ordreMax ?? 0) + 1;

    this.router.navigate(['settings/surveys-pages-add', this.surveyId, parentId, nextOrder]);
  }
  editPage(dataPage:SurveyPage) {
    this.router.navigate(['settings/surveys-pages-edit', this.surveyId, dataPage.id]);
  }

  toggleExpand() {
    this.allNodesExpanded = !this.allNodesExpanded;
    this.indicatorFields = this.treeService.updateNodeExpansion(this.indicatorFields, this.allNodesExpanded);
  }

  deletePage(page: SurveyPage) {
    this.confirmationService.confirm({
      message:
        this.translocoService.translate(
          'Confirmez-vous la suppression de la page'
        ) +
        ' "' +
        page.name +
        '" ?',
      header: 'Suppression',
      acceptLabel: this.translocoService.translate('Oui'),
      rejectLabel: this.translocoService.translate('Non'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.apiSurveyService
          .deletePage(page.id)
          .subscribe((response: any) => {
            if (response) {
              this.pages = this.pages.filter(
                (val: SurveyPage) => val.id !== page.id
              );
            }
          });
      },
    });
  }

}
