import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { Subscription } from 'rxjs';

import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { TableModule } from 'primeng/table';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';

import { AuthService } from '../../../../services/auth/auth.service';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';

import { TableGroupService } from '../../../../services/table/table-group.service';
import { MailReport } from '../../../../models/mail-report';
import { ApiMailReportsService } from '../../../../services/api/api-mail-reports.service';
import { User } from '../../../../models/user';


@Component({
  selector: 'app-mail-reports-list',
  standalone: true,
  imports: [
    ButtonModule,
    CommonModule,
    ConfirmDialogModule,
    DropdownModule,
    FormsModule,
    MultiSelectModule,
    TableModule,
    TranslocoModule,
  ],
  templateUrl: './mail-reports-list.component.html',
  styleUrl: './mail-reports-list.component.css',
  providers: [MessageService, ConfirmationService],
})
export class MailReportsListComponent implements OnInit, OnDestroy {
  mailReportSubscription: Subscription = new Subscription();
  dataLoaded: boolean = false;
  mailReports: MailReport[] = [];
  nbRows: number = 10;
  distinctUsers: Array<User> = [];

  constructor(
    private apiMailReportService: ApiMailReportsService,
    private authService: AuthService,
    private confirmationService: ConfirmationService,
    private router: Router,
    protected tableGroupService: TableGroupService,
    private translocoService: TranslocoService
  ) { }

  isRoot(): boolean {
    return this.authService.isRoot();
  }

  ngOnInit() {
    this.mailReportSubscription = this.apiMailReportService
      .getMailReports()
      .subscribe((data: MailReport[]) => {
        this.mailReports = data;
        this.dataLoaded = true;

        // Extraire la liste des utilisateurs distincts (pour le filtre)
        this.distinctUsers = this.getDistinctUsers(this.mailReports);
      });
  }

  getDistinctUsers(mailReports: MailReport[]): User[] {
    const usersMap = new Map<number, User>(); // Utilisation de Map pour éviter les doublons

    mailReports.forEach(report => {
      if (report.user && !usersMap.has(report.user.id)) {
        usersMap.set(report.user.id, report.user); // Ajoute l'utilisateur s'il n'existe pas dans la map
      }
    });

    return Array.from(usersMap.values()); // Retourne un tableau avec les utilisateurs distincts
  }

  addMailReport() {
    this.router.navigate(['settings/mail-reports-add']);
  }
  editMailReport(mailReport: MailReport) {
    this.router.navigate(['settings/mail-reports-edit', mailReport.id]);
  }
  editLicences(userData: User) {
    this.router.navigate(['settings/mail-reports-licences', userData.id]);
  }

  canWrite(): boolean {
    return this.authService.canWrite();
  }

  deleteMailReport(mailReport: MailReport) {
    this.confirmationService.confirm({
      message:
        this.translocoService.translate(
          'Confirmez-vous la suppression du rapport mail'
        ) +
        ' "' +
        mailReport.title +
        '" ?',
      header: 'Suppression',
      acceptLabel: this.translocoService.translate('Oui'),
      rejectLabel: this.translocoService.translate('Non'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.apiMailReportService.deleteMailReport(mailReport.id).subscribe((response) => {
          if (response) {
                this.mailReports = this.mailReports.filter(
                  (val: MailReport) => val.id !== mailReport.id
                );
          }
        });
      },
    });
  }

  ngOnDestroy() {
    this.mailReportSubscription.unsubscribe();
  }

}
