@if(authService.tokenLogin) {
<router-outlet></router-outlet>
} @else if (!authService.isLoggedInUser()) {
<app-login></app-login>
}
@else {
@defer (when isLoaded) {
<p-toolbar class="global-toolbar">
  <div class="p-toolbar-group-start">
    <a href="/"><img src="assets/images/logo-blanc-xl.png" height="37" /></a>
  </div>
  <div class="p-toolbar-group-center">
  </div>
  <div class="p-toolbar-group-end">
    <span class=" header-text-user">
      {{ 'Espace Clients Zest HACCP' | transloco }}<br>
      {{ 'Bonjour' | transloco }} {{ usrData.first_name }} {{ usrData.last_name }}
    </span>
    @if(this.authService.isRoot()) {
    <a (click)="goToCart()" [title]="'Déconnexion' | transloco"><i class="pi pi-shopping-cart header-action-logo" pBadge
        [value]="cartNbProducts"></i></a>
    }
    <a (click)="authService.logout()" [title]="'Déconnexion' | transloco"><i
        class="pi pi-power-off header-action-logo"></i></a>
  </div>
</p-toolbar>


<div class="global-div">
  <app-navigation-menu></app-navigation-menu>

  <div class="global-content">
    @if(!hideBreadCrumb) {
      <div class="global-breadcrumb">
        <p-breadcrumb class="max-w-full me-2" [home]="home" [model]="path"></p-breadcrumb>
      </div>
    }
    <router-outlet></router-outlet>
  </div>
</div>

<app-loading-modal></app-loading-modal>
<app-content-modal></app-content-modal>

<footer>
  <div class="footer-left">{{ 'Espace Clients Zest HACCP' | transloco }} - 1.0.0</div>
  <div class="footer-center"></div>
  <div class="footer-right">{{ usrData.first_name }} {{ usrData.last_name }}</div>
</footer>
}

}