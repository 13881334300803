<ul class="pc-menu">
    @for (item of items; track item) {
        <li routerLinkActive="active" [class]="item.styleClass">
            <a [routerLink]="item.routerLink" >
                <i [class]="item.icon"></i>
                {{ item.label | transloco }}
            </a>
        </li>
    }
</ul>

<ul class="tablet-menu">
    @for (item of items; track item) {
        <li routerLinkActive="active" [class]="item.styleClass">
            <a [routerLink]="item.routerLink" >
                <i [class]="item.icon"></i>
            </a>
        </li>
    }
</ul>
