import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import moment from 'moment';

import { environment } from '../../../environments/environment';
import { User } from '../../models/user';
import { Client } from '../../models/client';
import { ClientGroup } from '../../models/client-group';

@Injectable({
  providedIn: 'root',
})
export class ApiUsersService {
  constructor(private http: HttpClient) {}

  getUsers(onlyActive:boolean = false): Observable<User[]> {
    return this.http
      .get<any>(
        environment.apiURL +
          'espace_client/get_users.php?onlyActive='+onlyActive
      )
      .pipe(
        map((response: any[]) => {
          return response.map((dataUsr) => {
            return new User(
              parseInt(dataUsr.usr_id),
              dataUsr.usr_first_name,
              dataUsr.usr_last_name,
              dataUsr.usr_login,
              moment(new Date(dataUsr.usr_in_date)),
              (dataUsr.usr_out_date ? moment(new Date(dataUsr.usr_out_date)): null),
              new Client(
                parseInt(dataUsr.cli_id),
                dataUsr.cli_name
              ),
              new ClientGroup(
                parseInt(dataUsr.cgr_id),
                dataUsr.cgr_name
              )
            );
          });
        }),
        catchError((error) => {
          return of([]);
        })
      );
  }
  getUser(userId: string): Observable<User> {
    return this.http
      .get<any>(
        environment.apiURL +
          'espace_client/get_user_detail.php?userId=' +
          userId
      )
      .pipe(
        map((dataUsr: any) => {
          let objUser = new User(
            parseInt(dataUsr.usr_id),
            dataUsr.usr_first_name,
            dataUsr.usr_last_name,
            dataUsr.usr_login,
            moment(new Date(dataUsr.usr_in_date)),
            (dataUsr.usr_out_date ? moment(new Date(dataUsr.usr_out_date)): null),
            new Client(
              parseInt(dataUsr.usr_cli_id),
              dataUsr.cli_name
            ),
            null
          );

          objUser.email = dataUsr.usr_mail;
          objUser.mobilePhone = dataUsr.usr_gsm;
          objUser.profilId = parseInt(dataUsr.usr_pro_id);
          objUser.lang = dataUsr.usr_lang;
          objUser.surveyForm = parseInt(dataUsr.usr_survey_form);
          objUser.writerEspaceClient = parseInt(dataUsr.usr_writer_espace_client);

          return objUser;
        })
      );
  }

  getUserLicences(usrId:string): Observable<Client[]> {
    return this.http
      .get<any>(
        environment.apiURL +
          'espace_client/get_user_licences.php?userId='+usrId
      )
      .pipe(
        map((response: any[]) => {
          return response.map((dataLicence) => {
            let objLicence =  new Client(
              parseInt(dataLicence['cli_id']),
              dataLicence['cli_name'],
              dataLicence['cli_city'],
              new ClientGroup(parseInt(dataLicence['cgr_id']), dataLicence['cgr_name'])
            );

            return objLicence;

          });
        }),
        catchError((error) => {
          return of([]);
        })
      );
  }

  getUserToken(userId: number): Observable<string> {
    return this.http
      .get<any>(
        environment.apiURL +
          'espace_client/get_user_token.php?userId=' +
          userId
      )
      .pipe(
        map((dataUsr: any) => {
          return dataUsr;
        })
      );
  }
  postUser(formData: FormData): Observable<any> {
    return this.http.post<any>(
      environment.apiURL + 'espace_client/post_user.php',
      formData
    );
  }

  activateUser(userId: number, userActivate: number): Observable<any> {
    return this.http.get<any>(
      environment.apiURL +
        'espace_client/activate_user.php?userId=' +
        userId + '&userActivate='+ userActivate
    );
  }

  moveLicence(userId: number, licenceId: number, addLicence: boolean): Observable<any> {
    return this.http.get<any>(
      environment.apiURL +
        'espace_client/post_user_licence.php?userId=' +
        userId + '&licenceId='+ licenceId + '&addLicence=' + (addLicence ? 1 : 0)
    );
  }
  
}
