@defer (when dataLoaded) {
  <div>
    <p-panel [header]="'Ajout/Modification des Outputs' | transloco">
      <form [formGroup]="editForm">
        <div class="formgrid grid">
          <div class="col text-left" style="margin-bottom: 15px;">
            <button
            pButton
            [label]="'Accéder aux Paramètres' | transloco"
            icon="pi pi-cog"
            class="p-button-success"
            (click)="accessToParameters()"
            [disabled]="!addEnabled"
            ></button>
          </div>
          <div class="col text-right">
            <button
            pButton
            [label]="'Ajouter un output' | transloco"
            icon="pi pi-plus"
            class="p-button-success"
            (click)="newOutput()"
            [disabled]="!addEnabled"
            ></button>
          </div>
        </div>

      <div>
        <p-table
          [value]="processOutputList"
          selectionMode="single"
          [tableStyle]="{'align-item': 'center', 'min-width': '50px', 'max-width': '100%'}"
          styleClass="p-datatable-gridlines p-datatable-striped"
          stateStorage="session"
          stateKey="processOutputList-session"
          dataKey="tou_id"
          [(selection)]="selectedOutput"
          [paginator]="true"
          [rows]="20"
          [showCurrentPageReport]="false">
        <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="rou_rot_id" class="text-center">{{ 'Type ' | transloco }}<p-sortIcon field="rou_rot_id"></p-sortIcon></th>
          <th class="text-center">{{ 'Nom' | transloco }}</th>
          <th class="text-center">{{ 'Obligatoire' | transloco }}</th>
          <th class="text-center">{{ 'Chemin' | transloco }}</th>
          <th class="text-center">{{ 'Ordre ' | transloco }}</th>
          <th></th>
        </tr>
        </ng-template>

        <ng-template pTemplate="body" let-output let-ri="rowIndex" style="{'align-item': 'center'}">
        <tr>
          <td class="text-left">{{ output.tot_name }}</td>
          <td class="text-left">{{ output.tou_name }}</td>
          <td class="text-left">{{ output.tou_compulsory == 0 ? 'Non' : 'Oui' }}</td>
          <td class="text-left">{{ output.tou_exec_path }}</td>
          <td class="text-left">{{ output.tou_order }}</td>
          <td>
            <div class="flex align-items-center justify-content-center gap-2">
            <button
            pButton
            type="button"
            icon="pi pi-pencil"
            class="flex p-button-rounded p-button-success mr-2"
            [pTooltip]="'Modifier ce paramètre' | transloco"
            tooltipPosition="left"
            [disabled]="areButtonsDisabled"
            (click)="onRowSelect(output, output.tou_id)"
            >
            </button>
            <button
              pButton
              type="button"
              icon="pi pi-trash"
              class="flex p-button-rounded p-button-unactivate mr-2"
              (click)="deleteOutput(output)"
              [pTooltip]="'Supprimer ce paramètre' | transloco"
              [disabled]="areButtonsDisabled"
              tooltipPosition="left"
            ></button>
            </div>
          </td>
        </tr>
          </ng-template>
        </p-table>
      </div>

  <div *ngIf="outputIsSelected" class="py-5">
    <p-panel [header]='setting | transloco'>
      <form [formGroup]="editForm" class="py-5">
      <div class="formgrid grid">
        <div class="field col-12 md:col-6 xl:col-6">
          <label for="printType" class="label-required">{{
          "Type de sortie" | transloco
          }}</label>
          <p-dropdown
          [style]="{ width: '100%' }"
          [options]="dropdownPrintType"
          class="w-full"
          formControlName="param_printType"
          optionLabel="tot_name"
          optionValue="tot_id"
          [required]="true"
          ></p-dropdown>
          @if(isErrorFormControl(editForm.controls['param_printType'])) {
          <small class="text-red-500">{{ "Champs obligatoire" | transloco }}</small>
          }
        </div>
        <div class="field col-12 md:col-6 xl:col-6">
          <label for="printName" class="label-required">{{ "Nom de l'étiquette" | transloco }}</label>
          <br />
          <input
          type="text"
          pInputText
          formControlName="param_printName"
          [required]="true"
          class="inputName w-full mr-2"
          />
          @if(isErrorFormControl(editForm.controls['param_printName'])) {
          <small class="text-red-500">{{ "Champs obligatoire" | transloco }}</small>
          }
        </div>
        <div class="field col-12 md:col-6 xl:col-6">
          <label for="required" class="label-required">{{
          "Obligatoire ?" | transloco
          }}</label>
          <p-dropdown
          [style]="{ width: '100%' }"
          [options]="ouiNon"
          class="w-full"
          formControlName="param_required"
          [filter]="true"
          optionLabel="label"
          optionValue="value"
          [required]="true"
          ></p-dropdown>
          @if(isErrorFormControl(editForm.controls['param_required'])) {
          <small class="text-red-500">{{ "Champs obligatoire" | transloco }}</small>
          }
        </div>
        <div class="field col-12 md:col-6 xl:col-6">
          <label for="outputPath" class="label-required">{{
          "Chemin" | transloco
          }}</label>
          <p-dropdown
          [style]="{ width: '100%' }"
          [options]="path"
          class="w-full"
          formControlName="param_outputPath"
          [filter]="true"
          optionLabel="label"
          optionValue="value"
          [required]="true"
          ></p-dropdown>
          @if(isErrorFormControl(editForm.controls['param_outputPath'])) {
          <small class="text-red-500">{{ "Champs obligatoire" | transloco }}</small>
          }
        </div>
        <div class="field col-12 md:col-6 xl:col-6">
          <label for="order" class="label-required">{{
          "Ordre" | transloco
          }}</label>
          <br>
          <p-inputNumber
            inputId="param_order"
            formControlName="param_order"
            [min]="1"
            [max]="100"
            [step]=1
            [showButtons]="true"
            [required]="true">
          </p-inputNumber>
          @if(isErrorFormControl(editForm.controls['param_order'])) {
          <small class="text-red-500">{{ "Champs obligatoire" | transloco }}</small>
          }
        </div>
        </div>
        <div class="grid py-1">
          <div class="col-12"></div>
            <div class="col text-center">
              <button
                pButton
                [label]="'Annuler' | transloco"
                icon="pi pi-minus"
                class="p-button-unactivate"
                style = "margin-left: 15px"
                [disabled]="cancelEnabled"
                (click)="cancel()"
              ></button>
              <button
                pButton
                type="submit"
                [label]="'Enregistrer' | transloco"
                icon="pi pi-save"
                class="p-button-success"
                style="margin-left: 20px;"
                [disabled]="!editForm.valid && !modifInProgress"
                (click)="save()"
              ></button>
            </div>
        </div>
      </form>
  </p-panel>
    </div>
  </form>
  </p-panel>
</div>

}@placeholder {
<div class="text-center">{{ 'Chargement en cours...' | transloco }}</div>
}
<p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>
