@defer (when dataLoaded) {
<div class="grid">
    <div class="col-12 md:col-6 xl:col-6">
        <h3 class="pt-1 mt-0">
            <i class="fa fa-square-plus"></i>
            {{ "Ajout de licences" | transloco }}
        </h3>
        <p-table 
            #tableAvailable
            [value]="availableLicences" 
            styleClass="p-datatable-gridlines p-datatable-striped"
            [paginator]="true"
            [rows]="15"
            [showCurrentPageReport]="false"
            [currentPageReportTemplate]="'Lignes {first} à {last} sur un total de {totalRecords}' | transloco"
            >
            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="group.id">{{ 'Groupe' | transloco }} <p-sortIcon field="group.id"></p-sortIcon></th>
                    <th pSortableColumn="name">{{ 'Nom' | transloco }} <p-sortIcon field="name"></p-sortIcon></th>
                    <th pSortableColumn="city">{{ 'Ville' | transloco }} <p-sortIcon field="city"></p-sortIcon></th>
                    @if(canWrite()) {
                        <th></th>
                    }
                </tr>
                <tr>
                    <th>
                      <p-columnFilter field="group.id" matchMode="in" [showMenu]="false">
                        <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                          <p-multiSelect
                            [ngModel]="value"
                            [options]="groups"
                            [placeholder]="'Toutes' | transloco"
                            (onChange)="filter($event.value)"
                            optionLabel="name"
                            optionValue="id"
                            [virtualScroll]="true"
                            [virtualScrollItemSize]="50"
                            class="liste-recherche-table"
                          >
                          </p-multiSelect>
                        </ng-template>
                      </p-columnFilter>
                    </th>
                    <th>
                        <p-columnFilter
                        type="text"
                        field="name"
                        matchMode="contains"
                      ></p-columnFilter>
                      
                    </th>
                    <th>
                      <p-columnFilter
                        type="text"
                        field="city"
                        matchMode="contains"
                      ></p-columnFilter>
                    </th>
                    @if(canWrite()) {
                        <th></th>
                    }
                  </tr>
            </ng-template>
            <ng-template pTemplate="body" let-licence>
                <tr>
                    <td>{{ licence.group.name }}</td>
                    <td>{{ licence.name }}</td>
                    <td>{{ licence.city }}</td>
                    @if(canWrite()) {
                        <td class="center-text pt-1 pb-1">
                            <button
                                pButton
                                icon="fa fa-angle-right"
                                class="p-button-rounded p-button-activate"
                                (click)="moveLicence(licence, true)"
                            ></button>
                        </td>
                    }
                </tr>
            </ng-template>
        </p-table>
    </div>
    <div class=" col-12 md:col-6 xl:col-6">
        <h3 class="pt-1 mt-0">
            <i class="fa fa-plug-circle-check mr-2"></i>
            {{ "Licences affectées" | transloco }}
        </h3>
        <p-table 
            #tableLicences
            [value]="affectedLicences" 
            styleClass="p-datatable-gridlines p-datatable-striped"
            [paginator]="true"
            [rows]="15"    
            [showCurrentPageReport]="true"
            [currentPageReportTemplate]="'Lignes {first} à {last} sur un total de {totalRecords}' | transloco"
            >
            <ng-template pTemplate="header">
                <tr>
                    @if(canWrite()) {
                        <th></th>
                    }
                    <th pSortableColumn="group.id">{{ 'Groupe' | transloco }} <p-sortIcon field="group.id"></p-sortIcon></th>
                    <th pSortableColumn="name">{{ 'Nom' | transloco }} <p-sortIcon field="name"></p-sortIcon></th>
                    <th pSortableColumn="city">{{ 'Ville' | transloco }} <p-sortIcon field="city"></p-sortIcon></th>
                </tr>
                <tr>
                    @if(canWrite()) {
                        <th></th>
                    }
                    <th>
                      <p-columnFilter field="group.id" matchMode="in" [showMenu]="false">
                        <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                          <p-multiSelect
                            [ngModel]="value"
                            [options]="groups"
                            [placeholder]="'Toutes' | transloco"
                            (onChange)="filter($event.value)"
                            optionLabel="name"
                            optionValue="id"
                            [virtualScroll]="true"
                            [virtualScrollItemSize]="50"
                            class="liste-recherche-table"
                          >
                          </p-multiSelect>
                        </ng-template>
                      </p-columnFilter>
                    </th>
                    <th>
                        <p-columnFilter
                        type="text"
                        field="name"
                        matchMode="contains"
                      ></p-columnFilter>
                      
                    </th>
                    <th>
                      <p-columnFilter
                        type="text"
                        field="city"
                        matchMode="contains"
                      ></p-columnFilter>
                    </th>
                  </tr>
            </ng-template>
            <ng-template pTemplate="body" let-licence>
                <tr>
                    @if(canWrite()) {
                        <td class="center-text pt-1 pb-1">
                            <button
                                pButton
                                icon="fa fa-angle-left"
                                class="p-button-rounded p-button-unactivate"
                                (click)="moveLicence(licence, false)"
                            ></button>
                        </td>
                    }
                    <td>{{ licence.group.name }}</td>
                    <td>{{ licence.name }}</td>
                    <td>{{ licence.city }}</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>
} @placeholder {
<div class="text-center">{{ "Chargement en cours..." | transloco }}</div>
}