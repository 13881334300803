import { Client } from "./client";

export class CustomTable {
    public id: number;
    public name: string;
    public client: Client;

    constructor(
        id: number,
        name: string,
        client: Client,
        ) {
        this.id = id;
        this.name = name;
        this.client = client;
    }
}