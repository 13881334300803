@defer (when dataLoaded) {
<div>
<p-panel [header]="'Gestion des Outputs' | transloco">
  <form [formGroup]="editForm">
    <div class="formgrid grid">
      <div class="col text-left" style="margin-bottom: 15px;">
        <button
        pButton
        [label]="'Accéder aux Paramètres' | transloco"
        icon="pi pi-cog"
        class="p-button-success"
        (click)="accessToParameters()"
        [disabled]="!addEnabled"
        ></button>
      </div>
      <div class="col text-right" style="margin-bottom: 15px;">
        <button
        pButton
        [label]="'Ajouter un Output' | transloco"
        icon="pi pi-plus"
        class="p-button-success"
        (click)="addOutputReception()"
        [disabled]="!addEnabled"
        ></button>
      </div>
    </div>

<div>
  <p-table
    [value]="receptionOutputList"
    selectionMode="single"
    [tableStyle]="{'align-item': 'center', 'max-width': '100%'}"
    styleClass="p-datatable-gridlines p-datatable-striped"
    stateStorage="session"
    stateKey="receptionOutputList-session"
    currentPageReportTemplate="Lignes {first} à {last} sur un total de {totalRecords}"
    dataKey="rou_id"
    [(selection)]="selectedOutput"
    selectionMode="single"
    [paginator]="true"
    [rows]="20"
    [showCurrentPageReport]="false">

    <ng-template pTemplate="header">
    <tr>
    <th rowspan="3" >{{ 'Type de réception' | transloco }}</th>
    <th colspan="4" class="text-center">{{ 'Output' | transloco }}</th>
    <th></th>
    <th></th>
    </tr>
    <tr></tr>
    <tr>

    <th pSortableColumn="rou_rot_id" class="text-left">{{ 'Type de sortie ' | transloco }}<p-sortIcon field="rou_rot_id"></p-sortIcon></th>
    <th class="text-left">{{ 'Nom de l\'étiquette' | transloco }}</th>
    <th class="text-left">{{ 'Obligatoire' | transloco }}</th>
    <th class="text-left">{{ 'Chemin' | transloco }}</th>
    <th class="text-left">{{ 'Ordre ' | transloco }}</th>
    <th>Actions</th>
    </tr>
    </ng-template>

    <ng-template pTemplate="body"
      let-output
      let-ri="rowIndex"
      style="{'align-item': 'center'}">
    <tr>
    <td  class="text-left">{{ output.cpr_name }}</td>
    <td class="text-left">{{ output.rot_name }}</td>
    <td class="text-left">{{ output.rou_name }}</td>
    <td class="text-left">{{ output.rou_compulsory == 0 ? 'Non' : 'Oui' }}</td>
    <td class="text-left">{{ output.rou_exec_path }}</td>
    <td class="text-left">{{ output.rou_order }}</td>
    <td>
      <div class="flex align-items-center justify-content-center gap-2">
      <button
        pButton
        type="button"
        icon="pi pi-pencil"
        class="flex p-button-rounded p-button-success mr-2"
        [pTooltip]="'Modifier cet Output' | transloco"
        tooltipPosition="left"
        (click)="onRowSelect(output, output.cpr_id)"
        [disabled]="areButtonsDisabled"
      ></button>
      <button
        pButton
        type="button"
        icon="pi pi-trash"
        class="flex p-button-rounded p-button-unactivate mr-2"
        (click)="deleteReceptionOutput(output)"
        [pTooltip]="'Supprimer cet Output' | transloco"
        [disabled]="areButtonsDisabled"
        tooltipPosition="left"
      ></button>
      </div>
    </td>
   </tr>
  </ng-template>
</p-table>
</div>

<div *ngIf="displayTableau" class="py-5">
  <p-panel [header]='setting | transloco'>
    <form [formGroup]="editForm" (ngSubmit)="save()" >
      <div class="formgrid grid">
            <div class="field col-12 md:col-6 xl:col-6">
              <label for="receptionType">{{
                "Type de réception" | transloco
                }}</label>
                <br />
                <input
                type="text"
                pInputText
                  formControlName="param_receptionType"
                class="inputName w-full mr-2"
                />
            </div>
            <div class="field col-12 md:col-6 xl:col-6">
              <label for="printType" class="label-required">{{
                "Type de sortie" | transloco
                }}</label>
              <p-dropdown
                [style]="{ width: '100%' }"
                [options]="dropdownPrintType"
                class="w-full"
                formControlName="param_printType"
                optionLabel="rot_name"
                optionValue="rot_id"
                [required]="true"
              ></p-dropdown>
              @if(isErrorFormControl(editForm.controls['param_printType'])) {
              <small class="text-red-500">{{ "Champs obligatoire" | transloco }}</small>
              }
            </div>
            <div class="field col-12 md:col-6 xl:col-6">
              <label for="printName" class="label-required">{{ "Nom de l'étiquette" | transloco }}</label>
              <br />
              <input
                type="text"
                pInputText
                formControlName="param_printName"
                [required]="true"
                class="inputName w-full mr-2"
              />
              @if(isErrorFormControl(editForm.controls['param_printName'])) {
              <small class="text-red-500">{{ "Champs obligatoire" | transloco }}</small>
              }
            </div>
            <div class="field col-12 md:col-6 xl:col-6">
              <label for="required" class="label-required">{{
                "Obligatoire ?" | transloco
                }}</label>
              <p-dropdown
                [style]="{ width: '100%' }"
                [options]="ouiNon"
                class="w-full"
                formControlName="param_required"
                optionLabel="label"
                optionValue="value"
                [required]="true"
              ></p-dropdown>
              @if(isErrorFormControl(editForm.controls['param_required'])) {
              <small class="text-red-500">{{ "Champs obligatoire" | transloco }}</small>
              }
            </div>
            <div class="field col-12 md:col-6 xl:col-6">
              <label for="outputPath" class="label-required">{{
                "Chemin" | transloco
                }}</label>
              <p-dropdown
                [style]="{ width: '100%' }"
                [options]="path"
                class="w-full"
                formControlName="param_outputPath"
                [filter]="true"
                optionLabel="label"
                optionValue="value"
                [required]="true"
              ></p-dropdown>
              @if(isErrorFormControl(editForm.controls['param_outputPath'])) {
              <small class="text-red-500">{{ "Champs obligatoire" | transloco }}</small>
              }
            </div>
            <div class="field col-12 md:col-6 xl:col-6">
            <label for="param_order" class="label-required">{{ "Ordre" | transloco }}</label>
            <br />
            <p-inputNumber
              inputId="param_order"
              formControlName="param_order"
              [min]="1"
              [max]="100"
              [step]=1
              [style]="{ height: '45px' }"
              [showButtons]="true"
              [required]="true"
            ></p-inputNumber>
              @if(isErrorFormControl(editForm.controls['param_order'])) {
              <small class="text-red-500">{{ "Champs obligatoire" | transloco }}</small>
              }
            </div>
      </div>
      <div class="grid py-1">
        <div class="col-12"></div>
         <div class="col-12 md:col-12 xl:col-12 text-center">
          <button
            pButton
            [label]="'Annuler' | transloco"
            icon="pi pi-minus"
            class="p-button-unactivate"
            [disabled]="cancelEnabled"
            (click)="cancel()"
          ></button>
          <button
            pButton
            [label]="'Enregistrer' | transloco"
            icon="pi pi-save"
            class="p-button-success"
            style = "margin-left: 15px"
            [disabled]="!editForm.valid || !modifInProgress"
            (click)="save()"
          ></button>
        </div>
      </div>
    </form>
  </p-panel>
    </div>
  </form>
</p-panel>
</div>

}@placeholder {
<div class="text-center">{{ 'Chargement en cours...' | transloco }}</div>
}
<p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>
<p-toast position="top-center"></p-toast>
