import { Client } from "./client";
import { SurveyPage } from "./survey-page";

export class Survey {
    public id: number;
    public name: string;
    public comment: string;
    public client: Client;
    public pages: SurveyPage[];
    
    constructor(
        id: number,
        name: string,
        comment: string,
        client: Client,
        pages: SurveyPage[] = []
    ) {
        this.id = id;
        this.name = name;
        this.comment = comment;
        this.client = client;
        this.pages = pages;
    }
}