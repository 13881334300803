import { Injectable } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';

@Injectable({
    providedIn: 'root',
  })

export class HandleDialogService {
    dialogRef: DynamicDialogRef | undefined;

    constructor(
        public dialogService: DialogService,
    ) {};

    showDialog(componentType:any, headerLabel:string, data:any) {
        let width = (data.width ? data.width : '80%');
        let minHeight = (data.minHeight ? data.minHeight : '700px');
   
        this.dialogRef = this.dialogService.open(componentType, {
              header: headerLabel,
              width,
              contentStyle: { 
                'min-height': minHeight, 
                'overflow': 'auto' },
              data,
          });

          return this.dialogRef.onClose; // c'est un observable
      }
}