import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { FormArray, FormsModule, FormGroup, FormBuilder, Validators, ReactiveFormsModule, AbstractControlOptions  } from '@angular/forms';
import { Subscription } from 'rxjs';


import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { MultiSelectModule } from 'primeng/multiselect';
import { PanelModule } from 'primeng/panel';

import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco'; 
import { EditComponent } from '../../../core/edit/edit.component';
import { SessionStorageService } from '../../../../services/storage/session-storage.service';
import { Frequency } from '../../../../models/frequency';
import { CalendarModule } from 'primeng/calendar';

import { AppConstants } from '../../../../constants/app.constants';
import { ApiMailReportsService } from '../../../../services/api/api-mail-reports.service';
import moment from 'moment';
import { MailReport } from '../../../../models/mail-report';

@Component({
  selector: 'app-mail-reports-edit',
  templateUrl: './mail-reports-edit.component.html',
  styleUrl: './mail-reports-edit.component.css',
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  imports: [
    ButtonModule,
    CalendarModule,
    DropdownModule,
    InputNumberModule,
    MultiSelectModule,
    FormsModule,
    PanelModule,
    ReactiveFormsModule,
    TranslocoModule
  ],
})
export class MailReportsEditComponent extends EditComponent implements OnInit, OnDestroy {

  frequencies: any = [];
  globalDatas: any = [];

  days: any = [];
    
  mailReportId: string = '';
  mailReportSubscription: Subscription = new Subscription();
  mailReport: MailReport | undefined;
  editForm: FormGroup;

  saveError:boolean = false;
  
  constructor(
    private apiMailReportService : ApiMailReportsService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private sessionStorageService: SessionStorageService,
    private router: Router,
  ) {
      super(route);

      this.editForm = this.formBuilder.group({
        mre_title: ['', Validators.required],
        mre_fre_id: [null, Validators.required],
        mre_day_id: ['0'],
        mre_start_date: [null],
        recipients: this.formBuilder.array([]) 
      }, { validators: this.startDateValidator } as AbstractControlOptions); 
   }

   startDateValidator(group: FormGroup) {
    const frequency = group.controls['mre_fre_id'].value;
    const startDate = group.controls['mre_start_date'].value;

    if (frequency && frequency.code != AppConstants.CODE_PERIODE_SEMAINE && !startDate) {
      return { requiredDate: true };
    } else {
      return null;
    }
  }

  override ngOnInit() {
    super.ngOnInit();

    this.globalDatas = this.sessionStorageService.get('globalDatas');
    this.frequencies = this.globalDatas?.frequencies;
    this.days = this.globalDatas?.days;

    this.mailReportId = this.route.snapshot.paramMap.get('id') ?? '';

    if(this.mailReportId != '') {
      this.mailReportSubscription = this.apiMailReportService
        .getMailReport(this.mailReportId)
        .subscribe((mailReport: MailReport) => {
          this.mailReport = mailReport;

          let foundFre = this.frequencies.find((oneFreq: Frequency) => oneFreq.id ==  mailReport.frequency.id);

          this.editForm.controls['mre_title'].setValue(mailReport.title);
          this.editForm.controls['mre_fre_id'].setValue(foundFre);
          this.editForm.controls['mre_day_id'].setValue(mailReport.day?.id);
          this.editForm.controls['mre_start_date'].setValue(mailReport.startDate);

          this.dataLoaded = true;

          mailReport.recipients.forEach((oneRecipient: any) => {
            this.addRecipient(oneRecipient.mrr_email);
          });
        });
    } else {
      this.addRecipient();
      this.dataLoaded = true;
    }  
  }
  ngOnDestroy() {
      this.mailReportSubscription.unsubscribe();
  }
  
  addRecipient(emailValue:string = '') {
    const emailField = this.formBuilder.control(emailValue, [Validators.required, Validators.email]);
    (this.editForm.get('recipients') as FormArray).push(emailField);
  }
  removeEmail(index: number) {
    (this.editForm.get('recipients') as FormArray).removeAt(index);
  }
  get emailControls() {
    return (this.editForm.get('recipients') as FormArray).controls;
  }

  isWeekPeriod(valPeriode:string): boolean {
    return valPeriode == AppConstants.CODE_PERIODE_SEMAINE;
  }

  save() {    
    
    const formData = {
      mre_id: this.mailReportId,
      mre_title: this.editForm.controls['mre_title'].value,
      mre_fre_id: this.editForm.controls['mre_fre_id'].value.id,
      mre_day_id: (this.isWeekPeriod(this.editForm.controls['mre_fre_id'].value.code) ? this.editForm.controls['mre_day_id'].value : null),
      mre_start_date: (!this.isWeekPeriod(this.editForm.controls['mre_fre_id'].value.code) ? moment(this.editForm.controls['mre_start_date'].value).format('YYYY-MM-DD') : null),
      recipients: this.editForm.controls['recipients'].value
    };

    this.apiMailReportService.postMailReport(formData)
    .subscribe(response => {
      this.saveError = !response;

      if(response) {
        this.router.navigate(['settings/mail-reports']);
      }
    });
  }
}
