@defer (when dataLoaded) {
  <form [formGroup]="editForm" style="display: block" (ngSubmit)="save()">
    <p-panel [header]="titlePage">
      <div class="field">
        <label for="libelle" class="label-required">{{
          "Libellé" | transloco
        }}</label>
        <input
          type="text"
          pInputText
          id="libelle"
          formControlName="libelle"
          required="true"
          autofocus
          class="p-inputtext w-full"
        />
        @if(isErrorFormControl(editForm.controls['libelle'])) {
        <small class="text-red-500">{{ "Libellé requis" | transloco }}</small>
        }
      </div>
      <div class="field">
        <label for="datas" class="label-required">{{
          "Données" | transloco
        }}</label>
        <input
          type="text"
          pInputText
          id="datas"
          formControlName="datas"
          required="true"
          autofocus
          class="p-inputtext w-full"
        />
        @if(isErrorFormControl(editForm.controls['datas'])) {
        <small class="text-red-500">{{ "Libellé requis" | transloco }}</small>
        }
      </div>

      @if(saveError) {
      <div class="text-red-500">
        {{ "Une erreur est survenue lors de l'enregistrement" | transloco }}
      </div>
      }
      <div class="flex overflow-hidden">
        <div class="flex-grow-1 flex align-items-center justify-content-center">
          <p-button
            type="submit"
            icon="pi pi-save"
            [disabled]="!editForm.valid"
            [label]="'Enregistrer' | transloco"
          ></p-button>
        </div>
        <div
          class="flex-none flex align-items-center justify-content-center mr-3"
        >
          <div class="label-required">
            {{ "Champs obligatoires" | transloco }}
          </div>
        </div>
      </div>
    </p-panel>
  </form>
}