export class SurveyQuestion {
    public id: number;
    public name: string;
    public reference: string;
    public order: number;
    public qtyId: number;
    public imoId: number;
    public placeholder: string;
    public valDefault: string;
    public required: number;
    public valuesList: string;
    
    constructor(
        id: number,
        name: string,
        reference: string,
        order: number,
        qtyId: number,
        imoId: number,
        placeholder: string,
        valDefault: string,
        required: number,
        valuesList: string
    ) {
        this.id = id;
        this.name = name;
        this.reference = reference;
        this.order = order;
        this.qtyId = qtyId;
        this.imoId = imoId;
        this.placeholder = placeholder;
        this.valDefault = valDefault;
        this.required = required;
        this.valuesList = valuesList;
    }
}