import { Day } from "./day";
import { Frequency } from "./frequency";
import { User } from "./user";

export class MailReport {
    public id: number;
    public frequency: Frequency;
    public day: Day | null;
    public title: string;
    public startDate: Date | null;
    public lastDate: Date | null;
    public user: User;
    public recipients: Array<string>;
  
    constructor(
      id: number,
      frequency: Frequency,
      day: Day | null,
      title: string,
      startDate: Date | null,
      lastDate: Date | null,
      user: User,
      recipients: Array<string>
    ) {
      this.id = id;
      this.frequency = frequency;
      this.day = day;
      this.title = title;
      this.startDate = startDate;
      this.lastDate = lastDate;
      this.user = user;
      
      //destinataires
      this.recipients = recipients;
    }
  }
  