import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { TranslocoModule, TranslocoService } from '@ngneat/transloco';

import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { FieldsetModule } from 'primeng/fieldset';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';

import { Subscription } from 'rxjs';

import { ApiEshopService } from '../../../services/api/api-eshop.service';
import { AuthService } from '../../../services/auth/auth.service';
import { EshopOrder } from '../../../models/eshop-order';
import { OrderService } from '../../../services/eshop/order.service';

import { EshopOrderProduct } from '../../../models/eshop-order-product';


@Component({
  selector: 'app-eshop-order-detail',
  templateUrl: './eshop-order-detail.component.html',
  styleUrl: './eshop-order-detail.component.css',
  standalone: true,
  imports: [
    ButtonModule,
    CommonModule,
    DropdownModule,
    FieldsetModule,
    ReactiveFormsModule,
    TableModule,
    ToastModule,
    TranslocoModule,
  ],
  providers:[MessageService]
})

export class EshopOrderDetailComponent implements OnDestroy, OnInit {
  
  ordersSubscription: Subscription = new Subscription();
  order: any;
  dataLoaded: boolean = false;
  LIBELLE_STATUT: any;
  LIBELLE_PAIEMENT: any;
  orderId:string = '';

  statuts: any = [];

  statusForm: FormGroup;

  constructor(
    private activeRoute: ActivatedRoute,
    private authService: AuthService,
    private apiEshopService: ApiEshopService,
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    public orderService: OrderService,
    private router: Router,
    private translocoService: TranslocoService
  ) {
    this.LIBELLE_STATUT = this.orderService.LIBELLE_STATUT;
    this.LIBELLE_PAIEMENT = this.orderService.LIBELLE_PAIEMENT;

    this.statuts = Object.keys(this.LIBELLE_STATUT).map(key => {
      return { label: this.LIBELLE_STATUT[key], value: key };
    });

    this.statusForm = this.formBuilder.group({
      orderStatus: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.activeRoute.paramMap.subscribe(params => {
      this.orderId = params.get('id') ?? '';
    
        if (this.orderId !== '') {
          this.ordersSubscription = this.apiEshopService
            .getOneOrder(this.orderId)
            .subscribe((data: EshopOrder) => {
              this.order = data;
              this.dataLoaded = true;

              this.statusForm.controls['orderStatus'].setValue(this.order.status);
          });
        }
    });
  }

  ngOnDestroy(): void {
    this.ordersSubscription.unsubscribe();
  }

  save() {
    if(this.isRoot()) {
      let formData = new FormData();    

      const formControls = Object.keys(this.statusForm.controls);
      formData.append("orderId", this.orderId);

      // Parcourez les clés
      formControls.forEach(controlName => {
        // Vous pouvez accéder au contrôle individuel par son nom
        const control = this.statusForm.get(controlName);

        if(control) {
          formData.append(controlName, control.value);
        }
      });
      
      this.apiEshopService.postOrderStatus(formData)
      .subscribe(response => {
        if(response) {
          this.messageService.add({ 
            severity: 'success', 
            summary: this.translocoService.translate("Succès"), 
            detail: this.translocoService.translate("Statut de la commande enregistré") 
          });
        }
      });
    }
  }

  isRoot(): boolean {
    return this.authService.isRoot();
  }

  redirectProduct(oneProduct: EshopOrderProduct) {
    this.router.navigate(['eshop/product/', oneProduct.id, oneProduct.titleForUrl]);
  }
}
