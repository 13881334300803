import { Component, OnDestroy, OnInit } from '@angular/core';
import { TableModule } from 'primeng/table';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco'; 
import { Client } from '../../../../models/client';
import { AuthService } from '../../../../services/auth/auth.service';
import { ButtonModule } from 'primeng/button';
import { Subscription } from 'rxjs';
import { ApiClientsService } from '../../../../services/api/api-clients.service';
import { Router } from '@angular/router';
import { ClientGroup } from '../../../../models/client-group';
import { MultiSelectModule } from 'primeng/multiselect';
import { CommonModule } from '@angular/common';
import { CheckboxModule } from 'primeng/checkbox';
import { FormsModule } from '@angular/forms';
import { environment } from '../../../../../environments/environment';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { SynchroService } from '../../../../services/utils/synchro.service';
import { DialogModule } from 'primeng/dialog';

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [
    ButtonModule,
    CheckboxModule,
    CommonModule,
    DialogModule,
    FormsModule,
    MultiSelectModule,
    TableModule,
    ToastModule,
    TranslocoModule
  ],
  templateUrl: './licences-list.component.html',
  styleUrl: './licences-list.component.css',
  providers:[MessageService]
})
export class LicencesListComponent implements OnInit, OnDestroy {
  licences: Client[] = [];
  dataLoaded: boolean = false;
  clientSubscription: Subscription = new Subscription();
  groups: ClientGroup[] = [];
  displayCanceled:boolean = false;
  modalVisible:boolean = false;
  clientId:number= 0;
  retourSynchro:string = '';
  synchroLaunched:number = 0;

  constructor(
    private authService: AuthService, 
    private apiClientsService: ApiClientsService,
    private messageService: MessageService,
    private router: Router,
    private synchroService: SynchroService,
    private translocoService: TranslocoService,) {

    }

  ngOnInit() {
    this.loadClients();
    this.groups = this.authService.getUserGroups();
  }

  loadClients() {
    this.clientSubscription = this.apiClientsService
    .getClients(this.displayCanceled)
    .subscribe((dataClients: Client[]) => {
      this.licences = dataClients;
      this.dataLoaded = true;
    });
  }
  connectV3(oneClient: Client) {
      this.apiClientsService
      .getToken(oneClient.id)
      .subscribe((response: any) => {
        if (response) {
          window.open(environment.v3URL+'token?appToken='+ response);
        } else {
          this.messageService.add({ 
            severity: 'error', 
            summary: this.translocoService.translate("Erreur"), 
            detail: this.translocoService.translate("Aucun utilisateur. Connexion impossible") 
          });
        }
      });
  }
  chooseSynchro(oneClient:Client) {
    this.retourSynchro = '';
    this.synchroLaunched = 0;

    this.clientId = oneClient.id;
    this.modalVisible = true;
  }

  launchSynchro(typeSynchro:number) {
    this.synchroLaunched = 1;

    this.synchroService.launch(this.clientId, typeSynchro).subscribe((response:any) => {
      if(response == '') {
        this.retourSynchro = 'Erreur lors du lancement de la synchro';
      } else {
        this.retourSynchro = response.join('\n');
      }
      this.synchroLaunched = 2;
    });
  }

  ngOnDestroy(): void {
    if(this.clientSubscription) {
      this.clientSubscription.unsubscribe();
    }
  }

  isRoot(): boolean {
    return this.authService.isRoot();
  }

  canWrite(): boolean {
    return this.authService.canWrite();
  }

  editLicence(oneClient:Client) {
    this.router.navigate(['settings/licences-edit', oneClient.id]);
  }

  addLicence() {
    this.router.navigate(['settings/licences-add']);
  }

}
