@defer (when dataLoaded) {
<form [formGroup]="editForm" (ngSubmit)="save()">
  <p-panel [header]="titlePage">
    <div class="field">
      <label for="mre_title" class="label-required">{{
        "Titre" | transloco
        }}</label>
      <input type="text" pInputText id="mre_title" formControlName="mre_title" required="true" autofocus
        class="p-inputtext w-full" />
      @if(isErrorFormControl(editForm.controls['mre_title'])) {
      <small class="text-red-500">{{ "Titre requis" | transloco }}</small>
      }
    </div>


    <h3><i class="fa fa-clock fa-lg"></i> {{ 'Planification :' | transloco }}</h3>
    
    <div class="formgrid grid">
      <div class="field col-12 md:col-6 xl:col-6">
        <div class="field">
          <label for="mre_fre_id" class="label-required">{{ 'Fréquence' | transloco }}</label>
          <p-dropdown autoWidth="false" [style]="{ width: '100%' }" [options]="frequencies" class="w-full"
            formControlName="mre_fre_id" optionLabel="name" [placeholder]="'Sélectionnez une fréquence' | transloco"
            [required]="true"></p-dropdown>
          @if(isErrorFormControl(editForm.controls['mre_fre_id'])) {
          <small class="text-red-500">{{ 'Fréquence requise' | transloco }}</small>
          }
        </div>
      </div>
      <div class="field col-12 md:col-6 xl:col-6">
        @if(editForm.controls['mre_fre_id'].value) {
          @if(this.isWeekPeriod(editForm.controls['mre_fre_id'].value.code)) {
          <div class="field">
            <label for="mre_day_id" class="label-required">{{ 'Jour' | transloco }}</label>
            <p-dropdown autoWidth="false" [style]="{ width: '100%' }" [options]="days" class="w-full"
              formControlName="mre_day_id" optionLabel="name" optionValue="id"
              [placeholder]="'Sélectionnez une fréquence' | transloco" [required]="true"></p-dropdown>
            @if(isErrorFormControl(editForm.controls['mre_day_id'])) {
            <small class="text-red-500">{{ 'Fréquence requise' | transloco }}</small>
            }
          </div>
          }
          @else {
          <div class="field">
            <label for="mre_start_date" class="label-required">{{ 'À partir de' | transloco }}</label>

            <p-calendar [readonlyInput]="false" [style]="{ width: '100%' }" class="w-full" dateFormat="dd/mm/yy"
              formControlName="mre_start_date">
            </p-calendar>
            @if(hasFormError(editForm, 'requiredDate')) {
              <br /><small class="text-red-500">{{
                "Date de début requise"
                  | transloco
              }}</small>
              }
          </div>
          }
        }
      </div>
    </div>

    <h3><i class="fa fa-envelopes-bulk fa-lg"></i> {{ 'Destinataires des rapports :' | transloco }}</h3>

    <div formArrayName="recipients">
      @for (email of emailControls; track email; let idx = $index) {
      <div class="field">
        <label [for]="idx" class="label-required">{{ 'Email destinataire N°' | transloco }}{{ idx + 1}}</label>
        <div class="flex">
          <input pInputText type="email" [id]="idx" [formControlName]="idx" class="p-inputtext w-full mr-2">
          @if(emailControls.length > 1) {
          <button pButton icon="pi pi-trash" class="p-button-p-button-success" (click)="removeEmail(idx)"></button>
          }
        </div>
        @if(email.invalid && (email.dirty || email.touched)) {
        @if(email['errors'] && email['errors']['required']) {
        <br /><small class="text-red-500">{{ 'L\'email est requis.' | transloco }}</small>
        }
        @if(email['errors'] && email['errors']['email']) {
        <br /><small class="text-red-500">{{ 'L\'email est invalide.' | transloco }}</small>
        }
        }
      </div>
      }
    </div>

    <button pButton [label]="'Ajouter un destinataire supplémentaire' | transloco" icon="pi pi-plus"
      class="p-button-success mb-2" type="button" (click)="addRecipient()"></button>

    @if(saveError) {
    <div class="text-red-500">
      {{ "Une erreur est survenue lors de l'enregistrement" | transloco }}
    </div>
    }

    <div class="flex overflow-hidden">
      <div class="flex-grow-1 flex align-items-center justify-content-center">
        <p-button type="submit" icon="pi pi-save" [disabled]="!editForm.valid"
          [label]="'Enregistrer' | transloco"></p-button>
      </div>
      <div class="flex-none flex align-items-center justify-content-center mr-3">
        <div class="label-required">{{ "Champs obligatoires" | transloco }}</div>
      </div>
    </div>
  </p-panel>
</form>
}