@defer (when dataLoaded) { @if(cartProducts.length == 0) {
<div class="text-center">{{ "Aucun produit dans le panier" | transloco }}</div>
} @else {
<form [formGroup]="facturationForm" (ngSubmit)="onSubmit()">
  <div class="grid flex">
    <div class="col-12 md:col-6 lg:col-6">
      <p-panel [header]="'Détails de facturation' | transloco">
        <div class="formgrid grid">
          <div class="field col-12 md:col-6 lg:col-6">
            <label for="nom" class="label-required">{{ 'Nom' | transloco }}</label>
            <input
              id="nom"
              type="text"
              pInputText
              formControlName="nom"
              maxlength="100"
              required="true"
              class="p-inputtext w-full"
            />
            @if(isErrorFormControl(facturationForm.controls['nom'])) {
            <small class="text-red-500">{{ "Nom requis" | transloco }}</small>
            }
          </div>

          <div class="field col-12 md:col-6 lg:col-6">
            <label for="prenom" class="label-required">{{ 'Prénom' | transloco }}</label>
            <input
              id="prenom"
              type="text"
              pInputText
              formControlName="prenom"
              maxlength="100"
              class="p-inputtext w-full"
            />
            @if(isErrorFormControl(facturationForm.controls['prenom'])) {
            <small class="text-red-500">{{
              "Prénom requis" | transloco
            }}</small>
            }
          </div>

          <div class="field col-12">
            <label for="nomEntreprise">{{ 'Nom de l\'entreprise' | transloco }}</label>
            <input
              id="nomEntreprise"
              type="text"
              maxlength="100"
              pInputText
              formControlName="nomEntreprise"
              class="p-inputtext w-full"
            />
          </div>

          <div class="field col-12 md:col-6 lg:col-6">
            <label for="rue" class="label-required"
              >{{ 'Numéro et nom de la rue' | transloco }}</label
            >
            <input
              id="rue"
              type="text"
              pInputText
              formControlName="rue"
              maxlength="150"
              class="p-inputtext w-full"
            />
            @if(isErrorFormControl(facturationForm.controls['rue'])) {
            <small class="text-red-500">{{ "Rue requise" | transloco }}</small>
            }
          </div>

          <div class="field col-12 md:col-6 lg:col-6">
            <label for="batiment">{{ 'Bâtiment, appartement, lot, ...' | transloco }}</label>
            <input
              id="batiment"
              type="text"
              pInputText
              maxlength="150"
              formControlName="batiment"
              class="p-inputtext w-full"
            />
          </div>

          <div class="field col-12 md:col-6 lg:col-6">
            <label for="codePostal" class="label-required">{{ 'Code postal' | transloco }}</label>
            <input
              id="codePostal"
              type="text"
              maxlength="30"
              pInputText
              formControlName="codePostal"
              class="p-inputtext w-full"
            />
            @if(isErrorFormControl(facturationForm.controls['codePostal'])) {
            <small class="text-red-500">{{
              "Code postal requis" | transloco
            }}</small>
            }
          </div>

          <div class="field col-12 md:col-6 lg:col-6">
            <label for="ville" class="label-required">{{ 'Ville' | transloco }}</label>
            <input
              id="ville"
              type="text"
              maxlength="100"
              pInputText
              formControlName="ville"
              class="p-inputtext w-full"
            />
            @if(isErrorFormControl(facturationForm.controls['ville'])) {
            <small class="text-red-500">{{
              "Ville requise" | transloco
            }}</small>
            }
          </div>
          <div class="field col-12">
            <label for="pays" class="label-required">{{ 'Pays' | transloco }}</label>
            <p-dropdown
              [options]="countries"
              formControlName="pays"
              optionLabel="name"
              optionValue="name"
              [style]="{ width: '100%' }"
            ></p-dropdown>
            @if(isErrorFormControl(facturationForm.controls['pays'])) {
            <small class="text-red-500">{{ "Pays requis" | transloco }}</small>
            }
          </div>

          <div class="field col-12 md:col-6 lg:col-6">
            <label for="telephone" class="label-required">{{ 'Téléphone' | transloco }}</label>
            <input
              id="telephone"
              type="text"
              maxlength="20"
              pInputText
              formControlName="telephone"
              class="p-inputtext w-full"
            />
            @if(isErrorFormControl(facturationForm.controls['telephone'])) {
            <small class="text-red-500">{{
              "Téléphone requis" | transloco
            }}</small>
            }
          </div>

          <div class="field col-12 md:col-6 lg:col-6">
            <label for="email" class="label-required">{{ 'Adresse email' | transloco }}</label>
            <input
              id="email"
              type="text"
              maxlength="150"
              pInputText
              formControlName="email"
              class="p-inputtext w-full"
            />
            @if(isErrorFormControl(facturationForm.controls['email'])) {
            <small class="text-red-500">{{
              "Adresse email requise" | transloco
            }}</small>
            }
          </div>

          <div class="field col-12">
            <label for="notes">{{ 'Notes de commande' | transloco }}</label>
            <textarea
              id="notes"
              pInputTextarea
              formControlName="notes"
              class="p-inputtext w-full"
              [placeholder]="'Remarques concernant votre commande, par ex. notes spéciales pour la livraison.' | transloco"
            ></textarea>
          </div>
        </div>
      </p-panel>
    </div>

    <div class="col-12 md:col-6 lg:col-6">
      <div class="grid flex gap-2 mt-0 mr-0">
        <p-panel
          [header]="'Résumé de votre commande' | transloco"
          class="panel-eshop-order"
        >
          <p-table [value]="cartProducts" class="table-eshop-order">
            <ng-template pTemplate="header">
              <tr>
                <th>{{ "Produit" | transloco }}</th>
                <th class="text-right col-sous-total">
                  {{ "Quantité" | transloco }}
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-product>
              <tr>
                <td>
                  {{ product.productObject.title }}
                </td>
                <td class="text-right col-sous-total">
                  {{ product.quantity }}
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="footer">
              <tr>
                <td class="text-right">{{ "Sous total HT" | transloco }}</td>
                <td class="text-right">
                  {{ cartTotalPrice | number : "1.2-2" }}&euro;
                </td>
              </tr>
              <tr>
                <td class="text-right">{{ "TVA" | transloco }}</td>
                <td class="text-right">
                  {{ cartTotalTax | number : "1.2-2" }}&euro;
                </td>
              </tr>
              <tr>
                <td class="text-right">{{ "Total TTC" | transloco }}</td>
                <td class="text-right">
                  {{ cartTotalPrice + cartTotalTax | number : "1.2-2" }}&euro;
                </td>
              </tr>
            </ng-template>
          </p-table>
        </p-panel>
        <p-panel [header]="'Conditions générales de vente' | transloco">
          {{
            "Vos données personnelles seront utilisées pour le traitement de votre commande, vous accompagner au cours de votre visite du site web, et pour d'autres raisons décrites dans notre"
              | transloco
          }}
          <a (click)="openConfidentialite()" class="linkDoc"
            >{{ "politique de confidentialité" | transloco }} </a
          >.

          <div class="field col-12 mt-2 mb-0">
            <p-checkbox
              class="mb-1"
              [binary]="true"
              id="accepteCGV"
              formControlName="accepteCGV"
              value="1"
            ></p-checkbox>
            <label for="accepteCGV" class="label-required ml-2">
              {{ "J'ai lu et j'accepte les" | transloco }}
              <a (click)="openCGV()" class="linkDoc"
                >{{ "conditions générales de vente" | transloco }}
              </a>
            </label>
            @if(isErrorFormControl(facturationForm.controls['accepteCGV'])) {
            <p>
              <small class="text-red-500">{{
                "Vous devez accepter les conditions générales de vente"
                  | transloco
              }}</small>
            </p>
            }
          </div>
        </p-panel>
        <p-panel
          [header]="'Mode de paiement' | transloco"
          class="panel-eshop-payment"
        >
          <div class="field col-12">
            <p-radioButton
              formControlName="modePaiement"
              value="virement"
              inputId="payVir"
            />
            <label for="payVir" class="ml-2 mb-1">
              Virement <i class="fa fa-money-bill-transfer fa-lg"></i>
            </label>
            @if(facturationForm.controls['modePaiement'].value == 'virement') {
            <p class="mb-0">
              {{ 'Effectuez le paiement directement depuis votre compte bancaire.
              Veuillez utiliser l\'ID de votre commande comme référence du
              paiement.' | transloco }}              
              <br />
              {{ 'Votre commande ne sera pas expédiée tant que les fonds ne
              seront pas reçus.' | transloco }}
            </p>
            }
          </div>
          <div class="field col-12">
            <p-radioButton
              formControlName="modePaiement"
              value="cb"
              inputId="payCb"
            />
            <label for="payCb" class="ml-2 mb-1">
              {{ 'Carte bancaire' | transloco }} <i class="fa fa-credit-card fa-lg"></i>
            </label>
          </div>

          @if(isErrorFormControl(facturationForm.controls['modePaiement'])) {
          <small class="text-red-500">{{
            "Mode de paiement requis" | transloco
          }}</small>
          }
        </p-panel>
      </div>
    </div>
  </div>
  <div class="text-right mt-2">
    @if(saveError) {
      <div class="text-red-500">
        {{ "Une erreur est survenue lors de l'enregistrement" | transloco }}
      </div>
    }
    <button
      pButton
      [label]="'Valider la commande' | transloco"
      icon="fa-solid fa-cart-plus"
      class="flex-1"
    ></button>
  </div>
</form>
} } @placeholder {
<div class="text-center">{{ 'Chargement en cours...' | transloco }}</div>
}
