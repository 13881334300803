import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { forkJoin, Subscription } from 'rxjs';

import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { TableModule } from 'primeng/table';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';

import { AuthService } from '../../../services/auth/auth.service';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';

import { TableGroupService } from '../../../services/table/table-group.service';
import { MailReport } from '../../../models/mail-report';
import { ApiMailReportsService } from '../../../services/api/api-mail-reports.service';
import { User } from '../../../models/user';
import { ApiSensorsService } from '../../../services/api/api-sensors.service';
import { SensorAlert } from '../../../models/sensor-alert';
import { AppConstants } from '../../../constants/app.constants';
import { Sensor } from '../../../models/sensor';
import { Client } from '../../../models/client';
import { DialogModule } from 'primeng/dialog';
import { PanelModule } from 'primeng/panel';


@Component({
  selector: 'app-emails-list',
  standalone: true,
    imports: [
    ButtonModule,
    CommonModule,
    ConfirmDialogModule,
    DialogModule,
    DropdownModule,
    FormsModule,
    MultiSelectModule,
    PanelModule,
    TableModule,
    TranslocoModule,
  ],
  templateUrl: './emails-list.component.html',
  providers: [MessageService, ConfirmationService],
})
export class EmailsListComponent implements OnInit, OnDestroy {
  mailReportSubscription: Subscription = new Subscription();
  dataLoaded: boolean = false;
  nbRows: number = 10;
  distinctUsers: Array<User> = [];
  alertTypes: Array<any> = [];

  allReports: Array<MailReport|SensorAlert> = [];
  licences : Client[] = [];
  modalVisible:boolean = false;

  CODE_PERIODE_SEMAINE:string =  AppConstants.CODE_PERIODE_SEMAINE;


  allMailReports:any = [];
  allSensorReports:any = [];

  constructor(
    private apiMailReportService: ApiMailReportsService,
    private apiSensorService: ApiSensorsService,
    private authService: AuthService,
    private confirmationService: ConfirmationService,
    private router: Router,
    protected tableGroupService: TableGroupService,
    private translocoService: TranslocoService
  ) { }

  isRoot(): boolean {
    return this.authService.isRoot();
  }

  ngOnInit() {
    const mailReports$ = this.apiMailReportService.getMailReports();
    const sensorAlerts$ = this.apiSensorService.getAlerts();
    this.licences = this.authService.getUserLicences();

    setTimeout(() => {
      this.alertTypes = [
        { "code": "mailReport", "label": this.translocoService.translate('mailReport')}, 
        { "code": "sensorAlert", "label": this.translocoService.translate('sensorAlert')}
      ];
      
    }, 200);

    this.mailReportSubscription = forkJoin({
      mailReports: mailReports$,
      sensorAlerts: sensorAlerts$
    }).subscribe({
      next: ({ mailReports, sensorAlerts }) => {
        this.allMailReports = mailReports.map(mail => ({ ...mail, objectType: 'mailReport' }));
        this.allSensorReports = sensorAlerts.map((alert:any) => {
          alert.objectType = 'sensorAlert';
          alert.title = alert.sensors
            .map((sensor: Sensor) => sensor.label)
            .join(', ');

          return alert;
        });

        this.loadGlobalList();
        
      },
      error: (err) => {
        console.error('Erreur lors du chargement des données :', err);
        this.dataLoaded = true; // Permet d'afficher un état "chargé" même en cas d'erreur
      }
    });
  }

  loadGlobalList() {
    this.allReports = [...this.allMailReports, ...this.allSensorReports];

    // Extraire la liste des utilisateurs distincts (pour le filtre)
    this.distinctUsers = this.getDistinctUsers(this.allMailReports);

    this.dataLoaded = true;
  }

  getDistinctUsers(mailReports: MailReport[]): User[] {
    const usersMap = new Map<number, User>(); // Utilisation de Map pour éviter les doublons

    mailReports.forEach(report => {
      if (report.user && !usersMap.has(report.user.id)) {
        usersMap.set(report.user.id, report.user); // Ajoute l'utilisateur s'il n'existe pas dans la map
      }
    });

    return Array.from(usersMap.values()); // Retourne un tableau avec les utilisateurs distincts
  }

  addMailReport() {
  }
  editMailReport(mailReport: MailReport) {
    this.router.navigate(['settings/mail-reports-edit', mailReport.id]);
  }
  editLicences(userData: User) {
    this.router.navigate(['settings/mail-reports-licences', userData.id]);
  }

  canWrite(): boolean {
    return this.authService.canWrite();
  }

  deleteMailReport(mailReport: MailReport) {
    this.confirmationService.confirm({
      message:
        this.translocoService.translate(
          'Confirmez-vous la suppression du rapport mail'
        ) +
        ' "' +
        mailReport.title +
        '" ?',
      header: 'Suppression',
      acceptLabel: this.translocoService.translate('Oui'),
      rejectLabel: this.translocoService.translate('Non'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.apiMailReportService.deleteMailReport(mailReport.id).subscribe((response) => {
          if (response) {
                this.allMailReports = this.allMailReports.filter(
                  (val: MailReport) => val.id !== mailReport.id
                );
                this.loadGlobalList();
          }
        });
      },
    });
  }


  editSensorAlert(sensorData: SensorAlert) {
    this.router.navigate(['settings/sensors-alert-edit', sensorData.id]);
  }
  deleteSensorAlert(sensorData: SensorAlert) {
    this.confirmationService.confirm({
      message:
        this.translocoService.translate(
          'Confirmez-vous la suppression de l\'alerte ? '
        ),
        header: 'Suppression',
        acceptLabel: this.translocoService.translate('Oui'),
        rejectLabel: this.translocoService.translate('Non'),
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.apiSensorService.deleteSensorAlert(sensorData.id).subscribe((response) => {
            if (response) {
              this.allSensorReports = this.allSensorReports.filter(
                (val: SensorAlert) => val.id !== sensorData.id
              );
              this.loadGlobalList();
            }
          });
        },
    });
  }

  addReport() {
    this.modalVisible = true;
  }

  validNewReport(typeAlertCode:string) {
    if(typeAlertCode == 'mailReport') {
      this.router.navigate(['settings/mail-reports-add']);
    } else {
      this.router.navigate(['settings/sensors-alert-add']);
    }
  } 

  ngOnDestroy() {
    this.mailReportSubscription.unsubscribe();
  }

}
