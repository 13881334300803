@if(authService.tokenLogin) {
  <router-outlet></router-outlet>
} @else if (!authService.isLoggedInUser()) {
  <app-login></app-login>
} 
@else {
  @defer (when isLoaded) {
    <p-toolbar class="global-toolbar">
        <div class="p-toolbar-group-start">
          <a href="/"><img src="assets/images/logo_appli_blanc_menu.png" /></a>
        </div>
        <div class="p-toolbar-group-center">
            {{ 'ESPACE CLIENT' | transloco }}
        </div>
        <div class="p-toolbar-group-end">
          <span class="hide-sm">{{ 'Bonjour' | transloco }} {{ usrData.first_name }} {{ usrData.last_name }}</span>
          @if(this.authService.isRoot()) {
            <p-button class="ml-3 green" icon="pi pi-shopping-cart" [badge]="cartNbProducts" [label]="cartTotalPrice" (click)="goToCart()"></p-button>
          }
          <p-button class="ml-3" icon="pi pi-power-off" (click)="authService.logout()"></p-button>
        </div>
        <div class="flex flex-wrap max-width align-items-center hide-md flex-row">
          <div class="flex-1">
            <app-left-menu></app-left-menu>
          </div>
          <div class="flex-1">{{ 'Bonjour' | transloco }} {{ usrData.first_name }} {{ usrData.last_name }}</div>
      </div>
    </p-toolbar>

    <div class="m-2">
      <div class="grid grid-nogutter">
          <div class="col-12 xl:col-2 hide-sm ">
            <app-left-menu></app-left-menu>
          </div>
          <div class="col-12 xl:col-10">
            <div class="grid grid-nogutter ml-2-md">
              <div class="col-12">
                <p-breadcrumb class="max-w-full" [home]="home" [model]="path"></p-breadcrumb>
              </div>
              <div class="col-12 mt-2">
                <router-outlet></router-outlet>
              </div>
            </div>
          </div>
      </div>
    </div>
    <app-loading-modal></app-loading-modal>
    <app-content-modal></app-content-modal>
  }

}