@defer (when dataLoaded) { 
  <div class="grid flex">    
    <div class="col-12 md:col-6 lg:col-3 ">
      <p-fieldset legend="Facturation / Livraison">
          <p class="m-0">
              {{ order.name }} {{ order.first_name }}              
          </p>
          <p class="m-0">{{ order.company }}</p>
          <p class="m-0">{{ order.address }} {{ order.address2 }}</p>
          <p class="m-0">{{ order.zipcode }} {{ order.city }}</p>
          <p class="m-0">{{ order.country }}</p>
          <p class="mb-0">{{ order.phone_number }}</p>
          <p class="m-0">{{ order.email }}</p>
      </p-fieldset>
    </div>

    <div class="col-12 md:col-6 lg:col-3">
      <p-fieldset legend="Informations">
        <p class="mt-0">
          {{ "Date de commande :" | transloco }} {{ order.date | date : "dd/MM/yyyy HH:mm" }}
        </p>
        <p class="mt-0">
          {{ "Par :" | transloco }} {{ order.user.firstName }} {{ order.user.lastName }}
        </p>
        <p class="mt-0">
          {{ "Mode paiement :" | transloco }} {{ LIBELLE_PAIEMENT[order.payment_method] }}
        </p>
        @if(order.payment_date) {
          <p class="mt-0">
            {{ "Date de validation du paiement :" | transloco }} {{ order.payment_date | date : "dd/MM/yyyy" }}
          </p>
        }
      </p-fieldset>
    </div>

    <div class="col-12 md:col-6 lg:col-3">
        <p-fieldset [legend]="'Statut de la commande' | transloco" class="p-0 m-0">
          @if(isRoot()) {
          <form [formGroup]="statusForm" (ngSubmit)="save()">
            <div class="field">
              <p-dropdown
                formControlName="orderStatus"
                autoWidth="false"
                [style]="{ width: '100%' }"
                [options]="statuts"
                class="w-full"
              ></p-dropdown>
            </div>
            <div class="flex overflow-hidden">
              <div class="flex-grow-1 flex align-items-center justify-content-center">
                  <p-button
                  type="submit"
                  icon="pi pi-save"
                  [disabled]="!statusForm.valid"
                  [label]="'Enregistrer' | transloco"
                ></p-button>
              </div>
          </div>  
          </form>
          } 
          @else {
            {{ LIBELLE_STATUT[order.status] }}
          }
        </p-fieldset>
      
    </div>
      <div class="col-12 md:col-6 lg:col-3">
        <p-fieldset legend="Notes client">
          <p class="m-0">
            @if(order.notes != '') {
              {{ order.notes }}
            } 
            @else {
              {{ 'Aucune note' | transloco }}
            }
          </p>
        </p-fieldset>
      </div>
  </div>

  <p-table 
    [value]="order.products" 
    [tableStyle]="{ 'min-width': '50rem' }"
    styleClass="table-with-bottom-border"
    >
    <ng-template pTemplate="header">
        <tr>
            <th></th>
            <th>{{ 'Produit' | transloco }}</th>
            <th class="text-right col-sous-total">{{ 'Quantité' | transloco }}</th>
            <th class="text-right col-sous-total">{{ 'Sous-total HT' | transloco }}</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-product>
        <tr>
            <td class="text-center">
              <a (click)="redirectProduct(product)">
                <img
                [alt]="product.title"
                [src]="product.photo1"
                class="photoCart"
                />
              </a>
            </td>
            <td>
              <a (click)="redirectProduct(product)">{{ product.title }}</a>
            </td>
            <td class="text-right col-sous-total">
              {{ product.quantity }}
            </td>
            <td class="text-right col-sous-total">{{ product.totalHtPrice | number: '1.2-2' }}&euro;</td>
        </tr>
    </ng-template>
    <ng-template pTemplate="footer">
        <tr>
            <td colspan="3" class="text-right">{{ 'Sous total HT' | transloco }}</td>
            <td class="text-right">{{ order.total_price_ht | number: '1.2-2' }}&euro;</td>
        </tr>
        <tr>
            <td colspan="3" class="text-right">{{ 'TVA' | transloco }}</td>
            <td class="text-right">{{ order.total_tva | number: '1.2-2' }}&euro;</td>
        </tr>
        <tr>
            <td colspan="3" class="text-right">{{ 'Total TTC' | transloco }}</td>
            <td class="text-right">{{ order.total_price_ht + order.total_tva | number: '1.2-2' }}&euro;</td>
        </tr>
    </ng-template> 
  </p-table>
  <p-toast />
 } @placeholder {
<div class="text-center">{{ 'Chargement en cours...' | transloco }}</div>
}
