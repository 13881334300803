import { Component, AfterViewInit, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { FormsModule, FormGroup, FormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';

import { Subscription } from 'rxjs';
import { CommonModule } from '@angular/common';

import { ActivatedRoute } from '@angular/router';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';

import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { ChartModule } from 'primeng/chart';
import { PanelModule } from 'primeng/panel';

import zoomPlugin from 'chartjs-plugin-zoom';

import { Chart } from 'chart.js';
import { ApiSensorsService } from '../../../../services/api/api-sensors.service';

import { Sensor } from '../../../../models/sensor';
import { SensorMeasure } from '../../../../models/sensor-measure';
import { UtilsService } from '../../../../services/utils/utils.service';
import moment from 'moment';

@Component({
  selector: 'app-sensors-histo',
  standalone: true,
  templateUrl: './sensors-histo.component.html',
  styleUrl: './sensors-histo.component.css',
  imports: [
    ButtonModule,
    CalendarModule,
    ChartModule,
    CommonModule,
    FormsModule,
    PanelModule,
    ReactiveFormsModule,
    TranslocoModule,
  ],
})
export class SensorsHistoComponent implements AfterViewInit, OnInit, OnDestroy {
  sensorId: string = '';
  sensorSubscription: Subscription = new Subscription();
  titlePanel: string = '';
  sensor: Sensor | null = null;
  dataLoaded: boolean = false;

  @ViewChild('chartTemperatures') chartRef!: any;

  plageHeures: number = 1;
  lastLegendDate: Date | null = null;
  zoomButtonDisabled: boolean = true;

  documentStyle = getComputedStyle(document.documentElement);

  dataChart: any = {
    labels: [],
    datasets: [
      {
        data: [],
        fill: false,
        borderColor: '#ea5133',
        pointStyle: 'circle',
        pointRadius: 3,
        pointHoverRadius: 7,
        backgroundColor: '#ea5133',
        //showLine: false,
      },
    ],
  };

  zoomOptions = {
    pan: {
      enabled: true,
      mode: 'x',
      modifierKey: 'ctrl',
    },
    zoom: {
      mode: 'x',
      wheel: {
        enabled: true,
      },
      drag: {
        enabled: true,
      },
      pinch: {
        enabled: true
      },
      onZoomComplete: () => {
        this.zoomButtonDisabled = false;
      },
    },
  };

  optionChart: any = {};
  
  searchForm: FormGroup;
  titlePage: string = '';

  constructor(
    private apiSensorService: ApiSensorsService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private translocoService: TranslocoService,
    private utilsService: UtilsService
  ) {

    Chart.register(zoomPlugin);

    let tmpDateStart = new Date();
    tmpDateStart.setDate(tmpDateStart.getDate() - 1);

    this.searchForm = this.formBuilder.group({
      periodStart: [tmpDateStart, Validators.required],
      periodEnd: [new Date(), Validators.required]
    });

    const self = this;


    this.optionChart = {
      maintainAspectRatio: false,
      responsive: false,
      //aspectRatio: 1,
      scales: {
        y: {
          min: -12,
          max: 12,
          grid: {
            display: true,
            drawOnChartArea: false,
          },
        },
        x: {
          grid: {
            display: false,
          },
          ticks: {
            autoSkip: false,        
            callback: function(value:number, index:number, ticks:any) {
              let newThis =this as any; // on est obligé de forcer le typage any
              let displayLegend = false;

              if (self.plageHeures == 0) {
                // on affiche tout
                displayLegend = true;
              } else {
                const valDate = moment(newThis.getLabelForValue(value), 'DD/MM/YYYY HH:mm').toDate();

                if (
                  self.lastLegendDate == null ||
                  self.utilsService.differenceEnHeures(self.lastLegendDate, valDate) >=
                  self.plageHeures
                ) {
                  self.lastLegendDate = valDate;
                  displayLegend = true;
                }
              }

              // Renvoyer le label ou une chaîne vide selon la logique définie
              return displayLegend ? newThis.getLabelForValue(value) : '';
            },
          },
        },
      },
      plugins: {
        legend: {
          display: false,
          labels: {
            usePointStyle: true,
          },
        },
        tooltip: {
          callbacks: {
            title: (tooltipItems: any) => {
              if (tooltipItems[0].datasetIndex == 0) {
                return tooltipItems[0].label;
              } else {
                return '';
              }
            },
          },
        },
        zoom: this.zoomOptions,
      },
    };

  }

  ngOnInit() {

    this.sensorId = this.route.snapshot.paramMap.get('id') ?? '';
    this.titlePage = this.route.snapshot.data['path'].slice(-1)[0]['label'];
  }

  ngAfterViewInit(): void {
    if (this.sensorId != '') {
      this.sensorSubscription = this.apiSensorService
        .getSensor(this.sensorId)
        .subscribe((oneSensor: Sensor) => {
          this.sensor = oneSensor;
          this.titlePanel = `${this.titlePage} : ${this.sensor.client?.name} - ${this.sensor.workspace?.name} - ${this.sensor.label}`;

          //on initialise les bornes si on en a
          if (this.sensor.minTemp !== null && this.sensor.maxTemp !== null) {
            this.dataChart.datasets.push(
              {
                label: this.translocoService.translate('Température minimale'),
                data: [],
                fill: false,
                pointStyle: false,
                borderDash: [4, 4],
                tooltip: function () {
                  return false;
                },
              },
              {
                label: this.translocoService.translate('Température maximale'),
                data: [],
                fill: false,
                pointStyle: false,
                borderDash: [4, 4],
              }
            );
          }
          this.dataLoaded = true;

          setTimeout(() => {
            this.loadTemperatures();
          }, 300);
        });
    }
  }

  ngOnDestroy(): void {
    if (this.sensorSubscription) {
      this.sensorSubscription.unsubscribe();
    }
  }

  clearGraphData() {
    this.dataChart.labels = [];

    this.dataChart.datasets.forEach((dataset: any) => {
      dataset.data = [];
    });
    this.chartRef.refresh();
  }

  calculPlageLegende(nbHeures: number): number {
    if (nbHeures >= 168) {
      return 24;
    } else if (nbHeures >= 24) {
      return 4;
    } else if (nbHeures >= 4) {
      return 1;
    } else {
      return 0;
    }
  }

  loadTemperatures() {
    if (this.searchForm.controls['periodStart'].value > this.searchForm.controls['periodEnd'].value) {
      let tmpDate: Date = this.searchForm.controls['periodStart'].value;
      tmpDate.setDate(tmpDate.getDate() + 1);
      this.searchForm.controls['periodEnd'].setValue(tmpDate);
    }

    const heuresEntreDates = this.utilsService.differenceEnHeures(
      this.searchForm.controls['periodStart'].value,
      this.searchForm.controls['periodEnd'].value,
    );

    this.plageHeures = this.calculPlageLegende(heuresEntreDates);

    this.clearGraphData();

    this.sensorSubscription = this.apiSensorService
      .getMeasuresSensors(this.sensorId, this.searchForm.controls['periodStart'].value, this.searchForm.controls['periodEnd'].value)
      .subscribe((measureList: SensorMeasure[]) => {
        let i = 0;
        measureList.map((measureData: SensorMeasure) => {
          // ajout date et valeur
          this.dataChart.labels.push(
            measureData.date.format('DD/MM/YYYY HH:mm')
          );
          this.dataChart.datasets[0].data.push(measureData.value);

          // et les bornes si présentes
          if (this.sensor?.minTemp !== null && this.sensor?.maxTemp !== null) {
            this.dataChart.datasets[1].data.push(this.sensor?.minTemp);
            this.dataChart.datasets[2].data.push(this.sensor?.maxTemp);

          }

          i++;
        });

        this.optionChart.scales.y.min = (this.sensor?.minTemp ?? 0) - 8;
        this.optionChart.scales.y.max = (this.sensor?.maxTemp ?? 0) + 8;

        this.chartRef.refresh();

      });
  }

  resetZoom() {
    const chartInstance = this.chartRef.chart;
    chartInstance.resetZoom();
    this.zoomButtonDisabled = true;
  }
}
