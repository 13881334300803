import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import { MenuItem } from 'primeng/api';
import { filter, map, mergeMap } from 'rxjs/operators';
import { TranslocoService } from '@ngneat/transloco'; 

import { ApiEshopService } from './services/api/api-eshop.service';
import { AuthService } from './services/auth/auth.service';
import { CartService } from './services/eshop/cart.service';
import { EshopProduct } from './models/eshop-product';
import { Subscription } from 'rxjs';
import { GlobalDatas } from './models/global-datas';
import { SessionStorageService } from './services/storage/session-storage.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.css',
})
export class AppComponent implements OnInit, OnDestroy {
  path = undefined;
  usrData: any = [];
  home: MenuItem | undefined;
  isLoaded:boolean = false;

  cartNbProducts: string = '0';
  cartTotalPrice: string = '';

  cartSubscription: Subscription = new Subscription();
  productSubscription: Subscription = new Subscription();
  
  globalDatas: GlobalDatas | null = null;
  hideBreadCrumb= false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private apiEshopService: ApiEshopService,
    public authService: AuthService,
    private cartService: CartService,
    private router: Router, 
    private sessionStorageService: SessionStorageService,
    private translocoService: TranslocoService
    ) {}

  ngOnInit() {   
    this.initPath();
    this.subscribeToCartUpdates();    
  }

  initPath() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map(route => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      mergeMap(route => route.data.pipe(
        map(data => ({ data, params: route.snapshot.params }))
      ))
    )
    .subscribe(({ data, params }) => {
      if(this.authService.isLoggedInUser()) {
        this.usrData = this.authService.getUserData();
      }

      if(data['tokenLogin']) {
        this.authService.tokenLogin = data['tokenLogin'];
      }

      // Fonction pour remplacer les paramètres dynamiquement
      const replaceParams = (link: string, params: any) => {
        return link.replace(/:([a-zA-Z]+)/g, (_, key) => params[key] || `:${key}`);
      };

      // j'ai du mettre un léger timeout sinon la traduction n'est pas totalement initialisée en cas de F5
      setTimeout(() => {
        if(data['path']) {
          this.globalDatas = this.sessionStorageService.get('globalDatas');

          this.path = data['path'].map((onePath: any) => {
            const updatedPath = { ...onePath };
            // Remplacer dynamiquement tous les paramètres dans routerLink
            if (updatedPath.routerLink && typeof updatedPath.routerLink === 'string') {
              updatedPath.routerLink = replaceParams(updatedPath.routerLink, params);
            }

            if(updatedPath.label =='[ESHOP_CATEGORY_TITLE]') {
              
               let oCategory = this.globalDatas?.eshopCategories.find((oneCategory) => oneCategory.id == params['id']);

               if(oCategory) {
                updatedPath.label = oCategory.title;
               }
            }

            updatedPath.label = this.translocoService.translate(updatedPath.label);
                        
            return updatedPath;
          });
        } else {
          this.path = undefined;
        }

        this.hideBreadCrumb = data['hideBreadCrumb'] ?? false;

        this.home = { icon: 'pi pi-home mr-2', label: this.translocoService.translate('Accueil'), routerLink: '/' };
        
        this.isLoaded = true;
      }, 100);
    });
  }

  goToCart() {
    this.router.navigate(['eshop/cart/']);
  }

  subscribeToCartUpdates() {
    // S'abonner aux changements du panier
    this.cartSubscription = this.cartService.getCartItems().subscribe(() => {
      this.updateCartInfos();
    });
  }

  updateCartInfos() {
    // Recalculer le nombre de produits dans le panier
    const nbProducts: number = this.cartService.getTotalQuantity();
    this.cartNbProducts = nbProducts.toString();

    if (nbProducts > 0) {
      this.productSubscription = this.apiEshopService
        .getProducts()
        .subscribe((products: EshopProduct[]) => {
          this.cartTotalPrice = this.cartService.getTotalPrice(products).toString() + '€';
        });
    } else {
      this.cartTotalPrice = '';
    }
  }

  ngOnDestroy(): void {
    if(this.productSubscription) {
      this.productSubscription.unsubscribe();
    }
    if (this.cartSubscription) {
      this.cartSubscription.unsubscribe();
    }
  }
}
