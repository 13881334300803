@if(displayGlobal) {
    <div class="row mb-3">
        <p-checkbox
        [label]="labelAllTasks"
        [binary]="true"
        [(ngModel)]="displayAll" 
        inputId="binary"
        (onChange)="getDatas()"
        ></p-checkbox>
    </div>
}

@if(displayGlobal) {
    <p-table 
        [value]="datas" 
        styleClass="p-datatable-gridlines p-datatable-striped"
        [paginator]="true"
        [rows]="10"
        [showCurrentPageReport]="true"
        [currentPageReportTemplate]="'Lignes {first} à {last} sur un total de {totalRecords}' | transloco"
        [selectionMode]="selectionMode"
        (onRowSelect)="getDatasDetails($event)"
    >
    <ng-template pTemplate="header">
        <tr>
            @for (oneCol of colsTable; track oneCol) {
                <th pSortableColumn="{{ oneCol.field }}">{{ oneCol.header | transloco }} <p-sortIcon field="{{ oneCol.field}}"></p-sortIcon></th>
            }
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-oneData>
        <tr [pSelectableRow]="oneData.tas_tpl_id">
            @for (oneCol of colsTable; track oneCol) {
                <td class="{{ oneCol.alignment }}">{{ oneData[oneCol.field] }}</td>
            }
        </tr>
    </ng-template>
    </p-table>
} 
@else {
    <p-table 
        [value]="datasDetails" 
        styleClass="p-datatable-gridlines p-datatable-striped"
        [paginator]="true"
        [rows]="10"
        [showCurrentPageReport]="true"
        [currentPageReportTemplate]="'Lignes {first} à {last} sur un total de {totalRecords}' | transloco"
    >
    <ng-template pTemplate="header">
        <tr>
            <th pSortableColumn="cli_name">{{ 'Licence' | transloco }} <p-sortIcon field="cli_name"></p-sortIcon></th>
            <th pSortableColumn="obj_name">{{ 'Objet' | transloco }} <p-sortIcon field="obj_name"></p-sortIcon></th>
            <th pSortableColumn="wrk_name">{{ 'Zone' | transloco }} <p-sortIcon field="wrk_name"></p-sortIcon></th>
            <th pSortableColumn="tas_todo_date">{{ 'Prévue le' | transloco }} <p-sortIcon field="tas_todo_date"></p-sortIcon></th>
            <th pSortableColumn="tas_done">{{ 'Réalisée' | transloco }} <p-sortIcon field="tas_done"></p-sortIcon></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-oneData>
        <tr>
            <td>{{ oneData.cli_name }}</td>
            <td>{{ oneData.obj_name }}</td>
            <td>{{ oneData.wrk_name }}</td>
            <td>{{ oneData.tas_todo_date | date:'dd/MM/yyyy HH:mm' }}</td>
            <td class="text-center">{{ oneData.tas_done }}</td>
        </tr>
    </ng-template>
    </p-table>
    <button
        pButton
        [label]="'Retour' | transloco"
        icon="fa fa-circle-left fa-2x"
        class="p-button-success mt-2"
        (click)="backGlobal()"></button>
}