import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { EshopProduct } from '../../models/eshop-product';
import { UtilsService } from '../utils/utils.service';
import { EshopOrder } from '../../models/eshop-order';
import { User } from '../../models/user';
import moment from 'moment';
import { EshopOrderProduct } from '../../models/eshop-order-product';
import { EshopCategory } from '../../models/eshop-category';

@Injectable({
  providedIn: 'root',
})
export class ApiEshopService {
  constructor(
    private http: HttpClient,
    private utilsService: UtilsService
  ) {}

  getProducts(categoryId:string = ''): Observable<EshopProduct[]> {
    return this.http
      .get<any>(
        environment.apiURL +
          'espace_client/get_eshop_products.php?categoryId='+categoryId
      )
      .pipe(
        map((response: any[]) => {
          return response.map((dataProduct) => {
            return new EshopProduct(
              parseInt(dataProduct.epr_id),
              dataProduct.epr_title.trim(),
              this.utilsService.transformStringToUrlFormat(dataProduct.epr_title.trim()),
              dataProduct.epr_description,
              dataProduct.epr_short_description,
              environment.eshopImagesURL + (dataProduct.epr_photo_1 ? dataProduct.epr_photo_1 : 'no_photo.png'),
              (dataProduct.epr_photo_2 ? environment.eshopImagesURL + dataProduct.epr_photo_2 : ''),
              (dataProduct.epr_photo_3 ? environment.eshopImagesURL + dataProduct.epr_photo_3 : ''),
              parseFloat(dataProduct.epr_ht_price),
              parseFloat(dataProduct.epr_tva),
              parseInt(dataProduct.epr_eca_id)
            );
          });
        }),
        catchError((error) => {
          return of([]);
        })
      );
  }

  getOneProduct(productId: string): Observable<EshopProduct> {
    return this.http
      .get<any>(
        environment.apiURL +
          'espace_client/get_eshop_products.php?productId=' +
          productId
      )
      .pipe(
        map((dataProduct: any) => {
            return new EshopProduct(
              parseInt(dataProduct.epr_id),
              dataProduct.epr_title.trim(),
              this.utilsService.transformStringToUrlFormat(dataProduct.epr_title.trim()),
              dataProduct.epr_description,
              dataProduct.epr_short_description,
              (dataProduct.epr_photo_1 ? environment.eshopImagesURL + dataProduct.epr_photo_1 : ''),
              (dataProduct.epr_photo_2 ? environment.eshopImagesURL + dataProduct.epr_photo_2 : ''),
              (dataProduct.epr_photo_3 ? environment.eshopImagesURL + dataProduct.epr_photo_3 : ''),
              parseFloat(dataProduct.epr_ht_price),
              parseFloat(dataProduct.epr_tva),
              parseInt(dataProduct.epr_eca_id)
            );
        })
      );
  }

  postOrder(formData: FormData): Observable<any> {
    return this.http.post<any>(
      environment.apiURL + 'espace_client/post_eshop_order.php',
      formData
    );
  }
  
  postOrderStatus(formData: FormData): Observable<any> {
    return this.http.post<any>(
      environment.apiURL + 'espace_client/post_eshop_order_status.php',
      formData
    );
  }

  getOrders(): Observable<EshopOrder[]> {
    return this.http
      .get<any>(
        environment.apiURL +
          'espace_client/get_eshop_orders.php'
      )
      .pipe(
        map((response: any[]) => {
          return response.map((dataOrder) => {
            return new EshopOrder(
                parseInt(dataOrder.eor_id),
                moment(new Date(dataOrder.eor_date)),
                new User(dataOrder.eor_usr_id, dataOrder.usr_first_name, dataOrder.usr_last_name),                
                dataOrder.eor_name,
                dataOrder.eor_first_name,
                dataOrder.eor_company,
                dataOrder.eor_country,
                dataOrder.eor_address,
                dataOrder.eor_address2,
                dataOrder.eor_zipcode,
                dataOrder.eor_city,
                dataOrder.eor_phone_number,
                dataOrder.eor_email,
                dataOrder.eor_notes,
                dataOrder.eor_payment_method,
                (dataOrder.eor_payment_date ? moment(new Date(dataOrder.eor_payment_date)) : null),
                dataOrder.eor_status,
                parseFloat(dataOrder.eor_total_ht_price),
                parseFloat(dataOrder.eor_total_tva)
            );
          });
        }),
        catchError((error) => {
          return of([]);
        })
      );
  }

  getOneOrder(orderId: string): Observable<EshopOrder> {
    return this.http
      .get<any>(
        environment.apiURL +
          'espace_client/get_eshop_orders.php?orderId=' +
          orderId
      )
      .pipe(
        map((dataOrder: any) => {
            let oOrder = new EshopOrder(
                parseInt(dataOrder.eor_id),
                moment(new Date(dataOrder.eor_date)),
                new User(dataOrder.eor_usr_id, dataOrder.usr_first_name, dataOrder.usr_last_name),                
                dataOrder.eor_name,
                dataOrder.eor_first_name,
                dataOrder.eor_company,
                dataOrder.eor_country,
                dataOrder.eor_address,
                dataOrder.eor_address2,
                dataOrder.eor_zipcode,
                dataOrder.eor_city,
                dataOrder.eor_phone_number,
                dataOrder.eor_email,
                dataOrder.eor_notes,
                dataOrder.eor_payment_method,
                (dataOrder.eor_payment_date ? moment(new Date(dataOrder.eor_payment_date)) : null),
                dataOrder.eor_status,
                parseFloat(dataOrder.eor_total_ht_price),
                parseFloat(dataOrder.eor_total_tva)
            );

            oOrder.products = dataOrder.products.map((dataProduct: any) => {
              return new EshopOrderProduct(
                parseInt(dataProduct.epr_id),
                dataProduct.epr_title.trim(),
                this.utilsService.transformStringToUrlFormat(dataProduct.epr_title.trim()),
                dataProduct.epr_description,
                dataProduct.epr_short_description,
                environment.eshopImagesURL + (dataProduct.epr_photo_1 ? dataProduct.epr_photo_1 : 'no_photo.png'),
                (dataProduct.epr_photo_2 ? environment.eshopImagesURL + dataProduct.epr_photo_2 : ''),
                (dataProduct.epr_photo_3 ? environment.eshopImagesURL + dataProduct.epr_photo_3 : ''),
                parseFloat(dataProduct.epr_ht_price),
                parseFloat(dataProduct.epr_tva),
                parseInt(dataProduct.eop_quantity),
                parseFloat(dataProduct.eop_total_ht_price),
                parseFloat(dataProduct.eop_total_tva),
                parseInt(dataProduct.epr_eca_id)
              );
            });

            return oOrder;

        })
      );
  }

  mapCategories(apiData: any[]): EshopCategory[] {
    let categories: EshopCategory[] = [];

    apiData.forEach((dataC: any) => {
      categories.push(
        new EshopCategory(
          parseInt(dataC['eca_id']),
          dataC['eca_title'],
          this.utilsService.transformStringToUrlFormat(dataC['eca_title']),
          dataC['eca_icon']
        )
      );
    });

    return categories;
  }


  getStats(periodStart: string, periodEnd: string): Observable<any[]> { //EshopOrder[]
    console.log("periodStart", periodStart);
    console.log("periodEnd", periodEnd);
    return this.http
      .get<any>(
        environment.apiURL +
          'espace_client/get_eshop_stats.php?periodStart=' + moment(periodStart).format('YYYY-MM-DD') + '&periodEnd=' + moment(periodEnd).format('YYYY-MM-DD')
      )
      .pipe(
        map((response: any[]) => {
          return response;
        }),
        catchError((error) => {
          return of([]);
        })
      );
  }

  postProduct(formData: FormData): Observable<any> {
    return this.http.post<any>(
      environment.apiURL + 'espace_client/post_eshop_product.php',
      formData
    );
  }

  deleteProduct(productId: number): Observable<any> {
    return this.http.get<any>(
      environment.apiURL +
        'espace_client/delete_eshop_product.php?productId=' +
        productId
    );
  }
}
