@defer (when dataLoaded) {
<div>
  <p-panel [header]="'Anomalies' | transloco">
    <div>
      <p-table
        *ngIf="tabGlobal"
        [value]="anomalies"
        styleClass="p-datatable-gridlines p-datatable-striped"
        stateStorage="session"
        stateKey="anomaliesList-session"
        [(selection)]="anomaliesSelected"
        selectionMode="single"
        [tableStyle]="{'align-item': 'center', 'min-width': '50px', 'max-width': '100%'}"
        dataKey="cli_id"
        [paginator]="true"
        [rows]="20"
        [showCurrentPageReport]="true"
        currentPageReportTemplate="{totalRecords} {{ 'élément(s)' | transloco }}"
      >
        <ng-template pTemplate="header">
          <tr>
            <th class="text-center" pSortableColumn="cli_id">{{ 'Licence' | transloco }} <p-sortIcon field="cli_id"></p-sortIcon></th>
            <th class="text-center" pSortableColumn="nbAnomalies">{{ 'Nombre d\'anomalies ouvertes' | transloco }}<p-sortIcon field="nbAnomalies"></p-sortIcon></th>
            @if(canWrite()) {
            <th  class="text-center">{{ 'Actions' | transloco }}</th>
            }
          </tr>
          <tr>
            <th>
              <p-columnFilter field="cli_id" matchMode="in" [showMenu]="false">
                <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                  <p-multiSelect
                    [ngModel]="value"
                    [options]="anomalies"
                    [placeholder]="'Toutes' | transloco"
                    (onChange)="filter($event.value)"
                    optionLabel="cli_name"
                    optionValue="cli_id"
                    [showToggleAll]="false"
                    class="liste-recherche-table"
                    >
                  </p-multiSelect>
                </ng-template>
              </p-columnFilter>
            </th>
              <th colspan="2"></th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body"
          let-anomalies
          style="{'align-item': 'center'}"
          >
          <tr>
            <td>
              {{ anomalies.cli_id + ' - ' + anomalies.cli_name }}
            </td>
            <td class="text-right">
              {{ anomalies.nbAnomalies }}
            </td>
            @if(canWrite()) {
            <td class="flex align-items-center justify-content-center gap-3" >
              <a (click)="seeAnomalies(anomalies)" title="{{ 'Voir les anomalies' | transloco}}">
                  <i class="pi pi-eye"></i>
              </a>

              <a (click)="deleteAnomalies(anomalies)" title="{{ 'Supprimer les anomalies' | transloco}}">
                  <i class="pi pi-trash"></i>
              </a>
            </td>
            }
          </tr>
        </ng-template>
      </p-table>

      <div *ngIf="loading" class="loading-container">
      <p>Chargement en cours...</p>
      <i class="pi pi-spin pi-spinner" style="font-size: 2rem;"></i>
      </div>

      <div *ngIf="tabDetails && !loading">
        <div class="col text-right panel-actions" style="margin-bottom: 15px;">
          <button
            pButton
            [label]="'Retour' | transloco"
            icon="pi pi-arrow-left"
            class="p-button-success"
            (click)="backToGlobal()"
          ></button>
        </div>

        <p-table
        [value]="anomaliesDetail"
        styleClass="p-datatable-gridlines "
        stateStorage="session"
        stateKey="anomaliesDetailsList-session"
        [(selection)]="anomaliesSelected"
        selectionMode="single"
        [tableStyle]="{'align-item': 'center', 'min-width': '50px', 'max-width': '100%'}"
        dataKey="cli_id"
        [paginator]="true"
        [rows]="20"
        [showCurrentPageReport]="true"
        currentPageReportTemplate="{totalRecords} {{ 'élément(s)' | transloco }}"
        >
          <ng-template pTemplate="header">
            <tr>
            <th class="text-center" pSortableColumn="ano_create_date">{{ 'Date' | transloco }}<p-sortIcon field="anomaliesDetail.ano_create_date"></p-sortIcon></th>
            <th class="text-center" pSortableColumn="ano_name">{{ 'Nom de l\'anomalie' | transloco }}<p-sortIcon field="anomaliesDetail.ano_name"></p-sortIcon></th>
            <th class="text-center" pSortableColumn="wrk_name">{{ 'Zone' | transloco }}<p-sortIcon field="anomaliesDetail.wrk_name"></p-sortIcon></th>
            <th class="text-center" pSortableColumn="obj_name">{{ 'Nom de l\'objet' | transloco }}<p-sortIcon field="anomaliesDetail.obj_name"></p-sortIcon></th>
            <th class="text-center" pSortableColumn="ano_details">{{ 'Détail' | transloco }}<p-sortIcon field="anomaliesDetail.ano_details"></p-sortIcon></th>
            <th class="text-center" pSortableColumn="usr_full_name">{{ 'Utilisateur' | transloco }}<p-sortIcon field="anomaliesDetail.usr_full_name"></p-sortIcon></th>
            </tr>
          </ng-template>

          <ng-template pTemplate="body"
          let-anomaliesDetail
          style="{'align-item': 'center'}"
          >
          <tr>
          <td class="text-left">
          <div >
          <span>{{ anomaliesDetail.ano_create_date | date:'dd/MM/yyyy' }}</span>
          </div>
          </td>
          <td class="text-left">
          <div >
          <span>{{ anomaliesDetail.ano_name }}</span>
          </div>
          </td>
          <td class="text-left">
          <div >
          <span>{{ anomaliesDetail.wrk_name }}</span>
          </div>
          </td>
          <td class="text-left">
          <div >
          <span>{{ anomaliesDetail.obj_name }}</span>
          </div>
          </td>
          <td class="text-left">
          <div >
          <span>{{ anomaliesDetail.ano_details }}</span>
          </div>
          </td>
          <td class="text-left">
          <div >
          <span>{{ anomaliesDetail.usr_full_name }}</span>
          </div>
          </td>
          </tr>
          </ng-template>
        </p-table>
        <div class="col space-between">
          <button
            pButton
            [label]="'Stats par date' | transloco"
            icon="pi pi-calendar"
            class="p-button-success statOverButton"
            (click)="stats(anomaliesDetail, 'date')"
          ></button>
          <button
            pButton
            [label]="'Stats par zone' | transloco"
            icon="pi pi-th-large"
            class="p-button-success statOverButton"
            (click)="stats(anomaliesDetail, 'zone')"
          ></button>
          <button
            pButton
            [label]="'Stats par objet' | transloco"
            icon="pi pi-box"
            class="p-button-success statOverButton"
            (click)="stats(anomaliesDetail, 'element')"
          ></button>
          <button
            pButton
            [label]="'Stats par collaborateur' | transloco"
            icon="pi pi-user"
            class="p-button-success statOverButton"
            (click)="stats(anomaliesDetail, 'user')"
          ></button>
        </div>
        <div class="card">
          <p-chart *ngIf="statVisible" type="bar" [data]="basicData" [options]="basicOptions" />
        </div>
      </div>
    </div>
  </p-panel>
</div>



}@placeholder {
<div class="text-center">{{ 'Chargement en cours...' | transloco }}</div>
}
<p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>
<p-toast position="top-center"></p-toast>
