import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormsModule, FormGroup, FormBuilder, Validators, AbstractControlOptions, ReactiveFormsModule  } from '@angular/forms';
import { Subscription } from 'rxjs';

import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { PanelModule } from 'primeng/panel';
import { InputNumberModule } from 'primeng/inputnumber';

import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco'; 
import { EditComponent } from '../../../core/edit/edit.component';
import { ApiProcessListService } from '../../../../services/api/api-process-list.service';
import { TrackingProcess } from '../../../../models/tracking-process';
@Component({
  selector: 'app-process-traceability-edit',
  templateUrl: './process-traceability-edit.component.html',
  styleUrl: './process-traceability-edit.component.css',
  standalone: true,
  imports: [
    ButtonModule,
    CheckboxModule,
    DropdownModule,
    InputNumberModule,
    FormsModule,
    PanelModule,
    ReactiveFormsModule,
    TranslocoModule
  ],
})
export class ProcessTraceabilityEditComponent extends EditComponent implements OnInit, OnDestroy {
  processSubscription: Subscription = new Subscription();
  
  editForm: FormGroup;
  tprId: string = '';
  clientId: string = '';

  saveError:boolean = false;
  selectedImage: { fullPath: string, label: string } | null = null;

  optionsLaunch: any = [
    {value: 0, label : 'On ne peut pas lancer ce process' },
    {value: 1, label : 'On peut lancer ce process' },
    {value: 2, label : 'On ne peut lancer ce process que depuis une fiche technique' },
  ];

  optionsLauncher: any = [
    {value: 0, label : 'On ne peut pas lancer ce process' },
    {value: 1, label : 'On peut lancer ce process' },
    {value: 2, label : 'Process par défaut à gauche' },
    {value: 3, label : 'Process par défaut à droite' },
  ];

  optionsGestionDlc: any = [
    {value: 1, label : 'DLC identique pour tous les lots' },
    {value: 2, label : 'DLC par lot de sortie' },
    {value: 3, label : 'Aucune DLC appliquée en sortie' },
  ];

  optionsFonctionDlc: any = [
    {value: '', label : 'Aucune' },
    {value: '_dlc_by_product', label : '_dlc_by_product' },
  ];

  optionsImages: Array<any> = [
    { value: 'views/img/picto_autoControle.png', label:'Auto contrôle' },
    { value: 'views/img/picto_changementDeTemperature.png', label:'Changement de température' },
    { value: 'views/img/picto_changerHuile.png', label:'Changer l\'huile' },
    { value: 'views/img/picto_coordonnees.png', label:'Coordonnées' },
    { value: 'views/img/picto_cpnp.png', label:'CPNP' },
    { value: 'views/img/picto_cuisson.png', label:'Cuisson' },
    { value: 'views/img/picto_deconditionnement.png', label:'Déconditionnement' },
    { value: 'views/img/picto_decongelation.png', label:'Décongélation' },
    { value: 'views/img/picto_decoupe.png', label:'Découpe' },
    { value: 'views/img/picto_DLC.png', label:'DLC' },
    { value: 'views/img/picto_emballage.png', label:'Emballage' },
    { value: 'views/img/picto_fournisseurs.png', label:'Fournisseurs' },
    { value: 'views/img/picto_miseEnRayon.png', label:'Mise en rayon' },
    { value: 'views/img/picto_ok.png', label:'Case validée' },
    { value: 'views/img/picto_options.png', label:'Options' },
    { value: 'views/img/picto_outils.png', label:'Outils' },
    { value: 'views/img/picto_preparationExpedition.png', label:'Préparation expédition' },
    { value: 'views/img/picto_rangement.png', label:'Rangement' },
    { value: 'views/img/picto_suiviJournalier.png', label:'Suivi journalier' },
    { value: 'views/img/picto_tracabilite.png', label:'Traçabilité' },
    { value: 'views/img/picto_transformer.png', label:'Transformer' },
    { value: 'views/img/picto_vider.png', label:'Supprimer' },
  ];
  
  constructor(
    private apiProcessService: ApiProcessListService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
  ) {
      super(route);

      this.editForm = this.formBuilder.group({
        tpr_name: ['', Validators.required],
        tpr_comment: [''],
        tpr_max_delay: [''],
        tpr_order: ['', Validators.required],
        tpr_hidden: [false],
        tpr_picto_path: [''],

        tpr_min_nb_lots_in: [0, Validators.required],
        tpr_max_nb_lots_in: [0, Validators.required],
        tpr_min_nb_lots_out: [0, Validators.required],
        tpr_max_nb_lots_out: [0, Validators.required],
        
        tpr_copy_lots_in_out: [false],
        tpr_display_in_stock: [false],
        tpr_display_in_dlc: [false],
        tpr_stock_decrease: [false],
        tpr_display_lot_in_table: [true],
        tpr_block_lots_in: [false],
        tpr_block_lots_out: [false],
        tpr_from_treatment: [false],
        tpr_fifo: [false],

        tpr_from_recipe: [0],
        tpr_from_launcher: [0],
        tpr_dlc_type: [1],
        tpr_dlc_formula: [''],

        tpr_dlc_extend: [false],
        tpr_display_popup_extend_dlc: [false],
        tpr_auto_close_lots_with_dlc: [0],
      }, { validators: this.minMaxValidator } as AbstractControlOptions); 

      this.optionsImages.forEach((oneImage: any) => {
        oneImage.fullPath = oneImage.value.replace('views/img', 'assets/images/process');
      });

      this.selectedImage = this.optionsImages[0]; 
   }

   minMaxValidator(group: FormGroup) {

    let retourValidators :any = {  };

    const lotsInMin = group.controls['tpr_min_nb_lots_in'].value;
    const lotsInMax = group.controls['tpr_max_nb_lots_in'].value;

    if (lotsInMax !== null && lotsInMin !== null && lotsInMax > 0 && lotsInMax < lotsInMin) {
      retourValidators.invalidRangeLotsIn = true;
    } 

    const lotsOutMin = group.controls['tpr_min_nb_lots_out'].value;
    const lotsOutMax = group.controls['tpr_max_nb_lots_out'].value;

    if (lotsOutMax !== null && lotsOutMin !== null && lotsOutMax > 0 && lotsOutMax < lotsOutMin) {
      retourValidators.invalidRangeLotsOut = true;
    } 

    return retourValidators;
  }

  override ngOnInit() {
    super.ngOnInit();
    
    this.tprId = this.route.snapshot.paramMap.get('id') ?? '';
    this.clientId = this.route.snapshot.paramMap.get('clientId') ?? '';
    this.titlePage = this.route.snapshot.data['path'].slice(-1)[0]['label'];
  

    if(this.tprId != '') {
      // recuperer les données du process
      this.processSubscription = this.apiProcessService.getProcess(parseFloat(this.tprId)).subscribe((oTpr: TrackingProcess) => {

        this.editForm.controls['tpr_name'].setValue(oTpr.name);
        this.editForm.controls['tpr_comment'].setValue(oTpr.comment);
        this.editForm.controls['tpr_max_delay'].setValue(oTpr.maxDelay);
        this.editForm.controls['tpr_order'].setValue(oTpr.order);
        this.editForm.controls['tpr_hidden'].setValue(oTpr.hidden == 1);

        this.selectedImage = this.optionsImages.find((oneImage) => oneImage.value == oTpr.pictoPath); 
        this.editForm.controls['tpr_picto_path'].setValue(this.selectedImage);

        this.editForm.controls['tpr_min_nb_lots_in'].setValue(oTpr.minNbLotsIn);
        this.editForm.controls['tpr_max_nb_lots_in'].setValue(oTpr.maxNbLotsIn);
        this.editForm.controls['tpr_min_nb_lots_out'].setValue(oTpr.minNbLotsOut);
        this.editForm.controls['tpr_max_nb_lots_out'].setValue(oTpr.maxNbLotsOut);

        
        this.editForm.controls['tpr_copy_lots_in_out'].setValue(oTpr.copyLotsInOut == 1);
        this.editForm.controls['tpr_display_in_stock'].setValue(oTpr.displayInStock == 1);
        this.editForm.controls['tpr_display_in_dlc'].setValue(oTpr.displayInDlc == 1);
        this.editForm.controls['tpr_stock_decrease'].setValue(oTpr.stockDecrease == 1);
        this.editForm.controls['tpr_block_lots_in'].setValue(oTpr.blockLotsIn == 1);
        this.editForm.controls['tpr_block_lots_out'].setValue(oTpr.blockLotsOut == 1);
        this.editForm.controls['tpr_from_treatment'].setValue(oTpr.fromTreatment == 1);
        this.editForm.controls['tpr_fifo'].setValue(oTpr.fifo == 1);

        this.editForm.controls['tpr_from_recipe'].setValue(oTpr.fromRecipe);
        this.editForm.controls['tpr_from_launcher'].setValue(oTpr.fromLauncher);
        this.editForm.controls['tpr_dlc_type'].setValue(oTpr.dlcType);
        this.editForm.controls['tpr_dlc_formula'].setValue(oTpr.dlcFormula);

        this.editForm.controls['tpr_dlc_extend'].setValue(oTpr.dlcExtend == 1);
        this.editForm.controls['tpr_display_popup_extend_dlc'].setValue(oTpr.displayPopupExtendDlc == 1);

        this.editForm.controls['tpr_auto_close_lots_with_dlc'].setValue(oTpr.autoCloseLotsWithDlc);
        this.editForm.controls['tpr_display_lot_in_table'].setValue(oTpr.displayLotInTable == 1);
      });    
    }
  }
  ngOnDestroy() {
      this.processSubscription.unsubscribe();
  }

  save() {    
    let formData = new FormData();

    formData.append('tpr_id', this.tprId);

    formData.append('tpr_name', this.editForm.controls['tpr_name'].value);
    formData.append('tpr_comment', this.editForm.controls['tpr_comment'].value);
    formData.append('tpr_max_delay', this.editForm.controls['tpr_max_delay'].value);
    formData.append('tpr_order', this.editForm.controls['tpr_order'].value);
    formData.append('tpr_hidden', (this.editForm.controls['tpr_hidden'].value ? '1' : '0'));
    formData.append('tpr_picto_path', this.editForm.controls['tpr_picto_path'].value.value);

    formData.append('tpr_min_nb_lots_in', this.editForm.controls['tpr_min_nb_lots_in'].value);
    formData.append('tpr_max_nb_lots_in', this.editForm.controls['tpr_max_nb_lots_in'].value);
    formData.append('tpr_min_nb_lots_out', this.editForm.controls['tpr_min_nb_lots_out'].value);
    formData.append('tpr_max_nb_lots_out', this.editForm.controls['tpr_max_nb_lots_out'].value);
    
    formData.append('tpr_copy_lots_in_out', (this.editForm.controls['tpr_copy_lots_in_out'].value ? '1' : '0'));
    formData.append('tpr_display_in_stock', (this.editForm.controls['tpr_display_in_stock'].value ? '1' : '0'));
    formData.append('tpr_display_in_dlc', (this.editForm.controls['tpr_display_in_dlc'].value ? '1' : '0'));
    formData.append('tpr_stock_decrease', (this.editForm.controls['tpr_stock_decrease'].value ? '1' : '0'));
    formData.append('tpr_display_lot_in_table', (this.editForm.controls['tpr_display_lot_in_table'].value ? '1' : '0'));
    formData.append('tpr_block_lots_in', (this.editForm.controls['tpr_block_lots_in'].value ? '1' : '0'));
    formData.append('tpr_block_lots_out', (this.editForm.controls['tpr_block_lots_out'].value ? '1' : '0'));
    formData.append('tpr_from_treatment', (this.editForm.controls['tpr_from_treatment'].value ? '1' : '0'));
    formData.append('tpr_fifo', (this.editForm.controls['tpr_fifo'].value ? '1' : '0'));

    formData.append('tpr_from_recipe', this.editForm.controls['tpr_from_recipe'].value);
    formData.append('tpr_from_launcher', this.editForm.controls['tpr_from_launcher'].value);
    formData.append('tpr_dlc_type', this.editForm.controls['tpr_dlc_type'].value);
    formData.append('tpr_dlc_formula', this.editForm.controls['tpr_dlc_formula'].value);

    formData.append('tpr_dlc_extend', (this.editForm.controls['tpr_dlc_extend'].value ? '1' : '0'));
    formData.append('tpr_display_popup_extend_dlc', (this.editForm.controls['tpr_dlc_extend'].value && this.editForm.controls['tpr_display_popup_extend_dlc'].value ? '1' : '0'));

    formData.append('tpr_auto_close_lots_with_dlc', this.editForm.controls['tpr_auto_close_lots_with_dlc'].value);
    formData.append('tpr_stats', '0');

    this.apiProcessService.postProcess(formData)
    .subscribe(response => {
      this.saveError = !response;

      if(response) {
        if(this.clientId != '') {
          this.router.navigate(['settings/licences-edit/', this.clientId], 
            {fragment : 'process'});
        } else {
          this.router.navigate(['settings/process/process-list-traceability']);
        }
      }
    });
  }
}
