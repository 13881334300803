import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { MailReport } from '../../models/mail-report';
import { User } from '../../models/user';
import { Frequency } from '../../models/frequency';
import { Day } from '../../models/day';
import { Client } from '../../models/client';
import { ClientGroup } from '../../models/client-group';

@Injectable({
  providedIn: 'root',
})
export class ApiMailReportsService {
  constructor(private http: HttpClient) { }

  getMailReports(): Observable<MailReport[]> {
    return this.http
      .get<any>(environment.apiURL + 'espace_client/get_mail_reports.php')
      .pipe(
        map((response: any[]) => {
          return response.map((dataMailReport) => {
            return new MailReport(
              parseInt(dataMailReport.mre_id),
              new Frequency(dataMailReport.mre_fre_id, dataMailReport.fre_name),
              dataMailReport.mre_day_id ? new Day(parseInt(dataMailReport.mre_day_id), dataMailReport.day_name, 1) : null,
              dataMailReport.mre_title,
              dataMailReport.mre_start_date ? new Date(dataMailReport.mre_start_date) : null,
              dataMailReport.mre_last_date ? new Date(dataMailReport.mre_last_date) : null,
              new User(
                parseInt(dataMailReport.mre_usr_id),
                dataMailReport.usr_first_name,
                dataMailReport.usr_last_name
              ),
              []
            );
          });
        }),
        catchError((error) => {
          return of([]); // Retourne un tableau vide en cas d'erreur
        })
      );
  }

  getMailReport(mailReportId: string): Observable<MailReport> {
    return this.http
      .get<any>(environment.apiURL + 'espace_client/get_mail_reports.php?mailReportId=' +
        mailReportId)
      .pipe(
        map((dataMailReport: any) => {
          return new MailReport(
            parseInt(dataMailReport.mre_id),
            new Frequency(dataMailReport.mre_fre_id, dataMailReport.fre_name),
            dataMailReport.mre_day_id ? new Day(parseInt(dataMailReport.mre_day_id), dataMailReport.day_name, 1) : null,
            dataMailReport.mre_title,
            dataMailReport.mre_start_date ? new Date(dataMailReport.mre_start_date) : null,
            dataMailReport.mre_last_date ? new Date(dataMailReport.mre_last_date) : null,
            new User(
              parseInt(dataMailReport.mre_usr_id),
              dataMailReport.usr_first_name,
              dataMailReport.usr_last_name
            ),
            dataMailReport.recipients
          );
        }));
  }

  postMailReport(formData: any): Observable<any> {
    return this.http.post<any>(
      environment.apiURL + 'espace_client/post_mail_report.php',
      formData
    );
  }

  deleteMailReport(mailReportId: number): Observable<any> {
    return this.http.get<any>(
      environment.apiURL +
      'espace_client/delete_mail_report.php?mailReportId=' +
      mailReportId
    );
  }

  getMailReportLicences(mailReportId:string): Observable<Client[]> {
    return this.http
      .get<any>(
        environment.apiURL +
          'espace_client/get_mail_report_clients.php?mailReportId='+mailReportId
      )
      .pipe(
        map((response: any[]) => {
          return response.map((dataLicence) => {
            let objLicence =  new Client(
              parseInt(dataLicence['cli_id']),
              dataLicence['cli_name'],
              dataLicence['cli_city'],
              new ClientGroup(parseInt(dataLicence['cgr_id']), dataLicence['cgr_name'])
            );

            return objLicence;

          });
        }),
        catchError((error) => {
          return of([]);
        })
      );
  }

  moveLicence(mailReportId: number, licenceId: number, addLicence: boolean): Observable<any> {
    return this.http.get<any>(
      environment.apiURL +
        'espace_client/post_mail_report_client.php?mailReportId=' +
        mailReportId + '&licenceId='+ licenceId + '&addLicence=' + (addLicence ? 1 : 0)
    );
  }

}
