@defer (when dataLoaded) {
<div class="grid">
  <div class="col text-right">
  <button
  pButton
  [label]="'Ajouter une alerte' | transloco"
  icon="pi pi-plus"
  class="p-button-success"
  (click)="addAlert()"
  *ngIf="addAlertButton"
  ></button>
  </div>
</div>
<p-table
  #myTable
  [value]="alertList"
  styleClass="p-datatable-gridlines p-datatable-striped"
  stateStorage="session"
  stateKey="alertList-session"
  [(selection)]="selectedAlerts"
  rowGroupMode="rowspan"
  groupRowsBy="client.id"
  [paginator]="true"
  [rows]="20"
  [showCurrentPageReport]="true"
  currentPageReportTemplate="{totalRecords} {{ 'élément(s)' | transloco }}"
>
<ng-template pTemplate="header">
  <tr>
    <th class="text-center" pSortableColumn="client.id">{{ 'Licence' | transloco }} <p-sortIcon field="client.id"></p-sortIcon></th>
    <th class="text-center" rowspan="2">{{ 'Alerte' | transloco }}</th>
    <th class="text-center" rowspan="2">{{ 'Niveau d\'alerte' | transloco }}</th>
    <th class="text-center" rowspan="2">{{ 'Fréquence' | transloco }}</th>
    <th class="text-center" rowspan="2">{{ 'Délai en minutes' | transloco }}</th>
    <th class="text-center" rowspan="2">{{ 'Message' | transloco }}</th>
    @if(canWrite()) {
    <th class="text-center" rowspan="2">Actions</th>
    }
  </tr>
  <tr>
  <th>
  <p-columnFilter field="client.id" matchMode="in" [showMenu]="false">
    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
      <p-multiSelect
      [ngModel]="value"
      [options]="licences"
      [placeholder]="'Toutes' | transloco"
      (onChange)="filter($event.value); applyFilter($event.value)"
      optionLabel="name"
      optionValue="id"
      class="liste-recherche-table"
      >
      </p-multiSelect>
    </ng-template>
    @if(canWrite()) {
    <th></th>
    }
</p-columnFilter>
  </th>
  </tr>
</ng-template>
  <ng-template pTemplate="body"
    let-alerts
    let-ri="rowIndex"
    style="{'align-item': 'center'}"
    let-rowgroup="rowgroup"
    let-rowspan="rowspan">
    <tr>
      <td *ngIf="rowgroup" [attr.rowspan]="rowspan" class="text-left">
      <div class="myinline">
        <span>{{ alerts.client?.id + ' - ' + alerts.client?.name }}</span>
      </div>
      <td class="text-left">{{ (alerts.alertsType.ale_name).substring(2) }}</td>
      <td>
        <div class="myinline">
          <p-triStateCheckbox
            [(ngModel)]="alerts.cal_importance"
            [disabled] = "true"
            [ngClass]="{
                'info-checkbox': alerts.cal_importance === 'Information',
                'important-checkbox': alerts.cal_importance === 'Important',
                'tres-important-checkbox': alerts.cal_importance === 'Très important'
                }"
          ></p-triStateCheckbox>
          <label for="tricheckbox-{{alerts.cal_importance}}" style="margin-left: 5px;">
            {{ alerts.cal_importance }}
          </label>
        </div>
      </td>
      <td class="text-left">{{ alerts.alertsFrequencies.afr_name }}</td>
      <td class="text-left">{{ alerts.cal_repeat_delay }}</td>
      <td class="text-left text-ellipsis">{{ alerts.cal_message_perso }}</td>
      @if(canWrite()){
            <td *ngIf="rowgroup" [attr.rowspan]="rowspan"
                class="text-center">
              <div class="flex-center">
                <button
                  pButton
                  icon="pi pi-cog"
                  class="p-button-rounded p-button-success"
                  (click)="alertsSettings(alerts)"
                  [pTooltip]="'Gérer les alertes' | transloco"
                  tooltipPosition="left">
                </button>
              </div>
            </td>
            }
    </tr>
  </ng-template>
</p-table>

}@placeholder {
<div class="text-center">{{ 'Chargement en cours...' | transloco }}</div>
}
<p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>
