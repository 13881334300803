export const environment = {
    production: false,
    apiURL: 'https://preprod.zest-haccp.com/api/',
    eshopImagesURL: 'https://preprod.zest-haccp.com/_data/eshop/products/',
    v2URL: 'https://preprod.zest-haccp.com/',
    v3URL: 'https://preprod-angular.zest-haccp.com/',
    ecURL: 'https://preprod-espaceclient.zest-haccp.com/',
    
    UP2PAY_SITE:'1999888',
    UP2PAY_RANG:'32',
    UP2PAY_IDENTIFIANT:'2',
    UP2PAY_CLE_HMAC:'0123456789ABCDEF0123456789ABCDEF0123456789ABCDEF0123456789ABCDEF0123456789ABCDEF0123456789ABCDEF0123456789ABCDEF0123456789ABCDEF',
  };