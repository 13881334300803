@defer (when dataLoaded) {
<p-panel [header]="'Gestion des groupes' | transloco">
  <div class="formgrid grid">
      <div class="col text-right" style="margin-bottom: 15px;">
        <button
          pButton
          [label]="'Ajouter un groupe' | transloco"
          icon="pi pi-plus"
          class="p-button-success"
          (click)="displayAddCard()"
          [disabled] = "!addGroupDisabled"
        ></button>
      </div>
  </div>
  <div class="formgrid grid">
    <div class="col-12 no-padding-top">
      <p-card header="Liste des groupes">
          <div class="field">
            <p-tree
              [value]="treeNodes"
              selectionMode="single"
              class="w-full md:w-30rem listeGroupesLicences"
              [filter]="true"
              [(selection)]="selectedNodes"
              filterPlaceholder="Rechercher une licence ou un groupe"
              (onNodeSelect)="onNodeSelect($event)"
              (onNodeUnselect)="onNodeUnSelect()"/>
          </div>
      </p-card>
    </div>
    <div class="col-12 py-5">
        <div *ngIf="groupIsSelected" class="col-12 lg:col-12">
          <form [formGroup]="editForm">
            <p-panel [header]="'Groupe' | transloco">
                <div class="formgrid grid">
                  <div class="field col-12 md:col-12 xl:col-12">
                    <label for="param_name" class="label-required">{{ "Nom" | transloco }}</label>
                    <input
                      type="text"
                      pInputText
                      formControlName="param_name"
                      [required]="true"
                      class="p-inputtext w-full"
                      [style]="{ height: '45px'}"/>
                  </div>
                </div>
                <div class="formgrid grid">
                  <div class="col text-right">
                    @if(groupEmpty){
                    <button
                      pButton
                      [label]="'Effacer ce groupe' | transloco"
                      icon="pi pi-trash"
                      class="p-button-success"
                      (click)="deleteGroup()"
                      [disabled] = "!groupUpdate"
                    ></button>
                    }
                    <button
                      pButton
                      [label]="modifOrSave | transloco"
                      icon="pi pi-save"
                      class="p-button-success"
                      [style]="{ 'margin-left': '15px' }"
                      (click)="updateGroup()"
                      [disabled] = "!groupUpdate">
                    </button>
                  </div>
                </div>
            </p-panel>
          </form>

        <div *ngIf="!addaNewGroup" class="col-12 lg:col-12 py-5">
          <p-panel [header]="headerSousGroupe | transloco">
            <div class="d-flex align-items-center justify-content-between py-2">
                <button
                  pButton
                  [label]="'Ajouter un sous-groupe' | transloco"
                  icon="pi pi-plus"
                  class="p-button-success"
                  (click)="addSousGroup()"
                ></button>
                <!--<button
                  pButton
                  [label]="'Ajouter une licence' | transloco"
                  icon="pi pi-plus"
                  class="p-button-success"
                  (click)="addLicence()"
                  [disabled] = "!groupUpdate"
                ></button>-->
            </div>
            <ng-container *ngIf="!newSousGroup">
              <p-table
              [value]="sousGroupList"
              styleClass="p-datatable-gridlines p-datatable-striped"
              stateStorage="session"
              stateKey="groups-list-session"
              [(selection)]="selectedSousGroup"
              [paginator]="true"
              [rows]="20"
              [showCurrentPageReport]="false"
              currentPageReportTemplate="{totalRecords} {{ 'élément(s)' | transloco }}">
            <ng-template pTemplate="header">
              <tr>
                <th class="text-left">{{ 'Nom' | transloco }}</th>
              </tr>
            </ng-template>
              <ng-template
                pTemplate="body"
                let-sousGroups
                let-ri="rowIndex"
                style="{'align-item': 'center'}">
                <tr>
                  <td>{{ sousGroups.data.label }}</td>
                </tr>
              </ng-template>
          </p-table>
          </ng-container>
          <div *ngIf="newSousGroup" class="col-12 lg:col-12 py-5">
            <form [formGroup]="editForm">
                <div class="formgrid grid">
                  <div class="field col-12 md:col-12 xl:col-12">
                    <label for="param_name" class="label-required">{{ "Nom du sous-groupe" | transloco }}</label>
                    <input
                    type="text"
                    pInputText
                    formControlName="param_sous_name"
                    [required]="true"
                    class="p-inputtext w-full"
                    [style]="{ height: '45px'}"/>
                  </div>
                </div>
              <div class="formgrid grid">
                <div class="col text-right">
                  <button
                    pButton
                    [label]="'Annuler' | transloco"
                    icon="pi pi-minus"
                    class="p-button-success"
                    (click)="cancel()"
                  ></button>
                  <button
                    pButton
                    [label]="'Enregistrer' | transloco"
                    icon="pi pi-save"
                    class="p-button-success"
                    [style]="{ 'margin-left': '15px' }"
                    (click)="addGroup()"
                  ></button>
                </div>
              </div>
            </form>
          </div>
          </p-panel>
        </div>
    </div>
    </div>
  </div>
</p-panel>
}@placeholder {
<div class="text-center">{{ 'Chargement en cours...' | transloco }}</div>
}
<p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>
<p-toast position="top-center"></p-toast>

