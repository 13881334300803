import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import { Subscription } from 'rxjs';

import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { PanelModule } from 'primeng/panel';
import { InputNumberModule } from 'primeng/inputnumber';
import { TableModule } from 'primeng/table';
import { RippleModule } from 'primeng/ripple';
import {TooltipModule} from "primeng/tooltip";

import { AuthService } from '../../../../services/auth/auth.service';

import { ActivatedRoute } from '@angular/router';
import {Router} from "@angular/router";

import {TranslocoModule, TranslocoService} from '@ngneat/transloco';

import { Client } from '../../../../models/client';

import { EditComponent } from '../../../core/edit/edit.component';

import {InputTextModule} from "primeng/inputtext";

import {ReceptionType} from "../../../../models/reception-type-list";
import {ApiReceptionOutputListService} from "../../../../services/api/api-reception-output-list.service";
import {ReceptionOutputList} from "../../../../models/reception-output-list";
import {ConfirmationService, MessageService} from "primeng/api";
import {ConfirmDialogModule} from "primeng/confirmdialog";
import {ToastModule} from "primeng/toast";
import {SessionStorageService} from "../../../../services/storage/session-storage.service";
import {GlobalDatas} from "../../../../models/global-datas";

@Component({
  selector: 'app-process-list-reception-output-parameters',
  templateUrl: './process-list-reception-output-parameters.component.html',
  styleUrl: './process-list-reception-output-parameters.component.css',
  standalone: true,
  providers: [MessageService, ConfirmationService],
  imports: [
    CommonModule,
    ButtonModule,
    CheckboxModule,
    DropdownModule,
    InputNumberModule,
    RippleModule,
    FormsModule,
    PanelModule,
    ReactiveFormsModule,
    TranslocoModule,
    TableModule,
    InputTextModule,
    TooltipModule,
    ConfirmDialogModule,
    ToastModule,
  ]
})
export class ProcessListReceptionOutputParametersComponent extends EditComponent implements OnInit, OnDestroy {
  ReceptionOutputTypeSubscription: Subscription = new Subscription();
  ReceptionOutputPrintTypeSubscription: Subscription = new Subscription();
  ReceptionOutputPathSubscription: Subscription = new Subscription();
  ReceptionOutputListSubscription: Subscription = new Subscription();

  name: string = '';
  licences : Client[] = [];
  receptionOutputList: ReceptionOutputList[] = [];
  receptionType: ReceptionType[] = [];
  cliId: any;
  cprId: any;
  rou_id: string = "";

  editForm: FormGroup;
  ouiNon: { label: string, value: number }[];
  selectedOutputReceptionType: any;
  selectedOutput: any[] = [];
  path: any[] | undefined;
  dropdownPrintType: any[] | undefined;
  dropdownReceptionType: any[];
  globalDatas: GlobalDatas | null = null;

  addEnabled= true;
  cancelEnabled = false;
  displayTableau = false;
  modifInProgress = false;
  areButtonsDisabled = false;
  output: any;
  setting = '';

  yesNoOptions: { label: string, value: number }[] = [
    {label: 'Oui', value: 1},
    {label: 'Non', value: 0}
  ];

  constructor(
    private apiReceptionOutputListService: ApiReceptionOutputListService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private translocoService: TranslocoService,
    private router: Router,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private sessionStorageService: SessionStorageService,
  ) {
    super(route);

    this.editForm = this.formBuilder.group({
      param_receptionType: [{ value: '', disabled: true }],
      param_printType: ['', Validators.required],
      param_printName: ['', Validators.required],
      param_required: ['', Validators.required],
      param_outputPath: ['', Validators.required],
      param_order: ['', Validators.required],
    })
    this.dropdownPrintType = [];
    this.dropdownReceptionType = [];
    this.path = [];
    this.ouiNon = [];
    this.selectedOutputReceptionType = [];
  }

  override ngOnInit() {
    super.ngOnInit();

    this.globalDatas = this.sessionStorageService.get('globalDatas');
    this.cliId = this.route.snapshot.paramMap.get('cli_id') ?? '';
    this.cprId = this.route.snapshot.paramMap.get('cpr_id') ?? '';
    this.titlePage = this.route.snapshot.data['path'].slice(-1)[0]['label'];

    this.licences = this.authService.getUserLicences();

    this.ReceptionOutputListSubscription = this.apiReceptionOutputListService
      .getReceptionOutputListByLicence(this.cprId)
      .subscribe((data: ReceptionOutputList[]) => {
        this.receptionOutputList = data;
      });

    this.ReceptionOutputPrintTypeSubscription = this.apiReceptionOutputListService
      .getReceptionType(this.cliId)
      .subscribe((data => {
          this.dropdownReceptionType = data;
        })
      )

    this.dropdownPrintType = this.globalDatas?.output_reception_types;
    this.path = Array.isArray(this.globalDatas?.output_reception_path[0])
      ? this.globalDatas.output_reception_path[0]
        .filter(item => item && item.trim() !== '')
        .map(path => ({ label: path, value: path }))
      : [];

    this.ouiNon = [
      { label: 'Oui', value : 1 },
      { label: 'Non', value : 0 },
    ];

    this.dataLoaded = true;
  }

  accessToParameters() {
    this.router.navigate(['settings/process/process-reception/process-list-reception/', this.cliId,this.cprId]);
  }

  onRowSelect(event: any, cprId: any) {
    this.setting = "Modification de l'output";
    this.displayTableau = true;
    this.cancelEnabled = false;
    this.addEnabled = false;
    this.areButtonsDisabled = true;
    this.modifInProgress = true;
    this.output = event;
    this.rou_id = event.rou_id;
    if (this.output.cli_id !== '') {
      this.ReceptionOutputListSubscription = this.apiReceptionOutputListService
        .getReceptionOutputListByLicence(this.cprId)
        .subscribe((data: ReceptionOutputList[]) => {
          this.cprId = cprId;
          for (const item of data) {
            if (item.cpr_id == event.cpr_id) {
              this.editForm.controls['param_receptionType'].setValue(event.cpr_name);
              this.editForm.controls['param_printType'].setValue(event.rou_rot_id);
              this.editForm.controls['param_printName'].setValue(event.rou_name);
              this.editForm.controls['param_required'].setValue(event.rou_compulsory);
              this.editForm.controls['param_outputPath'].setValue(event.rou_exec_path);
              this.editForm.controls['param_order'].setValue(event.rou_order);
            }
          }
        });
    }
  }

  addOutputReception() {
    this.setting = "Nouvel output";
    this.displayTableau = true;
    this.areButtonsDisabled = true;
    this.cancelEnabled = false;
    this.addEnabled = false;
    this.modifInProgress = true;
    this.rou_id = "-1";
    this.editForm.controls['param_order'].setValue(1);
    this.editForm.controls['param_receptionType'].setValue(this.receptionOutputList[0].cpr_name);
  }
  deleteReceptionOutput(rowData: any) {
    this.confirmationService.confirm({
      message:
        this.translocoService.translate(
          'Confirmez-vous la suppression DEFINITIVE du process'
        ) +
        ' "' +
        rowData.rou_name +
        '" ?',
      header: 'Suppression',
      acceptLabel: this.translocoService.translate('Oui'),
      rejectLabel: this.translocoService.translate('Non'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.apiReceptionOutputListService.deleteOutput(rowData.rou_id).subscribe((response) => {
          if (response) {
            this.receptionOutputList = this.receptionOutputList.filter(
              (val: ReceptionOutputList) => val.rou_id !== rowData.rou_id
            );
            console.log('DEL OK');
          } else {
            console.log('DEL KO');
          }
        });
      },
    });
  }

  cancel(){
    this.displayTableau = false;
    this.cancelEnabled = true;
    this.addEnabled = true;
    this.areButtonsDisabled = false;

    this.editForm.controls['param_receptionType'].setValue('');
    this.editForm.controls['param_printType'].setValue('');
    this.editForm.controls['param_printName'].setValue('');
    this.editForm.controls['param_required'].setValue('');
    this.editForm.controls['param_outputPath'].setValue('');
    this.editForm.controls['param_order'].setValue('');
  }

  save() {
    let formData = new FormData();
    const formControls = Object.keys(this.editForm.controls);
    formControls.forEach(controlName => {
      const control = this.editForm.get(controlName);
      if (control) {
        formData.append(controlName, control.value);
      }
    });
    const rowData: any = {
      rou_id: this.rou_id,
      rou_cpr_id: this.cprId,
      cli_id: this.cliId,
      rot_id: formData.get('param_printType'),
      rou_name: formData.get('param_printName'),
      rou_compulsory: formData.get('param_required'),
      rou_exec_path: formData.get('param_outputPath'),
      rou_order: formData.get('param_order')
    };

    this.apiReceptionOutputListService.updateOutput(rowData).subscribe((response) => {
      if (response) {
        this.ReceptionOutputListSubscription = this.apiReceptionOutputListService
          .getReceptionOutputListByLicence(this.cprId)
          .subscribe((data: ReceptionOutputList[]) => {
            this.receptionOutputList = data;
          });
        this.cancel();
        console.log('Update OK', rowData);
      } else {
        this.messageService.add({ severity: 'warn', summary: 'Attention', detail: 'Tous les champs marqués d\'un astérisque sont obligatoires.' });
        console.log('Update KO', response);
      }
    });
  }

  ngOnDestroy() {
    this.ReceptionOutputTypeSubscription.unsubscribe();
    this.ReceptionOutputPrintTypeSubscription.unsubscribe();
    this.ReceptionOutputPathSubscription.unsubscribe();
  }
}
