import { Component, OnInit, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import {forkJoin, Subscription} from 'rxjs';

import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { PanelModule } from 'primeng/panel';
import { InputNumberModule } from 'primeng/inputnumber';
import { TableModule } from 'primeng/table';
import { RippleModule } from 'primeng/ripple';

import { AuthService } from '../../../../services/auth/auth.service';

import {ActivatedRoute, Router} from '@angular/router';
import {TranslocoModule, TranslocoService} from '@ngneat/transloco';

import { Client } from '../../../../models/client';

import { EditComponent } from '../../../core/edit/edit.component';

import {InputTextModule} from "primeng/inputtext";
import {TooltipModule} from "primeng/tooltip";

import {ProcessOutputList} from "../../../../models/process-output-list";
import {ApiProcessOutputListService} from "../../../../services/api/api-process-list-outputs";
import {ProcessOutputType} from "../../../../models/process-output-type";
import {ConfirmationService, MessageService} from "primeng/api";
import {ConfirmDialogModule} from "primeng/confirmdialog";
import {SessionStorageService} from "../../../../services/storage/session-storage.service";
import {GlobalDatas} from "../../../../models/global-datas";
import {ReceptionOutputList} from "../../../../models/reception-output-list";


@Component({
  selector: 'app-process-list-traceability-outputs',
  templateUrl: './process-list-traceability-outputs.component.html',
  styleUrl: './process-list-traceability-outputs.component.css',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    CheckboxModule,
    DropdownModule,
    InputNumberModule,
    RippleModule,
    FormsModule,
    PanelModule,
    ReactiveFormsModule,
    TranslocoModule,
    TableModule,
    InputTextModule,
    TooltipModule,
    ConfirmDialogModule,
  ],
  providers: [MessageService, ConfirmationService],
})
export class ProcessListTraceabilityOutputsComponent extends EditComponent implements OnInit, OnDestroy {
  ProcessListOutputSubscription: Subscription = new Subscription();
  ProcessOutputPrintTypeSubscription: Subscription = new Subscription();
  ProcessOutputPathSubscription: Subscription = new Subscription();

  processOutputList!: ProcessOutputList[];
  outputType: ProcessOutputType[] = [];

  name: string = '';
  licences : Client[] = [];
  tprId: any;
  cliId: any;
  touId: any;
  selectedOutput = [];
  outputIsSelected= false;
  outputSelected: any;


  editForm: FormGroup;
  dropdownPrintType: any[] | undefined;
  ouiNon: { label: string, value: number }[];
  globalDatas: GlobalDatas | null = null;
  selectedOutputProcessType: any;
  path: any[] | undefined;
  addEnabled= true;
  cancelEnabled = false;
  modifInProgress= false;
  areButtonsDisabled = false;
  setting = '';

  constructor(
    private apiProcessOutputListService: ApiProcessOutputListService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private translocoService: TranslocoService,
    private confirmationService: ConfirmationService,
    private router: Router,
    private sessionStorageService: SessionStorageService,
  ) {
    super(route);

    this.editForm = this.formBuilder.group({
      param_printType: ['', Validators.required],
      param_printName: ['', Validators.required],
      param_required: ['', Validators.required],
      param_outputPath: ['', Validators.required],
      param_order: ['', Validators.required],
    })
    this.dropdownPrintType = [];
    this.path = [];
    this.ouiNon = [];
    this.selectedOutputProcessType = [];
  }

  override ngOnInit() {
    super.ngOnInit();

    this.globalDatas = this.sessionStorageService.get('globalDatas');
    this.cliId = this.route.snapshot.paramMap.get('cli_id') ?? '';
    this.tprId = this.route.snapshot.paramMap.get('tpr_id') ?? '';
    this.titlePage = this.route.snapshot.data['path'].slice(-1)[0]['label'];
    this.licences = this.authService.getUserLicences();

    this.ProcessListOutputSubscription = this.apiProcessOutputListService
      .getProcessList(this.tprId)
      .subscribe((data: ProcessOutputList[]) => {
        this.processOutputList = data;
      });

    this.dropdownPrintType = this.globalDatas?.output_process_types;
    this.path = Array.isArray(this.globalDatas?.output_process_path[0])
      ? this.globalDatas.output_process_path[0]
        .filter(item => item && item.trim() !== '')
        .map(path => ({ label: path, value: path }))
      : [];

    this.ouiNon = [
      { label: 'Oui', value : 1 },
      { label: 'Non', value : 0 },
    ];

    this.dataLoaded = true;
  }

  accessToParameters() {
      this.router.navigate(['settings/process/process-list-traceability-parameters/', this.cliId, this.tprId]);
  }

  onRowSelect(event: any, touId: any) {
    this.setting = "Modification de l'output";
    this.addEnabled = false;
    this.outputIsSelected = true;
    this.areButtonsDisabled = true;

    if (this.tprId != '') {
      this.ProcessListOutputSubscription = this.apiProcessOutputListService
        .getProcessList(this.tprId)
        .subscribe((dataparam: ProcessOutputList[]) => {
          this.touId = touId;
          for (const item of dataparam) {
            if (item.tou_id == event.tou_id) {
              this.editForm.controls['param_printType'].setValue(event.tou_tot_id);
              this.editForm.controls['param_printName'].setValue(event.tou_name);
              this.editForm.controls['param_required'].setValue(event.tou_compulsory);
              this.editForm.controls['param_outputPath'].setValue(event.tou_exec_path);
              this.editForm.controls['param_order'].setValue(event.tou_order);
            }
          }
        });
    }
  }

  newOutput() {
    this.setting = "Nouvel output";
    this.addEnabled = false;
    this.outputIsSelected = true;
    this.areButtonsDisabled = true;
    this.touId =  -1;

    this.editForm.controls['param_printType'].setValue(1);
    this.editForm.controls['param_printName'].setValue('');
    this.editForm.controls['param_required'].setValue('');
    this.editForm.controls['param_outputPath'].setValue('');
    this.editForm.controls['param_order'].setValue(1);
  }

  updateOutput(event: any) {
    const rowData = {...event};
    rowData['client'] = rowData['client']['cli_id'];
    delete rowData.client;
    if (rowData['tou_id'] < 0) {
      rowData['tou_tpr_id'] = rowData['tpr_id'];
    }
    rowData['tot_id'] = rowData['tou_tot_id'];
    this.apiProcessOutputListService.updateOutput(rowData).subscribe((response) => {
      if (response) {
        this.ProcessListOutputSubscription = this.apiProcessOutputListService
          .getProcessList(this.tprId)
          .subscribe((data: ProcessOutputList[]) => {
            this.processOutputList = data;
          });
        this.cancel();
        console.log('Update OK', rowData);
      } else {
        console.log('Update KO', response);
      }
    });

  }

  deleteOutput(event: any) {
    this.confirmationService.confirm({
      message:
        this.translocoService.translate(
          'Vous êtes sur le point de supprimer définitivement cet ouput !'),
      header: 'Suppression',
      acceptLabel: this.translocoService.translate('Oui'),
      rejectLabel: this.translocoService.translate('Non'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.apiProcessOutputListService.deleteOutput( parseInt(event.tou_id)).subscribe((response) => {
          if (response) {
            this.processOutputList = this.processOutputList.filter(
              (val: ProcessOutputList) => val.tou_id !== event.tou_id
            );
            console.log('Deletion OK', event.tou_id);
          } else{
            console.log('Deletion KO', event.tou_id);
          }
        });
      },
    });
  }

  cancel() {
    this.addEnabled = true;
    this.outputIsSelected = false;
    this.areButtonsDisabled = false;

    this.editForm.controls['param_printType'].setValue('');
    this.editForm.controls['param_printName'].setValue('');
    this.editForm.controls['param_required'].setValue('');
    this.editForm.controls['param_outputPath'].setValue('');
    this.editForm.controls['param_order'].setValue('');
  }

  save() {
    let formData = new FormData();
    if (this.touId) {
      formData.append('tou_id', this.touId);
    } else {
      formData.append('tou_id', '-1');
    }
    formData.append('tou_name',this.editForm.controls['param_printName'].value);
    formData.append('tou_tpr_id', this.tprId);
    formData.append('tot_id',this.editForm.controls['param_printType'].value);
    formData.append('tou_compulsory',this.editForm.controls['param_required'].value);
    formData.append('tou_exec_path',this.editForm.controls['param_outputPath'].value);
    formData.append('tou_order',this.editForm.controls['param_order'].value);

    this.apiProcessOutputListService.updateOutput(formData).subscribe((response) => {
      if (response) {
        this.ProcessListOutputSubscription = this.apiProcessOutputListService
          .getProcessList(this.tprId)
          .subscribe((data: ProcessOutputList[]) => {
            this.processOutputList = data;
          });
        this.cancel();
        console.log('Update OK', formData);
      } else {
        console.log('Update KO', response);
      }
    });
  }

  ngOnDestroy() {
    this.ProcessOutputPrintTypeSubscription.unsubscribe();
    this.ProcessOutputPathSubscription.unsubscribe();
    this.ProcessListOutputSubscription.unsubscribe()
  }
}
