@defer (when dataLoaded) {
  @if(canWrite()) {
    <div class="grid">
        <div class="col text-right">
            <button pButton  [label]="'Ajouter une donnée' | transloco" icon="pi pi-plus" class="p-button-success" (click)="addCustomTableData()" ></button>  
        </div>
    </div>
}

<p-table
  #dt
  [value]="customTablesDatas"
  styleClass="p-datatable-gridlines p-datatable-striped"
  stateStorage="session"
  stateKey="tables-datas-list-session"
  [paginator]="true"
  [rows]="10"
  [showCurrentPageReport]="true"
  currentPageReportTemplate="Lignes {first} à {last} sur un total de {totalRecords}"
>
  <ng-template pTemplate="header">
    <tr>
      <th pSortableColumn="libelle">
        {{ "Libellé" | transloco }}<p-sortIcon field="libelle"></p-sortIcon>
      </th>
      <th pSortableColumn="datas">
        {{ "Données" | transloco }}<p-sortIcon field="datas"></p-sortIcon>
      </th>
      @if(canWrite()) {
        <th></th>
      }
    </tr>
    <tr>
      <th>
        <p-columnFilter
          type="text"
          field="libelle"
          matchMode="contains"
        ></p-columnFilter>
      </th>
      <th>
        <p-columnFilter
          type="text"
          field="datas"
          matchMode="contains"
        ></p-columnFilter>
      </th>
      @if(canWrite()) {
        <th></th>
      }
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-cTableData>
    <tr>
      <td>{{ cTableData.libelle }}</td>
      <td>{{ cTableData.datas }}</td>
      @if(canWrite()) {
        <td class="flex align-items-center justify-content-center">
          <button pButton icon="pi pi-pencil" class="flex p-button-rounded p-button-success mr-2" (click)="editData(cTableData)"></button>
          <button pButton icon="pi pi-trash" class="flex p-button-rounded p-button-warning" (click)="deleteData(cTableData)"></button>
        </td>
      }
    </tr>
  </ng-template>
</p-table>
} @placeholder {
<div class="text-center">{{ "Chargement en cours..." | transloco }}</div>
}

<p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>