import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TranslocoRootModule } from './transloco-root.module';

// PRIME NG
import { ButtonModule } from 'primeng/button';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { CardModule } from 'primeng/card';
import { DialogModule } from 'primeng/dialog';
import { ToolbarModule } from 'primeng/toolbar';
import { PanelMenuModule } from 'primeng/panelmenu';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TableModule } from 'primeng/table';

// CUSTOM MODULES
import { LoginComponent } from './components/login/login.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { LeftMenuComponent } from './components/global/left-menu/left-menu.component';
import { ContentModalComponent } from './components/global/content-modal/content-modal.component';
import { LoadingModalComponent } from './components/global/loading-modal/loading-modal.component';
import { SensorsListComponent } from './components/settings/sensors/sensors-list/sensors-list.component';
import { SensorsEditComponent } from './components/settings/sensors/sensors-edit/sensors-edit.component';

// CUSTOM SERVICES
import { HttpInterceptorService } from './services/auth/http-interceptor.service';
import { DialogService } from 'primeng/dynamicdialog';
import { HandleDialogService } from './services/dialog/handle-dialog.service';

@NgModule({
  declarations: [
    AppComponent,
    ContentModalComponent,
    LoadingModalComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    RouterModule,
    HttpClientModule,
    BrowserAnimationsModule,
    TranslocoRootModule,

    //prime ng
    BreadcrumbModule,
    ButtonModule,
    CardModule,
    DialogModule,
    PanelMenuModule,
    ProgressSpinnerModule,
    TableModule,
    ToolbarModule,

    //custom
    LoginComponent,
    DashboardComponent,
    LeftMenuComponent,
    SensorsEditComponent,
    SensorsListComponent
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
        useClass: HttpInterceptorService,
        multi: true,
    },
    DialogService,
    HandleDialogService,
    
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
