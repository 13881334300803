import { Component, OnInit, OnDestroy, ViewChild, Input } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { FormsModule } from '@angular/forms';
import { AuthService } from '../../../services/auth/auth.service';
import { Observable, Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

import { ButtonModule } from 'primeng/button';
import { MultiSelectModule } from 'primeng/multiselect';
import { Table, TableModule } from 'primeng/table';

import { Client } from '../../../models/client';
import { ClientGroup } from '../../../models/client-group';
import { PanelModule } from 'primeng/panel';

@Component({
  selector: 'app-affect-licences',
  templateUrl: './affect-licences.component.html',
  styleUrl: './affect-licences.component.css',
  standalone: true,
  imports: [
    ButtonModule,
    FormsModule,
    MultiSelectModule,
    PanelModule,
    TableModule,
    TranslocoModule,
  ],
})
export class AffectLicencesComponent implements OnInit, OnDestroy {
  @Input() getMethod!: (objectId: string) => Observable<any[]>;
  @Input() moveMethod!: (objectId: number, licenceId: number, add: boolean) => Observable<any>;

  allLicencesAdmin: Client[] = [];
  licencesIdAdmin: number[] = [];
  availableLicences: Client[] = [];

  affectedLicences: Client[] = [];
  affectedLicencesId: number[] = [];
  groups: ClientGroup[] = [];

  @ViewChild('tableAvailable') licencesAvailableRef!: Table;
  @ViewChild('tableLicences') licencesUserRef!: Table;


  constructor(
    private authService: AuthService,
    private route: ActivatedRoute
  ) {}

  dataLoaded: boolean = false;
  licencesSubscription: Subscription = new Subscription();
  objectId: string = ''; //l'objet pour qui on ajoute/retire les licences : utilisateur, rapport mail,...

  ngOnInit(): void {
    this.objectId = this.route.snapshot.paramMap.get('id') ?? '';

    if (this.objectId != '') {
      this.allLicencesAdmin = this.authService.getUserLicences();
      this.groups = this.authService.getUserGroups();

      this.refreshLicences();
    }
  }

  refreshLicences() {
    this.allLicencesAdmin.forEach((oneLicence) => {
      this.licencesIdAdmin.push(oneLicence.id);
    });

    this.licencesSubscription = this.getMethod(this.objectId)
      .subscribe((lstLicences: Client[]) => {
        // on filtre les licences de l'utilisateur
        // en mettant uniquement celle de l'utilisateur connecté
        lstLicences.forEach((oneLicence) => {
          if (this.licencesIdAdmin.indexOf(oneLicence.id) >= 0) {
            this.affectedLicences.push(oneLicence);
            this.affectedLicencesId.push(oneLicence.id);
          }
        });

        // dans les licences disponibles (pour ajout)
        // on enleve les licences déja affectees
        this.allLicencesAdmin.forEach((oneLicence) => {
          if (this.affectedLicencesId.indexOf(oneLicence.id) < 0) {
            this.availableLicences.push(oneLicence);
          }
        });

        this.dataLoaded = true;
      });
  }

  canWrite(): boolean {
    return this.authService.canWrite();
  }

  moveLicence(dataLicence: Client, addLicence: boolean) {
    this.moveMethod(parseInt(this.objectId), dataLicence.id, addLicence)
      .subscribe((response: any) => {
        if (response) {
          
          if(addLicence) {          
            const index = this.availableLicences.findIndex(
              (licence) => licence.id === dataLicence.id
            );

            if (index !== -1) {
              const licenceToMove = this.availableLicences.splice(index, 1)[0];
              this.affectedLicences.push(licenceToMove);

              this.licencesUserRef.totalRecords += 1;
              this.licencesAvailableRef.totalRecords -= 1;
            }
            

          } else {
            const index = this.affectedLicences.findIndex(
              (licence) => licence.id === dataLicence.id
            );
            
            if (index !== -1) {
              const licenceToMove = this.affectedLicences.splice(index, 1)[0];
              this.availableLicences.push(licenceToMove);
              
              this.licencesUserRef.totalRecords -= 1;
              this.licencesAvailableRef.totalRecords += 1;
            }
          }
        }
      });
  }

  ngOnDestroy(): void {
    if (this.licencesSubscription) {
      this.licencesSubscription.unsubscribe();
    }
  }
}
