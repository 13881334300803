@defer (when dataLoaded) {
<div class="card">
  <p-table
      [value]="receptionOutputList"
      styleClass="p-datatable-gridlines p-datatable-striped"
      stateStorage="session"
      stateKey="receptionOutputList-session"
      [(selection)]="selectedOutput"
      selectionMode="single"
      [tableStyle]="{'align-item': 'center', 'min-width': '50px', 'max-width': '100%'}"
      dataKey="rou_id"
      rowGroupMode="rowspan"
      groupRowsBy="client.id"
      editMode="row"
      [paginator]="true"
      [rows]="20"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="Lignes {first} à {last} sur un total de {totalRecords}"
>
      <ng-template pTemplate="header">
      <tr>
      <th class="text-center" pSortableColumn="client.id" class="text-center">{{ 'Licence' | transloco }} <p-sortIcon field="client.id"></p-sortIcon></th>
      <th class="text-center" pSortableColumn="cpr_name">{{ 'Type de réception' | transloco }}<p-sortIcon field="cpr_name"></p-sortIcon></th>
      @if(canWrite()) {
      <th  class="text-center">{{ 'Actions' | transloco }}</th>
      }
      </tr>
      <tr>
        <th>
        <p-columnFilter field="client.id" matchMode="in" [showMenu]="false">
          <ng-template pTemplate="filter" let-value let-filter="filterCallback">
            <p-multiSelect
            [ngModel]="value"
            [options]="licences"
            [placeholder]="'Toutes' | transloco"
            (onChange)="filter($event.value)"
            optionLabel="name"
            optionValue="id"
            [virtualScroll]="true"
            [showToggleAll]="false"
            [virtualScrollItemSize]="50"
            class="liste-recherche-table">
            </p-multiSelect>
          </ng-template>
        </p-columnFilter>
        </th>
        <th></th>
        <th></th>

      </ng-template>
      <ng-template pTemplate="body"
          let-output let-editing="editing"
          let-ri="rowIndex"
          style="{'align-item': 'center'}"
          let-rowgroup="rowgroup"
          let-rowspan="rowspan">
        <tr [pSelectableRow]="output">
          <td *ngIf="rowgroup" [attr.rowspan]="rowspan" class="text-left">
            <div class="myinline">
              <span>{{ output.cli_id + ' - ' + output.client?.name }}</span>
            </div>
          </td>
          <td  class="text-left">
            <div class="myinline">
              <span>{{ output.cpr_name }}</span>
            </div>
          </td>
          <td class="flex align-items-center justify-content-center" style="height:60px; display: flex;">
            <button
                pButton
                icon="pi pi-pencil"
                class="flex p-button-rounded p-button-success mr-2"
                (click)="updateTheProcess(output)"
                [pTooltip]="'Modifier le process' | transloco"
                tooltipPosition="left"
              >
            </button>
            <button
              pButton
              icon="pi pi-cog"
              class="flex p-button-rounded p-button-unactivate mr-2"
              (click)="updateReceptionParameter(output)"
              [pTooltip]="'Accés aux paramètres' | transloco"
              tooltipPosition="left"
            ></button>
            <button
              pButton
              icon="pi pi-print"
              class="flex p-button-rounded p-button-success mr-2"
              (click)="updateReceptionOutput(output)"
              [pTooltip]="'Accés aux outputs' | transloco"
              tooltipPosition="left"
            ></button>
            </td>
      </tr>
    </ng-template>
  </p-table>
</div>

}
@placeholder {
<div class="text-center">{{ 'Chargement en cours...' | transloco }}</div>
}
<p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>
