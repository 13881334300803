import { Component, OnInit } from '@angular/core';
import { TableModule } from 'primeng/table';
import { TranslocoModule } from '@ngneat/transloco'; 
import { Client } from '../../models/client';
import { Subscription } from 'rxjs';
import { ApiClientsService } from '../../services/api/api-clients.service';
import { CommonModule } from '@angular/common';


@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [
    CommonModule,
    TableModule,
    TranslocoModule
  ],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.css'
})
export class DashboardComponent implements OnInit {
  licences: Client[] = [];
  clientSubscription: Subscription = new Subscription();
  dataLoaded: boolean = false;

  constructor(private apiClientsService: ApiClientsService) {}

  ngOnInit() {
    this.clientSubscription = this.apiClientsService
      .getClients()
      .subscribe((dataClients: Client[]) => {
        this.licences = dataClients;
        this.dataLoaded = true;
      });
  }
}
