import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { MenuItem } from 'primeng/api';
import { TieredMenuModule } from 'primeng/tieredmenu';

import { PanelMenuModule } from 'primeng/panelmenu';
import { MenuModule } from 'primeng/menu';
import { ButtonModule } from 'primeng/button';

import { AuthService } from '../../../services/auth/auth.service';
import { GlobalDatas } from '../../../models/global-datas';
import { SessionStorageService } from '../../../services/storage/session-storage.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, mergeMap, switchMap } from 'rxjs';

@Component({
  selector: 'app-section-menu',
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  imports: [
    ButtonModule,
    MenuModule,
    PanelMenuModule,
    TieredMenuModule,
    TranslocoModule
  ],
  templateUrl: './section-menu.component.html',
  styleUrl: './section-menu.component.css'
})


export class SectionMenuComponent implements OnInit {
  items: MenuItem[] = [];
  restructuredItems: MenuItem[] = [];
  globalDatas: GlobalDatas | null = null;
  currMenu = '';

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private sessionStorageService: SessionStorageService,
    private router: Router, 
    private translocoService: TranslocoService,
  ) {}

  ngOnInit() {
    this.currMenu = this.router.url; // Récupère l'URL complète
    // j'ai du mettre un léger timeout sinon la traduction n'est pas totalement initialisée en cas de F5
    setTimeout(() => {
        this.initMenus();      
    }, 100);
  }

  initMenus() {
    this.globalDatas = this.sessionStorageService.get('globalDatas');
        

      if(this.currMenu == '/eshop') {
        this.items = [
          {
            label: this.translocoService.translate('Commandes'),
            icon: 'fa fa-cart-flatbed-suitcase pi-fw',
            routerLink: 'orders-list',
            visible: this.authService.isRoot()
          },
          {
            label: this.translocoService.translate('Statistiques'),
            icon: 'fa fa-sack-dollar pi-fw',
            routerLink: 'stats',
            visible: this.authService.isRoot()
          }];

        this.globalDatas?.eshopCategories.forEach(eshopCategory => {
          this.items.push({
            label: this.translocoService.translate(eshopCategory.title),
            icon: 'fa ' + eshopCategory.icon + ' pi-fw',
            routerLink: ['category/', eshopCategory.id, eshopCategory.titleForUrl]
          });
        });

        this.items.push({
          label: 'Tous les produits',
          icon: 'fa fa-list pi-fw',
          routerLink: 'all/'
        });
      } else if(this.currMenu == '/dashboards') {
        this.items = [
          {
            label: this.translocoService.translate('Alertes'),
            icon: 'fa fa-bell pi-fw',
            routerLink: 'sensors-triggered-alert',
          },
          {
            label: this.translocoService.translate('Monitoring CouchDB'),
            icon: 'fa fa-database pi-fw',
            routerLink: 'monitoring-couchdb',
            visible: this.authService.isRoot()
          },
          {
            label: this.translocoService.translate('Statistiques'),
            icon: 'fa fa-chart-column pi-fw',
            routerLink: 'statistics'
          }
        ];
      } else if(this.currMenu == '/settings') {
        this.items = [
          {
            label: this.translocoService.translate('Audits'),
            icon: 'fa fa-list-check pi-fw',
            routerLink: 'surveys'
          },
          {
            label: this.translocoService.translate('Groupes'),
            icon: 'fa fa-address-book pi-fw',
            routerLink: 'groups/groups-list',
            visible: this.authService.isRoot()
          },
          {
            label: this.translocoService.translate('Licences'),
            icon: 'fa fa-cutlery pi-fw',
            routerLink: 'licences',
            visible: this.authService.isRoot()
          },
          {
            label: this.translocoService.translate('Message d\'avertissement'),
            icon: 'fa fa-warning pi-fw',
            routerLink: 'alerts-for-users'
          },
          {
            label: this.translocoService.translate('Objectifs'),
            icon: 'fa fa-bullseye pi-fw',
            routerLink: 'stats-targets'
          },
          {
            label: this.translocoService.translate('Process de réception'),
            icon: 'fa fa-truck pi-fw',
            routerLink: 'process/process-reception',
            visible: this.authService.isRoot(),
          },
          {
            label: this.translocoService.translate('Process de traçabilité'),
            icon: 'fa fa-arrows-spin pi-fw',
            routerLink: 'process/process-list-traceability',
            visible: this.authService.isRoot(),
          },
          {
            label: this.translocoService.translate('Rapports mails'),
            icon: 'fa fa-envelope pi-fw',
            routerLink: 'emails'
          },
          {
            label: this.translocoService.translate('Recettes'),
            icon: 'fa fa-blender pi-fw',
            routerLink: 'dishes',
            visible: this.authService.isRoot()
          },
          {
            label: this.translocoService.translate('Sondes'),
            icon: 'fa fa-podcast pi-fw',
            routerLink: 'sensors',
          },
          {
            label: this.translocoService.translate('Tables'),
            icon: 'fa fa-table-list pi-fw',
            routerLink: 'tables',
            visible: this.authService.isRoot()
          },
          {
            label: this.translocoService.translate('Utilisateurs'),
            icon: 'fa fa-users pi-fw',
            routerLink: 'users',
            visible: this.authService.isRoot()  || this.authService.isDanielleThiriet()
          }
        ];
      } else if(this.currMenu == '/admin') {
        this.items = [
          {
            label: this.translocoService.translate('Anomalies'),
            icon: 'fa fa-times-circle pi-fw',
            routerLink: 'anomalies',
            visible: this.authService.isRoot(),
          },
          {
            label: this.translocoService.translate('Bandeaux'),
            icon: 'fa fa-flag pi-fw',
            routerLink: 'bandeau/bandeau-list',
            visible: this.authService.isRoot()
          },
          {
            label: this.translocoService.translate('Indicateurs de satisfaction'),
            icon: 'fa fa-clipboard-list pi-fw',
            routerLink: 'indicators',
            visible: this.authService.isRoot()
          },
          {
            label: this.translocoService.translate('LS Suppression/Transfert'),
            icon: 'fa fa-times-circle pi-fw',
            routerLink: 'ls-products/ls-products-del-fwd',
            visible: this.authService.isRoot(),
          },
          {
            label: this.translocoService.translate('Liste des produits LS'),
            icon: 'fa fa-list-check pi-fw',
            routerLink: 'ls-products/ls-products-list',
            visible: this.authService.isRoot(),
          }
        ];
      }
  }


  onNodeCollapse(event: any) {
    const collapsedNode = event.node;

    // Vérifie si l'élément ne peut pas être réduit
    if (collapsedNode.unclosable) {
      collapsedNode.expanded = true; // Force l'élément à rester ouvert
    }
  }
}
