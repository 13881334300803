import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { Client } from '../../models/client';
import { ProcessOutputList } from '../../models/process-output-list';
import {ProcessOutputType} from "../../models/process-output-type";

@Injectable({
  providedIn: 'root',
})
export class ApiProcessOutputListService {
  constructor(private http: HttpClient) {
  }

  getProcessList(tpr_id: string): Observable<any[]> {
    return this.http
      .get<any[]>(environment.apiURL + 'espace_client/get_output_process.php', {params: {tpr_id}})
      .pipe(
        map((response: any[]) => {
          return response.map((dataProcessList) => {
            return new ProcessOutputList(
              parseInt(dataProcessList.tou_id),
              parseInt(dataProcessList.tou_tpr_id),
              dataProcessList.tou_name,
              parseInt(dataProcessList.tou_tot_id),
              dataProcessList.tot_name,
              parseInt(dataProcessList.tou_compulsory),
              parseInt(dataProcessList.tou_order),
              dataProcessList.tou_exec_path,
              parseInt(dataProcessList.tou_from_id),
              new Client(
                parseInt(dataProcessList.cli_id),
                dataProcessList.cli_name)
            )
          })
        }),
        catchError((error) => {
          return of([]);
        })
      );
  }

  deleteOutput(tou_id: number): Observable<any> {
    return this.http.get<any>(
      environment.apiURL +
      'espace_client/delete_output_process.php?tou_id=' +
      tou_id
    );
  }

  updateOutput(formData: FormData): Observable<any> {
    return this.http.post<any>(
      environment.apiURL +
      'espace_client/post_update_output_process.php',
      formData
    );
  }

  getOutputProcessType(): Observable<ProcessOutputType[]> {
    return this.http
      .get<any>(environment.apiURL + 'espace_client/get_output_process_type.php')
      .pipe(
        map((response: any[]) => {
          return response.map((dataOutputList) => {
            return new ProcessOutputType(
              parseInt(dataOutputList.tot_id, 10),
              dataOutputList.tot_name
            )
          })
        }),
        catchError((error) => {
          return of([]);
        })
      );
  }

  getOutputProcessPath(): Observable<any> {
    return this.http
      .get<any[]>(environment.apiURL + 'espace_client/get_output_process_path.php?')
      .pipe(
        map(data => {
          return data.filter(path => typeof path === 'string' && path.trim() !== '')
            .map(path => {
              const parts = path.split('/');
              return { label: parts.pop(), value: path };
            });
        })
      );
  }
}
