import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { Subscription } from 'rxjs';
import { EditComponent } from '../../../core/edit/edit.component';

import { ButtonModule } from 'primeng/button';
import { PanelModule } from 'primeng/panel';
import { InputNumberModule } from 'primeng/inputnumber';
import { ApiSurveysService } from '../../../../services/api/api-surveys.service';
import { SurveyPage } from '../../../../models/survey-page';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { TableModule } from 'primeng/table';
import { AuthService } from '../../../../services/auth/auth.service';
import { ConfirmationService } from 'primeng/api';
import { SurveyQuestion } from '../../../../models/survey-question';

@Component({
  selector: 'app-surveys-pages-edit',
  templateUrl: './surveys-pages-edit.component.html',
  styleUrl: './surveys-pages-edit.component.css',
  standalone: true,
  imports: [
    ButtonModule,
    ConfirmDialogModule,
    InputNumberModule,
    PanelModule,
    ReactiveFormsModule,
    TableModule,
    TranslocoModule,
  ],
  providers: [ConfirmationService],
})
export class SurveysPagesEditComponent
  extends EditComponent
  implements OnInit, OnDestroy
{
  editForm: FormGroup;
  saveError: boolean = false;
  pageSubcription: Subscription = new Subscription();
  pageId: string = '';
  surveyId: string = '';
  ordreMax:number = 0;
  questions: SurveyQuestion[] = [];

  constructor(
    private apiSurveysService: ApiSurveysService,
    private authService: AuthService,
    private confirmationService: ConfirmationService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private translocoService: TranslocoService,
  ) {
    super(route);

    this.editForm = this.formBuilder.group({
      name: ['', Validators.required],
      order: [1, Validators.required],
    });
  }

  override ngOnInit() {
    super.ngOnInit();

    this.pageId = this.route.snapshot.paramMap.get('id') ?? '';
    this.surveyId = this.route.snapshot.paramMap.get('surveyId') ?? '';

    this.titlePage = this.route.snapshot.data['path'].slice(-1)[0]['label'];

    if (this.pageId != '') {
      this.pageSubcription = this.apiSurveysService
        .getPage(this.pageId)
        .subscribe((oPage: SurveyPage) => {
          this.questions = oPage.questions;

          this.editForm.controls['name'].setValue(oPage.name);
          this.editForm.controls['order'].setValue(oPage.order);

          //on calcule l'ordre max pour chaque parent
          this.ordreMax = oPage.questions.reduce((max, page) => {
            return page.order > max ? page.order : max;
          }, 0)
        });
    } else {
      this.editForm.controls['order'].setValue(
        this.route.snapshot.paramMap.get('nextOrder')
      );
    }
  }

  ngOnDestroy() {
    this.pageSubcription.unsubscribe();
  }

  save() {
    let formData = new FormData();
    formData.append('spa_id', this.pageId);
    formData.append('spa_sur_id', this.surveyId);
    formData.append('spa_name', this.editForm.controls['name'].value);
    formData.append('spa_order', this.editForm.controls['order'].value);

    this.apiSurveysService
      .postSurveyPage(formData)
      .subscribe((response: any) => {
        this.saveError = !response;

        if (response) {
          if(this.pageId == '') {
            //création on reste sur le formulaire pour ajouter les champs
            this.router.navigate(['settings/surveys-pages-edit', this.surveyId, response.spa_id])
          } else {
            this.router.navigate(['settings/surveys-edit', this.surveyId]);
          }
        }
      });
  }
  
  canWrite(): boolean {
    return this.authService.canWrite();
  }

  addQuestion() {
    const nextOrder = (this.ordreMax ?? 0) + 1;

    this.router.navigate(['settings/surveys-questions-add', this.surveyId, this.pageId, nextOrder]);
  }
  
  editQuestion(oQuestion:SurveyQuestion) {
    this.router.navigate(['settings/surveys-questions-edit', this.surveyId, this.pageId, oQuestion.id]);
  }

  deleteQuestion(oQuestion:SurveyQuestion) {
    this.confirmationService.confirm({
      message:
        this.translocoService.translate(
          'Confirmez-vous la suppression de la question'
        ) +
        ' "' +
        oQuestion.name +
        '" ?',
      header: 'Suppression',
      acceptLabel: this.translocoService.translate('Oui'),
      rejectLabel: this.translocoService.translate('Non'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.apiSurveysService
          .deleteQuestion(oQuestion.id)
          .subscribe((response: any) => {
            if (response) {
              this.questions = this.questions.filter(
                (val: SurveyQuestion) => val.id !== oQuestion.id
              );
            }
          });
      },
    });
  }

}