
export class EshopCategory {
    public id: number;
    public title: string;
    public titleForUrl: string;
    public icon: string;
    
    constructor(
        id: number,
        title: string,
        titleForUrl: string,
        icon: string,
    ) {
        this.id = id;
        this.title = title;
        this.titleForUrl = titleForUrl;
        this.icon = icon;
    }
}