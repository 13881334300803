@defer (when dataLoaded) {
<p-panel header="{{ 'Indicateurs de satisfaction' | transloco }}">
  <div class="grid panel-actions">
    <div class="col text-right">
      <button
        pButton
        [label]="'Ajouter un indicateur de satisfaction' | transloco"
        icon="pi pi-plus"
        class="p-button-success"
        (click)="addIndicator()"
      ></button>
    </div>
  </div>
  <p-table
    #dt
    [value]="indicators"
    styleClass="p-datatable-gridlines p-datatable-striped"
    stateStorage="session"
    stateKey="indicators-list-session"
    [paginator]="true"
    [rows]="10"
    [showCurrentPageReport]="true"
    currentPageReportTemplate="{totalRecords} {{ 'élément(s)' | transloco }}"
  >
    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="label">
          {{ "Titre" | transloco }}
          <p-sortIcon field="label"></p-sortIcon>
        </th>
        <th pSortableColumn="nbUsers">
          {{ "Utilisateurs" | transloco }}
          <p-sortIcon field="nbUsers"></p-sortIcon>
        </th>
        <th pSortableColumn="nbResponses">
          {{ "Réponses" | transloco }}
          <p-sortIcon field="nbResponses"></p-sortIcon>
        </th>
        <th pSortableColumn="noteAverage">
          {{ "Note moyenne" | transloco }}
          <p-sortIcon field="noteAverage"></p-sortIcon>
        </th>
        <th></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-indic>
      <tr>
        <td>{{ indic.label }}</td>
        <td>{{ indic.nbUsers }}</td>
        <td>{{ indic.nbResponses }}</td>
        <td>{{ indic.noteAverage }}</td>
        <td class="flex align-items-center justify-content-center gap-3">
          <a (click)="editIndicator(indic)" title="{{ 'Modifier' | transloco}}">
            <i class="pi pi-pencil"></i>
          </a>
          <a (click)="usersIndicator(indic)" title="{{ 'Utilisateurs' | transloco}}">
            <i class="pi pi-users"></i>
          </a>
          <a (click)="statsIndicator(indic)" title="{{ 'Statistiques' | transloco}}">
            <i class="fa fa-ranking-star"></i>
          </a>
          <a (click)="deleteIndicator(indic)" title="{{ 'Supprimer' | transloco}}">
            <i class="pi pi-trash"></i>
          </a>
        </td>
      </tr>
    </ng-template>
  </p-table>
</p-panel>
<p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>
} @placeholder {
<div class="text-center">{{ "Chargement en cours..." | transloco }}</div>
}
