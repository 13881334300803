import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormsModule, FormGroup, FormBuilder, Validators, AbstractControlOptions, ReactiveFormsModule  } from '@angular/forms';
import { Subscription } from 'rxjs';

import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { PanelModule } from 'primeng/panel';
import { InputNumberModule } from 'primeng/inputnumber';

import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco'; 
import { EditComponent } from '../../../core/edit/edit.component';
import { ApiProcessListService } from '../../../../services/api/api-process-list.service';
import { TrackingProcess } from '../../../../models/tracking-process';
import { ApiClientsService } from '../../../../services/api/api-clients.service';
import { ClientParametersReception } from '../../../../models/client-parameters-reception';
@Component({
  selector: 'app-process-reception-edit',
  templateUrl: './process-reception-edit.component.html',
  styleUrl: './process-reception-edit.component.css',
  standalone: true,
  imports: [
    ButtonModule,
    CheckboxModule,
    DropdownModule,
    InputNumberModule,
    FormsModule,
    PanelModule,
    ReactiveFormsModule,
    TranslocoModule
  ],
})
export class ProcessReceptionEditComponent extends EditComponent implements OnInit, OnDestroy {
  cprSubscription: Subscription = new Subscription();
  
  editForm: FormGroup;
  cprId: string = '';
  cprObj: ClientParametersReception | null = null;

  saveError:boolean = false;
  selectedImage: { fullPath: string, label: string } | null = null;

  optionsDisplay: any = [
    {value: 0, label : 'Masqué' },
    {value: 1, label : 'Affiché et facultatif' },
    {value: 2, label : 'Affiché et obligatoire' },
  ];

  constructor(
    private apiClientsService: ApiClientsService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
  ) {
      super(route);

      this.editForm = this.formBuilder.group({
        cpr_name: [''],
        cpr_dlc: ['0'],
        cpr_numlot: ['0'],
        cpr_tlo: ['0'],
        cpr_auto_close_lot: ['0'],

        cpr_img_force: [false],
        cpr_temperature: [false],
        cpr_output: [false],
        cpr_supplier: [false],
      }); 
   }

  override ngOnInit() {
    super.ngOnInit();
    
    this.cprId = this.route.snapshot.paramMap.get('id') ?? '';
    this.titlePage = this.route.snapshot.data['path'].slice(-1)[0]['label'];
  
    if(this.cprId != '') {
      // recuperer les données du process
      this.cprSubscription = this.apiClientsService.getReceptionParameters(parseFloat(this.cprId)).subscribe((oCpr: ClientParametersReception) => {

        this.cprObj = oCpr;

        this.editForm.controls['cpr_name'].setValue(oCpr.name);
        this.editForm.controls['cpr_dlc'].setValue(oCpr.dlc);
        this.editForm.controls['cpr_numlot'].setValue(oCpr.numLot);
        this.editForm.controls['cpr_tlo'].setValue(oCpr.tlo);
        this.editForm.controls['cpr_auto_close_lot'].setValue(oCpr.autoCloseLot);

        this.editForm.controls['cpr_img_force'].setValue(oCpr.imageForce == 1);
        this.editForm.controls['cpr_temperature'].setValue(oCpr.temperature == 1);
        this.editForm.controls['cpr_output'].setValue(oCpr.output == 1);
        this.editForm.controls['cpr_supplier'].setValue(oCpr.supplier == 1);
      });    
    }
  }
  ngOnDestroy() {
      this.cprSubscription.unsubscribe();
  }

  save() {    
    let formData = new FormData();

    formData.append('cpr_id', this.cprId);
    formData.append('cpr_dlc', this.editForm.controls['cpr_dlc'].value);
    formData.append('cpr_numlot', this.editForm.controls['cpr_numlot'].value);
    formData.append('cpr_tlo', this.editForm.controls['cpr_tlo'].value);
    formData.append('cpr_auto_close_lot', this.editForm.controls['cpr_auto_close_lot'].value);

    formData.append('cpr_img_force', (this.editForm.controls['cpr_img_force'].value ? '1' : '0'));
    formData.append('cpr_temperature', (this.editForm.controls['cpr_temperature'].value ? '1' : '0'));
    formData.append('cpr_output', (this.editForm.controls['cpr_output'].value ? '1' : '0'));
    formData.append('cpr_supplier', (this.editForm.controls['cpr_supplier'].value ? '1' : '0'));


    this.apiClientsService.postReceptionParameters(formData)
    .subscribe(response => {
      this.saveError = !response;

      if(response) {
        this.router.navigate(['settings/process/process-reception']);
      }
    });
  }
}
