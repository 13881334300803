@defer (when dataLoaded) {
  @if(cartProducts.length == 0) {
    <div class="text-center">{{ 'Aucun produit dans le panier' | transloco }}</div>
  }
  @else 
  {
    <p-table 
      [value]="cartProducts" 
      [tableStyle]="{ 'min-width': '50rem' }"
      styleClass="table-with-bottom-border">
      <ng-template pTemplate="header">
          <tr>
              <th></th>
              <th>{{ 'Produit' | transloco }}</th>
              <th class="text-right">{{ 'Prix unitaire HT' | transloco }}</th>
              <th class="text-right col-sous-total">{{ 'Quantité' | transloco }}</th>
              <th class="text-right col-sous-total">{{ 'Sous-total HT' | transloco }}</th>
              <th class="text-center">{{ 'Supprimer' | transloco }}</th>
          </tr>
      </ng-template>
      <ng-template pTemplate="body" let-product>
          <tr>
              <td class="text-center">
                <a (click)="redirectProduct(product.productObject)">
                  <img
                  [alt]="product.productObject.title"
                  [src]="product.productObject.photo1"
                  class="photoCart"
                  />
                </a>
              </td>
              <td>
                <a (click)="redirectProduct(product.productObject)">{{ product.productObject.title }}</a>
              </td>
              <td class="text-right">{{ product.productObject.htPrice | number: '1.2-2' }}&euro;</td>
              <td class="text-right col-sous-total">
                <p-inputNumber 
                  inputId="integeronly" 
                  [(ngModel)]="product.quantity" 
                  (ngModelChange)="onQuantityChange(product)"
                  class="qtyOrder"
                  mode="decimal"
                  [min]="0"
                  [step]="1"
                  [showButtons]="true"
                  buttonLayout="horizontal"
                  incrementButtonIcon="pi pi-plus" 
                  decrementButtonIcon="pi pi-minus"
                  [useGrouping]="false" />
              </td>
              <td class="text-right col-sous-total">{{ product.quantity * product.productObject.htPrice | number: '1.2-2' }}&euro;</td>
              <td class="text-center">
                <button pButton icon="pi pi-trash" class="p-button-rounded p-button-warning" (click)="removeProduct(product)"></button>
              </td>
          </tr>
      </ng-template>
      <ng-template pTemplate="footer">
          <tr>
              <td colspan="4" class="text-right">{{ 'Sous total HT' | transloco }}</td>
              <td class="text-right">{{ cartTotalPrice | number: '1.2-2' }}&euro;</td>
              <td></td>
          </tr>
          <tr>
              <td colspan="4" class="text-right">{{ 'TVA' | transloco }}</td>
              <td class="text-right">{{ cartTotalTax | number: '1.2-2' }}&euro;</td>
              <td></td>
          </tr>
          <tr>
              <td colspan="4" class="text-right">{{ 'Total TTC' | transloco }}</td>
              <td class="text-right">{{ cartTotalPrice + cartTotalTax | number: '1.2-2' }}&euro;</td>
              <td></td>
          </tr>
      </ng-template>
    </p-table>
    <div class="text-right mt-4">
      <button
        pButton
        [label]="'Passer à la commande' | transloco"
        icon="fa-solid fa-cart-plus"
        class="flex-1"
        (click)="redirectOrder()"
      ></button>
    </div>
}
} @placeholder {
<div class="text-center">{{ 'Chargement en cours...' | transloco }}</div>
}
