<div [formGroup]="editForm">
  <p-checkbox class="mb-1" [binary]="true" formControlName="cpa_stats_menu" (click)="clicModuleStats()" value="1"
    [label]="'Activer le module statistiques' | transloco"></p-checkbox>

  @if(detailStats) {
    <h3>
      <i class="fa fa-list-check mr-2"></i>
      {{ "Données affichées" | transloco }}
    </h3>
  <div class="field-checkbox flex flex-row align-items-start gap-3 mt-4">
    <p-checkbox class="mb-1" [binary]="true" formControlName="cpa_stats_tasks" value="1"
      [label]="'Tâches' | transloco"></p-checkbox>
    <p-checkbox class="mb-1" [binary]="true" formControlName="cpa_stats_temps" value="1"
      [label]="'Températures' | transloco"></p-checkbox>
    <p-checkbox class="mb-1" [binary]="true" formControlName="cpa_stats_ncs" value="1"
      [label]="'Non conformités' | transloco"></p-checkbox>
  </div>

  <div class="formgrid grid">
    <div class="field col-12 md:col-4 xl:col-4">
      <div class="field">
        <label for="tpr_stats_treatments">{{ 'Traitements clôts/non clôts' | transloco }}</label>
        <p-multiSelect
          [options]="processList"
          optionLabel="tpr_name_clean"
          optionValue="tpr_id"
          class="w-full"
          autoWidth="false"
          formControlName="tpr_stats_treatments"
          [style]="{ width: '100%' }"
        >
        </p-multiSelect>
      </div>
    </div>
    <div class="field col-12 md:col-4 xl:col-4">
      <div class="field">
        <label for="tpr_stats_photos">{{ 'Photos rattachées' | transloco }}</label>
        <p-multiSelect
          [options]="processList"
          optionLabel="tpr_name_clean"
          optionValue="tpr_id"
          class="w-full"
          autoWidth="false"
          formControlName="tpr_stats_photos"
          [style]="{ width: '100%' }"
        >
        </p-multiSelect>
      </div>
    </div>
    <div class="field col-12 md:col-4 xl:col-4">
      <div class="field">
        <label for="tpr_stats_lots">{{ 'Lots rattachés' | transloco }}</label>
        <p-multiSelect
          [options]="processList"
          optionLabel="tpr_name_clean"
          optionValue="tpr_id"
          class="w-full"
          autoWidth="false"
          formControlName="tpr_stats_lots"
          [style]="{ width: '100%' }"
        >
        </p-multiSelect>
      </div>
    </div>
  </div>
  }
</div>