export class Frequency {
    public id: number;
    public name: string;
    public delay: number;
    public code: string;

    constructor(
        id: number,
        name: string,
        delay: number = 0,
        code: string = ''
    ) {
        this.id = id;
        this.name = name;
        this.delay = delay;
        this.code = code;
    }
}