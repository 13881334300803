@defer (when dataLoaded) {
  <form [formGroup]="editForm" style="display: block" (ngSubmit)="save()">
    <p-panel [header]="titlePage">
      <div class="formgrid grid">
        <div class="field col-12">
          <label for="reference" class="label-required">{{
            "Référence" | transloco
          }}</label>
          <input
            type="text"
            pInputText
            id="reference"
            formControlName="reference"
            required="true"
            autofocus
            class="p-inputtext w-full"
          />
          @if(isErrorFormControl(editForm.controls['reference'])) {
          <small class="text-red-500">{{ "Référence requise" | transloco }}</small>
          }
        </div>

        <div class="field col-12">
          <label for="name" class="label-required">{{
            "Titre" | transloco
          }}</label>
          <input
            type="text"
            pInputText
            id="name"
            formControlName="name"
            required="true"
            autofocus
            class="p-inputtext w-full"
          />
          @if(isErrorFormControl(editForm.controls['name'])) {
          <small class="text-red-500">{{ "Titre requis" | transloco }}</small>
          }
        </div>
        
        <div class="field col-12 md:col-3 xl:col-3">
          <label for="field_type" class="label-required">{{
            "Type de question" | transloco
          }}</label>
          <p-dropdown
            autoWidth="false"
            [style]="{ width: '100%' }"
            [options]="questionTypes"
            class="w-full"
            formControlName="qty_id"
            (ngModelChange)="onQuestionTypeChange()"
            optionLabel="qty_name"
            optionValue="qty_id"
          ></p-dropdown>
          @if(isErrorFormControl(editForm.controls['field_type'])) {
          <small class="text-red-500">{{
            "Type de champ requis" | transloco
          }}</small>
          }
        </div>
        
        <div class="field col-12 md:col-3 xl:col-3">
          <label for="field_type" class="label-required">{{
            "Mode de saisie" | transloco
          }}</label>
          <p-dropdown
            autoWidth="false"
            [style]="{ width: '100%' }"
            [options]="surveysInputModes"
            class="w-full"
            formControlName="imo_id"
            optionLabel="imo_name"
            optionValue="imo_id"
          ></p-dropdown>
          @if(isErrorFormControl(editForm.controls['field_type'])) {
          <small class="text-red-500">{{
            "Type de champ requis" | transloco
          }}</small>
          }
        </div>


        <div class="field col-12 md:col-2 xl:col-2">
          <label for="order" class="label-required">{{
            "Ordre d'affichage" | transloco
          }}</label>
          <br />
          <p-inputNumber
            inputId="order"
            formControlName="order"
            [min]="-50"
            [max]="5000"
            [showButtons]="true"
            buttonLayout="horizontal"
            incrementButtonIcon="pi pi-plus" 
            decrementButtonIcon="pi pi-minus"
            [required]="true"
          ></p-inputNumber>
          @if(isErrorFormControl(editForm.controls['order'])) {
          <small class="text-red-500">{{ "Ordre requis" | transloco }}</small>
          }
        </div>

        @if(displayOptionsText) {
          <div class="field col-12 md:col-6 xl:col-6">
            <label for="valDefault">{{
              "Valeur par défaut" | transloco
            }}</label>
            <input
              type="text"
              pInputText
              id="valDefault"
              formControlName="valDefault"
              autofocus
              class="p-inputtext w-full"
            />
          </div>
          <div class="field col-12 md:col-6 xl:col-6">
            <label for="placeholder">{{
              "Texte aide" | transloco
            }}</label>
            <input
              type="text"
              pInputText
              id="placeholder"
              formControlName="placeholder"
              autofocus
              class="p-inputtext w-full"
            />
          </div>
      }

      </div>

      
      @if(displayOptionsValues) {
        <div class="field col-12 md:col-12 xl:col-12 mb-0">
          <h3 class="mb-0 mt-0">
            <i class="fa fa-list mr-2"></i>
            {{ "Valeurs" | transloco }}
          </h3>
        </div>

        <div formArrayName="que_values_list">
          @for (oneValue of valueControls; track oneValue; let idx = $index) {
          <div class="field">
            <label [for]="idx" class="label-required">{{ 'Valeur N°' | transloco }}{{ idx + 1}}</label>
            <div class="flex">
              <input pInputText type="oneValue" [id]="idx" [formControlName]="idx" class="p-inputtext w-full mr-2">
              @if(valueControls.length > 1) {
              <button pButton icon="pi pi-trash" class="p-button-p-button-success" (click)="removeValue(idx)"></button>
              }
            </div>
            @if(oneValue.invalid && (oneValue.dirty || oneValue.touched)) {
              @if(oneValue['errors'] && oneValue['errors']['required']) {
              <br /><small class="text-red-500">{{ 'La valeur est requise.' | transloco }}</small>
              }
            }
          </div>
          }
        </div>

        <button pButton [label]="'Ajouter une valeur supplémentaire' | transloco" icon="pi pi-plus"
          class="p-button-success mb-2" type="button" (click)="addValue()"></button>
      }

      <div class="field col-12 md:col-12 xl:col-12 mb-0">
        <h3 class="mb-0 mt-0">
          <i class="fa fa-gear mr-2"></i>
          {{ "Options" | transloco }}
        </h3>
      </div>
      <div class="field col-2 md:col-2 xl:col-2">
        <p-checkbox
          class="mb-1"
          [binary]="true"
          formControlName="required"
          value="1"
          [label]="'Saisie obligatoire' | transloco"
        ></p-checkbox>
      </div>


      @if(saveError) {
      <div class="text-red-500">
        {{ "Une erreur est survenue lors de l'enregistrement" | transloco }}
      </div>
      }
      <div class="flex overflow-hidden">
        <div class="flex-grow-1 flex align-items-center justify-content-center">
          <p-button
            type="submit"
            icon="pi pi-save"
            [disabled]="!editForm.valid"
            [label]="'Enregistrer' | transloco"
          ></p-button>
        </div>
        <div
          class="flex-none flex align-items-center justify-content-center mr-3"
        >
          <div class="label-required">
            {{ "Champs obligatoires" | transloco }}
          </div>
        </div>
      </div>
    </p-panel>
  </form>
}