@defer (when dataLoaded) {
<form [formGroup]="editForm" (ngSubmit)="save()">
  <p-panel [header]="titlePage">
    <p-tabView styleClass="tabview-custom" [(activeIndex)]="activeTabIndex">
      @if(cliId == '') {
        <p-tabPanel>
          <ng-template pTemplate="header">
            <div class="flex align-items-center gap-2">
              <i class="fa fa-clone"></i>
              {{ "Modèle" | transloco }}
            </div>
          </ng-template>

          <app-licences-edit-clone [editForm]="editForm" [cliId]="cliId" (changeLicenceEvent)="changeLicenceModele()">
          </app-licences-edit-clone>
        </p-tabPanel>
      }
      <p-tabPanel>
        <ng-template pTemplate="header">
          <div class="flex align-items-center gap-2">
            <i class="fa fa-house"></i>
            {{ "Coordonnées" | transloco }}
          </div>
        </ng-template>

        <app-licences-edit-details [editForm]="editForm" [cliId]="cliId">
        </app-licences-edit-details>
      </p-tabPanel>
      <p-tabPanel>
        <ng-template pTemplate="header">
          <div class="flex align-items-center gap-2">
            <i class="fa fa-address-book"></i>
            {{ "Appartenance" | transloco }}
          </div>
        </ng-template>
        <app-licences-edit-groups [editForm]="editForm" [cliId]="cliId">
        </app-licences-edit-groups>
      </p-tabPanel>
      @if(!cloneLicence) {
        <p-tabPanel>
          <ng-template pTemplate="header">
            <div class="flex align-items-center gap-2">
              <i class="fa fa-gears"></i>
              {{ "Options" | transloco }}
            </div>
          </ng-template>
          <app-licences-edit-options [editForm]="editForm" [cliId]="cliId">
          </app-licences-edit-options>
        </p-tabPanel>
      }
      <p-tabPanel>
        <ng-template pTemplate="header">
          <div class="flex align-items-center gap-2">
            <i class="fa fa-image"></i>
            {{ "Logo" | transloco }}
          </div>
        </ng-template>
        <app-licences-edit-logo [editForm]="editForm" [cliId]="cliId">
        </app-licences-edit-logo>
      </p-tabPanel>
      @if(!cloneLicence) {
        <p-tabPanel>
          <ng-template pTemplate="header">
            <div class="flex align-items-center gap-2">
              <i class="fa fa-chart-column"></i>
              {{ "Statistiques" | transloco }}
            </div>
          </ng-template>
          <app-licences-edit-stats [editForm]="editForm" [cliId]="cliId">
          </app-licences-edit-stats>
        </p-tabPanel>
      }
      @if(cliId != '') {
        <p-tabPanel>
          <ng-template pTemplate="header">
            <div class="flex align-items-center gap-2">
              <i class="fa fa-warning"></i>
              {{ "Alertes" | transloco }}
            </div>
          </ng-template>
          <app-alerts-for-users-add></app-alerts-for-users-add>
        </p-tabPanel>
        <p-tabPanel>
          <ng-template pTemplate="header">
            <div class="flex align-items-center gap-2">
              <i class="fa fa-arrows-spin"></i>
              {{ "Process de traçabilité" | transloco }}
            </div>
          </ng-template>
          <app-process-list-traceability></app-process-list-traceability>
        </p-tabPanel>
        <p-tabPanel>
          <ng-template pTemplate="header">
            <div class="flex align-items-center gap-2">
              <i class="fa fa-podcast"></i>
              {{ "Sondes" | transloco }}
            </div>
          </ng-template>
          <app-sensors-settings></app-sensors-settings>
        </p-tabPanel>
        <p-tabPanel>
          <ng-template pTemplate="header">
            <div class="flex align-items-center gap-2">
              <i class="fa fa-users"></i>
              {{ "Utilisateurs" | transloco }}
            </div>
          </ng-template>
          <app-licences-edit-users></app-licences-edit-users>
        </p-tabPanel>
      }
    </p-tabView>
    @if(saveError) {
    <div class="text-red-500">
      {{ "Une erreur est survenue lors de l'enregistrement" | transloco }}
    </div>
    }
    <div class="flex overflow-hidden">
      <div class="flex-grow-1 flex align-items-center justify-content-center">
        <p-button type="submit" icon="pi pi-save" [disabled]="!editForm.valid"
          [label]="'Enregistrer' | transloco"></p-button>
      </div>
      <div class="flex-none flex align-items-center justify-content-center mr-3">
        <div class="label-required">{{ "Champs obligatoires" | transloco }}</div>
      </div>
    </div>
  </p-panel>
</form>
<p-toast />
}