<form [formGroup]="editForm" style="display: block" (ngSubmit)="save()">
  <p-panel [header]="titlePage">
    <div class="field">
      <label for="sen_cli_id" class="label-required">{{
        "Licence" | transloco
      }}</label>
      <p-dropdown
        autoWidth="false"
        [style]="{ width: '100%' }"
        [options]="licences"
        class="w-full"
        formControlName="sur_cli_id"
        [filter]="true"
        optionLabel="displayName"
        optionValue="id"
        [placeholder]="'Sélectionnez une licence' | transloco"
        [required]="true"
        [virtualScroll]="true"
        [virtualScrollItemSize]="50"
      ></p-dropdown>
      @if(isErrorFormControl(editForm.controls['sur_cli_id'])) {
      <small class="text-red-500">{{ "Licence requise" | transloco }}</small>
      }
    </div>
    <div class="field">
      <label for="sur_name" class="label-required">{{
        "Titre" | transloco
      }}</label>
      <input
        type="text"
        pInputText
        id="sur_name"
        formControlName="sur_name"
        required="true"
        autofocus
        class="p-inputtext w-full"
      />
      @if(isErrorFormControl(editForm.controls['sur_name'])) {
      <small class="text-red-500">{{ "Titre requis" | transloco }}</small>
      }
    </div>

    <div class="field">
      <label for="sur_comment">{{
        "Commentaire" | transloco
      }}</label>
      <input
        type="text"
        pInputText
        id="sur_comment"
        formControlName="sur_comment"
        autofocus
        class="p-inputtext w-full"
      />
    </div>

    @if(saveError) {
    <div class="text-red-500">
      {{ "Une erreur est survenue lors de l'enregistrement" | transloco }}
    </div>
    }
    <div class="flex overflow-hidden">
      <div class="flex-grow-1 flex align-items-center justify-content-center">
        <p-button
          type="submit"
          icon="pi pi-save"
          [disabled]="!editForm.valid"
          [label]="'Enregistrer' | transloco"
        ></p-button>
      </div>
      <div
        class="flex-none flex align-items-center justify-content-center mr-3"
      >
        <div class="label-required">
          {{ "Champs obligatoires" | transloco }}
        </div>
      </div>
    </div>
  </p-panel>
</form>

@if(surveyId != '') {
<div class="mt-3">
  <p-panel [header]="'Pages' | transloco">
    <div class="grid panel-actions">
      <div class="col text-right">
        
        @if(canWrite()) {
          @if(pageInCache) {
            <button
              pButton
              [label]="'Coller la page ici' | transloco"
              icon="pi pi-clone"
              class="p-button-gray mr-3"
              (click)="pastePage()"
            ></button>
          }
          <button
            pButton
            [label]="'Ajouter une page' | transloco"
            icon="pi pi-plus"
            class="p-button-success"
            (click)="addPage(0)"
          ></button>
        }
      </div>
    </div>
    
    @if(pages.length > 0) {
      <p-table
        [value]="pages"
        styleClass="p-datatable-gridlines p-datatable-striped"
      >
        <ng-template pTemplate="header">
          <tr>
            <th pSortableColumn="name">{{ 'Nom' | transloco }} <p-sortIcon field="name"></p-sortIcon></th>            
            <th pSortableColumn="order">{{ "Ordre" | transloco }}<p-sortIcon field="order"></p-sortIcon></th>
            @if(canWrite()) {
              <th></th>
            }
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-onePage>
          <tr>
            <td>{{ onePage.name }}</td>
            <td>{{ onePage.order }}</td>
            @if(canWrite()) {
              <td class="flex align-items-center justify-content-center gap-3">
                <a (click)="editPage(onePage)" title="{{ 'Modifier la page' | transloco}}">
                  <i class="pi pi-pencil"></i>
                </a>
                <a (click)="copyPage(onePage)" title="{{ 'Copier la page' | transloco}}">
                    <i class="pi pi-clone"></i>
                </a>
                <a (click)="deletePage(onePage)" title="{{ 'Supprimer la page' | transloco}}">
                    <i class="pi pi-trash"></i>
                </a>
              </td> 
            }
          </tr>
        </ng-template>
      </p-table>
    }
  </p-panel>
</div>
<p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>
<p-toast></p-toast>
}
