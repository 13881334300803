@defer (when dataLoaded) {
  <p-panel header="{{ 'Statistiques indicateur' | transloco }}">
    <p-table
      #dt
      [value]="responses"
      styleClass="p-datatable-gridlines p-datatable-striped"
      stateStorage="session"
      stateKey="indicators-stat-session"
      [paginator]="true"
      [rows]="10"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="{totalRecords} {{ 'élément(s)' | transloco }}"
    >
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="date_response">
            {{ "Date réponse" | transloco }}
            <p-sortIcon field="date_response"></p-sortIcon>
          </th>
          <th pSortableColumn="last_name">
            {{ "Nom" | transloco }}
            <p-sortIcon field="last_name"></p-sortIcon>
          </th>
          <th pSortableColumn="first_name">
            {{ "Prénom" | transloco }}
            <p-sortIcon field="first_name"></p-sortIcon>
          </th>
          <th *ngFor="let col of cols" [pSortableColumn]="col.field">
              {{ col.header }}
              <p-sortIcon [field]="col.field"></p-sortIcon>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-stat>
        <tr>
          <td>{{ stat.date_response | date:'dd/MM/yyyy HH:mm' }}</td>
          <td>{{ stat.last_name }}</td>
          <td>{{ stat.first_name }}</td>
          <td *ngFor="let col of cols">
              {{ stat[col.field] }}
          </td>
        </tr>
      </ng-template>
    </p-table>
  </p-panel>
  } @placeholder {
  <div class="text-center">{{ "Chargement en cours..." | transloco }}</div>
  }
  