@defer (when dataLoaded) {
<p-panel>
  <ng-template pTemplate="header">
    <i class="fa fa-search mr-2"></i>
    <span class="p-panel-title">{{ "Période de recherche" | transloco }}</span>
  </ng-template>
  <form [formGroup]="searchForm">
    <div class="formgroup-inline">
      <div class="field">
        <label>{{ "Du" | transloco }}</label
        >&nbsp;
        <p-calendar
          [readonlyInput]="true"
          [showTime]="false"
          aria-label="Période"
          dateFormat="dd/mm/yy"
          (onSelect)="loadStats()"
          formControlName="periodStart"
          onse
        ></p-calendar>
      </div>
      <div class="field">
        <label>{{ "au" | transloco }}</label
        >&nbsp;
        <p-calendar
          [readonlyInput]="true"
          [showTime]="false"
          aria-label="Période"
          dateFormat="dd/mm/yy"
          (onSelect)="loadStats()"
          formControlName="periodEnd"
        ></p-calendar>
      </div>
    </div>
  </form>
</p-panel>

<div class="grid flex mt-2">
  <div class="col-12 lg:col-6 no-padding-top">
    <p-panel>
      <ng-template pTemplate="header">
        <i class="fa fa-cart-flatbed-suitcase mr-2"></i>
        <span class="p-panel-title">{{ "Commandes" | transloco }}</span>
      </ng-template>
      <p-chart
        type="bar"
        [data]="datasOrders"
        [options]="configOrders"
        #chartOrders
      />
      <p-chart
        type="bar"
        [data]="datasOrdersCount"
        [options]="configOrdersCount"
        #chartOrdersCount
      />
    </p-panel>
  </div>

  <div class="col-12 lg:col-6 no-padding-top">
    <p-panel>
      <ng-template pTemplate="header">
        <i class="fa fa-folder mr-2"></i>
        <span class="p-panel-title">{{ "Categories" | transloco }}</span>
      </ng-template>
      <p-chart
        type="bar"
        [data]="datasCategories"
        [options]="configCategories"
        #chartCategories
      />
      <p-chart
        type="bar"
        [data]="datasCategoriesCount"
        [options]="configCategoriesCount"
        #chartCategoriesCount
      />
    </p-panel>
  </div>

  <div class="col-12 no-padding-top">
    <p-panel>
      <ng-template pTemplate="header">
        <i class="fa fa-tablet-screen-button mr-2"></i>
        <span class="p-panel-title">{{ "Produits" | transloco }}</span>
      </ng-template>

      <p-tabView>
        <p-tabPanel [header]="tab" *ngFor="let tab of periods">
          <p-table
            [value]="dataProducts[tab.toLowerCase()]"
            styleClass="p-datatable-gridlines p-datatable-striped"
            stateStorage="session"
            stateKey="eshop-stats-products-jour"
            [paginator]="true"
            [rows]="10"
            [showCurrentPageReport]="true"
            currentPageReportTemplate="Lignes {first} à {last} sur un total de {totalRecords}"
          >
            <ng-template pTemplate="header">
              <tr>
                <th pSortableColumn="epr_title">
                  {{ "Produit" | transloco }}
                  <p-sortIcon field="epr_title"></p-sortIcon>
                </th>
                <th pSortableColumn="product_count">
                  {{ "Quantité" | transloco }}
                  <p-sortIcon field="product_count"></p-sortIcon>
                </th>
                <th pSortableColumn="total_ht">
                  {{ "Total HT" | transloco }}
                  <p-sortIcon field="total_ht"></p-sortIcon>
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-dataPr>
              <tr>
                <td>{{ dataPr.epr_title }}</td>
                <td>{{ dataPr.product_count }}</td>
                <td>{{ dataPr.total_ht | number : "1.2-2" }}€</td>
              </tr>
            </ng-template>
          </p-table>
        </p-tabPanel>
        
      </p-tabView>
    </p-panel>
  </div>
</div>
} @placeholder {
<div class="text-center">{{ "Chargement en cours..." | transloco }}</div>
}
