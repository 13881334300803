@defer (when dataLoaded) {
  
  <p-panel header="{{ 'Données' | transloco }}">
    @if(canWrite()) {
      <div class="grid panel-actions">
          <div class="col text-right">
              <button pButton  [label]="'Ajouter une donnée' | transloco" icon="pi pi-plus" class="p-button-success" (click)="addCustomTableData()" ></button>  
          </div>
      </div>
    }

    <p-table
      #dt
      [value]="customTablesDatas"
      styleClass="p-datatable-gridlines p-datatable-striped"
      stateStorage="session"
      stateKey="tables-datas-list-session"
      [paginator]="true"
      [rows]="10"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="{totalRecords} {{ 'élément(s)' | transloco }}"
    >
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="libelle">
            {{ "Libellé" | transloco }}<p-sortIcon field="libelle"></p-sortIcon>
          </th>
          <th pSortableColumn="datas">
            {{ "Données" | transloco }}<p-sortIcon field="datas"></p-sortIcon>
          </th>
          @if(canWrite()) {
            <th></th>
          }
        </tr>
        <tr>
          <th>
            <p-columnFilter
              type="text"
              field="libelle"
              matchMode="contains"
            ></p-columnFilter>
          </th>
          <th>
            <p-columnFilter
              type="text"
              field="datas"
              matchMode="contains"
            ></p-columnFilter>
          </th>
          @if(canWrite()) {
            <th></th>
          }
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-cTableData>
        <tr>
          <td>{{ cTableData.libelle }}</td>
          <td>{{ cTableData.datas }}</td>
          @if(canWrite()) {
            <td class="flex align-items-center justify-content-center gap-3">
              <a (click)="editData(cTableData)" title="{{ 'Modifier la ligne' | transloco}}">
                  <i class="pi pi-pencil"></i>
              </a>

              <a (click)="deleteData(cTableData)" title="{{ 'Supprimer la ligne' | transloco}}">
                <i class="pi pi-trash"></i>
              </a>
            </td>
          }
        </tr>
      </ng-template>
    </p-table>
  </p-panel>
} @placeholder {
<div class="text-center">{{ "Chargement en cours..." | transloco }}</div>
}

<p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>