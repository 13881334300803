import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';

import { TranslocoModule } from '@ngneat/transloco';

import { ButtonModule } from 'primeng/button';
import { MultiSelectModule } from 'primeng/multiselect';
import { TableModule } from 'primeng/table';

import { Subscription } from 'rxjs';

import { ApiEshopService } from '../../../services/api/api-eshop.service';
import { EshopOrder } from '../../../models/eshop-order';
import { OrderService } from '../../../services/eshop/order.service';
import { AuthService } from '../../../services/auth/auth.service';

@Component({
  selector: 'app-eshop-orders-list',
  templateUrl: './eshop-orders-list.component.html',
  styleUrl: './eshop-orders-list.component.css',
  standalone: true,
  imports: [
    ButtonModule,
    CommonModule,
    MultiSelectModule,
    TableModule,
    TranslocoModule,
  ],
})

export class EshopOrdersListComponent implements OnDestroy, OnInit {
  
  ordersSubscription: Subscription = new Subscription();
  orders: EshopOrder[] = [];
  dataLoaded: boolean = false;
  LIBELLE_STATUT: any;
  LIBELLE_PAIEMENT: any;

  statuts: any = [];
  modesPaiement: any = [];

  constructor(
    private apiEshopService: ApiEshopService,
    private authService: AuthService,
    public orderService: OrderService,
    private router: Router,
  ) {
    this.LIBELLE_STATUT = this.orderService.LIBELLE_STATUT;
    this.LIBELLE_PAIEMENT = this.orderService.LIBELLE_PAIEMENT;

    this.statuts = Object.keys(this.LIBELLE_STATUT).map(key => {
      return { label: this.LIBELLE_STATUT[key], value: key };
    });
    this.modesPaiement = Object.keys(this.LIBELLE_PAIEMENT).map(key => {
      return { label: this.LIBELLE_PAIEMENT[key], value: key };
    });
  }

  ngOnInit(): void {
    this.ordersSubscription = this.apiEshopService
      .getOrders()
      .subscribe((data: EshopOrder[]) => {
        this.orders = data;
        this.dataLoaded = true;
      });
  }

  ngOnDestroy(): void {
    this.ordersSubscription.unsubscribe();
  }

  isRoot(): boolean {
    return this.authService.isRoot();
  }

  detailOrder(oneOrder: EshopOrder) {
    this.router.navigate(['eshop/order-detail/', oneOrder.id]);
  }

  downloadInvoice(oneOrder: EshopOrder) {
    //on refait un appel API pour avoir les produits
    this.apiEshopService
      .getOneOrder(oneOrder.id.toString())
      .subscribe((data: EshopOrder) => {
        this.orderService.generateInvoicePdf(data);
    });
  
  }
}
