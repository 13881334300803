import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { ButtonModule } from 'primeng/button';
import { PanelModule } from 'primeng/panel';
import { ConfirmationService } from 'primeng/api';
import { KeyFilterModule } from 'primeng/keyfilter';

import { EditComponent } from '../../../core/edit/edit.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiTablesService } from '../../../../services/api/api-tables.service';
import { DropdownModule } from 'primeng/dropdown';
import { Client } from '../../../../models/client';
import { AuthService } from '../../../../services/auth/auth.service';

@Component({
  selector: 'app-tables-edit',
  templateUrl: './tables-edit.component.html',
  styleUrl: './tables-edit.component.css',
  standalone: true,
  imports: [
    ButtonModule,
    DropdownModule,
    KeyFilterModule,
    ReactiveFormsModule,
    PanelModule,
    TranslocoModule,
  ],
  providers: [ConfirmationService],
})
export class TablesEditComponent
  extends EditComponent
  implements OnInit
{
  editForm: FormGroup;
  saveError: boolean = false;
  licences : Client[] = [];

  constructor(
    private apiTablesService: ApiTablesService,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    super(route);

    this.editForm = this.formBuilder.group({
      cli_id: ['', Validators.required],
      code: ['', Validators.required],
    });
  }

  override ngOnInit() {
    super.ngOnInit();
    this.licences = this.authService.getUserLicences(); 

    this.titlePage = this.route.snapshot.data['path'].slice(-1)[0]['label'];
  }

  save() {
    let formData = new FormData();
    formData.append('code', this.editForm.controls['code'].value);
    formData.append('cli_id', this.editForm.controls['cli_id'].value);

    this.apiTablesService
      .postTable(formData)
      .subscribe((response: any) => {
        this.saveError = !response;

        if (response) {
            this.router.navigate(['settings/tables']);
        }
      });
  }
}
