export class ClientParametersReception {
    public id: number;
    public name: string;
    public tlo: number;
    public imageForce: number;
    public dlc: number;
    public numLot: number;
    public temperature: number;
    public output: number;
    public supplier: number;
    public autoCloseLot: number;
    
    constructor(
        id: number,
        name: string,
        tlo: number,
        imageForce: number,
        dlc: number,
        numLot: number,
        temperature: number,
        output: number,
        supplier: number,
        autoCloseLot: number
    ) {
        this.id = id;
        this.name = name;
        this.tlo = tlo;
        this.imageForce = imageForce;
        this.dlc = dlc;
        this.numLot = numLot;
        this.temperature = temperature;
        this.output = output;
        this.supplier = supplier;
        this.autoCloseLot = autoCloseLot;
    }
}