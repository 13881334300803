export class CustomTableData {
    public id: number;
    public libelle: string;
    public datas: string;

    constructor(
        id: number,
        libelle: string,
        datas: string,
        ) {
        this.id = id;
        this.libelle = libelle;
        this.datas = datas;
    }
}