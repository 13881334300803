@defer (when dataLoaded) {

  @if(isRoot()) {      
    <form [formGroup]="productForm">
      <p-panel [header]="titlePage">
        <div class="field">
          <label for="categorie" class="label-required">{{
            "Catégorie" | transloco
          }}</label>
          <p-dropdown
            formControlName="categorie"
            autoWidth="false"
            [style]="{ width: '100%' }"
            [options]="categories"
            class="w-full"
          ></p-dropdown>
          @if(isErrorFormControl(productForm.controls['categorie'])) {
          <small class="text-red-500">{{ "Catégorie requise" | transloco }}</small>
          }
        </div>

        <div class="field">
          <label for="title" class="label-required">{{
            "Titre" | transloco
          }}</label>
          <input
            type="text"
            pInputText
            id="title"
            formControlName="title"
            required="true"
            autofocus
            class="p-inputtext w-full"
          />
          @if(isErrorFormControl(productForm.controls['title'])) {
          <small class="text-red-500">{{ "Titre requis" | transloco }}</small>
          }
        </div>

        <div class="field">
          <label for="shortDescription" class="label-required">{{ "Description courte" | transloco }}</label>
          <textarea
              id="shortDescription"
              pInputTextarea
              formControlName="shortDescription"
              class="p-inputtext w-full"
              placeholder=""
              rows="5"
            ></textarea>
            @if(isErrorFormControl(productForm.controls['shortDescription'])) {
            <small class="text-red-500">{{ "Description courte requise" | transloco }}</small>
            }
        </div>

        <div class="field">
          <label for="description" class="label-required">{{ "Description longue" | transloco }}</label>
          <p-editor formControlName="description" [style]="{ height: '250px' }" />
          
          @if(isErrorFormControl(productForm.controls['description'])) {
            <small class="text-red-500">{{ "Description longue requise" | transloco }}</small>
            }
        </div>


        <div class="flex flex-row gap-3 align-items-center">
          <label for="htPrice" class="label-required">{{ "Prix HT" | transloco }}</label>
          <p-inputNumber 
                    inputId="htPrice" 
                    formControlName="htPrice"
                    class="qtyOrder"
                    mode="decimal"
                    [min]="0"
                    [showButtons]="false"
                    [useGrouping]="false" />
          
          @if(isErrorFormControl(productForm.controls['htPrice'])) {
            <small class="text-red-500">{{ "Prix HT requis" | transloco }}</small>
            }

          <label for="tva" class="label-required">{{ "TVA" | transloco }}</label>
          <p-inputNumber 
                    inputId="tva" 
                    formControlName="tva"
                    class="qtyOrder"
                    mode="decimal"
                    [min]="0"
                    [showButtons]="false"
                    [useGrouping]="false" />
          
          @if(isErrorFormControl(productForm.controls['tva'])) {
            <small class="text-red-500">{{ "TVA requise" | transloco }}</small>
            }
        </div>

        <div class="field mt-2 flex gap-3 align-items-center">
          <label for="photo1" class="label-required">{{ "Photo 1" | transloco }}</label>
            <div class="relative col-photo">
              @if(displayImage(productForm.controls['photo1'].value)) {
                <img class="block xl:block mx-auto border-round w-full " [src]="productForm.controls['photo1'].value" />
              }
            </div>
          <p-fileUpload 
            mode="basic" 
            [chooseLabel]="'Choisir une image' | transloco" 
            chooseIcon="pi pi-upload"
            [customUpload]="true"
            accept="image/*" 
            (onSelect)="onFileSelected($event, 'photo1')" 
             />
          
          @if(isErrorFormControl(productForm.controls['photo1'])) {
            <small class="text-red-500">{{ "Photo 1 requise" | transloco }}</small>
            }
        </div>
        <div class="field mt-2 flex gap-3 align-items-center">
          <label for="photo2">{{ "Photo 2" | transloco }}</label>
              <div class="relative col-photo">
                @if(displayImage(productForm.controls['photo2'].value)) {
                  <img class="block xl:block mx-auto border-round w-full " [src]="productForm.controls['photo2'].value" />
                }
              </div>
          <p-fileUpload 
            mode="basic" 
            [chooseLabel]="'Choisir une image' | transloco" 
            chooseIcon="pi pi-upload"
            [customUpload]="true"
            accept="image/*" 
            (onSelect)="onFileSelected($event, 'photo2')" 
             />
        </div>
        <div class="field mt-2 flex gap-3 align-items-center">
          <label for="photo3">{{ "Photo 3" | transloco }}</label>
          <div class="relative col-photo">
            @if(displayImage(productForm.controls['photo2'].value)) {
              <img class="block xl:block mx-auto border-round w-full " [src]="productForm.controls['photo3'].value" />
            }
          </div>
          <p-fileUpload 
            mode="basic" 
            [chooseLabel]="'Choisir une image' | transloco" 
            chooseIcon="pi pi-upload"
            [customUpload]="true"
            accept="image/*" 
            (onSelect)="onFileSelected($event, 'photo3')" 
             />
        </div>
     
    
        @if(saveError) {
        <div class="text-red-500">
          {{ "Une erreur est survenue lors de l'enregistrement" | transloco }}
        </div>
        }
        <div class="flex overflow-hidden mt-3">
          <div class="flex-grow-1 flex align-items-center justify-content-center">
            <p-button
              type="submit"
              icon="pi pi-save"
              [disabled]="!productForm.valid"
              [label]="'Enregistrer' | transloco"
              (click)="saveProduct()"
            ></p-button> 
          </div>
          <div
            class="flex-none flex align-items-center justify-content-center mr-3"
          >
            <div class="label-required">
              {{ "Champs obligatoires" | transloco }}
            </div>
          </div>
        </div>
      </p-panel>

    </form>
  }
} @placeholder {
<div class="text-center">{{ 'Chargement en cours...' | transloco }}</div>
}
