<div class="container">
    @if(authService.tokenLogin) {
        Authentification en cours....

        @if(loginError !== '') {
            <div class="text-red-500">{{ loginError | transloco }}</div>
        }
    } @else {

        <div class="global-login">
            <div class="body-background-login"></div>
            <div class="body-form-login">
                <div class="dashboard-container back-ground">        
                    <div class="login-container">
                        <img src="assets/images/logo-blanc-xl.png" height="100" class="logo-login-v4" alt="Zest HACCP" />
                        <div class="bloc-login">
                            <div class="sign-in-container">
                                <form #loginForm="ngForm" (ngSubmit)="login()">
                                    <div class="header">
                                        {{ "Bienvenue !" | transloco }}
                                    </div>
                                    <div class="login-form-input">
                                        <div class="input-icon-container">
                                            <input pInputText 
                                                type="text" 
                                                id="username" 
                                                name="username" 
                                                [(ngModel)]="username" 
                                                required 
                                                [placeholder]="'Identifiant' | transloco"
                                                class="login-form-control"
                                                 #usernameInput>
                                            <i class="fa fa-circle-user"></i>
                                        </div>
                                        <div class="input-icon-container">
                                            <input 
                                                id="passwd" 
                                                name="passwd" 
                                                value="" 
                                                type="password" 
                                                [(ngModel)]="passwd" 
                                                [placeholder]="'Mot de passe' | transloco"
                                                required 
                                                class="login-form-control" />
                                            <i class="fa fa-lock"></i>
                                        </div>
                            
                                        @if(loginError !== '') {
                                            <div class="text-red-500">{{ loginError | transloco }}</div>
                                        }
                                        <!-- <div class="input-icon-container">
                                            <input type="text" class="form-control" pAutoFocus [autofocus]="true" id="login" name="login"
                                                [placeholder]="label_login" pInputText [ngModel]="loginDefault" />
                                            <i class="fa fa-circle-user"></i>
                                        </div>
                                        <div class="input-icon-container">
                                            <input type="password" class="form-control" name="password" id="password"
                                                    [placeholder]="label_password"  [ngModel]="passwordDefault"  />
                                            <i class="fa fa-lock"></i>
                                        </div> -->
                                    </div>
                                    <div class="actions">
                                        <button id="connexion" class="toIdentify" type="submit"
                                            [disabled]="!loginForm.valid">
                                            {{ 'Se connecter' | transloco }}
                                        </button>                       
                                        
                                        <div class="clearfix"></div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
</div>
