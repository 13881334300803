import { Component, OnDestroy, OnInit, ViewEncapsulation, ViewChild, ChangeDetectorRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { Subscription } from 'rxjs';

import { AuthService } from '../../../services/auth/auth.service';
import { ApiAlertsListService } from "../../../services/api/api-alerts-users";

import { Client } from '../../../models/client';
import { Alerts } from "../../../models/alerts-users";
import { AlertsType } from "../../../models/alerts-users-type-list";
import { AlertsFrequencies } from "../../../models/alerts-users-frequencies-list";

import {TranslocoModule, TranslocoService} from '@ngneat/transloco';

import { ButtonModule } from 'primeng/button';
import { MultiSelectModule } from 'primeng/multiselect';
import { TableModule, Table } from 'primeng/table';
import { TooltipModule } from "primeng/tooltip";
import { ConfirmationService, MessageService } from 'primeng/api';
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { DropdownModule } from "primeng/dropdown";
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';
import { GlobalDatas } from '../../../models/global-datas';
import {SessionStorageService} from "../../../services/storage/session-storage.service";

@Component({
  selector: 'app-alerts-for-users',
  templateUrl: './alerts-for-users.component.html',
  styleUrl: './alerts-for-users.component.css',
  providers: [MessageService, ConfirmationService],
  standalone: true,
  imports: [
    ButtonModule,
    CommonModule,
    FormsModule,
    ConfirmDialogModule,
    MultiSelectModule,
    TableModule,
    TranslocoModule,
    TooltipModule,
    DropdownModule,
    TriStateCheckboxModule,
  ],
  encapsulation: ViewEncapsulation.None,
})

export class AlertsForUsersComponent implements OnDestroy, OnInit  {

  licences: Client[] = [];

  AlertsListSubscription: Subscription = new Subscription();
  AlertsTypeSubscription: Subscription = new Subscription();
  AlertsFrequenciesSubscription: Subscription = new Subscription();

  selectedAlerts = [];
  alertList: Alerts[] = [];
  alertsType: AlertsType[] = [];
  alertsFrequencies: AlertsFrequencies[] = [];
  globalDatas: GlobalDatas | null = null;

  dropdownAlertsType: { label: string, value: number }[] = [];
  dropdownAlertsFrequencies: { label: string, value: number }[] = [];

  dataLoaded: boolean = false;

  addAlertButton = false;
  idClient = 0;

  @ViewChild('myTable') myTable: Table | undefined;

  constructor(
    private authService: AuthService,
    private router: Router,
    private translocoService: TranslocoService,
    private apiAlertsListService: ApiAlertsListService,
    private sessionStorageService: SessionStorageService,
  ) {
  }

  ngOnInit(): void {
    this.addAlertButton = false;
    this.globalDatas = this.sessionStorageService.get('globalDatas');
    // chargement des process de chaque licences
    this.AlertsListSubscription = this.apiAlertsListService
      .getAlertsList()
      .subscribe((data: Alerts[]) => {
        this.alertList = data;
        this.dataLoaded = true;
      });

    this.dropdownAlertsFrequencies = Array.isArray(this.globalDatas?.alertes_frequencies)
      ? this.globalDatas.alertes_frequencies
        .filter(item => typeof item === 'string' && item.trim() !== '')
        .map(path => ({ label: path, value: path }))
      : [];

    this.dropdownAlertsType = Array.isArray(this.globalDatas?.alertes_types)
      ? this.globalDatas.alertes_types
        .map(path => ({ label: path, value: path }))
      : [];

    this.licences = this.authService.getUserLicences();
  }
  canWrite(): boolean {
    return this.authService.canWrite();
  }
  applyFilter(value: any): void {
    if (value.length == 1 ) {
      this.addAlertButton = true;
      this.idClient = value[0];
    } else {
      this.addAlertButton = false;
    }
  }

  addAlert() {
    this.router.navigate(['settings/alerts-for-users-add/alerts-for-users-add', this.idClient]);
  }

  alertsSettings(rowData: any) {
    this.router.navigate(['settings/alerts-for-users-add/alerts-for-users-add', rowData.client.id])
  }

  ngOnDestroy(): void {
    this.AlertsListSubscription.unsubscribe();
    this.AlertsTypeSubscription.unsubscribe();
    this.AlertsFrequenciesSubscription.unsubscribe();
  }
}
