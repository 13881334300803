import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslocoModule } from '@ngneat/transloco';

import { ButtonModule } from 'primeng/button';
import { PanelModule } from 'primeng/panel';

import { Subscription } from 'rxjs';

import { OrderService } from '../../../services/eshop/order.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-eshop-payment-error',
  templateUrl: './eshop-payment-error.component.html',
  styleUrl: './eshop-payment-error.component.css',
  standalone: true,
  imports: [
    ButtonModule,
    CommonModule,
    PanelModule,
    TranslocoModule,
  ]
})

export class EshopPaymentErrorComponent implements OnInit {
  
  productSubscription: Subscription = new Subscription();
  cartSubscription: Subscription = new Subscription()
  cartProducts: any[] = [];
  dataLoaded: boolean = false;
  cartTotalPrice: number = 0;
  cartTotalTax: number = 0;
  orderInfos: any = [];
  paymentInfos: any;

  constructor(
    public orderService: OrderService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.orderInfos = this.orderService.getOrderInfos();
    this.dataLoaded = true;
  }
  redirectOrder() {
    this.router.navigate(['eshop/order/']);
  }
}
