import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';

import { Subscription } from 'rxjs';
import { FormsModule } from '@angular/forms';

import { ApiSensorsService } from '../../../services/api/api-sensors.service';
import { AuthService } from '../../../services/auth/auth.service';

import { Client } from '../../../models/client';
import { Sensor } from '../../../models/sensor';
import { SensorTriggeredAlert } from '../../../models/sensor-triggered-alert';
import { TranslocoModule } from '@ngneat/transloco';

import { ButtonModule } from 'primeng/button';
import { FilterService } from 'primeng/api';
import { MultiSelectModule } from 'primeng/multiselect';
import { TableModule } from 'primeng/table';

import moment from 'moment';

@Component({
  selector: 'app-sensors-triggered-alert',
  templateUrl: './sensors-triggered-alert.component.html',
  styleUrl: './sensors-triggered-alert.component.css',
  standalone: true,
  imports: [
    ButtonModule,
    CommonModule,
    FormsModule,
    MultiSelectModule,
    TableModule,
    TranslocoModule,
  ],
})
export class SensorsTriggeredAlertComponent implements OnDestroy, OnInit {
  licences: Client[] = [];
  satSubscription: Subscription = new Subscription();
  sensorSubscription: Subscription = new Subscription();
  triggeredAlerts: SensorTriggeredAlert[] = [];
  sensors: Sensor[] = [];
  dataLoaded: boolean = false;

  constructor(
    private apiSensorService: ApiSensorsService,
    private authService: AuthService,
    private filterService: FilterService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    // chargement des alertes déclenchées
    this.satSubscription = this.apiSensorService
      .getTriggeredAlerts()
      .subscribe((data: SensorTriggeredAlert[]) => {
        this.triggeredAlerts = data;
        //chargement de la liste des sondes
        this.sensorSubscription = this.apiSensorService
          .getSensors()
          .subscribe((data: Sensor[]) => {
            this.sensors = data;
            this.dataLoaded = true;
          });
      });
    this.licences = this.authService.getUserLicences();

    this.filterService.register(
      'equalDate',
      (value: any, filter: any): boolean => {
        if (filter === undefined || filter === null) {
          return true;
        }

        if (value === undefined || value === null) {
          return false;
        }

        return (
          moment(value).format('YYYY-MM-DD') ===
          moment(filter).format('YYYY-MM-DD')
        );
      }
    );
  }
  graphSensor(sensorDataTrigger: SensorTriggeredAlert) {
    this.router.navigate(['dashboards/sensors-histo', sensorDataTrigger.sensor.id]);
  }

  ngOnDestroy(): void {
    this.satSubscription.unsubscribe();
    this.sensorSubscription.unsubscribe();
  }
}
