@defer (when dataLoaded) {
<div class="grid">
    <div class="col-12 md:col-6 xl:col-6">
      <p-panel header="{{ 'Ajout de licences' | transloco }}">
        <p-table 
            #tableAvailable
            [value]="availableLicences" 
            styleClass="p-datatable-gridlines p-datatable-striped"
            [paginator]="true"
            [rows]="15"
            [showCurrentPageReport]="false"
            currentPageReportTemplate="{totalRecords} {{ 'élément(s)' | transloco }}"
            >
            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="group.id">{{ 'Groupe' | transloco }} <p-sortIcon field="group.id"></p-sortIcon></th>
                    <th pSortableColumn="displayName">{{ 'Nom' | transloco }} <p-sortIcon field="displayName"></p-sortIcon></th>
                    <th pSortableColumn="city">{{ 'Ville' | transloco }} <p-sortIcon field="city"></p-sortIcon></th>
                    @if(canWrite()) {
                        <th></th>
                    }
                </tr>
                <tr>
                    <th>
                      <p-columnFilter field="group.id" matchMode="in" [showMenu]="false">
                        <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                          <p-multiSelect
                            [ngModel]="value"
                            [options]="groups"
                            [placeholder]="'Toutes' | transloco"
                            (onChange)="filter($event.value)"
                            optionLabel="name"
                            optionValue="id"
                            class="liste-recherche-table"
                          >
                          </p-multiSelect>
                        </ng-template>
                      </p-columnFilter>
                    </th>
                    <th>
                        <p-columnFilter
                        type="text"
                        field="displayName"
                        matchMode="contains"
                      ></p-columnFilter>
                      
                    </th>
                    <th>
                      <p-columnFilter
                        type="text"
                        field="city"
                        matchMode="contains"
                      ></p-columnFilter>
                    </th>
                    @if(canWrite()) {
                        <th></th>
                    }
                  </tr>
            </ng-template>
            <ng-template pTemplate="body" let-licence>
                <tr>
                    <td>{{ licence.group.name }}</td>
                    <td>{{ licence.displayName }}</td>
                    <td>{{ licence.city }}</td>
                    @if(canWrite()) {
                        <td class="center-text pt-1 pb-1">
                            <a (click)="moveLicence(licence, true)" title="{{ 'Ajouter' | transloco}}">
                                <i class="fa fa-angle-right"></i>
                            </a>
                        </td>
                    }
                </tr>
            </ng-template>
        </p-table>
      </p-panel>
    </div>
    <div class=" col-12 md:col-6 xl:col-6">
        <p-panel header="{{ 'Licences affectées' | transloco }}">
          <p-table 
              #tableLicences
              [value]="affectedLicences" 
              styleClass="p-datatable-gridlines "
              [paginator]="true"
              [rows]="15"    
              [showCurrentPageReport]="true"
              currentPageReportTemplate="{totalRecords} {{ 'élément(s)' | transloco }}"
              >
              <ng-template pTemplate="header">
                  <tr>
                      @if(canWrite()) {
                          <th></th>
                      }
                      <th pSortableColumn="group.id">{{ 'Groupe' | transloco }} <p-sortIcon field="group.id"></p-sortIcon></th>
                      <th pSortableColumn="displayName">{{ 'Nom' | transloco }} <p-sortIcon field="displayName"></p-sortIcon></th>
                      <th pSortableColumn="city">{{ 'Ville' | transloco }} <p-sortIcon field="city"></p-sortIcon></th>
                  </tr>
                  <tr>
                      @if(canWrite()) {
                          <th></th>
                      }
                      <th>
                        <p-columnFilter field="group.id" matchMode="in" [showMenu]="false">
                          <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                            <p-multiSelect
                              [ngModel]="value"
                              [options]="groups"
                              [placeholder]="'Toutes' | transloco"
                              (onChange)="filter($event.value)"
                              optionLabel="name"
                              optionValue="id"
                              class="liste-recherche-table"
                            >
                            </p-multiSelect>
                          </ng-template>
                        </p-columnFilter>
                      </th>
                      <th>
                          <p-columnFilter
                          type="text"
                          field="displayName"
                          matchMode="contains"
                        ></p-columnFilter>
                        
                      </th>
                      <th>
                        <p-columnFilter
                          type="text"
                          field="city"
                          matchMode="contains"
                        ></p-columnFilter>
                      </th>
                    </tr>
              </ng-template>
              <ng-template pTemplate="body" let-licence>
                  <tr>
                      @if(canWrite()) {
                          <td class="center-text pt-1 pb-1">
                            <a (click)="moveLicence(licence, false)" title="{{ 'Retirer de la liste' | transloco}}">
                                <i class="fa fa-angle-left"></i>
                            </a>
                          </td>
                      }
                      <td>{{ licence.group.name }}</td>
                      <td>{{ licence.displayName }}</td>
                      <td>{{ licence.city }}</td>
                  </tr>
              </ng-template>
          </p-table>
        </p-panel>
    </div>
</div>
} @placeholder {
<div class="text-center">{{ "Chargement en cours..." | transloco }}</div>
}