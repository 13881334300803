export class TrackingProcess {
  public id: number;
  public cliId: number;
  public name: string;
  public comment: string | null;
  public order: number;
  public pictoPath: string;
  public minNbLotsIn: number;
  public minNbLotsOut: number;
  public maxNbLotsIn: number | null;
  public maxNbLotsOut: number | null;
  public maxDelay: number | null;
  public stockDecrease: number;
  public fromRecipe: number;
  public fromLauncher: number;
  public fromTreatment: number;
  public dlcType: number;
  public dlcFormula: string | null;
  public dlcExtend: number;
  public fifo: number;
  public blockLotsIn: number;
  public blockLotsOut: number;
  public deleteDay: number | null;
  public hidden: number;
  public flag: string | null;
  public copyLotsInOut: number;
  public displayInStock: number;
  public displayInDlc: number;
  public displayPopupExtendDlc: number;
  public autoClose: number;
  public autoCloseLotsWithDlc: number;

  constructor(
    id: number,
    cliId: number,
    name: string,
    comment: string | null,
    order: number,
    pictoPath: string,
    minNbLotsIn: number,
    minNbLotsOut: number,
    maxNbLotsIn: number | null,
    maxNbLotsOut: number | null,
    maxDelay: number | null,
    stockDecrease: number,
    fromRecipe: number,
    fromLauncher: number,
    fromTreatment: number,
    dlcType: number,
    dlcFormula: string | null,
    dlcExtend: number,
    fifo: number,
    blockLotsIn: number,
    blockLotsOut: number,
    deleteDay: number | null,
    hidden: number,
    flag: string | null,
    copyLotsInOut: number,
    displayInStock: number,
    displayInDlc: number,
    displayPopupExtendDlc: number,
    autoClose: number,
    autoCloseLotsWithDlc: number
  ) {
    this.id = id;
    this.cliId = cliId;
    this.name = name;
    this.comment = comment;
    this.order = order;
    this.pictoPath = pictoPath;
    this.minNbLotsIn = minNbLotsIn;
    this.minNbLotsOut = minNbLotsOut;
    this.maxNbLotsIn = maxNbLotsIn;
    this.maxNbLotsOut = maxNbLotsOut;
    this.maxDelay = maxDelay;
    this.stockDecrease = stockDecrease;
    this.fromRecipe = fromRecipe;
    this.fromLauncher = fromLauncher;
    this.fromTreatment = fromTreatment;
    this.dlcType = dlcType;
    this.dlcFormula = dlcFormula;
    this.dlcExtend = dlcExtend;
    this.fifo = fifo;
    this.blockLotsIn = blockLotsIn;
    this.blockLotsOut = blockLotsOut;
    this.deleteDay = deleteDay;
    this.hidden = hidden;
    this.flag = flag;
    this.copyLotsInOut = copyLotsInOut;
    this.displayInStock = displayInStock;
    this.displayInDlc = displayInDlc;
    this.displayPopupExtendDlc = displayPopupExtendDlc;
    this.autoClose = autoClose;
    this.autoCloseLotsWithDlc = autoCloseLotsWithDlc;
  }
}
