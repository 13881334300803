import { Component, OnInit, OnDestroy } from '@angular/core';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { Subscription } from 'rxjs';

import { AuthService } from '../../../../services/auth/auth.service';

import { ConfirmationService, MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { TableModule } from 'primeng/table';

import { ApiTablesService } from '../../../../services/api/api-tables.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomTableData } from '../../../../models/custom-table-data';

@Component({
  selector: 'app-tables-datas',
  templateUrl: './tables-datas.component.html',
  styleUrl: './tables-datas.component.css',
  standalone: true,
  imports: [ButtonModule, CommonModule, ConfirmDialogModule, FormsModule, TableModule, TranslocoModule],
  providers: [MessageService, ConfirmationService],
})
export class TablesDatasComponent implements OnInit, OnDestroy {
  constructor(
    private apiTablesService: ApiTablesService,
    private authService: AuthService,
    private confirmationService: ConfirmationService,
    private route: ActivatedRoute,
    private router: Router,
    private translocoService: TranslocoService
  ) {}
  tablesSubscription: Subscription = new Subscription();
  customTablesDatas: CustomTableData[] = [];
  dataLoaded: boolean = false;

  tableId: string = '';

  ngOnInit() {
    this.tableId = this.route.snapshot.paramMap.get('id') ?? '';

    if(this.tableId != '') {
      this.tablesSubscription = this.apiTablesService
        .getTablesDatas(this.tableId)
        .subscribe((data: CustomTableData[]) => {
          this.customTablesDatas = data;
          this.dataLoaded = true;
        });
    }
  }
  canWrite(): boolean {
    return this.authService.canWrite();
  }
  addCustomTableData() {
    this.router.navigate(['settings/tables-datas-add', this.tableId]);
  }
  editData(oTableData: CustomTableData) {
    this.router.navigate(['settings/tables-datas-edit', this.tableId, oTableData.id]);
  }
  deleteData(oTableData: CustomTableData) {
    this.confirmationService.confirm({
      message:
        this.translocoService.translate(
          'Confirmez-vous la suppression de la ligne'
        ) +
        ' "' +
        oTableData.libelle +
        '" ?',
      header: 'Suppression',
      acceptLabel: this.translocoService.translate('Oui'),
      rejectLabel: this.translocoService.translate('Non'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.apiTablesService.deleteData(oTableData.id).subscribe((response) => {
          if (response) {
            this.customTablesDatas = this.customTablesDatas.filter(
              (val: CustomTableData) => val.id !== oTableData.id
            );
          }
        });
      },
    });
  }
  ngOnDestroy() {
    this.tablesSubscription.unsubscribe();
  }
}
