<div class="grid flex">
    <div class="col-12 no-padding-top">
        <p-card header="RECHERCHE">
            <form [formGroup]="searchForm">
                <div class="formgroup-inline">
                    <div class="field">
                        <label>{{ "Période du" | transloco }}</label>&nbsp;
                        <p-calendar [readonlyInput]="true" aria-label="Période" dateFormat="dd/mm/yy"
                            (onSelect)="changeStartPeriod()" formControlName="periodStart"></p-calendar>
                    </div>
                    <div class="field">
                        <label>{{ "au" | transloco }}</label>&nbsp;
                        <p-calendar [readonlyInput]="true" aria-label="Période" dateFormat="dd/mm/yy"
                            (onSelect)="changeEndPeriod()" formControlName="periodEnd"></p-calendar>
                    </div>
                </div>
                <div class="field">
                    <p-tree 
                        [value]="treeNodes" 
                        selectionMode="checkbox" 
                        class="w-full md:w-30rem listeGroupesLicences" 
                        [filter]="true" 
                        [(selection)]="selectedNodes"
                        filterPlaceholder="Rechercher une licence ou un groupe" />
                </div>
                <div class="text-center">
                    <button
                        pButton
                        label="Rechercher"
                        icon="fa fa-search"
                        class="p-button-success"
                        (click)="getPeriodDatas()"></button>
                </div>
            </form>
        </p-card>
    </div>
    <div class="col-12 lg:col-6 no-padding-top">
        <p-card header="REPORTING DES NETTOYAGES">
            <p class="m-0">
                <app-statistics-graph #barStatsTasks 
                    [legendTitle1]="'TACHES NON REALISEES'" 
                    [legendTitle2]="'TACHES REALISEES'" 
                    (opendDetailEvent)="opendDetail($event, 'tasks', 'REPORTING DES NETTOYAGES')">
                </app-statistics-graph>
            </p>
        </p-card>
    </div>
    <div class="col-12 lg:col-6 no-padding-top">
        <p-card header="REPORTING DES TEMPERATURES">
            <p class="m-0">
                <app-statistics-graph #barStatsTemperatures
                    [legendTitle1]="'TEMPERATURES NON REALISEES'" 
                    [legendTitle2]="'TEMPERATURES REALISEES'"
                    (opendDetailEvent)="opendDetail($event, 'temperatures', 'REPORTING DES TEMPERATURES')">
                </app-statistics-graph>
            </p>
        </p-card>
    </div>
    <div class="col-12 lg:col-6 no-padding-top">
        <p-card header="REPORTING DES NON CONFORMITES">
            <p class="m-0">
                <app-statistics-graph #barStatsNc
                    [legendTitle1]="'OUVERTES'" 
                    [legendTitle2]="'FERMEES'"
                    (opendDetailEvent)="opendDetail($event, 'ncs', 'REPORTING DES NON CONFORMITES')">>
                </app-statistics-graph>
            </p>
        </p-card>
    </div>
    <div class="col-12 lg:col-6 no-padding-top">
        <p-card header="PROCESS - CLOTURES">
            <p class="m-0">
                <app-statistics-graph #barStatsTreatment
                    [legendTitle1]="'OUVERTS'" 
                    [legendTitle2]="'CLOTURES'"
                    (opendDetailEvent)="opendDetail($event, 'treatments', 'PROCESS - CLOTURES')">
                </app-statistics-graph>
            </p>
        </p-card>
    </div>
    <div class="col-12 lg:col-6 no-padding-top">
        <p-card header="PROCESS - LOTS">
            <p class="m-0">
                <app-statistics-graph #barStatsLot
                    [legendTitle1]="'NON RATTACHES'" 
                    [legendTitle2]="'RATTACHES'"
                    (opendDetailEvent)="opendDetail($event, 'lots', 'PROCESS - LOTS')">
                </app-statistics-graph>
            </p>
        </p-card>
    </div>
    <div class="col-12 lg:col-6 no-padding-top">
        <p-card header="PROCESS - PHOTOS">
            <p class="m-0">
                <app-statistics-graph #barStatsPicture
                    [legendTitle1]="'NON RATTACHES'" 
                    [legendTitle2]="'RATTACHES'"
                    (opendDetailEvent)="opendDetail($event, 'photos', 'PROCESS - PHOTOS')">
                </app-statistics-graph>
            </p>
        </p-card>
    </div>
</div>