<div class="card">
  <p>🔍 Nombre total de lignes chargées : {{ productLS.length }}</p>
  <p-table
    #dt
    [value]="productLS"
    [scrollable]="true"
    scrollHeight="500px"
    [lazy]="true"
    [totalRecords]="totalRecords"
    [first]="first"
    (onLazyLoad)="loadProductLSLazy($event)"
    (onSort)="sortData($event)"
    [(selection)]="selectedLS"
    (selectionChange)="onSelectionChange($event)"
    class="fixed-header-table"
  >
    <ng-template pTemplate="header">

      <tr  class="sticky-header">
        <th></th>
        <th colspan="6" class="text-center" id="LabelLicence">
          {{ "Licence" | transloco }}
        </th>
      </tr>

      <tr  class="sticky-header">
        <th colspan="6" id="filtreLicence">
          <p-columnFilter field="client.id"  matchMode="in" [showMenu]="false" [showClearButton]="false">
            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
              <p-dropdown
                [ngModel]="value"
                [options]="licences"
                placeholder="Toutes"
                (onChange)="licenceCliSelected($event, filters.name, 0, 5, filters.name);resetStatus()"
                optionLabel="displayName"
                optionValue="id"
                class="liste-recherche-table"
                appendTo="body"
              >
              </p-dropdown>
            </ng-template>
          </p-columnFilter>
        </th>
      </tr>

      <tr>
        <th></th>
        <th pSortableColumn="lpr_libelle" id="lpr_libelle">
          {{ "Libéllé" | transloco }}
          <p-sortIcon field="lpr_libelle"></p-sortIcon>
        </th>
        <th pSortableColumn="lpr_marque" id="lpr_marque">
          {{ "Marque" | transloco }}
          <p-sortIcon field="lpr_marque"></p-sortIcon>
        </th>
        <th pSortableColumn="lpr_ean" id="lpr_ean">
          {{ "Identifiant (EAN)" | transloco }}
          <p-sortIcon field="lpr_ean"></p-sortIcon>
        </th>
        <th pSortableColumn="lst_name" id="lst_name">
          {{ "Status" | transloco }}
          <p-sortIcon field="lst_name"></p-sortIcon>
        </th>
      </tr>
      <tr>
        <th></th>
        <th>
          <p-columnFilter
            type="text"
            field="lpr_libelle"
            [(ngModel)]="filters.name"
            (keydown)="applyFilter($event)"
            [showMenu]="false"
            [showClearButton]="false"
          ></p-columnFilter>
        </th>
        <th>
          <p-columnFilter
            type="text"
            field="lpr_marque"
            [(ngModel)]="filters.name"
            (keydown)="applyFilterMarque($event)"
            [showMenu]="false"
            [showClearButton]="false"
          ></p-columnFilter>
        </th>
        <th>
          <p-columnFilter
            type="text"
            field="lpr_ean"
            (keydown)="validateNumberInput($event)"
            (input)="sanitizeInput($event)"
            [showMenu]="false"
            [showClearButton]="false"
          ></p-columnFilter>
        </th>
        <th>
          <p-dropdown
            [ngModel]="selectedStatus"
            [options]="status"
            optionLabel="label"
            optionValue="value"
            appendTo="body"
            (onChange)="loadProductsByStatus($event)"
          ></p-dropdown>
        </th>
      </tr>

    </ng-template>
    <ng-template pTemplate="body" let-product>
      <tr>
        <td>
          <p-tableCheckbox
            [value]="product"
            [disabled]="checkable">
          </p-tableCheckbox>
        </td>
        <td>{{ product.lpr_libelle }}</td>
        <td>{{ product.lpr_marque }}</td>
        <td>{{ product.lpr_ean }}</td>
        <td>{{ product.lst_name }}</td>
      </tr>
    </ng-template>
  </p-table>
</div>

<form [formGroup]="editForm" style="display: block" (ngSubmit)="save()">
  <div class="card flex justify-content-center">
  <p-dialog
    header="Header"
    [(visible)]="visible"
    [modal]="true"
    [closable]="true"
    [style]="{ width: '40rem'}">
    <ng-template pTemplate="header">
      <div class="w-full text-center">
        <span class="font-bold">
          Modifier le produit LS
        </span>
      </div>
    </ng-template>

    <ng-template pTemplate="body">
      <div class="flex items-center gap-4 mb-4">
        <label class="font-bold align-content-center mylabels">
          Libellé
        </label>
        <input type="text" pInputText formControlName="libelle" class="p-inputtext ml-5 myfields" />
      </div>

      <div class="flex items-center gap-4 mb-4">
        <label class="font-bold align-content-center mylabels">
          Marque
        </label>
        <input type="text" pInputText formControlName="marque" class="p-inputtext ml-5 myfields"/>
      </div>

      <div class="flex items-center gap-4 mb-4">
        <label class="font-bold align-content-center mylabels">
          EAN 13
        </label>
        <input type="text" pInputText formControlName="ean" class="p-inputtext ml-5 p-disabled myfields"/>
      </div>

      <div class="flex items-center gap-4 mb-2">
        <label class="font-bold align-content-center mylabels">
          Status
        </label>
        <input type="text" pInputText formControlName="statusLS" class="p-inputtext ml-5 p-disabled myfields"/>
      </div>
    </ng-template>


    <ng-template pTemplate="footer">
      <p-button
        type="submit"
        icon="pi pi-save"
        [disabled]="!editForm.valid"
        [label]="'Enregistrer' | transloco"></p-button>
    </ng-template>
  </p-dialog>
</div>

<p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>
<p-toast position="top-center"></p-toast>
