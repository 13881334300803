import { Component } from '@angular/core';
import { AffectLicencesComponent } from '../../../global/affect-licences/affect-licences.component';
import { ApiMailReportsService } from '../../../../services/api/api-mail-reports.service';

@Component({
  imports: [
    AffectLicencesComponent 
  ],
  providers: [],
  standalone: true,
  selector: 'mail-reports-licences',
  template: `
    <app-affect-licences
      [getMethod]="apiMailReportService.getMailReportLicences.bind(apiMailReportService)"
      [moveMethod]="apiMailReportService.moveLicence.bind(apiMailReportService)"
    ></app-affect-licences>
  `
})

export class MailReportsLicencesComponent {
  constructor(public apiMailReportService: ApiMailReportsService,) {}
}
