<form [formGroup]="editForm" style="display: block" (ngSubmit)="save()">
  <p-panel [header]="titlePage">
    <div class="formgrid grid">
      <div class="field col-12">
        <label for="name" class="label-required">{{
          "Titre" | transloco
        }}</label>
        <input
          type="text"
          pInputText
          id="name"
          formControlName="name"
          required="true"
          autofocus
          class="p-inputtext w-full"
        />
        @if(isErrorFormControl(editForm.controls['name'])) {
        <small class="text-red-500">{{ "Titre requis" | transloco }}</small>
        }
      </div>
      
      <div class="field col-12 md:col-3 xl:col-3">
        <label for="order" class="label-required">{{
          "Ordre d'affichage" | transloco
        }}</label>
        <br />
        <p-inputNumber
          inputId="order"
          formControlName="order"
          [min]="-50"
          [max]="5000"
          [showButtons]="true"
          [required]="true"
          buttonLayout="horizontal"
          incrementButtonIcon="pi pi-plus" 
          decrementButtonIcon="pi pi-minus"
        ></p-inputNumber>
        @if(isErrorFormControl(editForm.controls['order'])) {
        <small class="text-red-500">{{ "Ordre requis" | transloco }}</small>
        }
      </div>
    </div>

    @if(saveError) {
    <div class="text-red-500">
      {{ "Une erreur est survenue lors de l'enregistrement" | transloco }}
    </div>
    }
    <div class="flex overflow-hidden">
      <div class="flex-grow-1 flex align-items-center justify-content-center">
        <p-button
          type="submit"
          icon="pi pi-save"
          [disabled]="!editForm.valid"
          [label]="'Enregistrer' | transloco"
        ></p-button>
      </div>
      <div
        class="flex-none flex align-items-center justify-content-center mr-3"
      >
        <div class="label-required">
          {{ "Champs obligatoires" | transloco }}
        </div>
      </div>
    </div>
  </p-panel>
</form>
@if(pageId != '') {
  <div class="mt-3">
    <p-panel [header]="'Questions' | transloco">
      <div class="grid panel-actions">
        <div class="col text-right">
          @if(canWrite()) {
            @if(questionInCache) {
              <button
                pButton
                [label]="'Coller la question ici' | transloco"
                icon="pi pi-clone"
                class="p-button-gray mr-3"
                (click)="pasteQuestion()"
              ></button>
            }
            <button
              pButton
              [label]="'Ajouter une question' | transloco"
              icon="pi pi-plus"
              class="p-button-success"
              (click)="addQuestion()"
            ></button>
          }
        </div>
      </div>
      
      @if(questions.length > 0) {
        <p-table
          [value]="questions"
          styleClass="p-datatable-gridlines p-datatable-striped"
        >
          <ng-template pTemplate="header">
            <tr>
              <th pSortableColumn="reference">{{ 'Référence' | transloco }} <p-sortIcon field="reference"></p-sortIcon></th>  
              <th pSortableColumn="name">{{ 'Nom' | transloco }} <p-sortIcon field="name"></p-sortIcon></th>            
              <th pSortableColumn="order">{{ "Ordre" | transloco }}<p-sortIcon field="order"></p-sortIcon></th>
              @if(canWrite()) {
                <th></th>
              }
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-oneQuestion>
            <tr>
              <td>{{ oneQuestion.reference }}</td>
              <td>{{ oneQuestion.name }}</td>
              <td class="text-right">{{ oneQuestion.order }}</td>
              @if(canWrite()) {
                <td class="flex align-items-center justify-content-center gap-3">
                  <a (click)="editQuestion(oneQuestion)" title="{{ 'Modifier la question' | transloco}}">
                    <i class="pi pi-pencil"></i>
                  </a>
                  <a (click)="copyQuestion(oneQuestion)" title="{{ 'Copier la question' | transloco}}">
                      <i class="pi pi-clone"></i>
                  </a>
                  <a (click)="deleteQuestion(oneQuestion)" title="{{ 'Supprimer la question' | transloco}}">
                      <i class="pi pi-trash"></i>
                  </a>
                </td> 
              }
            </tr>
          </ng-template>
        </p-table>
      }
    </p-panel>
  </div>
  <p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>
  <p-toast></p-toast>
  }
  