import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { TranslocoModule, TranslocoService } from '@ngneat/transloco';

import { ButtonModule } from 'primeng/button';
import { DataViewModule } from 'primeng/dataview';
import { DropdownModule } from 'primeng/dropdown';
import { EditorModule } from 'primeng/editor';
import { FieldsetModule } from 'primeng/fieldset';
import { FileUploadModule } from 'primeng/fileupload';
import { InputNumberModule } from 'primeng/inputnumber';
import { MessageService } from 'primeng/api';

import { Subscription } from 'rxjs';

import { ApiEshopService } from '../../../services/api/api-eshop.service';
import { EshopProduct } from '../../../models/eshop-product';
import { UtilsService } from '../../../services/utils/utils.service';
import { CartService } from '../../../services/eshop/cart.service';
import { AuthService } from '../../../services/auth/auth.service';
import { PanelModule } from 'primeng/panel';
import { EditComponent } from '../../core/edit/edit.component';
import { SessionStorageService } from '../../../services/storage/session-storage.service';
import { EshopCategory } from '../../../models/eshop-category';

@Component({
  selector: 'app-eshop-product-edit',
  templateUrl: './eshop-product-edit.component.html',
  styleUrl: './eshop-product-edit.component.css',
  standalone: true,
  imports: [
    ButtonModule,
    CommonModule,
    DataViewModule,
    DropdownModule,
    EditorModule,
    FieldsetModule,
    FileUploadModule,
    FormsModule,
    InputNumberModule,
    PanelModule,
    ReactiveFormsModule,
    TranslocoModule,
  ],
  providers:[MessageService]
})

export class EshopProductEditComponent
    extends EditComponent
    implements OnDestroy, OnInit {
  
  productSubscription: Subscription = new Subscription();
  oneProduct: EshopProduct | null = null;

  productId:string = '';
  otherProducts: EshopProduct[] = [];

  sanitizedDescription: SafeHtml = '';
  qtyOrder:number = 1;

  photos:any[] = [];

  
  productForm: FormGroup;
  saveError: Boolean = false;
  categories: any = [];


  constructor(
    private activeRoute: ActivatedRoute,
    private apiEshopService: ApiEshopService,
    private authService: AuthService,
    private fb: FormBuilder,
    private messageService: MessageService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private sessionStorageService: SessionStorageService,
    private translocoService: TranslocoService,
    private utilsService: UtilsService
  ) {
    super(activeRoute);
    
    this.productForm = this.fb.group({
      id: [''],
      categorie: ['', Validators.required],
      title: ['', Validators.required],
      description: ['', Validators.required],
      shortDescription: ['', Validators.required],
      htPrice: [null, Validators.required],
      tva: ['20', Validators.required],
      photo1: ['', Validators.required],
      photo2: [''],
      photo3: [''],
    });
  }

  isRoot(): boolean {
    return this.authService.isRoot();
  }

  override ngOnInit(): void {
    super.ngOnInit();
    
    let globalDatas = this.sessionStorageService.get('globalDatas');

    this.categories = globalDatas?.eshopCategories.map((oneCategory:EshopCategory) => {
      return { label: oneCategory.title, value: oneCategory.id };
    });

    this.activeRoute.paramMap.subscribe(params => {
      if(this.isRoot()) {
        this.productId = params.get('id') ?? '';
    
        if (this.productId !== '') {
          this.loadProductData(this.productId);
        } else {
          // nouveau produit
          this.dataLoaded = true;
        }
      }
    });
  }

  loadProductData(productId: string): void {
    this.productSubscription = this.apiEshopService
      .getProducts()
      .subscribe((data: EshopProduct[]) => {
        this.oneProduct = data.find((oProduct) => oProduct.id == parseInt(productId)) ?? null;
        if (this.oneProduct) {         
          this.productForm.controls['id'].setValue(this.oneProduct.id);
          this.productForm.controls['categorie'].setValue(this.oneProduct.categoryId);
          this.productForm.controls['title'].setValue(this.oneProduct.title);
          this.productForm.controls['description'].setValue(this.oneProduct.description);
          this.productForm.controls['shortDescription'].setValue(this.oneProduct.shortDescription);
          this.productForm.controls['htPrice'].setValue(this.oneProduct.htPrice);
          this.productForm.controls['tva'].setValue(this.oneProduct.tva);

          if (this.oneProduct.photo1 !== '') {
            this.productForm.controls['photo1'].setValue(this.oneProduct.photo1);
          }
          if (this.oneProduct.photo2 !== '') {
            this.productForm.controls['photo2'].setValue(this.oneProduct.photo2);
          }
          if (this.oneProduct.photo3 !== '') {
            this.productForm.controls['photo3'].setValue(this.oneProduct.photo3);
          }
        }
  
        this.dataLoaded = true;
      });
  }

  onFileSelected(event: any, controlName: string) {
    const file = event.files[0];  

    if (file) {
      this.productForm.get(controlName)?.setValue(file);  
      this.productForm.get(controlName)?.updateValueAndValidity();
    }
  }

  displayImage(valueImage: any) {
    return !(valueImage instanceof File);
  }

  saveProduct() {
    if (this.productForm.invalid) {
      this.productForm.markAllAsTouched();  // Marquer tous les contrôles comme touchés
      return;
    }

    // Créer un objet FormData
    const formData = new FormData();
    
    // Ajouter les champs texte au FormData
    formData.append('id', this.productForm.get('id')?.value);
    formData.append('title', this.productForm.get('title')?.value);
    formData.append('description', this.productForm.get('description')?.value);
    formData.append('shortDescription', this.productForm.get('shortDescription')?.value);
    formData.append('htPrice', this.productForm.get('htPrice')?.value);
    formData.append('tva', this.productForm.get('tva')?.value);
    formData.append('categorie', this.productForm.get('categorie')?.value);
    
    // Ajouter les fichiers au FormData (si présents)
    const photo1File = this.productForm.get('photo1')?.value;
    if (photo1File) {
      formData.append('photo1', photo1File);
    }

    // Vous pouvez ajouter d'autres photos de la même manière si nécessaire
    const photo2File = this.productForm.get('photo2')?.value;
    if (photo2File) {
      formData.append('photo2', photo2File);
    }

    const photo3File = this.productForm.get('photo3')?.value;
    if (photo3File) {
      formData.append('photo3', photo3File);
    }

    const tmpProductTitle = this.utilsService.transformStringToUrlFormat(this.productForm.get('title')?.value.trim());

    this.apiEshopService.postProduct(formData).subscribe((response: any) => {
      this.saveError = !response;

      if (response) {
        this.router.navigate(['eshop/product', response, tmpProductTitle]);
      }
    });
  }

  ngOnDestroy(): void {
    this.productSubscription.unsubscribe();
  }
  
}
