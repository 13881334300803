import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { Dishe } from '../../models/dishe';
import { Client } from '../../models/client';

@Injectable({
  providedIn: 'root',
})
export class ApiDishesService {
  constructor(private http: HttpClient) {}

  getDishes(): Observable<any[]> {
    return this.http
      .get<any>(environment.apiURL + 'espace_client/get_dishes.php')
      .pipe(
        map((response: any[]) => {
          return response.map((dataDishe) => {
            return new Dishe(
              parseInt(dataDishe.rhe_id),
              dataDishe.dis_name,
              dataDishe.rhe_internal_code,
              dataDishe.rhe_identifiant,
              parseFloat(dataDishe.rhe_qty),
              dataDishe.uni_name,
              new Client(
                parseInt(dataDishe.dis_cli_id),
                dataDishe.cli_name
              )                           
            );
          });
        }),
        catchError((error) => {
          return of([]);
        })
      );
  }

  getDishesExport(filtersDishes:any): Observable<any[]> {   
    return this.http.post<any>(
      environment.apiURL + 'espace_client/get_dishes_export.php',
      filtersDishes
    );
  }
}
