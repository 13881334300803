import { AfterViewInit, Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

import { AuthService } from '../../services/auth/auth.service';
import { ApiService } from '../../services/api/api.service';
import { SessionStorageService } from '../../services/storage/session-storage.service';

import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';

import { TranslocoModule } from '@ngneat/transloco'; 

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    ButtonModule,
    CardModule,
    FormsModule,
    TranslocoModule
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.css'
})
export class LoginComponent implements AfterViewInit, OnInit {
  username: string = '';
  passwd: string = '';
  loginError: string = '';

  @ViewChild('usernameInput') usernameInputRef!: ElementRef;

  constructor(
    public authService: AuthService,
    private apiService: ApiService,
    private renderer: Renderer2,
    private route: ActivatedRoute,
    private router: Router,
    private sessionStorageService: SessionStorageService,
  ) {}

  ngOnInit(): void {
    let tokenData = this.route.snapshot.paramMap.get('dataToken') ?? '';

    if(tokenData != '') {
      this.authService.setToken(tokenData);
      this.apiService.loginWithToken()
      .pipe(
        catchError(error => {
          this.loginError = 'ERROR_AUTH_GLOBAL';
          return throwError(() => error);
        })
      )
      .subscribe(response => {
        this.loginResponse(response);
      });
    }
  }

  ngAfterViewInit() {
    this.renderer.selectRootElement(this.usernameInputRef.nativeElement).focus();
  }

  login() {
    this.loginError = '';
    
    this.apiService.login(this.username,this.passwd)
    .pipe(
      catchError(error => {
        this.loginError = 'ERROR_AUTH_GLOBAL';
        return throwError(() => error);
      })
    )
    .subscribe(response => {
      this.loginResponse(response);
    });
  }

  loginResponse(response: any) {
    if(!response) {
      this.loginError = 'ERROR_AUTH_GLOBAL';
    } else if(response.ERROR) {
      this.loginError = response.ERROR;
    } else {
      this.authService.login(response);

      //on charge les données globales : langues, profils,...
      this.apiService.getGlobalDatas().pipe(
        catchError(error => {
          this.loginError = 'ERROR_LOADING_CONFIGURATION';
          return throwError(() => error);
        })
      )
      .subscribe(responseGlobalData => {
        this.sessionStorageService.set('globalDatas', responseGlobalData);
        this.router.navigate(['/']);
      });


    }
  }
}
