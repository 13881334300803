import { Injectable } from '@angular/core';

import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  readonly STATUS_WAIT_PAYMENT = 'WAIT_PAYMENT';
  readonly MODE_VIREMENT = 'virement';
  readonly MODE_CARTE_BANCAIRE = 'cb';

  readonly LIBELLE_STATUT = {
    'WAIT_PAYMENT': 'Paiement en attente',
    'PAYMENT_OK': 'Paiement validé',
    'SHIPPED': 'Expédiée',
    'CANCELLED': 'Annulée',

  }
  readonly LIBELLE_PAIEMENT : any = {
    'virement': 'Virement',
    'cb': 'Carte bancaire'
  }

  private orderInfos: any[] = [];

  constructor() {}


  saveOrder(orderInfos:any): void {
    const { accepteCGV, ...filteredData } = orderInfos;
    this.orderInfos = filteredData;
    localStorage.setItem('order', JSON.stringify(this.orderInfos));
  }

  getOrderInfos(): any[] {
    const savedOrder = localStorage.getItem('order');
    if (savedOrder) {
      this.orderInfos = JSON.parse(savedOrder);
    }

    return this.orderInfos;
  }

  clear() {
    //on conserve les infos de facturation pour les commandes suivantes
    const savedOrder = localStorage.getItem('order');
    if (savedOrder) {
      let tmpOrderInfos : any = JSON.parse(savedOrder);
      tmpOrderInfos.id = '';
      tmpOrderInfos.modePaiement = '';
      tmpOrderInfos.itemsInCart = [];

      this.saveOrder(tmpOrderInfos);
    }
  }

  generateInvoicePdf(order: any): void {
    const doc = new jsPDF();

    doc.addImage('assets/images/logo_invoice.png', 'PNG', 10, 10, 80, 35);

    doc.setFontSize(10);

    // #########################################
    // adresse ZEST
    // #########################################
    doc.setFont('Helvetica', 'bold');
    doc.text('Zest HACCP',118, 15);
    doc.setFont('Helvetica', 'normal');
    doc.text('Parc scientifique Georges Besse II',118, 19);
    doc.text('180 rue Philippe Maupas',118, 23);
    doc.text('30035 Nîmes',118, 27);
    doc.text('France',118, 31);

    doc.setFontSize(16);
    doc.setFont('Helvetica', 'bold');
    doc.text(`FACTURE N° ${order.id}`, 15, 60);

    doc.setFontSize(10);

    // #########################################
    // INFOS CLIENT
    // #########################################
    let posY = 70;
    if(order.company) {
      doc.text(`${order.company}`, 15, posY);
      posY += 4;
    }

    doc.text(`${order.name} ${order.first_name}`, 15, posY);
    posY += 4;
    doc.setFont('Helvetica', 'normal');

    doc.text(`${order.address}`, 15, posY);
    posY += 4;

    if(order.address2) {
      doc.text(`${order.address2}`, 15, posY);
      posY += 4;
    }
    
    doc.text(`${order.zipcode} ${order.city}`, 15, posY);
    posY += 4;
    
    doc.text(`${order.country}`, 15, posY);
    posY += 4;

    doc.text(`${order.email}`, 15, posY);
    posY += 4;
    doc.text(`${order.phone_number}`, 15, posY);
    posY += 4;

    // #########################################
    // INFOS COMMANDE
    // #########################################
    let objDate =new Date(order.date);
    doc.text(`Date de commande : ${objDate.toLocaleString().substring(0, 10)}`, 118, 70);
    
    let objDatePay =new Date(order.payment_date);
    doc.text(`Date de facture : ${objDatePay.toLocaleString().substring(0, 10)}`, 118, 74);
    doc.text(`Mode de paiement : ${this.LIBELLE_PAIEMENT[order.payment_method]}`, 118, 78);

    // #########################################
    // PRODUITS
    // #########################################
    posY+= 6;
        
    let lstProducts:any = [];

    order.products.forEach((oneProduct:any) => {
      lstProducts.push([oneProduct.title, oneProduct.quantity, oneProduct.totalHtPrice.toFixed(2) + '€'])
    });
        
    
    let objTable = autoTable(doc, {
      head: [['Produit', 'Quantité', 'Sous total HT']],
      body: lstProducts,
      startY: posY,
      headStyles: {
        fillColor: [232, 99, 70], // Couleur d'arrière-plan de l'en-tête (rouge dans cet exemple)
        textColor: [255, 255, 255], // Couleur du texte de l'en-tête (blanc dans cet exemple)
        fontStyle: 'bold', // Style de police de l'en-tête,
      }
    });

    let finalY = (doc as any).lastAutoTable.finalY + 4;

    
    doc.text(`Sous total HT:`, 118, finalY + 6);
    doc.text(`${order.total_price_ht.toFixed(2)}€`, 154, finalY + 6);

    doc.text(`TVA:`, 118, finalY + 10);
    doc.text(`${order.total_tva.toFixed(2)}€`, 154, finalY + 10);

    doc.text(`Total TTC:`, 118, finalY + 14);
    doc.text(`${(order.total_price_ht + order.total_tva).toFixed(2)}€`, 154, finalY + 14);

    const pageHeight = doc.internal.pageSize.height;

    doc.text('Zest HACCP, une solution de Phytocontrol Group', 10, pageHeight - 20);
    doc.text('Numéro de SIRET : 81244973400035', 10, pageHeight - 16);
    doc.text('Numéro RCS : Nimes B 812 449 734', 10, pageHeight - 12);
    doc.text('Service clients : 0 800 916 478 – service-clients@zest-haccp.fr', 10, pageHeight - 8);

    // Enregistrer le PDF dans le navigateur
    doc.save(`facture_${order.id}.pdf`);
  }
}
