@defer (when dataLoaded) {
    <p-panel header="{{ 'Alertes' | transloco }}">
        <p-table 
            [value]="triggeredAlerts" 
            styleClass="p-datatable-gridlines p-datatable-striped"
            stateStorage="session"
            stateKey="sensors-alert-triggered-list-session"
            [paginator]="true"
            [rows]="10"
            [showCurrentPageReport]="true"
            currentPageReportTemplate="{totalRecords} {{ 'élément(s)' | transloco }}"
            >
            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="sensor.client.name">{{ 'Licence' | transloco }} <p-sortIcon field="sensor.client.name"></p-sortIcon></th>
                    <th pSortableColumn="sensor.label">{{ 'Sonde' | transloco }}<p-sortIcon field="sensor.label"></p-sortIcon></th>
                    <th pSortableColumn="date">{{ 'Date' | transloco }} <p-sortIcon field="date"></p-sortIcon></th>
                    <th></th>
                </tr>
                <tr>
                    <th>
                        <p-columnFilter field="sensor.client.id" matchMode="in" [showMenu]="false">
                            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                <p-multiSelect 
                                    [ngModel]="value" 
                                    [options]="licences" 
                                    [placeholder]="'Toutes' | transloco" 
                                    (onChange)="filter($event.value)" 
                                    optionLabel="displayName" 
                                    optionValue="id"
                                    class="liste-recherche-table"
                                    >
                                </p-multiSelect>
                            </ng-template>
                        </p-columnFilter>
                    </th>
                    <th>
                        <p-columnFilter field="sensor.id" matchMode="in" [showMenu]="false">
                            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                <p-multiSelect 
                                    [ngModel]="value" 
                                    [options]="sensors" 
                                    [placeholder]="'Toutes' | transloco" 
                                    (onChange)="filter($event.value)" 
                                    optionLabel="label" 
                                    optionValue="id"
                                    class="liste-recherche-table"
                                    >
                                </p-multiSelect>
                            </ng-template>
                        </p-columnFilter>
                    </th>
                    <th>
                        <p-columnFilter 
                            type="date" 
                            field="date" 
                            matchMode="equalDate"
                            class="hideFilterIcon"
                            >
                        </p-columnFilter>
                    </th>
                    <th></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-sensorAlert>
                <tr>
                    <td>{{ sensorAlert.sensor.client?.name }}</td>
                    <td>{{ sensorAlert.sensor.label }}</td>
                    <td>{{ sensorAlert.date| date:'dd/MM/yyyy HH:mm' }}</td>
                    <td class="flex align-items-center justify-content-center gap-3">
                        <a (click)="graphSensor(sensorAlert)" title="{{ 'Historique des mesures' | transloco}}">
                            <i class="pi pi-chart-line"></i>
                        </a>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </p-panel>
}
@placeholder {
    <div class="text-center">{{ 'Chargement en cours...' | transloco }}</div>
}