import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { MenuItem } from 'primeng/api';
import { MenuModule } from 'primeng/menu';

import { AuthService } from '../../../services/auth/auth.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, switchMap } from 'rxjs';

@Component({
  selector: 'app-navigation-menu',
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  imports: [
    MenuModule,
    TranslocoModule
  ],
  templateUrl: './navigation-menu.component.html',
  styleUrl: './navigation-menu.component.css'
})


export class NavigationMenuComponent implements OnInit {
  items: MenuItem[] = [];
  currMenu = '';

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private router: Router, 
    private translocoService: TranslocoService,
  ) {}

  ngOnInit() {
    if(this.activatedRoute?.firstChild?.snapshot?.url) {
      this.currMenu = this.activatedRoute.firstChild?.snapshot.url[0].path;
    }

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => {
        let route = this.activatedRoute;
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      switchMap(route => route.data.pipe(
        map(data => {
          const path = route.snapshot.url[0]?.path ?? '';
          return { data, path };
        })
      ))
    ).subscribe(({ path }) => {
      this.currMenu = path;
      this.initMenus();
    });


    // j'ai du mettre un léger timeout sinon la traduction n'est pas totalement initialisée en cas de F5
    setTimeout(() => {
        this.initMenus();      
    }, 100);
  }

  initMenus() {
      this.items = [
        {
          label: this.translocoService.translate('Boutique'),
          expanded: true,
          routerLink: 'eshop',
          styleClass: (this.currMenu == 'eshop' ? 'current-menu': ''),
          icon: 'fa fa-cart-shopping pi-fw',
          visible: this.authService.isRoot()
        },
        {
          label: this.translocoService.translate('Tableaux de bord'),
          expanded: true,
          routerLink: 'dashboards',
          styleClass: (this.currMenu == 'dashboards' ? 'current-menu': ''),
          icon: 'fa fa-gauge-high pi-fw'
        },
        {
          label: this.translocoService.translate('Paramétrages'),
          expanded: true,
          routerLink: 'settings',
          styleClass: (this.currMenu == 'settings' ? 'current-menu': ''),
          icon: 'fa fa-sliders pi-fw'
        },
        {
          label: this.translocoService.translate('Administration'),
          expanded: true,
          routerLink: 'admin',
          styleClass: (this.currMenu == 'admin' ? 'current-menu': ''),
          icon: 'fa fa-user-gear pi-fw',
          visible: this.authService.isRoot()
        },
      ];
  }
}
