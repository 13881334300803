import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root' // Fournit ce service à l'ensemble de l'application
})
export class TableGroupService {
  // Variables globales à chaque instance du service
  prochainIndexCouleur: number = 0;
  currentClass: string = 'row-group-class2';

  constructor() {}

  // Fonction pour obtenir la classe d'un groupe
  getGroupClass(nbRowsPerPage:number, rowIndex: number, rowspan: number | null): string {
    let retourClass = '';

    if (rowIndex == 0) {
        this.prochainIndexCouleur = 0;
    }

    if (rowIndex === this.prochainIndexCouleur) {
      // On est sur la première ligne du groupe ou une ligne seule
      this.currentClass = (this.currentClass === 'row-group-class2' ? 'row-group-class1' : 'row-group-class2');

      if (rowIndex % nbRowsPerPage !== 0) {
        retourClass = 'separatorGroup';
      }
    } 
    // Calcul de l'index de la prochaine ligne à changer
    this.prochainIndexCouleur = rowIndex + (rowspan ?? 1);

    return this.currentClass + ' ' + retourClass;

  }

  // Si nécessaire, ajouter une méthode pour réinitialiser l'état entre plusieurs tableaux
  resetGroupState() {
    this.prochainIndexCouleur = 0;
    this.currentClass = 'row-group-class2';
  }
}
