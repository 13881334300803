<form [formGroup]="editForm" (ngSubmit)="save()">
  <p-panel [header]="titlePage">
    <h3 class="pt-0 mt-0">
      <i class="fa fa-address-card mr-2"></i>
      {{ "Informations générales" | transloco }}
    </h3>
    <div class="formgrid grid">
      <div class="field col-12 md:col-12 xl:col-12">
        <label for="cpr_name">{{ "Libellé" | transloco }}</label>
        <br />
        @if(cprObj) {
          <b>{{ cprObj.name }}</b>
        }
      </div>
      <div class="field col-12 md:col-6 xl:col-4">
        <label for="cpr_dlc">{{ "DLC" | transloco }}</label>
        <p-dropdown autoWidth="false" [style]="{ width: '100%' }" [options]="optionsDisplay" class="w-full"
          formControlName="cpr_dlc"></p-dropdown>
      </div>
      <div class="field col-12 md:col-6 xl:col-4">
        <label for="cpr_numlot">{{ "N° de lot fournisseur" | transloco }}</label>
        <p-dropdown autoWidth="false" [style]="{ width: '100%' }" [options]="optionsDisplay" class="w-full"
          formControlName="cpr_numlot"></p-dropdown>
      </div>
      <div class="field col-12 md:col-6 xl:col-4">
        <label for="cpr_tlo">{{ "Lot en sortie" | transloco }}</label>
        <p-dropdown autoWidth="false" [style]="{ width: '100%' }" [options]="optionsDisplay" class="w-full"
          formControlName="cpr_tlo"></p-dropdown>
      </div>
      <div class="field col-12 md:col-6 xl:col-4">
        <label for="cpr_auto_close_lot">{{ "Clôture automatique du lots X jours après la réception" | transloco }}</label>
        <br />
        <p-inputNumber inputId="cpr_auto_close_lot" formControlName="cpr_auto_close_lot"
        suffix=" jour(s)" [min]="0" [max]="5000"
          [showButtons]="true"></p-inputNumber>
      </div>
    </div>

    <div class="field-checkbox flex flex-column align-items-start mb-4">
      <p-checkbox class="mb-1" [binary]="true" formControlName="cpr_img_force" value="1"
        [label]="'Photo obligatoire' | transloco"></p-checkbox>
      <p-checkbox class="mb-1" [binary]="true" formControlName="cpr_temperature" value="1"
        [label]="'Afficher le champ température' | transloco"></p-checkbox>
      <p-checkbox class="mb-1" [binary]="true" formControlName="cpr_output" value="1"
          [label]="'Afficher la zone d\'impression' | transloco"></p-checkbox>
      <p-checkbox class="mb-1" [binary]="true" formControlName="cpr_supplier" value="1"
        [label]="'Afficher le champ fournisseur' | transloco"></p-checkbox>
    </div>

    @if(saveError) {
    <div class="text-red-500">
      {{ "Une erreur est survenue lors de l'enregistrement" | transloco }}
    </div>
    }
    <div class="flex overflow-hidden">
      <div class="flex-grow-1 flex align-items-center justify-content-center">
        <p-button type="submit" icon="pi pi-save" [disabled]="!editForm.valid"
          [label]="'Enregistrer' | transloco"></p-button>
      </div>
      <div class="flex-none flex align-items-center justify-content-center mr-3">
        <div class="label-required">{{ "Champs obligatoires" | transloco }}</div>
      </div>
    </div>
  </p-panel>
</form>