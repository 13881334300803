import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import moment from 'moment';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiStatsService {
  constructor(private http: HttpClient) { }

  getGraphStats(datasToLoad: Array<string>, periodStart: Date, periodEnd: Date, lstLicences:Array<number>) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    const postParams = {
      datasToLoad:datasToLoad,
      dateStart:moment(periodStart).format('YYYY-MM-DD'),
      dateEnd:moment(periodEnd).format('YYYY-MM-DD'),
      lstLicences:lstLicences
    }

    return this.http.post<any>(environment.apiURL + 'espace_client/get_stats.php', postParams, { headers });
  }

  getStatsDetails(datasToLoad: string, periodeCode:string, periodStart: Date, periodEnd: Date, lstLicences:Array<number>, displayAll:boolean, tasTplId:number|null) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    const postParams = {
      datasToLoad:datasToLoad,
      periodeCode:periodeCode,
      dateStart:moment(periodStart).format('YYYY-MM-DD'),
      dateEnd:moment(periodEnd).format('YYYY-MM-DD'),
      lstLicences:lstLicences,
      tasTplId:tasTplId,
      displayAll:displayAll
    }

    return this.http.post<any>(environment.apiURL + 'espace_client/get_stats_details.php', postParams, { headers });
  }
}
