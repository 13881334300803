import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ChartModule } from 'primeng/chart';
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';

@Component({
    selector: 'app-statistics-graph',
    templateUrl: './statistics-graph.component.html',
    styleUrl: './statistics-graph.component.css',
    standalone: true,
    imports: [
      ChartModule
    ],
  })
  
  export class StatisticsGraphComponent implements OnInit  {
    @Input() legendTitle1:string = '';
    @Input() legendTitle2:string = '';  
    
    @Output() opendDetailEvent = new EventEmitter<number>();


    valOffset:boolean = true;
    
    statTarget:any;    
    datas: any;
    options: any;
    validateDatas: any = [0, 0, 0, 0, 0];
    noneValidateDatas: any =[0, 0, 0, 0, 0];
    dataForGraph:any = [];

    labelsGraph:Array<string> = [];
    valDataSets : any;
    OBJECTIF_TITLE = 'OBJECTIF';

    KEY_DAY = 'day';
    KEY_WEEK = 'week';
    KEY_MONTH = 'month';
    KEY_YEAR = 'year';
    KEY_RANGE = 'range';
    KEY_TOTAL = 'total';

    @ViewChild('chartStats') chartRef!: any;
       
    constructor() {}

    ngOnInit(): void {
        Chart.register(ChartDataLabels);
    }
    
    selectData(e: any) {
        let curIndex = e.element.index;
        if (this.statTarget != 0) {
            curIndex -= 1;
        }

        this.opendDetailEvent.emit(curIndex);
    }
   
    clearGraphData() {        
        this.validateDatas = [0, 0, 0, 0, 0];
        this.noneValidateDatas =[0, 0, 0, 0, 0];

        this.dataForGraph = [];
        this.labelsGraph = ['JOUR', 'SEMAINE', 'MOIS', 'ANNEE', 'PERIODE SELECTIONNEE'];
        this.valOffset = true;
    
        this.chartRef.refresh();
    }

    loadGraph(datasToLoad:any, targetToLoad:any) {
        
        this.clearGraphData();

        this.datas = datasToLoad;
        this.statTarget = targetToLoad;

        this.transformDatas();
        
        this.initDataSets();

        this.initTarget();

        this.dataForGraph = {
            labels: this.labelsGraph,                    
            datasets: this.valDataSets
        };

        this.initOptions();

        this.chartRef.refresh();
    }

    initTarget() {
        if(this.statTarget != 0) {
            this.validateDatas.unshift(null);
            this.validateDatas.push(null);
            
            this.noneValidateDatas.unshift(null);
            this.noneValidateDatas.push(null);

            this.labelsGraph.unshift('');
            this.labelsGraph.push('');
            
            this.valOffset = false;

            this.valDataSets.unshift({
                type:'line',
                label: this.OBJECTIF_TITLE,
                data: [this.statTarget, this.statTarget, this.statTarget, this.statTarget, this.statTarget, this.statTarget, this.statTarget],
                backgroundColor: '#FFFFFF',
                borderColor: '#1a273d',
                fill:false,
                borderDash: [6, 12],
                pointStyle: false,
                tooltip: function () {
                    return false;
                },
            });
        }
    }

    transformDatas() {
        this.validateDatas[0] = this.datas.day.percent_done;
        this.noneValidateDatas[0] = this.datas.day.percent_failed;

        this.validateDatas[1] = this.datas.week.percent_done;
        this.noneValidateDatas[1] = this.datas.week.percent_failed;

        this.validateDatas[2] = this.datas.month.percent_done;
        this.noneValidateDatas[2] = this.datas.month.percent_failed;

        this.validateDatas[3] = this.datas.year.percent_done;
        this.noneValidateDatas[3] = this.datas.year.percent_failed;

        this.validateDatas[4] = this.datas.range.percent_done;
        this.noneValidateDatas[4] = this.datas.range.percent_failed;
    }

    initDataSets() {
        this.valDataSets = [
            {
                type: 'bar',
                label: this.legendTitle2,
                backgroundColor: '#66bb6a',
                data: this.validateDatas,
                datalabels: {
                    color: 'black'
                  }
            },
            {
                type: 'bar',
                label: this.legendTitle1,
                backgroundColor: '#ef5350',
                data: this.noneValidateDatas,
                datalabels: {
                    color: 'white'
                  }
            }
        ];
    }

    initOptions() {
        this.options = {
            interaction: {
                mode: 'x'
            },
            maintainAspectRatio: false,
            aspectRatio: 0.8,
            responsive: false,
            plugins: {
                datalabels: {
                    formatter: function(value:any) {
                      return (value ? (value == 0 ? '' : value + '%') : '');
                    },
                    display: function(context:any) {
                        return context.dataset.type != 'line'; 
                      }
                  },
                tooltip: {
                    mode: 'index',
                    intersect: false,
                    callbacks: {
                        label: function(tooltipItem:any) {
                            return [tooltipItem.dataset.label+ ': ' + tooltipItem.formattedValue + '%'];
                      }
                    }
                },
                legend: {
                    position: 'bottom',
                    labels: {
                        font: {
                            size: 12
                        }
                    }
                },
                title: {
                    display: false
                }
            },
            scales: {
                x: {
                    offset: this.valOffset,
                    stacked: true,
                    ticks: {
                        font: { size: 10 },                                  
                        autoSkip: false,
                        maxRotation: 45,
                        minRotation: 45
                    },
                    grid: {
                        drawBorder: false
                    }
                },
                y: {
                    stacked: true,
                    grid: {
                        drawBorder: false
                    },
                    min: 0,
                    max: 100,
                    ticks: {
                        stepSize: 25
                    }
                }
            }
        };
    }

  }