<form [formGroup]="editForm" (ngSubmit)="save()">
  <p-panel [header]="titlePage">
    <h3 class="pt-0 mt-0">
      <i class="fa fa-address-card mr-2"></i>
      {{ "Informations générales" | transloco }}
    </h3>
    <div class="formgrid grid">
      <div class="field col-12 md:col-6 xl:col-6">
        <label for="tpr_name" class="label-required">{{ "Libellé" | transloco }}</label>
        <br />
        <input type="text" pInputText formControlName="tpr_name" [required]="true" class="p-inputtext w-full mr-2" />

        @if(isErrorFormControl(editForm.controls['tpr_name'])) {
        <br /><small class="text-red-500">{{ "Nom requis" | transloco }}</small>
        }
      </div>

      <div class="field col-12 md:col-6 xl:col-6">
        <label for="tpr_comment">{{ "Commentaire" | transloco }}</label>
        <br />
        <input type="text" pInputText formControlName="tpr_comment" class="p-inputtext w-full mr-2" />

        @if(isErrorFormControl(editForm.controls['tpr_comment'])) {
        <br /><small class="text-red-500">{{ "Commentaire requis" | transloco }}</small>
        }
      </div>


      <div class="field col-12 md:col-4 xl:col-3">
        <label for="tpr_order" class="label-required">{{ "Ordre d'affichage" | transloco }}</label>
        <br />
        <p-inputNumber inputId="tpr_order" formControlName="tpr_order" [min]="0" [max]="5000" [showButtons]="true"
          [required]="true"></p-inputNumber>
        @if(isErrorFormControl(editForm.controls['tpr_order'])) {
        <small class="text-red-500">{{
          "Ordre d'affichage requis" | transloco
          }}</small>
        }
      </div>

      <div class="field col-12 md:col-4 xl:col-3">
        <label for="tpr_max_delay">{{ "Durée maximum" | transloco }}</label>
        <br />
        <p-inputNumber inputId="tpr_max_delay" formControlName="tpr_max_delay" [min]="0" [max]="5000"
          [showButtons]="true"></p-inputNumber>
        @if(isErrorFormControl(editForm.controls['tpr_max_delay'])) {
        <small class="text-red-500">{{
          "Durée maximum requise" | transloco
          }}</small>
        }
      </div>
      <div class="field col-12 md:col-4 xl:col-4">
        <label for="tpr_picto_path">{{ "Image" | transloco }}</label>
        <p-dropdown autoWidth="false" [style]="{ width: '100%' }" optionLabel="label" class="w-full" [filter]="true"
          filterBy="label" [options]="optionsImages" formControlName="tpr_picto_path" [(ngModel)]="selectedImage">
          <ng-template pTemplate="selectedItem">
            <div class="flex align-items-center gap-2">
              <img [src]="selectedImage?.fullPath" style="width: 40px" />
              <div>{{ selectedImage?.label }}</div>
            </div>
          </ng-template>

          <ng-template let-image pTemplate="item">
            <div class="flex align-items-center gap-2">
              <img [src]="image.fullPath" style="width: 40px" />
              <div>{{ image.label }}</div>
            </div>
          </ng-template>
        </p-dropdown>
      </div>

    </div>


    <div class="field-checkbox flex flex-column align-items-start mb-4">
      <p-checkbox class="mb-1" [binary]="true" formControlName="tpr_hidden" value="1"
        [label]="'Masquer le process' | transloco"></p-checkbox>
    </div>

    <h3>
      <i class="fa fa-settings fa-barcode mr-2"></i>
      {{ "Lots" | transloco }}
    </h3>

    <div class="formgrid grid">
      <div class="field col-12 md:col-4 xl:col-3">
        <label for="tpr_min_nb_lots_in" class="label-required">{{ "Lots en entrée (minimum)" | transloco }}
        </label>
        <br />
        <p-inputNumber inputId="tpr_min_nb_lots_in" formControlName="tpr_min_nb_lots_in" [min]="-1" [max]="50"
          [showButtons]="true" [required]="true"></p-inputNumber>
        @if(isErrorFormControl(editForm.controls['tpr_min_nb_lots_in'])) {
        <small class="text-red-500">{{
          "Nombre minimum de lots en entrée requis" | transloco
          }}</small>
        }
      </div>
      <div class="field col-12 md:col-4 xl:col-3">
        <label for="tpr_max_nb_lots_in" class="label-required">{{ "Lots en entrée (maximum)" | transloco }}
        </label>
        <br />
        <p-inputNumber inputId="tpr_max_nb_lots_in" formControlName="tpr_max_nb_lots_in" [min]="-1" [max]="50"
          [showButtons]="true" [required]="true"></p-inputNumber>
        @if(isErrorFormControl(editForm.controls['tpr_max_nb_lots_in'])) {
        <br /><small class="text-red-500">Nombre maximum de lots en entrée requis</small>
        } @if(hasFormError(editForm, 'invalidRangeLotsIn')) {
        <br /><small class="text-red-500">{{
          "Le nombre maximal de lots doit être supérieure au minimum" | transloco
          }}</small>
        }
      </div>
    </div>
    <div class="formgrid grid">
      <div class="field col-12 md:col-4 xl:col-3">
        <label for="tpr_min_nb_lots_out" class="label-required">{{ "Lots en sortie (minimum)" | transloco }}
        </label>
        <br />
        <p-inputNumber inputId="tpr_min_nb_lots_out" formControlName="tpr_min_nb_lots_out" [min]="-1" [max]="50"
          [showButtons]="true" [required]="true"></p-inputNumber>
        @if(isErrorFormControl(editForm.controls['tpr_min_nb_lots_out'])) {
        <small class="text-red-500">{{
          "Nombre minimum de lots en sortie requis" | transloco
          }}</small>
        }
      </div>
      <div class="field col-12 md:col-4 xl:col-3">
        <label for="tpr_max_nb_lots_out" class="label-required">{{ "Lots en sortie (maximum)" | transloco }}
        </label>
        <br />
        <p-inputNumber inputId="tpr_max_nb_lots_out" formControlName="tpr_max_nb_lots_out" [min]="-1" [max]="50"
          [showButtons]="true" [required]="true"></p-inputNumber>
        @if(isErrorFormControl(editForm.controls['tpr_max_nb_lots_out'])) {
        <br /><small class="text-red-500">Nombre maximum de lots en sortie requis</small>
        } @if(hasFormError(editForm, 'invalidRangeLotsOut')) {
        <br /><small class="text-red-500">{{
          "Le nombre maximal de lots doit être supérieure au minimum" | transloco
          }}</small>
        }
      </div>
    </div>

    <div class="formgrid grid mb-4">
      <div class="field col-12 md:col-6 lg:col-6  xl:col-4 force-margin-bottom-0">
        <div class="field-checkbox flex flex-column align-items-start force-margin-bottom-0">
          <p-checkbox class="mb-1" [binary]="true" formControlName="tpr_copy_lots_in_out" value="1"
            [label]="'Copie automatique des lots en entrée > lots en sortie' | transloco"></p-checkbox>
          <p-checkbox class="mb-1" [binary]="true" formControlName="tpr_display_in_stock" value="1"
            [label]="'Les lots en sortie apparaissent dans l\'écran des stocks' | transloco"></p-checkbox>
          <p-checkbox class="mb-1" [binary]="true" formControlName="tpr_display_in_dlc" value="1"
            [label]="' Les lots en sortie apparaissent dans l\'écran suivi DLC ' | transloco"></p-checkbox>
          <p-checkbox class="mb-1" [binary]="true" formControlName="tpr_stock_decrease" value="1"
            [label]="'Le process débite les stocks entrants' | transloco"></p-checkbox>
        </div>
      </div>
      <div class="field col-12 md:col-6 lg:col-6  xl:col-4 force-margin-bottom-0">
        <div class="field-checkbox flex flex-column align-items-start force-margin-bottom-0">
          <p-checkbox class="mb-1" [binary]="true" formControlName="tpr_from_treatment" value="1"
            [label]="'On peut récupérer les lots depuis un autre process' | transloco"></p-checkbox>
          <p-checkbox class="mb-1" [binary]="true" formControlName="tpr_fifo" value="1"
            [label]="'Gestion FIFO' | transloco"></p-checkbox>

          @if(editForm.controls['tpr_from_recipe'].value != 0) {
          <p-checkbox class="mb-1" [binary]="true" formControlName="tpr_block_lots_in" value="1"
            [label]="'Blocage lots en entrée' | transloco"></p-checkbox>
          <p-checkbox class="mb-1" [binary]="true" formControlName="tpr_block_lots_out" value="1"
            [label]="'Blocage lots en sortie' | transloco"></p-checkbox>
          }
        </div>
      </div>

    </div>


    <h3>
      <i class="fa fa-settings fa-circle-play mr-2"></i>
      {{ "Lancement" | transloco }}
    </h3>

    <div class="formgrid grid">
      <div class="field col-12 md:col-6 xl:col-6">
        <label for="tpr_from_recipe">{{ "Lancement possible" | transloco }}</label>
        <p-dropdown autoWidth="false" [style]="{ width: '100%' }" [options]="optionsLaunch" class="w-full"
          formControlName="tpr_from_recipe"></p-dropdown>
      </div>
      <div class="field col-12 md:col-6 xl:col-6">
        <label for="tpr_from_launcher">{{ "Lancement rapide" | transloco }}</label>
        <p-dropdown autoWidth="false" [style]="{ width: '100%' }" [options]="optionsLauncher" class="w-full"
          formControlName="tpr_from_launcher"></p-dropdown>
      </div>
    </div>


    <h3>
      <i class="fa fa-settings fa-calendar-days mr-2"></i>
      {{ "DLC" | transloco }}
    </h3>
    <div class="formgrid grid">
      <div class="field col-12 md:col-6 xl:col-6">
        <label for="tpr_dlc_type">{{ "Gestion DLC" | transloco }}</label>
        <p-dropdown autoWidth="false" [style]="{ width: '100%' }" [options]="optionsGestionDlc" class="w-full"
          formControlName="tpr_dlc_type"></p-dropdown>
      </div>
      <div class="field col-12 md:col-6 xl:col-6">
        <label for="tpr_dlc_formula">{{ "Fonction DLC" | transloco }}</label>
        <p-dropdown autoWidth="false" [style]="{ width: '100%' }" [options]="optionsFonctionDlc" class="w-full"
          formControlName="tpr_dlc_formula"></p-dropdown>
      </div>
      <div class="field col-12 md:col-6 xl:col-4">
        <label for="tpr_auto_close_lots_with_dlc">{{ "Clôture automatique des lots X jours après la clôture du process" | transloco }}</label>
        <br />
        <p-inputNumber inputId="tpr_auto_close_lots_with_dlc" formControlName="tpr_auto_close_lots_with_dlc"
        suffix=" jour(s)" [min]="0" [max]="5000"
          [showButtons]="true"></p-inputNumber>
      </div>
    </div>
    <div class="field-checkbox flex flex-column align-items-start">
      <p-checkbox class="mb-1" [binary]="true" formControlName="tpr_dlc_extend" value="1"
        [label]="'Peut augmenter la DLC' | transloco"></p-checkbox>

      @if(editForm.controls['tpr_dlc_extend'].value != 0) {
      <p-checkbox class="mb-1" [binary]="true" formControlName="tpr_display_popup_extend_dlc" value="1"
        [label]="'Afficher la popup de prolongation' | transloco"></p-checkbox>
      }
    </div>

    @if(saveError) {
    <div class="text-red-500">
      {{ "Une erreur est survenue lors de l'enregistrement" | transloco }}
    </div>
    }
    <div class="flex overflow-hidden">
      <div class="flex-grow-1 flex align-items-center justify-content-center">
        <p-button type="submit" icon="pi pi-save" [disabled]="!editForm.valid"
          [label]="'Enregistrer' | transloco"></p-button>
      </div>
      <div class="flex-none flex align-items-center justify-content-center mr-3">
        <div class="label-required">{{ "Champs obligatoires" | transloco }}</div>
      </div>
    </div>
  </p-panel>
</form>