import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CardModule } from 'primeng/card';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MultiSelectModule } from 'primeng/multiselect';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';

import { Subscription } from 'rxjs';

import { AuthService } from '../../../services/auth/auth.service';

import { Client } from '../../../models/client';
import { ClientGroup } from '../../../models/client-group';
import { ApiStatsService } from '../../../services/api/api-stats';

import { StatisticsGraphComponent } from './statistics-graph.component';
import { CalendarModule } from 'primeng/calendar';
import { LoadingModalService } from '../../../services/modal/loading-modal.service';
import { TreeNode } from 'primeng/api';
import { TreeModule } from 'primeng/tree';
import { SessionStorageService } from '../../../services/storage/session-storage.service';
import { GlobalDatas } from '../../../models/global-datas';
import { ApiGroupsService } from '../../../services/api/api-groups';
import { HandleDialogService } from '../../../services/dialog/handle-dialog.service';
import { StatisticsDialogComponent } from './statistics-dialog.component';

interface City {
  name: string,
  code: string
}

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrl: './statistics.component.css',
  standalone: true,
  imports: [
    CalendarModule,
    CardModule,
    CommonModule,
    FormsModule,
    MultiSelectModule,
    StatisticsGraphComponent,
    ReactiveFormsModule,
    TreeModule,
    TranslocoModule,
  ]  
})


export class StatisticsComponent implements OnDestroy, OnInit {

  dataSubscription: Subscription = new Subscription();
  licences: Client[] = [];
  groups: ClientGroup[] = [];
  dataLoaded: boolean = false;
  datasStats: any = {};
  searchForm: FormGroup;

  graphsToLoad: Array<string> = ['tasks', 'temperatures', 'ncs', 'treatments', 'lots', 'photos'];

  treeNodes: any;
  selectedNodes: any = [];
  lstLicences:any = [];

  globalDatas: GlobalDatas | null = null;
  
  KEY_DAY = 'day';
  KEY_WEEK = 'week';
  KEY_MONTH = 'month';
  KEY_YEAR = 'year';
  KEY_RANGE = 'range';
  KEY_TOTAL = 'total';

  @ViewChild('barStatsTasks') barStatsTasks!: StatisticsGraphComponent;
  @ViewChild('barStatsTemperatures') barStatsTemperatures!: StatisticsGraphComponent;
  @ViewChild('barStatsNc') barStatsNc!: StatisticsGraphComponent;
  @ViewChild('barStatsTreatment') barStatsTreatment!: StatisticsGraphComponent;
  @ViewChild('barStatsLot') barStatsLot!: StatisticsGraphComponent;
  @ViewChild('barStatsPicture') barStatsPicture!: StatisticsGraphComponent;

  constructor(
    private apiGroupsService: ApiGroupsService,
    private apiStats: ApiStatsService,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private handleDialogService: HandleDialogService,
    private loadingModalService: LoadingModalService,
    private sessionStorageService: SessionStorageService,
    private translocoService: TranslocoService,
  ) {
    let tmpDateStart = new Date();
    tmpDateStart.setDate(tmpDateStart.getDate() - 15);

    this.searchForm = this.formBuilder.group({
      periodStart: [tmpDateStart, Validators.required],
      periodEnd: [new Date(), Validators.required]
    });

    this.treeNodes = [];
    this.lstLicences = [];
  }

  ngOnInit(): void {
    this.getPeriodDatas();

    this.licences = this.authService.getUserLicences();
    this.groups = this.authService.getUserGroups();

    this.globalDatas = this.sessionStorageService.get('globalDatas');

    if (this.globalDatas?.groups && this.globalDatas?.clientsGroupsFiltered) {
      this.treeNodes = this.apiGroupsService.convertToTreeNodes(this.globalDatas.groups, this.globalDatas?.clientsGroupsFiltered, this.licences);
    }
  }

  changeStartPeriod() {
    if (this.searchForm.controls['periodStart'].value > this.searchForm.controls['periodEnd'].value) {

      let tmpDate: Date = new Date(this.searchForm.controls['periodStart'].value);
      tmpDate.setDate(tmpDate.getDate() + 1);
      this.searchForm.controls['periodEnd'].setValue(tmpDate);
    }
  }

  changeEndPeriod() {
    if (this.searchForm.controls['periodStart'].value > this.searchForm.controls['periodEnd'].value) {

      let tmpDate: Date = new Date(this.searchForm.controls['periodEnd'].value);
      tmpDate.setDate(tmpDate.getDate() - 1);
      this.searchForm.controls['periodStart'].setValue(tmpDate);
    }
  }

  getPeriodDatas() {
    this.lstLicences = [];

    //on récupère les licences sélectionnées si il y en a
    if (this.selectedNodes) {
      this.lstLicences = this.selectedNodes.filter((oneNode: TreeNode) => oneNode.type == 'licence')
        .map((oneNode: TreeNode) => {
          return oneNode.data.id;
        });
    }

    this.loadingModalService.show(
      this.translocoService.translate('Statistiques'),
      this.translocoService.translate('Chargement en cours...')
    );

    this.dataSubscription = this.apiStats.getGraphStats(
      this.graphsToLoad,
      this.searchForm.controls['periodStart'].value,
      this.searchForm.controls['periodEnd'].value,
      this.lstLicences
    ).subscribe((result: any) => {
      this.datasStats = result;
      this.dataLoaded = true;

      this.barStatsTasks.loadGraph(this.datasStats.tasks, this.datasStats.target.tasks);
      this.barStatsTemperatures.loadGraph(this.datasStats.temperatures, this.datasStats.target.temperatures);
      this.barStatsNc.loadGraph(this.datasStats.ncs, this.datasStats.target.ncs);
      this.barStatsTreatment.loadGraph(this.datasStats.treatments, this.datasStats.target.treatments);
      this.barStatsLot.loadGraph(this.datasStats.lots, this.datasStats.target.lots);
      this.barStatsPicture.loadGraph(this.datasStats.photos, this.datasStats.target.photos);

      this.loadingModalService.hide();
    });
  }

  opendDetail(indexBarChart: number, typeDatas:string, title: string) {
    let periodCode: string = '';

    switch (indexBarChart) {
      case 0:
        periodCode = this.KEY_DAY;
        break;
      case 1:
        periodCode = this.KEY_WEEK;
        break;
      case 2:
        periodCode = this.KEY_MONTH;
        break;
      case 3:
        periodCode = this.KEY_YEAR;
        break;
      case 4:
        periodCode = this.KEY_RANGE;
        break;
    }

    this.handleDialogService.showDialog(StatisticsDialogComponent, title, {
      periodCode: periodCode,
      periodStart: this.searchForm.controls['periodStart'].value,
      periodEnd: this.searchForm.controls['periodEnd'].value,
      varName: typeDatas,
      lstLicences: this.lstLicences,
      minHeight:'480px'
    });
  }

  ngOnDestroy(): void {
    this.dataSubscription.unsubscribe();
  }
}
