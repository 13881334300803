@defer (when dataLoaded) {  
  <p-panel header="{{ 'Utilisateurs' | transloco }}">

  @if(canWrite()) {
    <div class="grid panel-actions">
      <div class="col text-right">
        <button
          pButton
          [label]="'Ajouter un utilisateur' | transloco"
          icon="pi pi-plus"
          class="p-button-success"
          (click)="addUser()"
        ></button>
      </div>
    </div>
  }

  <p-table
    [value]="userList"
    styleClass="p-datatable-gridlines p-datatable-striped"
    stateStorage="session"
    stateKey="users-list-session"
    [paginator]="true"
    [rows]="10"
    [showCurrentPageReport]="true"
    currentPageReportTemplate="{totalRecords} {{ 'élément(s)' | transloco }}"
  >
    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="clientGroup.name">
          {{ "Groupe" | transloco }} <p-sortIcon field="clientGroup.name"></p-sortIcon>
        </th>
        <th pSortableColumn="client.name">
          {{ "Licence" | transloco }} <p-sortIcon field="client.name"></p-sortIcon>
        </th>
        <th pSortableColumn="login">
          {{ "Identifiant" | transloco }}<p-sortIcon field="login"></p-sortIcon>
        </th>
        <th pSortableColumn="lastName">
          {{ "Nom" | transloco }} <p-sortIcon field="lastName"></p-sortIcon>
        </th>
        <th pSortableColumn="firstName">
          {{ "Prénom" | transloco }} <p-sortIcon field="firstName"></p-sortIcon>
        </th>
        <th pSortableColumn="inDate">
          {{ "Créé le" | transloco }} <p-sortIcon field="inDate"></p-sortIcon>
        </th>
        <th pSortableColumn="outDate">
          {{ "Résilié le" | transloco }} <p-sortIcon field="outDate"></p-sortIcon>
        </th>
        @if(canWrite()) {
        <th></th>
        }
      </tr>
      <tr>
        <th>
          <p-columnFilter field="clientGroup.id" matchMode="in" [showMenu]="false">
            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
              <p-multiSelect
                [ngModel]="value"
                [options]="groups"
                [placeholder]="'Toutes' | transloco"
                (onChange)="filter($event.value)"
                optionLabel="name"
                optionValue="id"
                class="liste-recherche-table"
              >
              </p-multiSelect>
            </ng-template>
          </p-columnFilter>
        </th>
        <th>
          <p-columnFilter field="client.id" matchMode="in" [showMenu]="false">
            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
              <p-multiSelect
                [ngModel]="value"
                [options]="licences"
                [placeholder]="'Toutes' | transloco"
                (onChange)="filter($event.value)"
                optionLabel="displayName"
                optionValue="id"
                class="liste-recherche-table"
              >
              </p-multiSelect>
            </ng-template>
          </p-columnFilter>
        </th>
        <th>
          <p-columnFilter
            type="text"
            field="login"
            matchMode="contains"
          ></p-columnFilter>
        </th>
        <th>
          <p-columnFilter
            type="text"
            field="lastName"
            matchMode="contains"
          ></p-columnFilter>
        </th>
        <th>
          <p-columnFilter
            type="text"
            field="firstName"
            matchMode="contains"
          ></p-columnFilter>
        </th>
        <th></th>
        <th></th>
        @if(canWrite()) {
        <th></th>
        }
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-oneUser>
      <tr [ngClass]="{ 'compte-resilie': oneUser.outDate }">
        <td>{{ oneUser.clientGroup.name }}</td>
        <td>{{ oneUser.client.name }} - {{ oneUser.client.id }}</td>
        <td>{{ oneUser.login }}</td>
        <td>{{ oneUser.lastName }}</td>
        <td>{{ oneUser.firstName }}</td>
        <td>{{ oneUser.inDate | date : "dd/MM/yyyy" }}</td>
        <td>{{ oneUser.outDate | date : "dd/MM/yyyy" }}</td>
        @if(canWrite()) {
        <td class="flex align-items-center justify-content-center gap-3">

          <a (click)="editUser(oneUser)" title="{{ 'Modifier l\'utilisateur' | transloco}}">
              <i class="pi pi-pencil"></i>
          </a> 

          @if(oneUser.outDate) {
            <a (click)="activateUser(oneUser, 1)" title="{{ 'Activer' | transloco}}">
                <i class="pi pi-check"></i>
            </a>
          } @else {
            <a (click)="activateUser(oneUser, 0)" title="{{ 'Désactiver' | transloco}}">
                <i class="pi pi-ban"></i>
            </a>
          }

          
          <a (click)="userLicences(oneUser)" title="{{ 'Gêrer les licences' | transloco}}">
              <i class="fa fa-store"></i>
          </a> 
          @if (isRoot()) {
            <a (click)="connectV3(oneUser)" title="{{ 'Se connecter à l\'application' | transloco}}">
                <i class="fa fa-user-secret"></i>
            </a>
          }
        </td>
        }
      </tr>
    </ng-template>
  </p-table>
</p-panel>
} @placeholder {
<div class="text-center">{{ "Chargement en cours..." | transloco }}</div>
}
<p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>
