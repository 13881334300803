@defer (when dataLoaded) {  
  <p-panel header="{{ 'Tables' | transloco }}">
      @if(canWrite()) {
        <div class="grid panel-actions">
            <div class="col text-right">
                <button pButton  [label]="'Ajouter une table' | transloco" icon="pi pi-plus" class="p-button-success" (click)="addCustomTable()" ></button>  
            </div>
        </div>
    }

    <p-table
      #dt
      [value]="customTables"
      styleClass="p-datatable-gridlines p-datatable-striped"
      stateStorage="session"
      stateKey="tables-list-session"
      [paginator]="true"
      [rows]="10"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="{totalRecords} {{ 'élément(s)' | transloco }}"
    >
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="client.name">
            {{ "Licence" | transloco }}
            <p-sortIcon field="client.name"></p-sortIcon>
          </th>
          <th pSortableColumn="name">
            {{ "Code" | transloco }}<p-sortIcon field="name"></p-sortIcon>
          </th>
          <th></th>
        </tr>
        <tr>
          <th>
            <p-columnFilter field="client.id"  name="client_id" matchMode="in" [showMenu]="false">
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <p-multiSelect
                  [ngModel]="value"
                  [options]="licences"
                  placeholder="Toutes"
                  (onChange)="filter($event.value)"
                  optionLabel="displayName"
                  optionValue="id"
                  class="liste-recherche-table"
                >
                </p-multiSelect>
              </ng-template>
            </p-columnFilter>
          </th>
          <th>
            <p-columnFilter
              type="text"
              field="name"
              matchMode="contains"
            ></p-columnFilter>
          </th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-cTable>
        <tr>
          <td>{{ cTable.client.name }}</td>
          <td>{{ cTable.name }}</td>
          <td class="flex align-items-center justify-content-center">
            <a (click)="redirectDatas(cTable)" title="{{ 'Modifier les données de la table' | transloco}}">
                <i class="fa fa-list"></i>
            </a>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </p-panel>
} @placeholder {
<div class="text-center">{{ "Chargement en cours..." | transloco }}</div>
}
