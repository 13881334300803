import { Client } from "./client";
import moment from "moment/moment";

export class ProductLS {

  public lpr_id: number;
  public lpr_libelle: string;
  public lpr_marque: string;
  public lpr_ean: number;
  public lpr_lang: string;
  public lpr_create_date: moment.Moment;
  public lst_name: string;
  public lcp_cli_id: number;
  public client: Client;

  constructor(
    lpr_id: number,
    lpr_libelle: string,
    lpr_marque: string,
    lpr_ean: number,
    lpr_lang: string,
    lpr_create_date: moment.Moment,
    lst_name: string,
    lcp_cli_id: number,
    client: Client,
  ) {
    this.lpr_id = lpr_id;
    this.lpr_libelle = lpr_libelle;
    this.lpr_marque = lpr_marque;
    this.lpr_ean = lpr_ean;
    this.lpr_lang = lpr_lang;
    this.lpr_create_date = lpr_create_date;
    this.lst_name = lst_name;
    this.lcp_cli_id = lcp_cli_id;
    this.client = client;
  }
}
