@defer (when dataLoaded) {
  @if(isRoot()) {
  <div class="grid">
    <div class="col text-right">
      <button
        pButton
        [label]="'Ajouter un produit' | transloco"
        icon="pi pi-plus"
        class="p-button-success"
        (click)="addProduct()"
      ></button>
    </div>
  </div>
}
<div class="grid flex">
  @for (oneProduct of products; track oneProduct) {

  <div class="col-12 md:col-6 lg:col-4 flex-initial flex  justify-content-center">
    <p-card>
      <ng-template pTemplate="header">
          <a (click)="redirectProduct(oneProduct)">
            <img
              [alt]="oneProduct.title"
              [src]="oneProduct.photo1"
              class="photoListe"
            />
          </a>
      </ng-template>
      <div class="productTitle">
        <a (click)="redirectProduct(oneProduct)">
          {{ oneProduct.title }}
        </a>
      </div>
      <span class="htPrice">{{ oneProduct.htPrice }}&euro;</span>
      <span class="tax">{{ 'HT' | transloco }}</span>
      <ng-template pTemplate="footer">
        <div class="flex gap-3 mt-1">
          <button
            pButton
            [label]="'Voir la fiche' | transloco"
            icon="fa fa-magnifying-glass"
            class="p-button-gray flex-1"
            (click)="redirectProduct(oneProduct)"
          ></button>
          <button
            pButton
            [label]="'Commander' | transloco"
            icon="fa-solid fa-cart-plus"
            class="p-button-success flex-1"
            (click)="addProductToCart(oneProduct.id, 1)"
          ></button>
        </div>
      </ng-template>
    </p-card>
  </div>
  }
</div>
<p-toast />
} @placeholder {
<div class="text-center">{{ 'Chargement en cours...' | transloco }}</div>
}
