import { Component, OnInit, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import { Subscription } from 'rxjs';

import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { PanelModule } from 'primeng/panel';
import { InputNumberModule } from 'primeng/inputnumber';
import { TableModule } from 'primeng/table';
import { RippleModule } from 'primeng/ripple';

import { AuthService } from '../../../../services/auth/auth.service';
import {ApiProcessListService} from "../../../../services/api/api-process-list.service";
import {ApiProcessListParametersService} from "../../../../services/api/api-process-list-parameters.service";

import {ActivatedRoute, Router} from '@angular/router';
import {TranslocoModule, TranslocoService} from '@ngneat/transloco';

import { Client } from '../../../../models/client';
import {ProcessListParameters} from "../../../../models/process-list-parameters";

import { EditComponent } from '../../../core/edit/edit.component';

import {InputTextModule} from "primeng/inputtext";
import {TooltipModule} from "primeng/tooltip";
import {ConfirmationService, MessageService} from "primeng/api";
import { ChipsModule } from 'primeng/chips';
import {ProcessList} from "../../../../models/process-list";
import {ConfirmDialogModule} from "primeng/confirmdialog";
import {Alerts} from "../../../../models/alerts-users";
import {UtilsService} from "../../../../services/utils/utils.service";


@Component({
  selector: 'app-process-list-traceability-parameters',
  templateUrl: './process-list-traceability-parameters.component.html',
  styleUrl: './process-list-traceability-parameters.component.css',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    CheckboxModule,
    DropdownModule,
    InputNumberModule,
    RippleModule,
    FormsModule,
    PanelModule,
    ReactiveFormsModule,
    TranslocoModule,
    TableModule,
    InputTextModule,
    TooltipModule,
    ChipsModule,
    ConfirmDialogModule,
  ],
  providers: [MessageService, ConfirmationService],
})

export class ProcessListTraceabilityParametersComponent extends EditComponent implements OnInit, OnDestroy {
  ProcessListParametersSubscription: Subscription = new Subscription();

  name: string = '';
  parameters!: ProcessListParameters[];
  licences : Client[] = [];
  tpaId: any;
  processSelected: any;
  processisSelected: boolean = false;
  areButtonsDisabled = false;
  cancelButtonDisabled= true;
  saveButtonDisabled = true;
  paramDlcByDefault: string = '';
  tprId: any;
  cliId: any;

  editForm: FormGroup;
  filteredTypes: { label: string, value: string }[];
  ouiNon: { label: string, value: number }[];
  ouiNonHisto: { label: string, value: number }[];
  longueur: { label: string, value: number|string|null }[];
  mode: { label: string, value: string|null }[];
  modeText: { label: string, value: number|string|null }[];
  longueurText: { label: string, value: number|string|null }[];
  listValues: string[] = [];
  default: any[] = [];
  firstList: string = '';
  addEnabled= true;
  setting = '';

  constructor(
    private apiProcessListService: ApiProcessListService,
    private apiProcessListParametersService: ApiProcessListParametersService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private confirmationService: ConfirmationService,
    private translocoService: TranslocoService,
    private router: Router,
    private utilsService: UtilsService,
  ) {
    super(route);

    this.editForm = this.formBuilder.group({
      param_order: ['', Validators.required],
      param_name: ['', Validators.required],
      param_type: ['', Validators.required],
      param_dlc: ['', Validators.required],
      param_length: [''],
      param_mode: [''],
      param_list:  [''],
      param_default: [''],
      param_default_when: [''],
      param_validation: [''],
      param_placeHolder: [''],
      param_readOnly: [''],
      param_requis: [''],
      param_dateFuture: [''],
      param_displayInTable: [''],
    })
    this.filteredTypes = [];
    this.longueur = [];
    this.ouiNon = [];
    this.ouiNonHisto = [];
    this.mode = [];
    this.modeText = [];
    this.longueurText = [];
  }

  override ngOnInit() {
    super.ngOnInit();

    this.cliId = this.route.snapshot.paramMap.get('cli_id') ?? '';
    this.tprId = this.route.snapshot.paramMap.get('tpr_id') ?? '';
    this.titlePage = this.route.snapshot.data['path'].slice(-1)[0]['label'];
    this.route.queryParams.subscribe(params => {
      this.name = params['name'];
    });
    this.licences = this.authService.getUserLicences();

    this.ProcessListParametersSubscription = this.apiProcessListParametersService
      .getProcessListParameters(this.tprId)
      .subscribe((selectedProcess: ProcessListParameters[]) => {
        this.parameters = selectedProcess;
        this.sortParametersByOrder();
      });
    this.licences = this.authService.getUserLicences();

    this.filteredTypes = [
      { label: 'Texte', value: 'varchar' },
      { label: 'Nombre entier', value: 'int' },
      { label: 'Nombre decimal', value: 'float' },
      { label: 'Date', value: 'date' },
      { label: 'Heure', value: 'hour' },
      { label: 'Separateur', value: 'separator' },
      { label: 'Commentaire', value: 'comment' },
    ];
    this.ouiNon = [
      { label: 'Oui (1ére ouverture)', value : 1 },
      { label: 'Oui (2nde ouverture)', value : 2 },
      { label: 'Non', value : 0 }
    ];
    this.ouiNonHisto = [
      { label: 'Oui', value : 1 },
      { label: 'Non', value : 0 }
    ];
    this.longueur = [
      {label: '5', value: 5},
      {label: '10', value: 10},
      {label: '30', value: 30},
      {label: '50', value: 50}
    ];
    this.mode = [
      {label: 'Champ libre', value: null},
      {label: 'Variateur', value: 'touchspin'},
      {label: 'Horloge', value: 'clock'},
      {label: 'Calendrier',value: 'calendar'},
      {label: 'Liste', value: 'list'},
      {label: 'Table', value: 'table'},
    ];
    this.modeText = [
      {label: 'Champ libre', value: 'null'},
      {label: 'Liste', value: 'list'},
    ];
    this.longueurText = [
      {label: '5', value: 5},
      {label: '10', value: 10},
      {label: '30', value: 30},
      {label: '50', value: 50}
    ];

    this.dataLoaded = true;
  }

  sortParametersByOrder() {
    this.parameters.sort((a, b) => a.tpa_order - b.tpa_order);
  }

  accessToOutputs() {
    this.router.navigate(['settings/process/process-list-traceability-outputs/', this.cliId, this.tprId]);
  }

  onRowSelect(event: any, tpaId: any) {
    this.setting = "Modification du paramètre";
    this.areButtonsDisabled = true;
    this.addEnabled = false;
    this.cancelButtonDisabled = false;
    this.saveButtonDisabled = false;
    this.processisSelected = true;

    for (const paramKey in event) {
      if (paramKey === "tpa_values_list" && event.tpa_values_list){
        this.firstList = event.tpa_values_list;
        this.listValues = this.firstList.split('|');
      }
      else{
        this.firstList = event.tpa_values_list;
      }
    }

    if (this.tprId != '') {
      this.ProcessListParametersSubscription = this.apiProcessListParametersService
        .getProcessListParameters(this.tprId)
        .subscribe((dataparam: ProcessListParameters[]) => {
          this.tpaId = tpaId;
          for (const item of dataparam) {
            if (item.tpa_id == event.tpa_id) {
              this.editForm.controls['param_order'].setValue(event.tpa_order);
              this.editForm.controls['param_name'].setValue(event.tpa_name_clean);
              this.editForm.controls['param_length'].setValue(event.tpa_length);
              this.editForm.controls['param_type'].setValue(event.tpa_type);
              this.editForm.controls['param_mode'].setValue(event.tpa_input_mode);
              this.editForm.controls['param_list'].setValue(this.listValues);
              this.editForm.controls['param_default'].setValue(event.tpa_default);
              this.editForm.controls['param_default_when'].setValue(event.tpa_default_when);
              this.editForm.controls['param_validation'].setValue(event.tpa_validation);
              this.editForm.controls['param_placeHolder'].setValue(event.tpa_placeholder);
              this.editForm.controls['param_readOnly'].setValue(event.tpa_readonly);
              this.editForm.controls['param_requis'].setValue(event.tpa_required);
              this.editForm.controls['param_dlc'].setValue(event.tpa_is_dlc);
              this.editForm.controls['param_dateFuture'].setValue(event.tpa_future_date);
              this.editForm.controls['param_displayInTable'].setValue(event.tpa_display_in_table);

              this.paramDlcByDefault = event.tpa_default;
            }
          }
        });
    }
  }

  typeChange() {
    this.utilsService.updateOptions(this.editForm.controls['tpa_input_mode'].value, this.mode,
      this.default, this.longueur,
      this.ouiNon, this.modeText, this.longueurText, this.editForm);
  }

  deleteProcessParameter(processParameter: ProcessListParameters){
    this.confirmationService.confirm({
      message:
          this.translocoService.translate(
              'Vous êtes sur le point de supprimer définitivement ce paramètre !'),
      header: 'Suppression',
      acceptLabel: this.translocoService.translate('Oui'),
      rejectLabel: this.translocoService.translate('Non'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.apiProcessListParametersService.deleteProcessParameter( processParameter.tpa_id).subscribe((response) => {
          if (response) {
            this.parameters = this.parameters.filter(
              (val: ProcessListParameters) => val.tpa_id !== processParameter.tpa_id
            );
            console.log('Deletion OK');
          } else{
            console.log('Deletion KO');
          }
        });
      },
    });
  }
  addParameter(){
    this.setting = "Nouveau paramètre";
    this.processisSelected = true;
    this.cancelButtonDisabled = false;
    this.saveButtonDisabled = false;
    this.areButtonsDisabled = true;
    this.addEnabled = false;
    this.listValues = [];
    this.processSelected = {};
    this.processSelected['tpa_id'] = '';

    this.editForm.controls['param_order'].setValue(1);
    this.editForm.controls['param_name'].setValue('');
    this.editForm.controls['param_type'].setValue('');
    this.editForm.controls['param_length'].setValue(5);
  }

  cancel(){
    this.areButtonsDisabled = false;
    this.cancelButtonDisabled = true;
    this.processisSelected = false;
    this.addEnabled = true;
    this.ProcessListParametersSubscription.unsubscribe()

    this.editForm.controls['param_order'].setValue('');
  }

  updateModeOptions() {
    this.utilsService.updateModeOptions(this.editForm.controls['param_type'].value, this.mode,
      this.default, this.longueur,
      this.ouiNon, this.modeText, this.longueurText, this.editForm)
  }
  updateDLCoptions() {
    this.utilsService.updateDLCoptions( this.editForm.controls['rpa_is_dlc'], this.mode,
      this.default, this.longueur, this.editForm)
  }

  getTheList() {
    this.utilsService.getTheList(this.editForm , this.listValues)
  }

  save(): void {
    let formData = new FormData();
    if (this.processSelected?.tpa_id) {
      formData.append('tpa_id', this.processSelected['tpa_id']);
    } else {
      formData.append('tpa_id', '');
    }
    formData.append('cli_id', this.processSelected?.cli_id)
    formData.append('tpr_id', this.tprId);
    formData.append('DelOrUpdate', '1');
    if (this.editForm.controls['param_dlc'].value == 1) {
      this.editForm.patchValue({
        param_default: 'date(\'d/m/Y\', strtotime("+ " .max(function=getDlc(tre_id)/function,'
          + (this.editForm.controls['param_default'].value) * 1440 + ') ." minutes"))'
      });
    } else if (this.editForm.controls['param_dlc'].value == 0){
      this.updateDLCoptions();
    }
    if (this.editForm.controls['param_default'].value == "") {
      this.editForm.patchValue({
        param_default: this.paramDlcByDefault
      });
    }
    const formControls = Object.keys(this.editForm.controls);

    formControls.forEach(controlName => {
      const control = this.editForm.get(controlName);
      if (control) {
        formData.append(controlName, control.value);
      }
    });

    this.apiProcessListParametersService.updateProcessParameter( formData).subscribe((response) => {
      if (response) {
        this.ProcessListParametersSubscription = this.apiProcessListParametersService
          .getProcessListParameters(this.tprId)
          .subscribe((selectedProcess: ProcessListParameters[]) => {
            this.parameters = selectedProcess;
            this.sortParametersByOrder();
          });
        console.log('Update OK');
      }
      else{
        console.log('Update KO');
      }
    });
  }
  ngOnDestroy() {
    this.ProcessListParametersSubscription.unsubscribe();
  }
}
