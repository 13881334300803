import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { TranslocoModule } from '@ngneat/transloco';
import { Subscription } from 'rxjs';
import { EditComponent } from '../../../core/edit/edit.component';

import { ButtonModule } from 'primeng/button';
import { PanelModule } from 'primeng/panel';
import { InputNumberModule } from 'primeng/inputnumber';
import { ApiSurveysService } from '../../../../services/api/api-surveys.service';
import { TableModule } from 'primeng/table';
import { AuthService } from '../../../../services/auth/auth.service';
import { SessionStorageService } from '../../../../services/storage/session-storage.service';
import { GlobalDatas } from '../../../../models/global-datas';
import { DropdownModule } from 'primeng/dropdown';
import { SurveyQuestion } from '../../../../models/survey-question';
import { CheckboxModule } from 'primeng/checkbox';

@Component({
  selector: 'app-surveys-questions-edit',
  templateUrl: './surveys-questions-edit.component.html',
  styleUrl: './surveys-questions-edit.component.css',
  standalone: true,
  imports: [
    ButtonModule,
    CheckboxModule,
    DropdownModule,
    InputNumberModule,
    PanelModule,
    ReactiveFormsModule,
    TableModule,
    TranslocoModule,
  ],
})
export class SurveysQuestionsEditComponent
  extends EditComponent
  implements OnInit, OnDestroy {
  editForm: FormGroup;
  saveError: boolean = false;
  questionSubscription: Subscription = new Subscription();
  pageId: string = '';
  questionId: string = '';
  surveyId: string = '';
  ordreMax: number = 0;
  globalDatas: GlobalDatas | null = null;
  questionTypes: any[] = [];
  allSurveysInputModes: any[] = [];
  surveysInputModes: any[] = [];

  displayOptionsText: boolean = false;
  displayOptionsValues: boolean = false;

  constructor(
    private apiSurveysService: ApiSurveysService,
    private authService: AuthService,
    private cdr: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private sessionStorageService: SessionStorageService,
  ) {
    super(route);

    this.editForm = this.formBuilder.group({
      reference: ['', Validators.required],
      name: ['', Validators.required],
      order: [1, Validators.required],
      imo_id: [null, Validators.required],
      qty_id: [null, Validators.required],
      valDefault: [''],
      placeholder: [''],
      required: [false],
      que_values_list: this.formBuilder.array([])
    });
  }

  override ngOnInit() {
    super.ngOnInit();

    this.questionId = this.route.snapshot.paramMap.get('id') ?? '';
    this.pageId = this.route.snapshot.paramMap.get('pageId') ?? '';
    this.surveyId = this.route.snapshot.paramMap.get('surveyId') ?? '';

    this.titlePage = this.route.snapshot.data['path'].slice(-1)[0]['label'];

    this.globalDatas = this.sessionStorageService.get('globalDatas');
    this.questionTypes = this.globalDatas ? this.globalDatas.surveysQuestionTypes : [];
    this.allSurveysInputModes = this.globalDatas ? this.globalDatas.surveysInputModes : [];

    //pour l'instant désactivé, à voir dans un second temps
    this.questionTypes = this.questionTypes.filter(oneQt => oneQt.qty_name != 'Table');

    if (this.questionId != '') {
      this.questionSubscription = this.apiSurveysService
        .getQuestion(this.questionId)
        .subscribe((oQuestion: SurveyQuestion) => {
          this.editForm.controls['reference'].setValue(oQuestion.reference);
          this.editForm.controls['name'].setValue(oQuestion.name);
          this.editForm.controls['order'].setValue(oQuestion.order);
          this.editForm.controls['imo_id'].setValue(oQuestion.imoId.toString());
          this.editForm.controls['qty_id'].setValue(oQuestion.qtyId.toString());
          this.editForm.controls['valDefault'].setValue(oQuestion.valDefault);
          this.editForm.controls['placeholder'].setValue(oQuestion.placeholder);

          this.editForm.controls['required'].setValue(oQuestion.required == 1);

          if (this.getCodeQuestionType() == 'list') {
            //initialise les valeurs
            if (oQuestion.valuesList != '') {
              oQuestion.valuesList.split('|').forEach(oneValue => {
                this.addValue(oneValue);
              });
            }
          }

          this.onQuestionTypeChange(oQuestion.imoId.toString());

          this.dataLoaded = true;
        });
    } else {
      this.editForm.controls['order'].setValue(
        this.route.snapshot.paramMap.get('nextOrder')
      );
      this.addValue();
      this.dataLoaded = true;
    }
  }

  ngOnDestroy() {
    this.questionSubscription.unsubscribe();
  }

  getCodeQuestionType() {
    const valQtyId = this.editForm.controls['qty_id'].value;
    let objQt = this.questionTypes.find((oneQty: any) => oneQty.qty_id == valQtyId);

    return objQt?.code ?? '';
  }

  save() {
    let formData = new FormData();
    formData.append('que_id', this.questionId);
    formData.append('que_spa_id', this.pageId);
    formData.append('que_order', this.editForm.controls['order'].value);
    formData.append('que_reference', this.editForm.controls['reference'].value);
    formData.append('que_name', this.editForm.controls['name'].value);
    formData.append('que_qty_id', this.editForm.controls['qty_id'].value);
    formData.append('que_imo_id', this.editForm.controls['imo_id'].value);
    formData.append('que_default', this.editForm.controls['valDefault'].value);
    formData.append('que_place_holder', this.editForm.controls['placeholder'].value);
    formData.append('que_required', (this.editForm.controls['required'].value ? '1' : '0'));

    if (this.getCodeQuestionType() == 'list') {
      formData.append('que_values_list', this.editForm.controls['que_values_list'].value.join('|'));
    } else {
      formData.append('que_values_list', '');
    }

    this.apiSurveysService
      .postSurveyQuestion(formData)
      .subscribe((response: any) => {
        this.saveError = !response;

        if (response) {
          this.router.navigate(['settings/surveys-pages-edit', this.surveyId, this.pageId]);
        }
      });
  }

  canWrite(): boolean {
    return this.authService.canWrite();
  }

  onQuestionTypeChange(imoId: string = '') {
    this.surveysInputModes = [];

    // Filtrer les zones en fonction de la licence sélectionnée
    if (this.editForm.controls['qty_id'].value) {
      const codeQt = this.getCodeQuestionType();

      if (codeQt != '') {
        if (codeQt == 'date' || codeQt == 'date_hour') {
          this.surveysInputModes = this.allSurveysInputModes.filter((oneInput: any) => oneInput.code == 'calendar');
        } else if (codeQt == 'list') {
          this.surveysInputModes = this.allSurveysInputModes.filter((oneInput: any) => oneInput.code == 'list' || oneInput.code == 'options');
        } else if (codeQt == 'varchar') {
          this.surveysInputModes = this.allSurveysInputModes.filter((oneInput: any) => oneInput.code == 'textarea' || oneInput.code == 'standard');
        } else {
          this.surveysInputModes = this.allSurveysInputModes.filter((oneInput: any) => oneInput.code == codeQt);
        }

        if (imoId == '') {
          if (this.surveysInputModes.length == 1) {
            this.editForm.controls['imo_id'].setValue(this.surveysInputModes[0].imo_id.toString());
          } else {
            this.editForm.controls['imo_id'].setValue(null);
          }
        }

        if (codeQt !== 'varchar') {
          this.editForm.controls['valDefault'].setValue('');
          this.editForm.controls['placeholder'].setValue('');
        }

        if (codeQt !== 'list') {
          this.clearValues();
        } else if (this.valueControls.length == 0) {
          this.addValue();
        }

        this.displayOptionsText = (codeQt == 'varchar');
        this.displayOptionsValues = (codeQt == 'list');
      }
    }
  }

  addQuestion() {
    const nextOrder = (this.ordreMax ?? 0) + 1;
    this.router.navigate(['settings/surveys-questions-add', this.surveyId, this.pageId, nextOrder]);
  }

  get valueControls() {
    return (this.editForm.get('que_values_list') as FormArray).controls;
  }

  clearValues() {
    (this.editForm.get('que_values_list') as FormArray).clear();
  }

  removeValue(index: number) {
    (this.editForm.get('que_values_list') as FormArray).removeAt(index);
  }

  addValue(textValue: string = '') {
    const textField = this.formBuilder.control(textValue, [Validators.required]);
    (this.editForm.get('que_values_list') as FormArray).push(textField);
    this.cdr.detectChanges();
  }
}
