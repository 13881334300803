@defer (when dataLoaded) {
@if(canWrite()) {
<div class="grid">
    <div class="col text-right">
        <button pButton [label]="'Ajouter un rapport mail' | transloco" icon="pi pi-plus" class="p-button-success"
            (click)="addMailReport()"></button>
    </div>
</div>
}
<p-table [value]="mailReports" styleClass="p-datatable-gridlines p-datatable-striped" stateStorage="session"
    stateKey="mail-reports-list-session" [paginator]="true" rowGroupMode="rowspan" groupRowsBy="user.id" [rows]="nbRows"
    [showCurrentPageReport]="true" currentPageReportTemplate="Lignes {first} à {last} sur un total de {totalRecords}">
    <ng-template pTemplate="header">
        <tr>
            @if(isRoot()) {
            <th pSortableColumn="fullName">{{ 'Utilisateur' | transloco }} <p-sortIcon field="fullName"></p-sortIcon>
            </th>
            }
            <th pSortableColumn="title">{{ 'Titre' | transloco }}<p-sortIcon field="title"></p-sortIcon></th>
            <th pSortableColumn="frequency.name">{{ 'Fréquence' | transloco }} <p-sortIcon
                    field="frequency.name"></p-sortIcon></th>
            <th pSortableColumn="day?.name">{{ 'Jour' | transloco }} <p-sortIcon field="day?.name"></p-sortIcon></th>
            <th pSortableColumn="startDate">{{ 'Date de début' | transloco }} <p-sortIcon
                    field="startDate"></p-sortIcon></th>
            <th pSortableColumn="lastDate">{{ 'Dernier envoi' | transloco }} <p-sortIcon field="lastDate"></p-sortIcon>
            </th>
            <th></th>
        </tr>
        <tr>
            @if(isRoot()) {
            <th>
                <p-columnFilter field="user.id" matchMode="in" [showMenu]="false">
                    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                        <p-multiSelect [ngModel]="value" [options]="distinctUsers" placeholder="Toutes"
                            (onChange)="filter($event.value)" optionLabel="fullName" optionValue="id"
                            [virtualScroll]="true" [virtualScrollItemSize]="50" class="liste-recherche-table">
                        </p-multiSelect>
                    </ng-template>
                </p-columnFilter>
            </th>
            }
            <th>
                <p-columnFilter type="text" field="title" matchMode="contains"></p-columnFilter>
            </th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-mailreport let-rowIndex="rowIndex" let-rowgroup="rowgroup" let-rowspan="rowspan">
        <tr [ngClass]="tableGroupService.getGroupClass(nbRows, rowIndex, rowspan)">
            @if(isRoot()) {
            <td *ngIf="rowgroup" [attr.rowspan]="rowspan">{{ mailreport.user.fullName }}</td>
            }
            <td>{{ mailreport.title }}</td>
            <td>{{ mailreport.frequency.name }}</td>
            <td>{{ mailreport.day?.name }}</td>
            <td>{{ mailreport.startDate | date:'dd/MM/yyyy' }}</td>
            <td>{{ mailreport.lastDate | date:'dd/MM/yyyy' }}</td>
            <td class="flex align-items-center justify-content-center">
                @if(canWrite()) {
                <button pButton icon="pi pi-pencil" class="flex p-button-rounded p-button-success mr-2"
                    (click)="editMailReport(mailreport)" title="Modifier"></button>
                <button pButton icon="fa fa-store fa-xl" class="flex p-button-rounded p-button-success mr-2"
                    (click)="editLicences(mailreport)" title="Licences"></button>
                <button pButton icon="pi pi-trash" class="flex p-button-rounded p-button-warning"
                    (click)="deleteMailReport(mailreport)" title="Supprimer"></button>
                }
            </td>
        </tr>
    </ng-template>
</p-table>
}
@placeholder {
<div class="text-center">{{ 'Chargement en cours...' | transloco }}</div>
}
<p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>