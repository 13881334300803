import { EshopOrderProduct } from "./eshop-order-product";
import { User } from "./user";

export class EshopOrder {
    public id: number;
    public date: moment.Moment;
    public dateOnly: Date = new Date();
    public user: User; // user qui a fait la commande

    public name: string;
    public first_name: string;
    public company: string;
    public country : string;
    public address : string;
    public address2 : string;
    public zipcode : string;
    public city : string;
    public phone_number : string;
    public email : string;
    public notes : string;
    public payment_method : string;
    public payment_date : moment.Moment | null;
    public status : string;
    public total_price_ht: number;
    public total_tva: number;
    public total:number = 0;

    public products: Array<EshopOrderProduct> = [];
    
    constructor(
        id: number,
        date: moment.Moment,
        user: User,

        name: string,
        first_name: string,
        company: string,
        country : string,
        address : string,
        address2 : string,
        zipcode : string,
        city : string,
        phone_number : string,
        email : string,
        notes : string,
        payment_method : string,
        payment_date : moment.Moment | null,

        status : string,
        total_price_ht: number,
        total_tva: number
    ) {
        this.id = id;
        this.date = date;
        this.dateOnly= date.toDate();
        this.user = user;

        this.name = name;
        this.first_name = first_name;
        this.company = company;
        this.country = country;
        this.address = address;
        this.address2 = address2;
        this.zipcode = zipcode;
        this.city = city;
        this.phone_number = phone_number;
        this.email = email;
        this.notes = notes;
        this.payment_method = payment_method;
        this.payment_date = payment_date;
        this.status = status;
        
        this.total_price_ht = total_price_ht;
        this.total_tva = total_tva;

        this.total = this.total_price_ht + this.total_tva;
    }
}