import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MultiSelectModule } from 'primeng/multiselect';
import { TableModule } from 'primeng/table';
import { FilterService } from 'primeng/api';

import { TranslocoModule } from '@ngneat/transloco';

import moment from 'moment';
import { Subscription } from 'rxjs';

import { ApiClientsService } from '../../../services/api/api-clients.service';
import { AuthService } from '../../../services/auth/auth.service';

import { ClientMonitoring } from '../../../models/client-monitoring';
import { Client } from '../../../models/client';
import { ClientGroup } from '../../../models/client-group';


interface Column {
  field: string;
  header: string;
}

@Component({
  selector: 'app-monitoring-couchdb',
  templateUrl: './monitoring-couchdb.component.html',
  styleUrl: './monitoring-couchdb.component.css',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MultiSelectModule,
    TableModule,
    TranslocoModule,
  ],
})

export class MonitoringCouchdbComponent implements OnDestroy, OnInit {
  
  monitSubscription: Subscription = new Subscription();
  monitorList: ClientMonitoring[] = [];
  licences: Client[] = [];
  groups: ClientGroup[] = [];
  dataLoaded: boolean = false;

  cols: Column[] = [];
  _selectedColumns: Column[] = [];

  constructor(
    private apiClientsService: ApiClientsService,
    private authService: AuthService,
    private filterService: FilterService
  ) {}

  ngOnInit(): void {


    this.cols = [
        { field: 'dbSize', header:'Taille DB' },
        { field: 'deletedDocs', header:'Docs suppr' },
        { field: 'anomalie', header:'Anomalies' },
        { field: 'treatment', header:'Process ouverts' },
        { field: 'treatmentHisto', header:'Process fermés' },
        { field: 'lot', header:'Lots' },
        { field: 'treatmentImage', header:'Images process' },
        { field: 'treatmentImageDelete', header:'Images process suppr.' },
        { field: 'traca', header:'Traças' },
        { field: 'reception', header:'Réceptions' },
        { field: 'lsClient', header:'LS' },
        { field: 'task', header:'Tâches' },
        { field: 'temperature', header:'Temp' },
        { field: 'oilChange', header:'Chgt Huile' },
        { field: 'oilControl', header:'Ctrl Huile' },
        { field: 'measure', header:'Mes Spec' },
        { field: 'taskHisto', header:'Tâches histo' },
        { field: 'temperatureHisto', header:'Temp histo' },
        { field: 'oilChangeHisto', header:'Chgt Huile histo' },
        { field: 'oilControlHisto', header:'Ctrl Huile histo' },
        { field: 'measureHisto', header:'Mes Spec histo' },
        { field: 'majDate', header:'Date mise à jour' },
    ];
    this._selectedColumns = this.cols.filter((oneCol => [
      'dbSize', 'deletedDocs', 'anomalie', 'treatment', 'majDate'
    ].indexOf(oneCol.field) >= 0));


    this.monitSubscription = this.apiClientsService
      .getMonitoring()
      .subscribe((data: ClientMonitoring[]) => {
        this.monitorList = data;
        this.dataLoaded = true;
      });
    this.licences = this.authService.getUserLicences();
    this.groups = this.authService.getUserGroups();

    this.filterService.register(
      'equalDate',
      (value: any, filter: any): boolean => {
        if (filter === undefined || filter === null) {
          return true;
        }

        if (value === undefined || value === null) {
          return false;
        }

        return (
          moment(value).format('YYYY-MM-DD') ===
          moment(filter).format('YYYY-MM-DD')
        );
      }
    );
  }
  
  get selectedColumns(): Column[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: Column[]) {
      //restore original order
      this._selectedColumns = this.cols.filter((col) => val.includes(col));
  }
  
  ngOnDestroy(): void {
    this.monitSubscription.unsubscribe();
  }
}
