import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CacheService {
  private cache = new Map<string, any>(); // Utilisation d'une Map pour stocker les clés/valeurs
  private cacheSubject = new BehaviorSubject<Map<string, any>>(this.cache);

  // Observable pour écouter les changements
  cache$ = this.cacheSubject.asObservable();

  // Stocker un élément dans le cache
  setItem(key: string, value: any): void {
    this.cache.set(key, value);
    this.cacheSubject.next(new Map(this.cache));
  }

  // Récupérer un élément du cache
  getItem(key: string): any {
    return this.cache.get(key) || null;
  }

  // Supprimer un élément du cache
  removeItem(key: string): void {
    this.cache.delete(key);
    this.cacheSubject.next(new Map(this.cache));
  }

  // Vider complètement le cache
  clear(): void {
    this.cache.clear();
    this.cacheSubject.next(new Map());
  }
}
