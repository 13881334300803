<div [formGroup]="editForm">
  
  <h3>
    <i class="fa fa-list-check mr-2"></i>
    {{ "Paramétrages" | transloco }}
  </h3>

  <div class="field-checkbox flex flex-row align-items-start gap-4">
    <p-checkbox class="mb-1" [binary]="true" formControlName="cpa_allow_change_resp" value="1"
      [label]="'Changement d\'utilisateur' | transloco"></p-checkbox>

    <p-checkbox class="mb-1" [binary]="true" formControlName="cpa_free_edition" value="1"
      [label]="'Version gratuite' | transloco"></p-checkbox>

    <p-checkbox class="mb-1" [binary]="true" formControlName="cpa_allow_v2" value="1"
      [label]="'Autoriser la connexion V2' | transloco"></p-checkbox>

    <p-checkbox class="mb-1" [binary]="true" formControlName="cpa_allow_v3" value="1"
      [label]="'Autoriser la connexion V3' | transloco"></p-checkbox>
  </div>

  <div class="flex flex-row align-items-start gap-4">
    <div class="field">
      <label for="cpa_output" class="label-required">{{ 'Type d\'ouput' | transloco }}</label>
      <p-dropdown autoWidth="false" [style]="{ width: '100%' }" [options]="outputs" class="w-full"
        formControlName="cpa_output" [filter]="true" optionLabel="name" optionValue="id" [required]="true"></p-dropdown>
      @if(isErrorFormControl(editForm.controls['cpa_output'])) {
      <small class="text-red-500">{{ 'Type d\'ouput requis' | transloco }}</small>
      }
    </div>
    <div class="field">
      <label for="cpa_synchro_days" class="label-required">{{
        "Nombre de jours de conservation des données" | transloco
        }}</label>
      <p-inputNumber id="cpa_synchro_days" formControlName="cpa_synchro_days" autofocus maxlength="4" class=" w-full"
        [required]="true" />
      @if(isErrorFormControl(editForm.controls['cpa_synchro_days'])) {
      <small class="text-red-500">{{ 'Nombre de jours requis' | transloco }}</small>
      }
    </div>
  </div>

  <h3>
    <i class="fa fa-bolt-lightning mr-2"></i>
    {{ "Déclencheurs" | transloco }}
  </h3>
  
  <div class="field-checkbox flex flex-row align-items-start gap-4">
  @for (oneTrigger of triggers; track oneTrigger) {
    
    <p-checkbox class="mb-1" [binary]="true" [formControlName]="'trigger_' + oneTrigger.id" value="1"
      [label]="oneTrigger.name"></p-checkbox>
  } 
  </div>

  <h3>
    <i class="fa fa-business-time mr-2"></i>
    {{ "Modules" | transloco }}
  </h3>
  <div class="field-checkbox flex flex-row align-items-start gap-4">
    <p-checkbox class="mb-1" [binary]="true" formControlName="cpa_audit_module" value="1"
      [label]="'Audit' | transloco"></p-checkbox>
    <p-checkbox class="mb-1" [binary]="true" formControlName="cpa_ls_module" value="1"
      [label]="'LS' | transloco"></p-checkbox>
    @if(!hideHomeReceipt) {
    <p-checkbox class="mb-1" [binary]="true" formControlName="rty_1" value="1" [label]="'Réception' | transloco"
      (click)="clicModuleReceipt()"></p-checkbox>
    }
    <p-checkbox class="mb-1" [binary]="true" formControlName="cpa_traceability_module" value="1"
      [label]="'Traçabilité' | transloco" (click)="clicModuleTraceability()"></p-checkbox>

    @if(detailTracea) {
    <p-checkbox class="mb-1" [binary]="true" formControlName="rty_2" value="2" (click)="clicModule(2)"
      [label]="'Réception' | transloco"></p-checkbox>
    <p-checkbox class="mb-1" [binary]="true" formControlName="rty_3" value="3" (click)="clicModule(3)"
      [label]="'Contrôle picking' | transloco"></p-checkbox>
    <p-checkbox class="mb-1" [binary]="true" formControlName="rty_4" value="4" (click)="clicModule(4)"
      [label]="'Ouvert le' | transloco"></p-checkbox>
    }
  </div>

  <div class="flex flex-column align-items-start gap-2">
    @for (oneModule of lstModules; track oneModule) {
    @if(oneModule.display) {

    <p-card [header]="'Module ' + oneModule.name" class="mt-2">
      <div class="field-checkbox flex flex-row align-items-start gap-4">
        <div>
          <div class="field">
            <label [for]="'cpr_name_' + oneModule.id">{{
              "Nom" | transloco
              }}</label>
            <input type="text" pInputText [id]="'cpr_name_' + oneModule.id" [formControlName]="'cpr_name_' + oneModule.id"
              autofocus maxlength="255" class="p-inputtext w-full" />
          </div>
          <div class="field">
            <label [for]="'cpr_auto_close_lot_' + oneModule.id">{{ "Clôture automatique du lots X jours après la
              réception" |
              transloco }}</label>
            <p-inputNumber [formControlName]="'cpr_auto_close_lot_' + oneModule.id"
              [inputId]="'cpr_auto_close_lot_' + oneModule.id" suffix=" jour(s)" [min]="0" [max]="5000"
              [showButtons]="true"
              buttonLayout="horizontal"
              incrementButtonIcon="pi pi-plus" 
              decrementButtonIcon="pi pi-minus"
              
            ></p-inputNumber>
          </div>

        </div>
        <div class="field-checkbox flex flex-column align-items-start gap-2">
          <p-checkbox class="mb-1" [binary]="true" [formControlName]="'cpr_img_force_' + oneModule.id" value="1"
            [label]="'Image obligatoire' | transloco"></p-checkbox>
          <p-checkbox class="mb-1" [binary]="true" [formControlName]="'cpr_temperature_' + oneModule.id" value="1"
            [label]="'Température' | transloco"></p-checkbox>
          <p-checkbox class="mb-1" [binary]="true" [formControlName]="'cpr_supplier_' + oneModule.id" value="1"
            [label]="'Fournisseur' | transloco"></p-checkbox>
          <p-checkbox class="mb-1" [binary]="true" [formControlName]="'cpr_output_' + oneModule.id" value="1"
            [label]="'Impression (output)' | transloco"></p-checkbox>
        </div>
        <div class="field-checkbox flex flex-column align-items-start gap-2">
          <div class="flex flex-row align-items-center gap-2">
            <label class="labelModuleRecep" [for]="'cpr_dlc_' + oneModule.id">{{ 'DLC' | transloco }}</label>
            <p-dropdown autoWidth="false" [style]="{ width: '100%' }" [options]="receptionsOptions" class="w-full"
              [formControlName]="'cpr_dlc_' + oneModule.id" [filter]="true" optionLabel="name"
              optionValue="id"></p-dropdown>
          </div>
          <div class="flex flex-row align-items-center gap-2">
            <label class="labelModuleRecep" [for]="'cpr_numlot_' + oneModule.id">{{ 'N° de lot fournisseur' | transloco
              }}</label>
            <p-dropdown autoWidth="false" [style]="{ width: '100%' }" [options]="receptionsOptions" class="w-full"
              [formControlName]="'cpr_numlot_' + oneModule.id" [filter]="true" optionLabel="name"
              optionValue="id"></p-dropdown>
          </div>
          <div class="flex flex-row align-items-center gap-2">
            <label class="labelModuleRecep" [for]="'cpr_tlo_' + oneModule.id">{{ 'Lot en sortie' | transloco }}</label>
            <p-dropdown autoWidth="false" [style]="{ width: '100%' }" [options]="receptionsOptions" class="w-full"
              [formControlName]="'cpr_tlo_' + oneModule.id" [filter]="true" optionLabel="name"
              optionValue="id"></p-dropdown>
          </div>
        </div>
      </div>
    </p-card>
    }
    }
  </div>

</div>