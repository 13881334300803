import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { CheckboxModule } from 'primeng/checkbox';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TableModule } from 'primeng/table';
import { ApiStatsService } from '../../../services/api/api-stats';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import moment from 'moment';
import { UtilsService } from '../../../services/utils/utils.service';

@Component({
    standalone: true,
    selector: 'app-statistic-dialog',
    templateUrl: './statistics-dialog.component.html',
    styleUrls: ['./statistics-dialog.component.scss'],
    imports: [
        ButtonModule,
        CheckboxModule,
        CommonModule,
        FormsModule,
        TableModule,
        TranslocoModule
    ]
})
export class StatisticsDialogComponent implements OnInit {
    datas = [];
    datasDetails = [];
    displayGlobal = true;
    periodCode: string = '';
    periodStart: Date = new Date();
    periodEnd: Date = new Date();
    varName: string = '';
    displayAll: boolean = false;

    labelAllTasks: string = '';
    lstLicences:any = [];

    colsTable: any = [];
    selectionMode: 'single' | null = null;

    constructor(
        private apiStatsService: ApiStatsService,
        public config: DynamicDialogConfig,
        public ref: DynamicDialogRef,
        private utilsService: UtilsService
    ) { }

    ngOnInit(): void {
        this.periodCode = this.config.data['periodCode'];
        this.periodStart = this.config.data['periodStart'];
        this.periodEnd = this.config.data['periodEnd'];
        this.varName = this.config.data['varName'];
        this.lstLicences = this.config.data['lstLicences'];

        switch (this.varName) {
            case 'temperatures':
                this.labelAllTasks = 'Afficher les températures réalisées à 100%';
                this.selectionMode = 'single';
                this.colsTable = [
                    { field: 'cli_name', header:'Licence', alignment:'text-left' },
                    { field: 'obj_name', header:'Objet', alignment:'text-left' },
                    { field: 'wrk_name', header:'Zone', alignment:'text-left' },
                    { field: 'tty_infinitif', header:'Action', alignment:'text-left' },
                    { field: 'percent_done', header:'% de validation', alignment:'text-right' }
                ];
                break;
            case 'tasks':
                this.labelAllTasks = 'Afficher les tâches réalisées à 100%';
                this.selectionMode = 'single';
                this.colsTable = [
                    { field: 'cli_name', header:'Licence', alignment:'text-left' },
                    { field: 'obj_name', header:'Objet', alignment:'text-left' },
                    { field: 'wrk_name', header:'Zone', alignment:'text-left' },
                    { field: 'tty_infinitif', header:'Action', alignment:'text-left' },
                    { field: 'percent_done', header:'% de validation', alignment:'text-right' }
                ];
                break;
            case 'ncs':
                this.labelAllTasks = 'Afficher les non conformités fermées';
                this.colsTable = [
                    { field: 'cli_name', header:'Licence', alignment:'text-left' },
                    { field: 'ano_create_date', header:'Date', alignment:'text-center'},
                    { field: 'ano_name', header:'Source de la NC', alignment:'text-left' },
                    { field: 'obj_name', header:'Objet', alignment:'text-left' },
                    { field: 'wrk_name', header:'Emplacement', alignment:'text-left' },
                    { field: 'ano_details', header:'Détails', alignment:'text-left' },
                    { field: 'usr_full_name', header:'Utilisateur', alignment:'text-left' },
                    { field: 'is_closed', header:'Fermée', alignment:'text-center' }
                ];
                break;
            case 'treatments':
                this.labelAllTasks = 'Afficher les process clôturés';
                this.colsTable = [
                    { field: 'cli_name', header:'Licence', alignment:'text-left' },
                    { field: 'tre_start_date', header:'Démarré le', alignment:'text-center'},
                    { field: 'tpr_name', header:'Process', alignment:'text-left' },
                    { field: 'usr_full_name', header:'Par', alignment:'text-left' },
                    { field: 'tre_tlo_name', header:'Produit', alignment:'text-left' },
                    { field: 'calc_column', header:'Clôturé', alignment:'text-center' }
                ];
                break;
            case 'lots':
                this.labelAllTasks = 'Afficher les process avec 100% des lots rattachés';
                this.colsTable = [
                    { field: 'cli_name', header:'Licence', alignment:'text-left' },
                    { field: 'tre_start_date', header:'Démarré le', alignment:'text-center'},
                    { field: 'tpr_name', header:'Process', alignment:'text-left' },
                    { field: 'usr_full_name', header:'Par', alignment:'text-left' },
                    { field: 'tre_tlo_name', header:'Produit', alignment:'text-left' },
                    { field: 'calc_column', header:'Lot(s) rattaché(s)', alignment:'text-center' }
                ];
                break;
            case 'photos':
                this.labelAllTasks = 'Afficher les process avec des photos rattachées';
                this.colsTable = [
                    { field: 'cli_name', header:'Licence', alignment:'text-left' },
                    { field: 'tre_start_date', header:'Démarré le', alignment:'text-center'},
                    { field: 'tpr_name', header:'Process', alignment:'text-left' },
                    { field: 'usr_full_name', header:'Par', alignment:'text-left' },
                    { field: 'tre_tlo_name', header:'Produit', alignment:'text-left' },
                    { field: 'calc_column', header:'Photo(s) rattachée(s)', alignment:'text-center' }
                ];
                break;
            default:
                break;
        }

        this.getDatas();
    }

    getDatas() {
        this.apiStatsService.getStatsDetails(this.varName, this.periodCode, this.periodStart, this.periodEnd, this.lstLicences, this.displayAll, null).subscribe((result: any) => {
            this.datas = result.map((oneLine:any) => {

                if(oneLine.percent_done) {
                  oneLine.percent_done = parseFloat(oneLine.percent_done);
                } 
                if(oneLine.is_closed) {
                    oneLine.is_closed = oneLine.is_closed == 1 ? 'Oui' : 'Non';
                }
                if(oneLine.ano_create_date) {
                    oneLine.ano_create_date = moment(new Date(oneLine.ano_create_date)).format('DD/MM/yyyy HH:mm');
                }
                if(oneLine.calc_column) {
                    oneLine.calc_column = oneLine.calc_column == 1 ? 'Oui' : 'Non';
                }
                if(oneLine.tre_start_date) {
                    oneLine.tre_start_date = moment(new Date(oneLine.tre_start_date)).format('DD/MM/yyyy HH:mm');
                }
                if(oneLine.tpr_name) {
                    oneLine.tpr_name = this.utilsService.cleanString(oneLine.tpr_name);
                }

                return oneLine;
            });
        });
    }

    backGlobal() {
        this.displayGlobal = true;
    }

    getDatasDetails(event: any) {
        if(event.data) {
            this.displayGlobal = false;
            this.apiStatsService.getStatsDetails(this.varName, this.periodCode, this.periodStart, this.periodEnd, this.lstLicences, this.displayAll, event.data).subscribe((result: any) => {
                this.datasDetails = result.map((oneLine:any) => {
    
                    oneLine.tas_done = oneLine.tas_done == 1 ? 'Oui' : 'Non';
    
                    return oneLine;
                });
            });
        }
    
    }

    newRowItem(event:any) {
        this.displayGlobal = false;
    }
    back() {
        this.displayGlobal = true;
    }
}
