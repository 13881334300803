import { Component } from '@angular/core';
import { ApiUsersService } from '../../../../services/api/api-users.service';
import { AffectLicencesComponent } from '../../../global/affect-licences/affect-licences.component';

@Component({
  imports: [
    AffectLicencesComponent 
  ],
  providers: [],
  standalone: true,
  selector: 'user-licences',
  template: `
    <app-affect-licences
      [getMethod]="apiUsersService.getUserLicences.bind(apiUsersService)"
      [moveMethod]="apiUsersService.moveLicence.bind(apiUsersService)"
    ></app-affect-licences>
  `
})

export class UsersLicencesComponent {
  constructor(public  apiUsersService: ApiUsersService) {}
}
