<div class="entete-sections">
    <div class="titre-menu">SIMPLIFIEZ<br>VOTRE QUOTIDIEN<br>AVEC ZEST HACCP</div><img
        src="assets/images/image-menu.png" alt="Zest HACCP" class="logo-menu-v4">
</div>

<div class="container-bloc-accueil">
    @for (item of items; track item) {
    <div class="col-bloc-accueil">
        <a [routerLink]="item.routerLink">
            <i [class]="item.icon"></i>
            {{ item.label | transloco }}
        </a>
    </div>
    }
</div>