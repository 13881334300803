import { Injectable } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { Client } from '../../models/client';
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root',
})
export class ApiGroupsService {

  groupsLicences: any[] = [];
  userLicences: Client[] = [];

  constructor(private http: HttpClient) {}

  // Fonction pour construire un arbre de TreeNode à partir de la liste des groupes
  buildTreeNode(fields: any[], parentId: string | null): TreeNode[] {

    const nodes = fields
        //.filter((field: any) => field.cgr_id in this.groupsLicences)  // Filtrer les champs pertinents
        .map((field:any) => {

              let children: any[] = [];

              if(field.childs) {
                children = this.buildTreeNode(field.childs, field.cgr_id);
              }

              if(field.cgr_id in this.groupsLicences) {
                //le groupe a des licences on les ajoute
                this.groupsLicences[field.cgr_id].forEach((oneLicenceId:string) => {
                  let oLicence: Client | undefined = this.userLicences.find((oneLicence:Client) => oneLicence.id === parseInt(oneLicenceId));

                  if(oLicence) {
                    children.push({
                        data: {
                            label: oLicence.displayName,
                            id: oLicence.id
                        },
                        type: 'licence',
                        key: oLicence.id,
                        icon: 'fa fa-store fa-xl',
                        label: oLicence.displayName,
                        expanded: false
                    });
                  }
                });

              }

              // Créer le noeud de groupe
              const node: TreeNode = {
                data: {
                    label: field.cgr_name,
                    id: field.cgr_id
                },
                type: 'groupe',
                key: field.cgr_id,
                icon: 'fa fa-folder fa-xl',
                label: field.cgr_name,
                expanded: false,
                children: children.length > 0 ? children : []
            };

            // Retourne le noeud s'il contient au moins un élément de type 'licence'
            //const hasLicenceChild = children.some(child => child.type === 'licence' || (child.children && child.children.length > 0));
            //return hasLicenceChild ? node : null;
          return node;
        })
        //.filter(node => node !== null); // Filtrer les noeuds nulls

    return nodes as TreeNode[];
  }

  convertToTreeNodes(groups: any[], groupsLicences: any[], userLicences: Client[]): TreeNode[] {
    this.groupsLicences = groupsLicences;
    this.userLicences = userLicences;

    let treeNodes = this.buildTreeNode(groups, null);

    return treeNodes;
  }

  deleteGroup(rowData: any): Observable<any> {
    const body = new URLSearchParams();
    for (const key in rowData) {
      if (rowData.hasOwnProperty(key)) {
        body.set(key, rowData[key]);
      }
    }
    return this.http.post<any>(
      environment.apiURL + 'espace_client/post_delete_group.php',
      body.toString(),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
    );
  }

  updateGroup(rowData: any): Observable<any> {
    const body = new URLSearchParams();
    for (const key in rowData) {
      if (rowData.hasOwnProperty(key)) {
        body.set(key, rowData[key]);
      }
    }
    return this.http.post<any>(
      environment.apiURL + 'espace_client/post_maj_group.php',
      body.toString(),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
    );
  }

  addGroup(rowData: any): Observable<any> {
    const body = new URLSearchParams();
    for (const key in rowData) {
      if (rowData.hasOwnProperty(key)) {
        body.set(key, rowData[key]);
      }
    }
    return this.http.post<any>(
      environment.apiURL + 'espace_client/post_add_group.php',
      body.toString(),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
    );
  }

  deleteOrNotLicence(rowData: any): Observable<any> {
    const body = new URLSearchParams();
    for (const key in rowData) {
      if (rowData.hasOwnProperty(key)) {
        body.set(key, rowData[key]);
      }
    }
    return this.http.post<any>(
      environment.apiURL + 'espace_client/post_maj_cancel_date_client.php',
      body.toString(),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
    );
  }

}
