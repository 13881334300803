@defer (when dataLoaded) {
    <p-table 
        [value]="licences" 
        styleClass="p-datatable-gridlines p-datatable-striped"
        [paginator]="true"
        [rows]="10"
        [showCurrentPageReport]="true"
        currentPageReportTemplate="{totalRecords} {{ 'élément(s)' | transloco }}"
        >
        <ng-template pTemplate="header">
            <tr>
                <th pSortableColumn="name">{{ 'Nom' | transloco }} 
                    <p-sortIcon field="name"></p-sortIcon>
                </th>
                <th pSortableColumn="city">{{ 'Ville' | transloco }} <p-sortIcon field="city"></p-sortIcon></th>
                <th pSortableColumn="lastLoginDate">{{ 'Date de dernière connexion' | transloco }} <p-sortIcon field="lastLoginDate"></p-sortIcon></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-licence>
            <tr>
                <td>{{ licence.name }}</td>
                <td>{{ licence.city }}</td>
                <td>{{ licence.lastLoginDate | date : "dd/MM/yyyy HH:mm" }}</td>
            </tr>
        </ng-template>
    </p-table>
}