import { Component, OnDestroy, OnInit} from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';

import { Subscription } from 'rxjs';

import { AuthService } from '../../../../services/auth/auth.service';

import { Client } from '../../../../models/client';

import {TranslocoModule, TranslocoService} from '@ngneat/transloco';

import { ButtonModule } from 'primeng/button';
import { MultiSelectModule } from 'primeng/multiselect';
import {Table, TableModule} from 'primeng/table';
import {TooltipModule} from "primeng/tooltip";
import { DropdownModule } from 'primeng/dropdown';
import { ChipsModule } from 'primeng/chips';
import {ToastModule} from "primeng/toast";
import { PanelModule } from 'primeng/panel';
import { InputNumberModule } from 'primeng/inputnumber';

import {ConfirmationService, MessageService} from 'primeng/api';
import {ConfirmDialogModule} from "primeng/confirmdialog";
import {ActivatedRoute, Router} from "@angular/router";
import {EditComponent} from "../../../core/edit/edit.component";
import {ReceptionListParameters} from "../../../../models/reception-list-parameters";
import {ApiReceptionListParametersService} from "../../../../services/api/api-reception-list-parameters.service";
import {ApiReceptionOutputListService} from "../../../../services/api/api-reception-output-list.service";
import {UtilsService} from "../../../../services/utils/utils.service";
import { ClientReceptionType } from '../../../../models/client-reception-type';

@Component({
  selector: 'app-process-list-reception',
  templateUrl: './process-list-reception.component.html',
  styleUrls: ['./process-list-reception.component.css'],
  standalone: true,
  imports: [
    ButtonModule,
    CommonModule,
    FormsModule,
    ConfirmDialogModule,
    MultiSelectModule,
    TableModule,
    TranslocoModule,
    TooltipModule,
    DropdownModule,
    ReactiveFormsModule,
    ChipsModule,
    ToastModule,
    PanelModule,
    InputNumberModule
  ],
  providers: [
    { provide: MessageService, useClass: MessageService },
    { provide: ConfirmationService, useClass: ConfirmationService },
  ],
})
export class ProcessListReceptionComponent extends EditComponent implements OnInit, OnDestroy {
  ReceptionListParametersSubscription: Subscription = new Subscription();
  ReceptionOutputTypeSubscription: Subscription = new Subscription();

  name: string = '';
  rowData: any;
  parameters!: ReceptionListParameters[];
  licences : Client[] = [];
  rpaId: any;
  cprId: any;
  cliId: any;
  processReceptionSelected: any;
  processReceptionisSelected = false;
  areButtonsDisabled = false;
  addEnabled = true;
  cancelEnabled = true;
  paramDlcByDefault: string = '';

  editForm: FormGroup;
  filteredTypes: { label: string, value: string }[];
  ouiNon: { label: string, value: number }[];
  ouiNonHisto: { label: string, value: number }[];
  longueur: { label: string, value: number|null }[];
  mode: { label: string, value: string|null }[];
  modeText: { label: string, value: number|string|null }[];
  longueurText: { label: string, value: number|string|null }[];
  listValues: string[] = [];
  default: any[] = [];
  firstList: string = '';
  setting= "";
  dropdownOutputReceptionType: { label: string, value: number }[] = [];


  constructor(
    private apiReceptionListParametersService: ApiReceptionListParametersService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private confirmationService: ConfirmationService,
    private translocoService: TranslocoService,
    private messageService: MessageService,
    private apiReceptionOutputListService: ApiReceptionOutputListService,
    private router: Router,
    private utilsService: UtilsService,
  ) {
    super(route);

    this.editForm = this.formBuilder.group({
      param_order: ['', Validators.required],
      param_name: ['', Validators.required],
      param_type: ['', Validators.required],
      param_dlc: [''],
      param_length: [''],
      param_mode: [''],
      param_list:  [''],
      param_default: [''],
      param_default_when: [''],
      param_validation: [''],
      param_placeHolder: [''],
      param_readOnly: [''],
      param_requis: [''],
    })
    this.filteredTypes = [];
    this.longueur = [];
    this.ouiNon = [];
    this.ouiNonHisto = [];
    this.mode = [];
    this.modeText = [];
    this.longueurText = [];
    this.dropdownOutputReceptionType = [];
  }

  override ngOnInit() {
    super.ngOnInit();
    this.cliId = this.route.snapshot.paramMap.get('cli_id') ?? '';
    this.cprId = this.route.snapshot.paramMap.get('cpr_id') ?? '';
    this.licences = this.authService.getUserLicences();

    this.filteredTypes = [
      { label: 'Texte', value: 'varchar' },
      { label: 'Nombre entier', value: 'int' },
      { label: 'Nombre decimal', value: 'float' },
      { label: 'Date', value: 'date' },
      { label: 'Heure', value: 'hour' },
      { label: 'Separateur', value: 'separator' },
      { label: 'Commentaire', value: 'comment' },
    ];
    this.ouiNon = [
      { label: 'Oui', value : 1 },
      { label: 'Non', value : 0 },
    ];
    this.longueur = [
      {label: '5', value: 5},
      {label: '10', value: 10},
      {label: '30', value: 30},
      {label: '50', value: 50}
    ];
    this.mode = [
      {label: 'Champ libre', value: null},
      {label: 'Variateur', value: 'touchspin'},
      {label: 'Horloge', value: 'clock'},
      {label: 'Calendrier',value: 'calendar'},
      {label: 'Liste', value: 'list'},
      {label: 'Table', value: 'table'},
    ];
    this.modeText = [
      {label: 'Champ libre', value: 'null'},
      {label: 'Liste', value: 'list'},
    ];
    this.longueurText = [
      {label: '5', value: 5},
      {label: '10', value: 10},
      {label: '30', value: 30},
      {label: '50', value: 50}
    ];

    this.ReceptionOutputTypeSubscription = this.apiReceptionOutputListService
      .getReceptionType(this.cliId)
      .subscribe((data: ClientReceptionType[]) => {
        this.dropdownOutputReceptionType = data.map(receptionType => ({
          label: receptionType.rty_name,
          value: receptionType.cpr_id
        }));
      })

    this.ReceptionListParametersSubscription = this.apiReceptionListParametersService
      .getReceptionListParameters(this.cliId, this.cprId)
      .subscribe((selectedProcess: ReceptionListParameters[]) => {
        this.parameters = selectedProcess;
      });

    this.dataLoaded = true;
  }

  accessToOuputs() {
    this.router.navigate(['settings/process/process-reception/process-list-reception-output-parameters/', this.cliId, this.cprId]);
  }

  displayTable(){
    this.setting = "Nouveau paramètre";
    this.processReceptionisSelected = true;
    this.areButtonsDisabled = true;
    this.cancelEnabled = false;
    this.addEnabled = false;
    this.listValues = [];
    this.processReceptionSelected = {};

    this.processReceptionSelected['rpa_id'] = '';
    this.editForm.controls['param_order'].setValue(1);
    this.editForm.controls['param_length'].setValue(5);
    this.editForm.controls['param_type'].setValue('varchar');
    this.editForm.controls['param_mode'].setValue('null');
    this.editForm.controls['param_list'].setValue(this.listValues);
    this.editForm.controls['param_default'].setValue('');
    this.editForm.controls['param_default_when'].setValue(0);
    this.editForm.controls['param_validation'].setValue(0);
    this.editForm.controls['param_placeHolder'].setValue('');
    this.editForm.controls['param_readOnly'].setValue(0);
    this.editForm.controls['param_requis'].setValue(0);
  }

  onRowSelect(event: any, rpaId: any) {
    this.setting = "Modification du paramètre";
    this.areButtonsDisabled = true;
    this.cancelEnabled = false;
    this.addEnabled = false;
    this.processReceptionisSelected = true;
    this.processReceptionSelected = event;

    for (const paramKey in event) {
      if (paramKey === "rpa_values_list" && event.rpa_values_list){
        this.firstList = event.rpa_values_list;
        this.listValues = this.firstList.split('|');
      }
      else{
        this.firstList = event.rpa_values_list;
      }
    }
    if (this.cliId !== '') {
      this.ReceptionListParametersSubscription = this.apiReceptionListParametersService
        .getReceptionListParameters(this.cliId, this.cprId)
        .subscribe((dataparam: ReceptionListParameters[]) => {
          this.rpaId = rpaId;
          for (const item of dataparam) {
            if (item.rpa_id == event.rpa_id) {
              this.editForm.controls['param_order'].setValue(event.rpa_order);
              this.editForm.controls['param_name'].setValue(event.rpa_name);
              this.editForm.controls['param_length'].setValue(event.rpa_length);
              this.editForm.controls['param_type'].setValue(event.rpa_type);
              this.editForm.controls['param_mode'].setValue(event.rpa_input_mode);
              this.editForm.controls['param_list'].setValue(this.listValues);
              this.editForm.controls['param_default'].setValue(event.rpa_default);
              this.editForm.controls['param_default_when'].setValue(event.rpa_default_when);
              this.editForm.controls['param_validation'].setValue(event.rpa_validation);
              this.editForm.controls['param_placeHolder'].setValue(event.rpa_placeholder);
              this.editForm.controls['param_readOnly'].setValue(event.rpa_readonly);
              this.editForm.controls['param_requis'].setValue(event.rpa_required);

              this.paramDlcByDefault = event.rpa_default;
            }
          }
        });
    }
  }

  typeChange() {
    this.utilsService.updateOptions(this.editForm.controls['param_mode'].value, this.mode,
      this.default, this.longueur,
      this.ouiNon, this.modeText, this.longueurText, this.editForm);
  }
  deleteReceptionParameter(processParameter: any) {
        this.confirmationService.confirm({
          message:
            this.translocoService.translate(
              'Vous êtes sur le point de supprimer définitivement ce paramètre !'),
          header: 'Suppression',
          acceptLabel: this.translocoService.translate('Oui'),
          rejectLabel: this.translocoService.translate('Non'),
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            this.apiReceptionListParametersService.deleteReceptionParameter(processParameter.rpa_id).subscribe((response) => {
              if (response) {
                this.parameters = this.parameters.filter(
                  (val: ReceptionListParameters) => val.rpa_id !== processParameter.rpa_id
                );
                console.log('Deletion OK');
              } else {
                console.log('Deletion KO');
              }
            });
          },
        });
      }
  cancel(){
    this.areButtonsDisabled = false;
    this.cancelEnabled = true;
    this.addEnabled = true;
    this.processReceptionisSelected = false;

    this.editForm.controls['param_order'].setValue('');
    this.editForm.controls['param_name'].setValue('');
    this.editForm.controls['param_length'].setValue('');
    this.editForm.controls['param_type'].setValue('');
    this.editForm.controls['param_mode'].setValue('');
    this.editForm.controls['param_list'].setValue('');
    this.editForm.controls['param_default'].setValue('');
    this.editForm.controls['param_default_when'].setValue('');
    this.editForm.controls['param_validation'].setValue('');
    this.editForm.controls['param_placeHolder'].setValue('');
    this.editForm.controls['param_readOnly'].setValue('');
    this.editForm.controls['param_requis'].setValue('');
  }

  updateModeOptions() {
    this.utilsService.updateModeOptions(this.editForm.controls['param_type'].value, this.mode,
      this.default, this.longueur,
      this.ouiNon, this.modeText, this.longueurText, this.editForm)
  }
  updateDLCoptions() {
    this.utilsService.updateDLCoptions( this.editForm.controls['rpa_is_dlc'], this.mode,
      this.default, this.longueur, this.editForm)
  }

  getTheList() {
    this.utilsService.getTheList(this.editForm , this.listValues)
  }

  save(): void {
    let formData = new FormData();
    if (this.processReceptionSelected?.rpa_id) {
      formData.append('rpa_id', this.processReceptionSelected['rpa_id']);
    } else {
      formData.append('rpa_id', '');
    }
    formData.append('cli_id', this.cliId);
    formData.append('cpr_id', this.cprId);
    formData.append('DelOrUpdate', '1');
    if (this.editForm.controls['param_default'].value == "") {
      this.editForm.patchValue({
        param_default: this.paramDlcByDefault
      });
    }
    const formControls = Object.keys(this.editForm.controls);
    formControls.forEach(controlName => {
      const control = this.editForm.get(controlName);
      if (control) {
        formData.append(controlName, control.value);
      }
    });
    if (this.editForm.controls['param_name'].value !== '' && this.editForm.controls['param_type'].value !== '') {
    this.apiReceptionListParametersService.updateReceptionParameter(formData).subscribe((response) => {
      if (response) {
        this.ReceptionListParametersSubscription = this.apiReceptionListParametersService
          .getReceptionListParameters(this.cliId, this.cprId)
          .subscribe((selectedProcess: ReceptionListParameters[]) => {
            this.parameters = selectedProcess;
          });
        this.cancel();

        this.ReceptionOutputTypeSubscription = this.apiReceptionOutputListService
          .getReceptionType(this.cliId)
          .subscribe((data: ClientReceptionType[]) => {
            this.dropdownOutputReceptionType = data.map(receptionType => ({
              label: receptionType.rty_name,
              value: receptionType.cpr_id
            }));
          })
        console.log('Update OK');
      }
       else {
        console.log('Update KO');
      }
    });
  } else {
      this.messageService.add({ severity: 'warn', summary: 'Attention', detail: 'Tous les champs marqués d\'un astérisque sont obligatoires.' });
    }
  }
  ngOnDestroy() {
    this.ReceptionListParametersSubscription.unsubscribe();
  }
}

