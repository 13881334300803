import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { Router } from '@angular/router';

import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';

import { Subscription } from 'rxjs';
import { ConfirmationService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { MultiSelectModule } from 'primeng/multiselect';
import { ApiSurveysService } from '../../../../services/api/api-surveys.service';
import { Survey } from '../../../../models/survey';
import { FormsModule } from '@angular/forms';
import { Client } from '../../../../models/client';
import { AuthService } from '../../../../services/auth/auth.service';
import { PanelModule } from 'primeng/panel';

@Component({
  selector: 'app-surveys-list',
  templateUrl: './surveys-list.component.html',
  styleUrl: './surveys-list.component.css',
  standalone: true,
  imports: [ButtonModule, FormsModule, MultiSelectModule, PanelModule, TableModule, TranslocoModule, ConfirmDialogModule],
  providers: [ConfirmationService],
})
export class SurveysListComponent implements OnInit, OnDestroy {
  surveys: Survey[] = [];
  dataLoaded: boolean = false;
  indicSubscription: Subscription = new Subscription();
  licences : Client[] = [];

  constructor(
    private apiSurveysService: ApiSurveysService,
    private authService: AuthService,
    private confirmationService: ConfirmationService,
    private router: Router,
    private translocoService: TranslocoService
  ) {}

  ngOnInit() {
    this.licences = this.authService.getUserLicences();
    this.indicSubscription = this.apiSurveysService
      .getSurveys()
      .subscribe((data: Survey[]) => {
        this.surveys = data;
        this.dataLoaded = true;
      });
  }

  loadSurveys() {
    
  }
  ngOnDestroy() {
    this.indicSubscription.unsubscribe();
  }

  addSurvey() {
    this.router.navigate(['settings/surveys-add']);
  }

  editSurvey(indicData: Survey) {
    this.router.navigate(['settings/surveys-edit', indicData.id]);
  }

  canWrite(): boolean {
    return this.authService.canWrite();
  }

  cloneSurvey(surveyData: Survey) {
    this.confirmationService.confirm({
      message:
        this.translocoService.translate(
          'Confirmez-vous la copie de l\'audit'
        ) +
        ' "' +
        surveyData.name +
        '" ?',
      header: 'Copie',
      acceptLabel: this.translocoService.translate('Oui'),
      rejectLabel: this.translocoService.translate('Non'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.apiSurveysService
          .cloneSurvey(surveyData.id)
          .subscribe((response: any) => {
            if (response) {
              this.router.navigate(['settings/surveys-edit', response]);
            }
          });
      },
    });
  }
  
  deleteSurvey(surveyData: Survey) {
    this.confirmationService.confirm({
      message:
        this.translocoService.translate(
          'Confirmez-vous la suppression de l\'audit'
        ) +
        ' "' +
        surveyData.name +
        '" ?',
      header: 'Suppression',
      acceptLabel: this.translocoService.translate('Oui'),
      rejectLabel: this.translocoService.translate('Non'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.apiSurveysService
          .deleteSurvey(surveyData.id)
          .subscribe((response: any) => {
            if (response) {
              this.surveys = this.surveys.filter(
                (val: Survey) => val.id !== surveyData.id
              );
            }
          });
      },
    });
  }
}
