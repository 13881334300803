import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { Indicator } from '../../models/indicator';
import { IndicatorFieldType } from '../../models/indicator-field-type';
import { IndicatorField } from '../../models/indicator-field';
import { TreeNode } from 'primeng/api';
import { User } from '../../models/user';
import moment from 'moment';
import { IndicatorUser } from '../../models/indicator-user';
import { Survey } from '../../models/survey';
import { Client } from '../../models/client';
import { SurveyPage } from '../../models/survey-page';
import { SurveyQuestion } from '../../models/survey-question';

@Injectable({
  providedIn: 'root',
})
export class ApiSurveysService {
  constructor(private http: HttpClient) {}

  postSurvey(formData: FormData): Observable<any> {
    return this.http.post<any>(
      environment.apiURL + 'espace_client/post_survey.php',
      formData
    );
  }

  getSurvey(surveyId:string): Observable<Survey> {
    return this.http
      .get<any>(
        environment.apiURL +
          'espace_client/get_surveys.php?surveyId=' + surveyId
      )
      .pipe(
        map((dataSurvey: any) => {
          const pages: SurveyPage[] = dataSurvey.pages.map((pageData: any) => {
            return new SurveyPage(
              parseInt(pageData.spa_id),
              pageData.spa_name,
              parseInt(pageData.spa_order)
            );
          });

          return new Survey(
            parseInt(dataSurvey.sur_id),
            dataSurvey.sur_name,
            dataSurvey.sur_comment,
            new Client(parseInt(dataSurvey.sur_cli_id), dataSurvey.cli_name),
            pages
          );
        })
      );
  }

  getSurveys(): Observable<Survey[]> {
    return this.http
      .get<any>(
        environment.apiURL +
          'espace_client/get_surveys.php'
      )
      .pipe(
        map((response: any[]) => {
          return response.map((dataSurvey) => {
            return new Survey(
              parseInt(dataSurvey.sur_id),
              dataSurvey.sur_name,
              dataSurvey.sur_comment,
              new Client(parseInt(dataSurvey.sur_cli_id), dataSurvey.cli_name)
            );
          });
        }),
        catchError((error) => {
          return of([]);
        })
      );
  }

  deleteSurvey(surveyId: number): Observable<any> {
    return this.http.get<any>(
      environment.apiURL +
        'espace_client/delete_survey.php?surveyId=' +
        surveyId
    );
  }

  postSurveyPage(formData: FormData): Observable<any> {
    return this.http.post<any>(
      environment.apiURL + 'espace_client/post_survey_page.php',
      formData
    );
  }

  getPage(pageId:string): Observable<SurveyPage> {
    return this.http
      .get<any>(
        environment.apiURL +
          'espace_client/get_surveys_page.php?pageId=' + pageId
      )
      .pipe(
        map((dataPage: any) => {


          const questions: SurveyQuestion[] = dataPage.questions.map((dataQuestion: any) => {
            return new SurveyQuestion(
              parseInt(dataQuestion.que_id),
              dataQuestion.que_name,
              dataQuestion.que_reference,
              parseInt(dataQuestion.que_order),
              parseInt(dataQuestion.que_qty_id),
              parseInt(dataQuestion.que_imo_id),
              dataQuestion.que_place_holder,
              dataQuestion.que_default,
              parseInt(dataQuestion.que_required),
              dataQuestion.que_values_list
            );
          });

          return new SurveyPage(
            parseInt(dataPage.spa_id),
            dataPage.spa_name,
            parseInt(dataPage.spa_order),
            questions
          );          
        })
      );
  }
  deletePage(pageId: number): Observable<any> {
    return this.http.get<any>(
      environment.apiURL +
        'espace_client/delete_survey_page.php?pageId=' +
        pageId
    );
  }
  postSurveyQuestion(formData: FormData): Observable<any> {
    return this.http.post<any>(
      environment.apiURL + 'espace_client/post_survey_question.php',
      formData
    );
  }
  getQuestion(questionId:string): Observable<SurveyQuestion> {
    return this.http
      .get<any>(
        environment.apiURL +
          'espace_client/get_surveys_question.php?questionId=' + questionId
      )
      .pipe(
        map((dataQuestion: any) => {

          return new SurveyQuestion(
            parseInt(dataQuestion.que_id),
              dataQuestion.que_name,
              dataQuestion.que_reference,
              parseInt(dataQuestion.que_order),
              parseInt(dataQuestion.que_qty_id),
              parseInt(dataQuestion.que_imo_id),
              dataQuestion.que_place_holder,
              dataQuestion.que_default,
              parseInt(dataQuestion.que_required),
              dataQuestion.que_values_list
          );          
        })
      );
  }
  deleteQuestion(questionId: number): Observable<any> {
    return this.http.get<any>(
      environment.apiURL +
        'espace_client/delete_survey_question.php?questionId=' +
        questionId
    );
  }
  cloneQuestion(questionId: number, pageId:string): Observable<any> {
    return this.http.get<any>(
      environment.apiURL +
        'espace_client/post_clone_survey_question.php?questionId=' +
        questionId + '&pageId=' + pageId
    );
  }
  clonePage(pageId: number, surveyId:string): Observable<any> {
    return this.http.get<any>(
      environment.apiURL +
        'espace_client/post_clone_survey_page.php?pageId=' +
        pageId + '&surveyId=' + surveyId
    );
  }
  cloneSurvey(surveyId:number): Observable<any> {
    return this.http.get<any>(
      environment.apiURL +
        'espace_client/post_clone_survey.php?surveyId=' + surveyId
    );
  }
}
