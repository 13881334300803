@defer (when dataLoaded) {
<div>
  <p-panel [header]="'Ajout/Modification des paramètres' | transloco">
  <form [formGroup]="editForm">
    <div class="formgrid grid">
      <div class="col text-left" style="margin-bottom: 15px;">
        <button
        pButton
        [label]="'Accéder aux outputs' | transloco"
        icon="pi pi-print"
        class="p-button-success"
        (click)="accessToOutputs()"
        [disabled]="!addEnabled"
        ></button>
      </div>
      <div class="col text-right" style="margin-bottom: 15px;">
        <button
        pButton
        [label]="'Ajouter un Paramètre' | transloco"
        icon="pi pi-plus"
        class="p-button-success"
        (click)="addParameter()"
        [disabled]="!addEnabled"
        ></button>
      </div>
    </div>

  <div>
  <p-table
    [value]="parameters"
    [resizableColumns]="true"
    selectionMode="single"
    [tableStyle]="{'align-item': 'center', 'min-width': '50px', 'max-width': '100%'}"
    styleClass="p-datatable-gridlines p-datatable-striped"
    stateStorage="session"
    stateKey="processListParameters-session"
    dataKey="id"
    [(selection)]="processSelected"
    [paginator]="true"
    [rows]="20"
    [showCurrentPageReport]="false"
  >
  <ng-template pTemplate="header">
    <tr>
      <th pSortableColumn="tpa_order" style="width:20%">
        {{ 'Ordre' | transloco }} <p-sortIcon field="tpa_order" />
      </th>
      <th pResizableColumn>{{ 'Nom' | transloco }}</th>
      <th pResizableColumn>{{ 'Type' | transloco }}</th>
      <th pResizableColumn>{{ 'Longueur' | transloco }}</th>
      <th pResizableColumn>{{ 'Mode' | transloco }}</th>
      <th pResizableColumn>{{ 'Liste' | transloco }}</th>
      <th pResizableColumn>{{ 'Defaut' | transloco }}</th>
      <th pResizableColumn>{{ 'Default time' | transloco }}</th>
      <th pResizableColumn>{{ 'Validation' | transloco }}</th>
      <th pResizableColumn>{{ 'Placeholder' | transloco }}</th>
      <th pResizableColumn>{{ 'Readonly' | transloco }}</th>
      <th pResizableColumn>{{ 'Requis' | transloco }}</th>
      <th pResizableColumn>{{ 'DLC' | transloco }}</th>
      <th pResizableColumn>{{ 'Date future' | transloco }}</th>
      <th pResizableColumn>{{ 'Dans tableau Histo ?' | transloco }}</th>
      <th style="min-width:10%">{{ 'Action' | transloco}}</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-processSelected let-ri="rowIndex"
               style="{'align-item': 'center'}">
    <tr>
      <td class="text-left">{{processSelected.tpa_order}}</td>
      <td class="text-left">{{processSelected.tpa_name_clean}}</td>
      <td class="text-left">{{processSelected.tpa_type}}</td>
      <td class="text-left">{{processSelected.tpa_length}}</td>
      <td class="text-left">{{processSelected.tpa_input_mode}}</td>
      <td class="text-left" style="max-width: 100px">{{processSelected.tpa_values_list}}</td>
      <td class="text-left" style="max-width: 100px">{{processSelected.tpa_default}}</td>
      <td class="text-left">{{processSelected.tpa_default_when}}</td>
      <td class="text-left">{{processSelected.tpa_validation}}</td>
      <td class="text-left">{{processSelected.tpa_placeholder}}</td>
      <td class="text-left">{{processSelected.tpa_readonly}}</td>
      <td class="text-left">{{ processSelected.tpa_required == 0 ? 'Non' : 'Oui' }}</td>
      <td class="text-left">{{processSelected.tpa_is_dlc}}</td>
      <td class="text-left">{{processSelected.tpa_future_date}}</td>
      <td class="text-left">{{processSelected.tpa_display_in_table}}</td>
      <td>
        <div class="flex align-items-center justify-content-center gap-2">
          <button
            pButton
            pRipple
            type="button"
            icon="pi pi-pencil"
            class="flex p-button-rounded p-button-success mr-2"
            [pTooltip]="'Modifier ce paramètre' | transloco"
            tooltipPosition="left"
            (click)="onRowSelect(processSelected, processSelected.tpa_id)"
            [disabled]="areButtonsDisabled"
            >
          </button>
          <button
            pButton
            icon="pi pi-trash"
            class="flex p-button-rounded p-button-unactivate mr-2"
            (click)="deleteProcessParameter(processSelected)"
            [pTooltip]="'Supprimer ce paramètre' | transloco"
            tooltipPosition="left"
            [disabled]="areButtonsDisabled"
          ></button>
        </div>
      </td>
    </tr>
  </ng-template>
</p-table>
</div>

  <div *ngIf="processisSelected" class="py-5">
<p-panel [header]='setting | transloco'>
    <form [formGroup]="editForm" (ngSubmit)="save()">
      <div class="formgrid grid">
        <div class="field col-6 md:col-6 xl:col-6">
            <label for="param_order" class="label-required">{{ "Ordre" | transloco }}</label>
            <br />
            <p-inputNumber
              inputId="param_order"
              formControlName="param_order"
              [min]="1"
              [max]="100"
              [step]=1
              [showButtons]="true"
              buttonLayout="horizontal"
              incrementButtonIcon="pi pi-plus" 
              decrementButtonIcon="pi pi-minus"
              [required]="true"
            ></p-inputNumber>
            @if(isErrorFormControl(editForm.controls['param_order'])) {
            <br /><small class="text-red-500">{{
            "Ordre requis" | transloco
            }}</small>
            }
      </div>
      <div class="field col-12"></div>
      <div class="field col-6 md:col-6 xl:col-6">
        <label for="param_name" class="label-required">{{ "Nom" | transloco }}</label>
        <br />
        <input
          type="text"
          pInputText
          formControlName="param_name"
          [required]="true"
          class="p-inputtext w-full mr-2"
        />
        @if(isErrorFormControl(editForm.controls['param_name'])) {
        <br /><small class="text-red-500">{{ "Nom requis" | transloco }}</small>
        }
      </div>
      <div class="field col-2 md:col-3 xl:col-3">
        <label for="type" class="label-required">{{
          "Type" | transloco
          }}</label>
          <p-dropdown
            [style]="{ width: '100%' }"
            [options]="filteredTypes"
            class="w-full"
            formControlName="param_type"
            [filter]="true"
            optionLabel="label"
            optionValue="value"
            [required]="true"
            (onChange)= "typeChange()"
          ></p-dropdown>
            @if(isErrorFormControl(editForm.controls['param_type'])) {
            <small class="text-red-500">{{ "Type requis" | transloco }}</small>
            }
      </div>
      <div *ngIf="editForm.controls['param_type'].value == 'date'" class="field col-6 md:col-6 xl:col-6">
        <label for="param_dlc" class="label-required">{{
          "DLC ?" | transloco
          }}</label>
        <p-dropdown
          [style]="{ width: '100%' }"
          [options]="ouiNonHisto"
          class="w-full"
          formControlName="param_dlc"
          [required]="true"
          optionLabel="label"
          optionValue="value"
        ></p-dropdown>
        @if(isErrorFormControl(editForm.controls['param_dlc'])) {
        <br /><small class="text-red-500">{{
        "Mention obligatoire" | transloco
        }}</small>
        }
      </div>
    </div>
    <div class="formgrid grid">
      <div *ngIf="editForm.controls['param_type'].value == 'int'
            || editForm.controls['param_type'].value == 'varchar'" class="field col-6 md:col-6 xl:col-6">
        <label for="longueur">{{
          "Longueur" | transloco
          }}</label>
        <p-dropdown
          [style]="{ width: '100%' }"
          [options]="longueurText"
          class="w-full"
          formControlName="param_length"
          optionLabel="label"
          optionValue="value"
        ></p-dropdown>
      </div>
      <div *ngIf="editForm.controls['param_type'].value == 'varchar'" class="field field col-6 md:col-6 xl:col-6">
        <label for="mode">{{
          "Mode" | transloco
          }}</label>
        <p-dropdown
          [style]="{ width: '100%' }"
          [options]="modeText"
          class="w-full"
          formControlName="param_mode"
          [filter]="true"
          optionLabel="label"
          optionValue="value"
        ></p-dropdown>
        @if(isErrorFormControl(editForm.controls['param_mode'])) {
        <small class="text-red-500">{{ "Mode requis" | transloco }}</small>
        }
      </div>
      <div *ngIf="editForm.controls['param_mode'].value === 'list'" class="field col-12">
        <label for="param_list">{{ "Liste" | transloco }}</label>
        <br />
        <p-chips [(ngModel)]="listValues" separator="," [ngModelOptions]="{standalone: true}"
                 (onBlur)="getTheList()"></p-chips>
      </div>
    </div>
    <div class="formgrid grid">
      <div *ngIf="editForm.controls['param_dlc'].value === 1" class="field col-3 md:col-3 xl:col-3">
        <label for="param_default">{{
          "Nb de jours de DLC par défaut" | transloco
          }}</label>
            <br />
            <p-inputNumber
              inputId="param_default"
              formControlName="param_default"
              [min]="1"
              [max]="100"
              [step]=1
              [showButtons]="true"
              buttonLayout="horizontal"
              incrementButtonIcon="pi pi-plus" 
              decrementButtonIcon="pi pi-minus"
              [required]="true"
            ></p-inputNumber>
      </div>
      <div *ngIf="editForm.controls['param_type'].value !== 'varchar'
            && editForm.controls['param_type'].value !== 'separator'
            && editForm.controls['param_type'].value !== 'comment'" class="field col-3 md:col-3 xl:col-3">
        <label for="param_default_when">{{
          "Temps par defaut" | transloco
          }}</label>
          <br />
            <p-inputNumber
            inputId="param_default_when"
            formControlName="param_default_when"
            [min]="1"
            [max]="100"
            [step]=1
            [showButtons]="true"
            buttonLayout="horizontal"
            incrementButtonIcon="pi pi-plus" 
            decrementButtonIcon="pi pi-minus"
            [required]="true"
          ></p-inputNumber>
      </div>
    </div>
    <div class="formgrid grid">
      <div *ngIf="editForm.controls['param_type'].value !== 'varchar'
      && editForm.controls['param_type'].value !== 'separator'
      && editForm.controls['param_type'].value !== 'comment'" class="field col-6 md:col-6 xl:col-6">
      <label for="validation">{{
      "Critère de Validation" | transloco
      }}</label>
      <input
      type="text"
      pInputText
      formControlName="param_validation"
      class="p-inputtext w-full mr-2"
      />
      </div>
    </div>
    <div class="formgrid grid">
      <div *ngIf="editForm.controls['param_type'].value !== 'separator'
            && editForm.controls['param_type'].value !== 'comment'" class="field col-6 md:col-6 xl:col-6">
        <label for="param_readOnly">{{
          "Lecture seule ?" | transloco
          }}</label>
        <p-dropdown
          [style]="{ width: '100%' }"
          [options]="ouiNonHisto"
          class="w-full"
          formControlName="param_readOnly"
          optionLabel="label"
          optionValue="value"
        ></p-dropdown>
      </div>
      <div *ngIf="editForm.controls['param_type'].value !== 'separator'
            && editForm.controls['param_type'].value !== 'comment'" class="field col-6 md:col-6 xl:col-6">
        <label for="param_requis">{{
          "Requis ?" | transloco
          }}</label>
        <p-dropdown
          [style]="{ width: '100%' }"
          [options]="ouiNon"
          class="w-full"
          formControlName="param_requis"
          optionLabel="label"
          optionValue="value"
        ></p-dropdown>
      </div>
      <div *ngIf="editForm.controls['param_type'].value == 'date'" class="field col-6 md:col-6 xl:col-6">
        <label for="param_dateFuture">{{
          "Date future ?" | transloco
          }}</label>
        <p-dropdown
          [style]="{ width: '100%' }"
          [options]="ouiNonHisto"
          class="w-full"
          formControlName="param_dateFuture"
          optionLabel="label"
          optionValue="value"
        ></p-dropdown>
      </div>
      <div *ngIf="editForm.controls['param_type'].value !== 'separator'
            && editForm.controls['param_type'].value !== 'comment'" class="field col-6 md:col-6 xl:col-6">
        <label for="param_displayInTable">{{
          "Afficher dans le tableau Histo ?" | transloco
          }}</label>
        <p-dropdown
          [style]="{ width: '100%' }"
          [options]="ouiNonHisto"
          class="w-full"
          formControlName="param_displayInTable"
          optionLabel="label"
          optionValue="value"
        ></p-dropdown>
      </div>
    </div>
    <div class="formgrid grid">
      <div *ngIf="editForm.controls['param_type'].value !== 'separator'
            && editForm.controls['param_type'].value !== 'comment'" class="field col-6 md:col-6 xl:col-6">
        <label for="placeHolder">{{
          "Valeur exemple" | transloco
          }}</label>
        <input
          type="text"
          pInputText
          formControlName="param_placeHolder"
          class="p-inputtext w-full mr-2"
        />
      </div>
    </div>
    <div class="grid py-1">
      <div class="col text-center">
        <button
          pButton
          [label]="'Annuler' | transloco"
          icon="pi pi-minus"
          class="p-button-unactivate"
          (click)="cancel()"
          [disabled]="cancelButtonDisabled"
        ></button>
        <button
          pButton
          [label]="'Enregistrer' | transloco"
          icon="pi pi-save"
          class="p-button-success"
          style = "margin-left: 20px;"
          [disabled]="!editForm.valid"
        ></button>
      </div>
    </div>
  </form>
  </p-panel>
</div>
  </form>
</p-panel>
</div>
}@placeholder {
<div class="text-center">{{ 'Chargement en cours...' | transloco }}</div>
}
<p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>
