import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { TranslocoModule, TranslocoService } from '@ngneat/transloco';

import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { DataViewModule } from 'primeng/dataview';
import { FieldsetModule } from 'primeng/fieldset';
import { GalleriaModule } from 'primeng/galleria';
import { InputNumberModule } from 'primeng/inputnumber';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';

import { Subscription } from 'rxjs';

import { ApiEshopService } from '../../../services/api/api-eshop.service';
import { EshopProduct } from '../../../models/eshop-product';
import { UtilsService } from '../../../services/utils/utils.service';
import { CartService } from '../../../services/eshop/cart.service';
import { AuthService } from '../../../services/auth/auth.service';
import { ConfirmDialogModule } from 'primeng/confirmdialog';

@Component({
  selector: 'app-eshop-product',
  templateUrl: './eshop-product.component.html',
  styleUrl: './eshop-product.component.css',
  standalone: true,
  imports: [
    ButtonModule,
    CardModule,
    CommonModule,
    ConfirmDialogModule,
    DataViewModule,
    FieldsetModule,
    FormsModule,
    GalleriaModule,
    InputNumberModule,
    ToastModule,
    TranslocoModule,
  ],
  providers:[ConfirmationService, MessageService]
})

export class EshopProductComponent implements OnDestroy, OnInit {
  
  productSubscription: Subscription = new Subscription();
  oneProduct: EshopProduct | null = null;
  dataLoaded: boolean = false;

  productId:string = '';
  otherProducts: EshopProduct[] = [];

  sanitizedDescription: SafeHtml = '';
  qtyOrder:number = 1;

  photos:any[] = [];


  constructor(
    private activeRoute: ActivatedRoute,
    private apiEshopService: ApiEshopService,
    private authService: AuthService,
    private cartService: CartService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private translocoService: TranslocoService,
    private utilsService: UtilsService
  ) {}

  ngOnInit(): void {
    this.activeRoute.paramMap.subscribe(params => {
      this.productId = params.get('id') ?? '';
  
      if (this.productId !== '') {
        this.loadProductData(this.productId);
      }
    });
  }
  isRoot(): boolean {
    return this.authService.isRoot();
  }

  loadProductData(productId: string): void {
    this.productSubscription = this.apiEshopService
      .getProducts()
      .subscribe((data: EshopProduct[]) => {
        this.oneProduct = data.find((oProduct) => oProduct.id == parseInt(productId)) ?? null;
        if (this.oneProduct) {
          this.sanitizedDescription = this.sanitizer.bypassSecurityTrustHtml(this.oneProduct.description);
  
          this.photos = []; // Réinitialiser les photos
          if (this.oneProduct.photo1 !== '') {
            this.photos.push(this.oneProduct.photo1);
          }
          if (this.oneProduct.photo2 !== '') {
            this.photos.push(this.oneProduct.photo2);
          }
          if (this.oneProduct.photo3 !== '') {
            this.photos.push(this.oneProduct.photo3);
          }
        }
  
        let filterProduct = data.filter((oProduct) => oProduct.id != parseInt(productId));
  
        let shuffledProducts = this.utilsService.shuffleArray(filterProduct);
  
        if (shuffledProducts.length >= 4) {
          shuffledProducts = shuffledProducts.slice(0, 4);
        }
  
        this.otherProducts = shuffledProducts;
        this.dataLoaded = true;
      });
  }

  ngOnDestroy(): void {
    this.productSubscription.unsubscribe();
  }
  
  addToCart(): void {
    if(this.oneProduct) {
      this.addProductToCart(this.oneProduct.id, this.qtyOrder);
    }
  }

  addProductToCart(productId:number, quantity:number) {
    this.cartService.addToCart(productId, quantity);

    this.messageService.add({ 
      severity: 'success', 
      summary: this.translocoService.translate("Succès"), 
      detail: this.translocoService.translate("Produit ajouté au panier avec succès") 
    });
  }

  redirectProduct(oneProduct: EshopProduct) {
    this.router.navigate(['eshop/product/', oneProduct.id, oneProduct.titleForUrl]);
  }

  editProduct() {
    if(this.oneProduct) {
      this.router.navigate(['eshop/product-edit/', this.oneProduct.id]);
    }
  }
  deleteProduct() {
    this.confirmationService.confirm({
      message: this.translocoService.translate('Confirmez-vous la suppression du produit ?'),
      header: 'Suppression',
      acceptLabel: this.translocoService.translate('Oui'),
      rejectLabel: this.translocoService.translate('Non'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        if(this.oneProduct) {
          this.apiEshopService
            .deleteProduct(this.oneProduct.id)
            .subscribe((response: any) => {
              if (response) {
                this.router.navigate(['eshop/all']);
              }
            });
        }
      },
    });
  }
}
