@defer (when dataLoaded) {
<div class="grid">
  <div class="col text-right">
    <button
      pButton
      [label]="'Ajouter un audit' | transloco"
      icon="pi pi-plus"
      class="p-button-success"
      (click)="addSurvey()"
    ></button>
  </div>
</div>
<p-table
  #dt
  [value]="surveys"
  styleClass="p-datatable-gridlines p-datatable-striped"
  stateStorage="session"
  stateKey="surveys-list-session"
  [paginator]="true"
  [rows]="10"
  [showCurrentPageReport]="true"
  currentPageReportTemplate="Lignes {first} à {last} sur un total de {totalRecords}"
>
  <ng-template pTemplate="header">
    <tr>
      <th pSortableColumn="client.name">{{ 'Licence' | transloco }} <p-sortIcon field="client.name"></p-sortIcon></th>            
      <th pSortableColumn="name">{{ "Titre" | transloco }}<p-sortIcon field="name"></p-sortIcon></th>
      @if(canWrite()) {
        <th></th>
      }
    </tr>
    <tr>
      <th>
          <p-columnFilter field="client.id" matchMode="in" [showMenu]="false">
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                  <p-multiSelect 
                      [ngModel]="value" 
                      [options]="licences" 
                      placeholder="Toutes" 
                      (onChange)="filter($event.value)" 
                      optionLabel="displayName" 
                      optionValue="id"
                      [virtualScroll]="true"
                      [virtualScrollItemSize]="50"
                      class="liste-recherche-table"
                      >
                  </p-multiSelect>
              </ng-template>
          </p-columnFilter>
      </th>
      <th>
          <p-columnFilter type="text" field="name" matchMode="contains"></p-columnFilter>
      </th>
      @if(canWrite()) {
        <th></th>
      }
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-survey>
    <tr>
      <td>{{ survey.client.name }}</td>
      <td>{{ survey.name }}</td>
      @if(canWrite()) {
        <td class="flex align-items-center justify-content-center">
          <button
            pButton
            icon="pi pi-pencil"
            class="flex p-button-rounded p-button-success mr-2"
            (click)="editSurvey(survey)"
          ></button>
          <button pButton icon="pi pi-trash" class="flex p-button-rounded p-button-warning" (click)="deleteIndicator(survey)"></button>
        </td>
      }
    </tr>
  </ng-template>
</p-table>
<p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>
} @placeholder {
<div class="text-center">{{ "Chargement en cours..." | transloco }}</div>
}
