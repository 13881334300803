import { Component, OnDestroy, OnInit} from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import { Subscription } from 'rxjs';

import {ApiReceptionOutputListService} from "../../../../services/api/api-reception-output-list.service";
import { AuthService } from '../../../../services/auth/auth.service';

import { Client } from '../../../../models/client';
import { ReceptionOutputList } from '../../../../models/reception-output-list';
import { ReceptionOutputType } from '../../../../models/reception-output-type';

import {TranslocoModule, TranslocoService} from '@ngneat/transloco';

import { ButtonModule } from 'primeng/button';
import { MultiSelectModule } from 'primeng/multiselect';
import {Table, TableModule } from 'primeng/table';
import {TooltipModule} from "primeng/tooltip";
import { DropdownModule } from 'primeng/dropdown';
import {ConfirmationService, MessageService} from 'primeng/api';
import {ConfirmDialogModule} from "primeng/confirmdialog";
import { Router} from "@angular/router";
import { InputNumberModule } from 'primeng/inputnumber';
import { ClientReceptionType } from '../../../../models/client-reception-type';

@Component({
  selector: 'app-process-reception',
  templateUrl: './process-list-reception-output.component.html',
  styleUrl: './process-list-reception-output.component.css',
  providers: [MessageService, ConfirmationService],
  standalone: true,
  imports: [
    ButtonModule,
    CommonModule,
    FormsModule,
    ConfirmDialogModule,
    MultiSelectModule,
    TableModule,
    TranslocoModule,
    TooltipModule,
    DropdownModule,
    ReactiveFormsModule,
    InputNumberModule
  ],
})
export class ProcessListReceptionOutputComponent implements OnDestroy, OnInit {

  ReceptionOutputListSubscription: Subscription = new Subscription();
  OutputTypeSubscription: Subscription = new Subscription();
  ReceptionTypeSubscription: Subscription = new Subscription();
  ReceptionOutputPathSubscription: Subscription = new Subscription();

  receptionOutputList: ReceptionOutputList[] = [];
  licences: Client[] = [];
  outputType: ReceptionOutputType[] = [];
  dropdownOutputType: { label: string, value: number }[] = [];
  receptionType: ClientReceptionType[] = [];
  dropdownReceptionType: { label: string, value: number }[] = [];
  path:any;
  uniqueReceptionOutputList: ReceptionOutputList[] = [];

  yesNoOptions: { label: string, value: number }[] = [
    {label: 'OUI', value: 1},
    {label: 'NON', value: 0}
  ];

  selectedOutput: any[] = [];
  dataLoaded: boolean = false;

  outputs: Array<{ rou_order: number }> = [];

  constructor(
    private authService: AuthService,
    private apiReceptionOutputListService: ApiReceptionOutputListService,
    private translocoService: TranslocoService,
    private confirmationService: ConfirmationService,
    private router: Router,
  ) {
    this.path = [];
  }

  ngOnInit(): void {
    // chargement des process de chaque licences
    this.ReceptionOutputListSubscription = this.apiReceptionOutputListService
      .getReceptionOutputList()
      .subscribe((data: ReceptionOutputList[]) => {
        this.receptionOutputList = data;
        this.uniqueReceptionOutputList = this.groupByClient(this.receptionOutputList);
        this.dataLoaded = true;
      });

    this.ReceptionTypeSubscription = this.apiReceptionOutputListService
      .getReceptionType(0)
      .subscribe((dataReception: ClientReceptionType[]) => {
        this.receptionType = dataReception;
        this.dropdownReceptionType = dataReception.map(receptionType => ({
          label: receptionType.rty_name,
          value: receptionType.rty_id
        }));
      })

    this.licences = this.authService.getUserLicences();
  }
  groupByClient(data: any[]): any[] {
    const grouped = data.reduce((acc, item) => {
      const clientId = item.client.id;

      if (!acc[clientId]) {
        acc[clientId] = {
          client: item.client,
          outputs: []
        };
      }

      // Push the current rou_id and other details into the outputs array
      acc[clientId].outputs.push({
        cli_id: item.client.id,
        cpr_name: item.cpr_name,
        cpr_id: item.cpr_id,
        rot_name: item.rot_name,
        rou_name: item.rou_name,
        rou_order: item.rou_order,
        rou_id: item.rou_id,
        rou_cpr_id: item.client.rou_cpr_id,
        rou_rot_id: item.rou_rot_id,
        rou_compulsory: item.rou_compulsory,
        rou_exec_path: item.rou_exec_path
      });

      return acc;
    }, {});
    return Object.values(grouped);
  }

  canWrite(): boolean {
    return this.authService.canWrite();
  }

  updateReceptionOutput(receptionOutputList: ReceptionOutputList) {
    this.router.navigate(['settings/process/process-reception/process-list-reception-output-parameters/', receptionOutputList.cli_id,receptionOutputList.cpr_id]);
  }

  addOutput(rowData: any) {
    rowData.rou_id = -1;
    this.router.navigate(['settings/process/process-reception/process-list-output-reception-add', rowData.client.id],
      { queryParams: { name: rowData.client?.name } });
  }

  updateReceptionParameter(rowData: any) {
    this.router.navigate(['settings/process/process-reception/process-list-reception/', rowData.client.id,rowData.cpr_id]);
  }

  updateOutput(event: any) {
    const rowData = {...event};
    delete rowData.client;

    this.apiReceptionOutputListService.updateOutput(rowData).subscribe((response) => {
      if (response) {
        window.location.reload();
        console.log('Update OK', rowData);
      } else {
        console.log('Update KO', response);
      }
    });
  }

  updateTheProcess(processData: any){
    this.router.navigate(['settings/process/process-reception-edit', processData.cpr_id]);
  }

  deleteOutput(rowData: any) {
    this.confirmationService.confirm({
      message:
        this.translocoService.translate(
          'Confirmez-vous la suppression DEFINITIVE du process'
        ) +
        ' "' +
        rowData.rou_name +
        '" ?',
      header: 'Suppression',
      acceptLabel: this.translocoService.translate('Oui'),
      rejectLabel: this.translocoService.translate('Non'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.apiReceptionOutputListService.deleteOutput(rowData.rou_id).subscribe((response) => {
          if (response) {
            window.location.reload();
            console.log('DEL OK');
          } else {
            console.log('DEL KO');
          }
        });
      },
    });
  }

  ngOnDestroy(): void {
    this.ReceptionOutputListSubscription.unsubscribe();
    this.OutputTypeSubscription.unsubscribe();
    this.ReceptionTypeSubscription.unsubscribe();
    this.ReceptionOutputPathSubscription.unsubscribe();
  }
}
